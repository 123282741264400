<template>
  <f7-page>
    <f7-navbar :title="$t('commons.sinStock')" back-link="Back"></f7-navbar>
    <f7-block strong>
      <p>{{ $t("commons.loSentimos") }}</p>
      <p>{{ $t("commons.noVacs") }}</p>
    </f7-block>
  </f7-page>
</template>
<script>
  export default {};
</script>