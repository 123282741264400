<template>
    <f7-page class="backgoris no-padding-bottom">
        <f7-block class="avatar_panel text-align-center no-margin-vertical padding-vertical ">
            <img :src="'../static/heros/avatar.jpg'" />
            <p class="no-margin-vertical bold text-color-white">
                {{ suprivx.apellido1 ? `${suprivx.apellido1 || ""} ${suprivx.apellido2 || ""}, ${suprivx.nombres || ""}` : $t("leftpanel.identidadNoDeclarada") }}
            </p>
            <p class="no-margin-vertical text-color-white">
                <font-awesome-icon v-if="suprivx.verified" class="text-color-teal" icon="check-circle"></font-awesome-icon>
                {{ user.email || $t("leftpanel.configureEmail") }}
            </p>
            <f7-badge v-if="dev" color="red">{{ $t("commons.desarrollo") }}</f7-badge>
        </f7-block>

        <f7-list class="latmenu no-margin-top">
            <f7-list-item
                v-for="menux in filtradoMenu"
                :key="menux.urlx"
                link="#"
                :class="{ active: menux.urlx == activeTab }"
                @click="gotoMenux(menux.urlx)"
                panel-close
                :title="menux.title"
                :badge="menux.badge ? '4' : null"
                badge-color="red"
                :footer="menux.footer"
            >
                <i slot="media" :class="`fas fa-lg fa-${menux.ico}`"></i>
                <font-awesome-icon icon="exclamation-triangle" class="text-color-red fa-lg" slot="after" v-if="(menux.chkConflict && tieneConflicto) || menux.conflict" />
            </f7-list-item>
        </f7-list>

        <language-selector select-color="white"></language-selector>

        <f7-block class="version_latx no-margin-vertical">MeVacuno {{ appVersion }}</f7-block>
        <f7-block class="no-margin-top"><f7-button @click="logmeout()" color="gray" outline>{{ $t("commons.cerrarSesion") }}</f7-button></f7-block>
        <f7-block class="text-align-center">
            <img @click="promptme()" src="../static/logos/mevacuno_neo.png" class="mevacnpng" />
        </f7-block>
        <f7-block class="text-align-center">
            <img src="../static/logos/gobsmall.jpg" class="goblogo-menu" />
        </f7-block>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { commonmixin } from "../mixins/common";
import LanguageSelector from "@/components/LanguageSelector.vue";
export default {
    mixins: [commonmixin],

    components: { LanguageSelector },

    data() {
        return {
            db: 0,
            pr: 0,
            progressdialog: null, //dialogo de proceso de avance del upload
            task: null, //tarea de upload de storage
            profile_preAvatarLoading: "../static/heros/ripple.gif",
            neofilename: null, //holder para nombre del archivo
        };
    },
    computed: {
        ...mapState(["user", "appVersion", "activeTab", "dev", "internacional", "tieneSolicitudEnConflicto"]),
        ...mapGetters(["tieneConflicto"]),
        suprivx() {
            return this.user.privx || {};
        },
        menu() {
            return [
                {
                    urlx: "/perfil/",
                    title: this.$t("leftpanel.miPerfil"),
                    footer: this.$t("leftpanel.identidadYContacto"),
                    ico: "user-circle",
                    chkConflict: true,
                },
                /* {
                    urlx: "/campanas/",
                    title: this.$t("leftpanel.misCampanas"),
                    footer: this.$t("leftpanel.declaracionIntencion"),
                    ico: "star",
                }, */
                {
                    urlx: "/agenda/",
                    title: this.$t("leftpanel.miAgenda"),
                    footer: this.$t("commons.gestioneHoras"),
                    ico: "calendar-day",
                },
                {
                    urlx: "/carnet/",
                    title: this.$t("leftpanel.misVacunas"),
                    footer: this.$t("leftpanel.comprobanteVacunacion"),
                    ico: "syringe",
                },
                // {
                //     urlx: "/homologacion/",
                //     title: this.$t("leftpanel.homologa"),
                //     footer: this.$t("leftpanel.vacunasextranjero"),
                //     ico: "globe-americas",
                //     conflict: this.tieneSolicitudEnConflicto,
                // },
                {
                    urlx: "/solicitudes/",
                    title: this.$t("leftpanel.solicitudes"),
                    footer: this.$t("leftpanel.validacionVacunas"),
                    ico: "check-circle"
                },
                {
                    urlx: "/encuestas/",
                    title: this.$t("leftpanel.misEncuestas"),
                    footer: this.$t("leftpanel.efectosAdversos"),
                    ico: "tasks",
                    // badge:'encosts'
                },
                {
                    urlx: "/about/",
                    title: this.$t("leftpanel.about"),
                    footer: window.location.hostname,
                    ico: "mobile-alt",
                }
                // {
                //   urlx:'/terceros/',
                //   title:'Terceros',
                //   footer:'Agendar a terceros',
                //   ico:'users',
                // }
            ];
        },

        menuInter() {
            return [
                {
                    urlx: "/perfil/",
                    title: this.$t("leftpanel.miPerfil"),
                    footer: this.$t("leftpanel.identidadYContacto"),
                    ico: "user-circle",
                    chkConflict: true,
                },
                {
                    urlx: "/carnetinter/",
                    title: this.$t("leftpanel.misVacunas"),
                    footer: this.$t("leftpanel.comprobanteVacunacion"),
                    ico: "syringe",
                },
                {
                    urlx: "/about/",
                    title: this.$t("leftpanel.about"),
                    footer: window.location.hostname,
                    ico: "mobile-alt",
                }
            ]
        },

        filtradoMenu() {
            if (this.internacional) {
                return this.menuInter;
            } else {
                return this.menu;
            }
        },
    },
    methods: {
        promptme() {
            let aho = Date.now();
            if (this.db >= 8 && aho - this.pr < 2000) {
                return this.$f7.dialog.prompt("clp", async clp => {
                    try {
                        this.$f7.dialog.preloader("Attempting");
                        let fxtocall = this.$firebase.functions().httpsCallable("admin-clp");
                        let daouts = await fxtocall({ dev: this.$store.state.dev, clp: clp });
                        if (daouts.data.error) {
                            throw new Error(daouts.data.error.message);
                        }
                        await this.$firebase.auth().signInWithCustomToken(daouts.data.payload);
                        window.location.reload();
                        // this.$f7.dialog.close();
                    } catch (error) {
                        this.$f7.dialog.close();
                        console.log(error, "error logging out");
                        this.$f7.dialog.alert(error.message, error.code);
                    }
                });
            } else if (aho - this.pr < 2000) {
                this.db++;
            } else {
                this.db = 0;
            }
            this.pr = aho;
        },
        async gotoMenux(urlx) {
            this.$store.commit("setWhatTo", { what: "activeTab", to: urlx });
            if (urlx == "/campanas/") {
                this.$store.commit("setWhatTo", { what: "agendando_a_tercero", to: null });
            }
            this.$f7.views.main.router.navigate(urlx);
        },
        logmeout() {
            console.log("Sign-out attempt");
            this.$store.commit("setWhatTo", { what: "cerrandoSesion", to: true });
            localStorage.removeItem("inter");
            return this.$firebase
                .auth()
                .signOut()
                .then(() => {
                    console.log("Sign-out successful");

                    let cualCallBack = `https://${window.location.hostname}`;
                    window.location.href = `https://accounts.claveunica.gob.cl/api/v1/accounts/app/logout?redirect=${encodeURIComponent(
                        cualCallBack
                    )}`;
                    setTimeout(() => {
                        this.$store.commit("setWhatTo", { what: "chao", to: true });
                        window.location.reload(true);
                    }, 2000);
                })
                .catch(error => {
                    console.log(error, "error logging out");
                    this.$f7.dialog.alert(error.message, error.code);
                });
        },
    },
};
</script>
<style>
.avatar_panel,
.backgoris {
    background: #001e27;
}
.avatar_panel {
    width: 100%;
    margin-top: 0px;
}
.avatar_panel img {
    border-radius: 50%;
    width: 50%;
    border: 4px solid white;
}
.latmenu .item-media svg {
    margin: 0 auto;
}
.latmenu li {
    background: #062d3a;
    color: #fff;
}
.latmenu li .item-footer {
    color: #b9b9b9;
}
.latmenu li .item-media {
    color: #3fb0bd;
}
.latmenu li.active {
    background: #ffffff;
    color: #062d3a;
}
.latmenu li.active .item-title {
    font-weight: bold;
}
.latmenu li.active .item-media {
    color: #062d3a;
}
.latmenu li.active .item-footer {
    color: #062d3a;
}
.version_latx {
    color: #3fafbd;
}
.mevacnpng {
    width: 80%;
    max-width: 80%;
}
#carnet-avatar-input {
    display: none;
}
.goblogo-menu {
    width: 125px;
}
</style>
