<template>
    <f7-page>
        <f7-navbar :subtitle="$t('respSeguim.formEsavi')" :title="formulario.name">
            <f7-nav-right>
                <f7-link popup-close>{{ $t("commons.cancelar") }}</f7-link>
            </f7-nav-right>
        </f7-navbar>
        <f7-list class="evlado no-margin-top">
            <f7-list-item :header="$t('commons.seguimientoPara')" :footer="getMyidentityFromPaxId(smt.a)" :title="smt.b">
                <img src="../static/heros/avatar.jpg" slot="media" />
            </f7-list-item>
        </f7-list>
        <f7-block v-html="$sanitize(formulario.instrucciones)" class="text-align-center"> </f7-block>
        <div v-for="preg in laspreguntas" :key="preg.id">
            <f7-card v-if="!preg.depde || canBeShown(preg.depde)">
                <f7-card-header class="bold"><span v-html="$sanitize(preg.encabezado)"></span> {{ preg.req ? "*" : "" }}</f7-card-header>
                <f7-card-content>
                    <f7-list class="nowhitespacetitle" v-if="preg.tipo == 'elige_many'">
                        <f7-list-item
                            v-for="alt in preg.alternativas"
                            @change="selectionaOption(preg.id, alt.id, $event.target.checked)"
                            :checked="myanswers[preg.id] && myanswers[preg.id][alt.id]"
                            :key="alt.id"
                            checkbox
                            :value="alt.id"
                            :title="alt.tx"
                        ></f7-list-item>
                    </f7-list>
                    <f7-list class="nowhitespacetitle" v-if="preg.tipo == 'elige_una'">
                        <f7-list-item
                            v-for="alt in preg.alternativas"
                            :name="`elradio_${preg.id}`"
                            @change="setprimitive(preg.id, alt.id)"
                            :checked="myanswers[preg.id] == alt.id"
                            :key="alt.id"
                            radio
                            :value="alt.id"
                            :title="alt.tx"
                        ></f7-list-item>
                    </f7-list>
                    <f7-list v-else>
                        <f7-list-input
                            v-if="preg.tipo == 'input_fecha'"
                            type="datepicker"
                            label="Indique fecha"
                            outline
                            placeholder=""
                            @change="setDate(preg.id, $event.target.value)"
                            readonly
                            :calendar-params="{
                                dateFormat: 'dd/mm/yyyy',
                                value: [],
                                disabled:
                                    preg.rangemin && preg.rangemax
                                        ? [
                                              {
                                                  to: $dayjs(preg.rangemin)
                                                      .subtract(1, 'day')
                                                      .toDate(),
                                              },
                                              {
                                                  from: $dayjs(preg.rangemax)
                                                      .add(1, 'day')
                                                      .toDate(),
                                              },
                                          ]
                                        : preg.rangemin
                                        ? [
                                              {
                                                  to: $dayjs(preg.rangemin)
                                                      .subtract(1, 'day')
                                                      .toDate(),
                                              },
                                          ]
                                        : preg.rangemax
                                        ? [
                                              {
                                                  from: $dayjs(preg.rangemax)
                                                      .add(1, 'day')
                                                      .toDate(),
                                              },
                                          ]
                                        : [],
                                locale: 'es',
                                closeOnSelect: true,
                            }"
                        >
                        </f7-list-input>
                        <f7-list-input
                            v-if="preg.tipo == 'input_numero'"
                            label="Valor"
                            type="number"
                            outline
                            :value="myanswers[preg.id]"
                            :min="preg.rangemin != null ? preg.rangemin : null"
                            :max="preg.rangemax != null ? preg.rangemax : null"
                            @input="setprimitive(preg.id, $event.target.value)"
                            validate
                            clear-button
                        >
                        </f7-list-input>
                        <f7-list-input
                            v-if="preg.tipo == 'input_texto'"
                            label="Valor"
                            :type="preg.txtarea ? 'textarea' : 'text'"
                            outline
                            :value="myanswers[preg.id]"
                            @input="setprimitive(preg.id, $event.target.value)"
                            clear-button
                        >
                        </f7-list-input>
                    </f7-list>
                </f7-card-content>
            </f7-card>
        </div>

        <f7-block><f7-button @click="respondeEsavi()" fill large raised color="teal">Enviar respuestas</f7-button></f7-block>
    </f7-page>
</template>
<script>
import { commonmixin } from "../mixins/common";

export default {
    mixins: [commonmixin],
    props: ["smt"],
    data() {
        return {
            myanswers: {},
        };
    },
    computed: {
        formulario() {
            return this.$store.state.forms[this.smt.f];
        },
        laspreguntas() {
            return Object.values(this.formulario.preguntas)
                .filter(unapreg => {
                    return !unapreg.disabled;
                })
                .map(unapreg => {
                    let alts = unapreg.alternativas || {};
                    let neoalts = {};
                    Object.values(alts).forEach(unalt => {
                        if (!unalt.inct) {
                            neoalts[unalt.id] = unalt;
                        }
                    });
                    unapreg.alternativas = neoalts;
                    return unapreg;
                })
                .sort((a, b) => {
                    if (a.orden > b.orden) {
                        return 1;
                    } else if (a.orden < b.orden) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
        },
    },
    methods: {
        async respondeEsavi() {
            try {
                //check required
                let culaesonvisibles = this.laspreguntas.filter(unapreg => {
                    return !unapreg.depde || this.canBeShown(unapreg.depde);
                });
                // console.log('estas son visibles',culaesonvisibles);
                let faltaUnaRequerida = culaesonvisibles.some(unapreg => {
                    return unapreg.req && this.myanswers[unapreg.id] == null;
                });
                if (faltaUnaRequerida) {
                    throw new Error(this.$t("errors.debeResponderTodas"));
                }
                //console.log('enviando ',this.myanswers)
                this.$f7.dialog.preloader(this.$t("respSeguim.guardandoResp"));
                let datous = await this.commonExecute(
                    { formid: this.smt.f, segid: this.smt.id, uid: this.smt.u, dt: this.smt.dt, answs: this.myanswers },
                    "mevacuno-respEsavi",
                    true
                );
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                //  console.log('sus respuesats',datous.payload);
                this.$f7.dialog.close();
                this.$emit("respondeok", this.smt.id);
                this.avisaOk("Respuestas enviadas");
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
        selectionaOption(pregid, altid, checked) {
            if (!this.myanswers[pregid]) {
                this.$set(this.myanswers, pregid, {});
            }
            this.$set(this.myanswers[pregid], altid, checked);
        },

        setDate(pregid, value) {
            if (value) {
                // console.log('seteando fecha a',value);
                let fechfix = this.$dayjs(value, "DD/MM/YYYY").format("YYYYMMDD");
                this.$set(this.myanswers, pregid, fechfix);
            }
        },
        setprimitive(pregid, value) {
            this.$set(this.myanswers, pregid, value);
        },
        canBeShown(depde) {
            if (!this.myanswers[depde.pregid]) {
                return false;
            } else {
                //evaluar
                if (depde.tipo == "any_value") {
                    return this.myanswers[depde.pregid];
                } else if (depde.tipo == "contiene") {
                    let myanserMin = this.myanswers[depde.pregid].toLowerCase();
                    let tosearch = depde.contiene.toLowerCase();
                    return myanserMin.includes(tosearch);
                } else if (depde.tipo == "elige_al_menos_estas_ids") {
                    let arregl = depde.elige_al_menos_estas_ids;
                    return arregl.every(unaltidrequerida => {
                        return this.myanswers[depde.pregid][unaltidrequerida];
                    });
                } else if (depde.tipo == "elige_exactamente_estas_ids") {
                    let arregl = depde.elige_exactamente_estas_ids;
                    let incluyeesas = arregl.every(unaltidrequerida => {
                        return this.myanswers[depde.pregid][unaltidrequerida];
                    });
                    return (
                        Object.values(this.myanswers[depde.pregid]).filter(unas => {
                            return unas;
                        }).length == arregl.length && incluyeesas
                    );
                } else if (depde.tipo == "equal_to") {
                    return this.myanswers[depde.pregid] == depde.equal_to;
                } else if (depde.tipo == "equal_to_optionid") {
                    return this.myanswers[depde.pregid] == depde.equal_to_optionid;
                } else if (depde.tipo == "mayor_que") {
                    return this.myanswers[depde.pregid] != "Invalid Date" && parseFloat(this.myanswers[depde.pregid]) > parseFloat(depde.mayor_que);
                } else if (depde.tipo == "menor_que") {
                    return this.myanswers[depde.pregid] != "Invalid Date" && parseFloat(this.myanswers[depde.pregid]) < parseFloat(depde.menor_que);
                } else {
                    return true;
                }
            }
        },
    },
};
</script>
<style></style>
