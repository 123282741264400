<template>
    <f7-page @page:beforein="getSolicitudes()" name="homologacion-index">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title :title="$t('homologacionIndex.navTitle')" :subtitle="$t('homologacionIndex.navSubtitle')"></f7-nav-title>
            <f7-nav-right v-if="privxInfo.verified">
                <f7-link @click="getMySolicitudes" icon-material="refresh" :tooltip="$t('homologacionIndex.refresh')"></f7-link>
            </f7-nav-right>
        </f7-navbar>
        <f7-block class="text-align-left" v-if="(!privxInfo.fechanac || !privxInfo.nac_iso2) && privxInfo.verified"
            >{{ $t("commons.debeVerificar") }}"<font-awesome-icon icon="user-circle" /> <b>{{ $t("leftpanel.miPerfil") }}</b
            >" {{ $t("commons.antesDeConsultarCampana") }}</f7-block
        >
        <f7-block class="text-align-left" v-else-if="privxInfo.conflicto !== undefined"
            >{{ $t("commons.debeResolverConflicto") }}"<font-awesome-icon icon="user-circle" /> <b>{{ $t("leftpanel.miPerfil") }}</b
            >" {{ $t("commons.antesDeConsultarCampana") }}</f7-block
        >

        <f7-block v-else-if="!privxInfo.verified && (!tieneSolicitudPendiente || !tieneSolicitudPendiente.wh || tieneSolicitudPendiente.rjt)">
            {{ $t("homologacionIndex.debeValidarIdentidad") }}"<font-awesome-icon icon="user-circle" /> <b>{{ $t("leftpanel.miPerfil") }}</b
            >".
        </f7-block>

        <template v-else>
            <f7-block class="text-align-center padding" v-if="loading">
                <f7-preloader></f7-preloader>
            </f7-block>
            <f7-block v-else-if="solicitudActiva">
                <f7-button v-if="candidatoQRTemporal" @click="openQR" href="#" fill raised color="blue"
                    ><font-awesome-icon icon="qrcode" class="fa-lg"></font-awesome-icon> {{ $t("homologacionIndex.qrTemporal") }}
                </f7-button>
                <f7-block-title class="no-margin-horizontal">{{ $t("homologacionIndex.enCurso") }}</f7-block-title>
                <f7-list media-list inset class="margin-bottom-half fixfamarg no-margin-horizontal">
                    <f7-list-item
                        :header="$t('homologacionIndex.ingresadaEl', { date: $d($dayjs.unix(solicitudActiva.wh), 'twoDigitsWithTime') })"
                        link="#"
                        @click="entraAsolicitud(solicitudActiva)"
                    >
                        <font-awesome-icon
                            v-if="solicitudActiva.est === 'En edición'"
                            slot="media"
                            class="fa-2x text-color-blue margin-top-half"
                            icon="pen-square"
                        ></font-awesome-icon>
                        <font-awesome-icon
                            v-else-if="solicitudActiva.est === 'En espera'"
                            slot="media"
                            class="fa-2x text-color-primary margin-top-half"
                            icon="clock"
                        ></font-awesome-icon>
                        <font-awesome-icon
                            v-else-if="solicitudActiva.est === 'En conflicto'"
                            slot="media"
                            class="fa-2x text-color-orange margin-top-half"
                            icon="exclamation-circle"
                        ></font-awesome-icon>
                        <font-awesome-icon
                            v-else-if="solicitudActiva.est === 'En revisión'"
                            slot="media"
                            class="fa-2x text-color-blue margin-top-half"
                            icon="eye"
                        ></font-awesome-icon>
                        <b slot="title">
                            {{ $t("homologacionIndex.vacunasDeclaradas", { counter: Object.values(solicitudActiva.vacs || {}).length }) }}
                        </b>
                        <div slot="footer" class="display-flex align-items-center">
                            <div v-if="solicitudActiva.est === 'En conflicto'" class="dot margin-right-half"></div>
                            <span :class="getVacunasRechazadasCount(solicitudActiva) ? 'bold' : ''">
                                {{ getSolicitudStatusText(solicitudActiva) }}
                            </span>
                        </div>
                        <div slot="footer">
                            {{ getSolicitudEstTranslate(solicitudActiva.est) }}
                            <span v-if="solicitudActiva.est === 'En edición'">
                                &bull;
                                <b>{{ $t("homologacionIndex.enEdicionRemember") }}</b>
                            </span>
                        </div>
                        <span slot="after">{{ $t("homologacionIndex.viewAndEdit") }}</span>
                    </f7-list-item>
                </f7-list>
            </f7-block>
            <f7-block v-else>
                {{ $t("homologacionIndex.noTieneSolicitudEnCurso") }}
                <f7-button @click="entraAsolicitud(null)" fill class="margin-top">
                    {{ $t("homologacionIndex.crearSolicitudHomologacion") }}
                </f7-button>
            </f7-block>
        </template>

        <template v-if="filterSolicitudesAceptadas.length && privxInfo.fechanac && privxInfo.verified">
            <f7-block>
                <f7-block-title class="no-margin-horizontal">{{ $t("homologacionIndex.solicitudesPasadas") }}</f7-block-title>
                <f7-list media-list>
                    <f7-list-item
                        v-for="solicitud in filterSolicitudesAceptadas"
                        :key="solicitud.id"
                        :header="$t('homologacionIndex.ingresadaEl', { date: $d($dayjs.unix(solicitud.wh), 'twoDigitsWithTime') })"
                        :footer="getSolicitudStatusText(solicitud)"
                        link="#"
                        @click="entraAsolicitud(solicitud, true)"
                    >
                        <font-awesome-icon slot="media" class="fa-2x margin-top-half" icon="check-circle" style="color: #04d90f;"></font-awesome-icon>
                        <b slot="title">
                            {{
                                $t("homologacionIndex.vacunasAprobadas", {
                                    counter: `${getVacunasAceptadasCount(solicitud)}/${Object.values(solicitud.vacs).length}`,
                                })
                            }}
                        </b>
                        <span slot="after">{{ $t("homologacionIndex.verDetalles") }}</span>
                    </f7-list-item>
                </f7-list>
            </f7-block>
        </template>

        <template v-if="filterSolicitudesCerradas.length && privxInfo.fechanac && privxInfo.verified">
            <f7-block>
                <f7-block-title class="no-margin-horizontal">{{ $t("homologacionIndex.solicitudesCerradas") }}</f7-block-title>
                <f7-list media-list>
                    <f7-list-item
                        v-for="solicitud in filterSolicitudesCerradas"
                        :key="solicitud.id"
                        :header="$t('homologacionIndex.ingresadaEl', { date: $d($dayjs.unix(solicitud.wh), 'twoDigitsWithTime') })"
                        :footer="getSolicitudStatusText(solicitud)"
                        link="#"
                        @click="entraAsolicitud(solicitud, true)"
                    >
                        <font-awesome-icon slot="media" class="fa-2x margin-top-half" icon="times-circle" style="color: gray;"></font-awesome-icon>
                        <b slot="title">
                            {{
                                $tc("homologacionIndex.vacunaAprobada", getVacunasAceptadasCount(solicitud), {
                                    counter: getVacunasAceptadasCount(solicitud),
                                })
                            }}
                        </b>
                        <span slot="after">{{ $t("homologacionIndex.verDetalles") }}</span>
                    </f7-list-item>
                </f7-list>
            </f7-block>
        </template>
        <f7-popup class="qr-popup" :opened="qrvisoropened" @popup:closed="closeviewer()">
            <qrtemp v-if="passforvisor" :camp="passforvisor"></qrtemp>
        </f7-popup>
    </f7-page>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import qrtemp from "../pages/qr_temp";
import { commonmixin } from "../mixins/common";

export default {
    components: {
        qrtemp,
    },
    mixins: [commonmixin],
    data() {
        return {
            loading: false,
            misSolicitudes: [],
            qrtemp: true,
            qrvisoropened: false,
            passforvisor: null,
        };
    },

    computed: {
        ...mapState(["tieneSolicitudPendiente"]),
        ...mapGetters(["privxInfo", "misVacunas"]),

        solicitudActiva() {
            return this.misSolicitudes.find(solicitud => solicitud.est != "Aceptada" && solicitud.est != "Cerrada");
        },
        filterSolicitudesAceptadas() {
            return Object.values(this.misSolicitudes || {})
                .filter(solicitud => solicitud.est === "Aceptada")
                .sort((a, b) => b.wh - a.wh);
        },
        filterSolicitudesCerradas() {
            return Object.values(this.misSolicitudes || {})
                .filter(solicitud => solicitud.est === "Cerrada")
                .sort((a, b) => b.wh - a.wh);
        },
        filterSolicitudesConflicto() {
            return Object.values(this.misSolicitudes || {})
                .filter(solicitud => solicitud.est === "En conflicto")
                .sort((a, b) => b.wh - a.wh);
        },
        filterSolicitudesEspera() {
            return Object.values(this.misSolicitudes || {})
                .filter(solicitud => solicitud.est === "En espera")
                .sort((a, b) => b.wh - a.wh);
        },
        candidatoQRTemporal() {
            if (this.passforvisor) {
                return true;
            }
            return false;
        },
    },

    methods: {
        async getSolicitudes() {
            try {
                if (this.privxInfo.verified) {
                    if ((!this.privxInfo.fechanac || !this.privxInfo.nac_iso2) && this.privxInfo.verified) {
                        let error = "PROFILE_INCOMPLETE";
                        throw error;
                    }
                    let dbtrxs = this.$firebase_coreapp.database(this.$store.getters.usrtrxDatabaseUrl);
                    this.loading = true;
                    let snphot = await dbtrxs.ref(`solicit_verif_open/${this.$store.state.dev ? "d" : "p"}/${this.privxInfo.id}`).once("value");
                    this.solicitudPrevia = snphot.val();
                    this.$store.commit("setWhatTo", { what: "tieneSolicitudPendiente", to: this.solicitudPrevia });

                    // console.log(this.solicitudPrevia, "solicitudPrevia");
                    this.loading = false;
                }
                this.getMySolicitudes();
            } catch (error) {
                this.loading = false;
                console.log(error);
                if (error == "PROFILE_INCOMPLETE") {
                    this.$f7.dialog.alert(
                        this.$t("commons.debeCompletarFechaNacimiento") + this.$t("leftpanel.miPerfil"),
                        this.$t("commons.permissionDenied")
                    );
                } else {
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            }
        },
        async getMySolicitudes() {
            if (this.privxInfo.verified || (this.tieneSolicitudPendiente && this.tieneSolicitudPendiente.wh && !this.tieneSolicitudPendiente.rjt)) {
                try {
                    this.loading = true;
                    let datous = await this.commonExecute({}, "mevacuno-getMisSolicitudesHomologacion", false);
                    if (datous.error) {
                        throw new Error(datous.error.message || datous.error);
                    }
                    this.misSolicitudes = datous.payload || [];
                    this.getQRTem();
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(this.$t("homologacionIndex.errorFetchSolicitudes"), error);
                    console.error(error);
                }
            }
        },
        async getQRTem() {
            this.passforvisor = null;
            try {
                //cargo la info para el qr temporal si es que aplica
                let acep = Object.values(this.misSolicitudes || {})
                    .filter(solicitud => solicitud.est === "Aceptada")
                    .sort((a, b) => b.wh - a.wh);
                let cerr = Object.values(this.misSolicitudes || {})
                    .filter(solicitud => solicitud.est === "Cerrada")
                    .sort((a, b) => b.wh - a.wh);
                let conf = Object.values(this.misSolicitudes || {})
                    .filter(solicitud => solicitud.est === "En conflicto")
                    .sort((a, b) => b.wh - a.wh);
                let soli = Object.values(this.misSolicitudes || {})
                    .filter(solicitud => solicitud.est === "En espera")
                    .sort((a, b) => b.wh - a.wh);
                if (soli.length && !acep.length && !cerr.length && !conf.length) {
                    let now = this.$dayjs();
                    let envio = this.$dayjs.unix(soli[0].last_sent_at);
                    let diff = now.diff(envio, "hour", true);
                    let creation = this.$dayjs.unix(soli[0].wh);
                    let diffCreateEnvio = envio.diff(creation, "hour", true);
                    if (!soli[0].last_resol_at && diff > 0 && diff < 360 && diffCreateEnvio <= 48) {
                        let pat = {};
                        let verif = false;
                        if (this.privxInfo.verified) {
                            pat = this.privxInfo;
                            pat.pid = `${this.privxInfo.idenType}_${this.privxInfo.identity}`;
                            verif = true;
                        } else {
                            pat = soli[0].pat;
                        }
                        let temp = await this.commonExecute({ dev: this.$store.state.dev, pat: pat, verif: verif }, "mevacuno-genera_qr_temp", false);
                        let payload = temp ? temp.payload : {};
                        if (payload.cond) {
                            this.passforvisor = payload;
                        }
                    }
                }
            } catch (error) {
                console.error(error);
            }
        },
        async view(solicitud) {
            let url = "/homologacion/create/";

            if (solicitud.est === "Aceptada") {
                url = "/homologacion/view/";
            }

            this.navTo(url);
        },
        async entraAsolicitud(solicitud, viewOnly = false) {
            try {
                if (!solicitud) {
                    this.$f7.dialog.preloader(solicitud ? this.$t("commons.cargando") : this.$t("commons.creando"));
                    let datous = await this.commonExecute({ solid: null }, "mevacuno-cargaSolicitudOrCreaNueva", false);
                    if (datous.error) {
                        throw new Error(datous.error.message || datous.error);
                    }
                    console.log("resposta", datous);
                    solicitud = datous.payload;
                    this.$f7.dialog.close();
                }

                this.$f7.views.main.router.navigate("/homologacion/create/", {
                    props: { sol: solicitud, viewOnly },
                });
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(this.$t("homologacionIndex.errorFetchSolicitudes"), error.message);
            }
        },
        navTo(url) {
            // this.$store.commit("setWhatTo", { what: "activeTab", to: url });
            this.$f7.views.main.router.navigate(url);
        },

        getSolicitudStatusText(solicitud) {
            switch (solicitud.est) {
                case "En espera":
                    return this.$t("homologacionIndex.enEsperaText");

                case "Aceptada":
                    return this.$t("homologacionIndex.todasLasVacunasAprobadas", {
                        date: this.$d(this.getUltimaResolucionDate(solicitud.vacs), "twoDigitsWithTime"),
                    });

                case "En conflicto":
                    const rechazadasCount = this.getVacunasRechazadasCount(solicitud);

                    if (rechazadasCount > 1) {
                        return this.$t("homologacionIndex.vacunasFueronRechazadas", { number: rechazadasCount });
                    } else {
                        return this.$t("homologacionIndex.unaVacunaRechazada");
                    }

                case "Cerrada":
                    return this.$t("homologacionIndex.cerrada");

                default:
                    return "";
            }
        },
        getVacunasAceptadasCount(solicitud) {
            return Object.values(solicitud.vacs || {}).filter(vacuna => vacuna.est && vacuna.est.resol === "Aceptada").length;
        },
        getVacunasRechazadasCount(solicitud) {
            return Object.values(solicitud.vacs || {}).filter(vacuna => vacuna.est && vacuna.est.resol === "Rechazada").length;
        },
        getUltimaResolucionDate(vacs) {
            const vacunas = Object.values(vacs || {});

            const ultimaVacuna = vacunas.sort((a, b) => {
                if (a.est && a.est.resol && b.est && b.est.resol) {
                    return a.est.when - b.est.when;
                }

                return 0;
            })[vacunas.length - 1];

            return this.$dayjs.unix(ultimaVacuna.est.when);
        },
        getSolicitudEstTranslate(est) {
            const translations = {
                "En espera": this.$t("homologacionIndex.enEspera"),
                "En edición": this.$t("homologacionIndex.enEdicion"),
                "En conflicto": this.$t("homologacionIndex.enConflicto"),
                "En revisión": this.$t("homologacionIndex.enRevision"),
                Aceptada: this.$t("homologacionCreate.aceptada"),
            };

            return translations[est] || est;
        },
        openQR() {
            this.qrvisoropened = true;
        },
        closeviewer() {
            this.qrvisoropened = false;
        },
    },
};
</script>
<style scoped>
.dot {
    height: 4px;
    width: 4px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
}
</style>
