import HomePage from '../pages/home.vue';
import Perfil from '../pages/perfil.vue';
import Paises from '../pages/paises.vue';
import Agenda from '../pages/agenda.vue';
import Carnet from '../pages/carnet.vue';
import CarnetInter from '../pages/carnet_inter.vue';
import Encuestas from '../pages/encuestas.vue';
import NotFoundPage from '../pages/404.vue';
import Nodisp from '../pages/no_disp.vue'
import EligeCentro from '../pages/eligecentro.vue'
import Campanas from '../pages/campanas.vue'
import Terceros from '../pages/terceros.vue'
import About from '../pages/about.vue'
import miesquema from '../pages/campaña_miesquema.vue'
import SolicitarVerificacion from '../pages/solicitar-verificacion.vue';
import HomologacionIndex from '../pages/homologacion-index.vue';
import HomologacionCreate from '../pages/homologacion-create.vue';
import HomologacionView from '../pages/homologacion-view.vue';
import DeclaraIdentidad from '../pages/declara-identidad.vue';
import C19 from '../pages/c19.vue';
import SolicitudesMain from '../pages/solicitudes/solicitudes-main.vue';
import SolicitudVacunaInter from '../pages/solicitudes/vacunas-inter-create.vue';
import SolicitudVacunaNac from '../pages/solicitudes/vacunas-nac-create.vue';


var routes = [{
        path: '/',
        component: HomePage,
    },
    {
        path: '/campanas/',
        component: Campanas,
    },
    {
        path: '/about/',
        component: About,
    },
    {
        path: '/terceros/',
        component: Terceros,
    },
    {
        path: '/miesquema/',
        component: miesquema,
    },
    {
        path: '/eligecentro/',
        component: EligeCentro,
    },
    {
        path: '/perfil/',
        component: Perfil,
    },

    {
        path: '/nodisp/',
        component: Nodisp,
    },
    {
        path: '/solicitar-verificacion/',
        component: SolicitarVerificacion,
    },
    {
        path: '/declara-identidad/',
        component: DeclaraIdentidad,
    },
    {
        path: '/paises/',
        component: Paises,
    },
    {
        path: '/agenda/',
        component: Agenda,
    },
    {
        path: '/carnet/',
        component: Carnet,
    },
    {
        path: '/carnetinter/',
        component: CarnetInter,
    },
    {
        path: '/encuestas/',
        component: Encuestas,
    },
    {
        path: '/homologacion/',
        component: HomologacionIndex,
    },
    {
        path: '/homologacion/create/',
        component: HomologacionCreate,
    },
    {
        path: '/homologacion/view/',
        component: HomologacionView,
    },
    {
        path: '/solicitudes/',
        component: SolicitudesMain,
    },
    {
        path: '/solicitudes/vacunas-inter/',
        component: SolicitudVacunaInter,
    },
    {
        path: '/solicitudes/vacunas-nac/',
        component: SolicitudVacunaNac,
    },
    {
        path: '/c19/',
        component: C19,
    },
    {
        path: '(.*)',
        component: NotFoundPage,
    },
];

export default routes;