<template>
    <f7-card>
        <f7-card-content>
            <f7-list media-list>
                <f7-list-item class="horalarge titlebold">
                    <span slot="title"><font-awesome-icon icon="clock"></font-awesome-icon> {{ cita.hin }}</span>
                    <span slot="header" :class="{ 'skeleton-text skeleton-effect-blink': !forinfo }"
                        >Para
                        <b class="text-color-purple">{{
                            forinfo ? `${forinfo.nombres} ${forinfo.apellido1}` : "Barbara Alejandra Lara Hernandez"
                        }}</b></span
                    >
                    <div slot="media" class="text-align-center">
                        <div class="mediafech">
                            <div class="grnd">{{ $dayjs(cita.fch).format("D") }}</div>
                            <div class="chix bold">{{ $dayjs(cita.fch).format("MMM") }}</div>
                            <div>{{ $dayjs(cita.fch).format("YYYY") }}</div>
                        </div>
                        <div v-if="cita.cnf" class="confirmaadx text-color-teal">{{ $t("commons.confirmada") }}</div>
                    </div>

                    <span class="text-color-primary bold" :class="{ 'skeleton-text skeleton-effect-blink': !camp_meta }" slot="subtitle">{{
                        camp_meta ? camp_meta.nombre : $t("commons.cargando")
                    }}</span>
                    <span :class="{ 'skeleton-text skeleton-effect-blink': !center_meta }" slot="text">{{
                        center_meta ? `${center_meta.name}, ${center_meta.direccion}` : $t("commons.cargandoVacunatorio")
                    }}</span>
                    <span :class="{ 'skeleton-text skeleton-effect-blink': !center_meta }" slot="footer">{{
                        center_meta ? `${center_meta.address.name}, ${center_meta.address.region}` : $t("commons.cargandoComunaDeVacunatorio")
                    }}</span>
                    <f7-button v-if="center_meta && center_meta.phones" @click="abrePhonos($event)" :class="cita.id" slot="after" fill color="primary"
                        ><font-awesome-icon icon="phone" class="fa-lg"></font-awesome-icon
                    ></f7-button>
                </f7-list-item>
                <f7-list-item
                    v-if="cita.prov"
                    class="provisoria headerbold text-align-center"
                    :header="$t('commons.horaProvisoria')"
                ></f7-list-item>
            </f7-list>
        </f7-card-content>
        <f7-card-footer v-if="!cita.cnf && !sepaso">
            <f7-button v-if="!sepaso && !cita.rex" @click="confirmacanel()" color="red"
                ><font-awesome-icon icon="calendar-times" class="fa-lg"></font-awesome-icon> {{ $t("commons.cancelar") }}</f7-button
            >
            <f7-button @click="attemptConfirm()" :class="{ noaun: !puedeConfirmar }" v-if="!cita.cnf && !cita.rex" fill
                ><font-awesome-icon icon="calendar-check" class="fa-lg"></font-awesome-icon> {{ $t("commons.confirmar") }}</f7-button
            >
        </f7-card-footer>
    </f7-card>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    props: ["cita"],
    data() {
        return {};
    },
    computed: {
        ...mapGetters(["privxInfo"]),
        camp_meta() {
            return this.$store.state.campanas[this.cita.cmp] || null;
        },
        center_meta() {
            return this.$store.state.vacunatorios[this.cita.cid] || null;
        },
        forinfo() {
            if (this.cita.for != this.cita.by) {
                return this.$store.state.misTerceros[this.cita.for] || null;
            } else {
                return this.privxInfo;
            }
        },
        puedeConfirmar() {
            let ahorax = this.$dayjs();
            let horasHasta = this.$dayjs(`${this.cita.fch} ${this.cita.hin}`, "YYYYMMDD HH:mm").diff(ahorax, "h");
            //console.log('horasHasta',horasHasta);
            return horasHasta < 48 && horasHasta >= 0 && !this.cita.prov && !this.cita.rex;
        },
        sepaso() {
            let diastrg = this.$dayjs(`${this.cita.fch} ${this.cita.hin}`, "YYYYMMDD HH:mm").format("YYYY-MM-DD");
            return this.$dayjs().isAfter(`${diastrg} ${this.cita.hin}`, "m");
        },
    },
    methods: {
        attemptConfirm() {
            if (this.cita.prov) {
                return this.$f7.dialog.alert(
                    this.$t("commons.noPuedeConfirmarHora"),
                    this.$t("commons.noConfirmacionStock")
                );
            } else if (this.puedeConfirmar) {
                return this.$emit("confirma", this.cita.id);
            } else {
                return this.$f7.dialog.alert(this.$t("commons.podraConfirmarHoraAqui"), this.$t("commons.noAbiertoConfirmacion"));
            }
        },
        abrePhonos(evento) {
            this.$emit("abrephonos", { targeto: this.cita.id, estosPhones: this.center_meta.phones });
        },
        confirmacanel() {
            return this.$f7.dialog.confirm(this.$t("commons.cancelarHoraConfirm"), this.$t("commons.confirmar"), () => {
                //console.log('confirmò');
                this.$emit("kancela", this.cita.id);
            });
        },
    },
};
</script>
<style>
.confirmaadx {
    font-size: 12px !important;
    font-weight: bold;
}
.horalarge .item-title {
    font-size: 28px;
    line-height: 30px;
    color: #000;
}
.chix {
    font-size: 18px;
    line-height: 13px;
}
.grnd {
    font-size: 30px;
    line-height: 30px;
}
.mediafech {
    background: #efefef;
    width: 72px;
    text-align: center;
    border-radius: 20%;
    padding: 12px 0px !important;
}
.mediafech div {
    width: 100%;
}
.noaun {
    opacity: 0.55 !important;
}
.provisoria {
    background: #ffea97;
}
</style>
