<template>
    <f7-page @page:beforein="guardoNacionalidad()" name="declara-identidad">
      <f7-navbar :title="$t('solicitarVerificacion.verificacionIdentidad')" :subtitle="$t('solicitarVerificacion.enLinea')" back-link="Back">   
      </f7-navbar>

        <f7-list v-if="!mysub.nac" class="margin-vertical">
            <f7-list-item :header="$t('declareIdentity.paisDeNacionalidad')" :title="decl_id_pais == 'XX'?$t('commons.seleccionar') : $store.getters.unPais(decl_id_pais).name" @click="fetchPaisesAndGo()" link="#"
                ><font-awesome-icon icon="pen" slot="after"
            /></f7-list-item>
        </f7-list>
        <f7-block class="no-margin-top" v-if="decl_id_pais != 'CL' && !mysub.num">
            <p>{{ $t('declareIdentity.indiqueTipoDoc') }}</p>
            <f7-segmented raised tag="p">
                <f7-button
                    v-for="identidad in dictIdentities"
                    :key="identidad.id"
                    :active="idenType == identidad.id"
                    @click="moveToOtroIdenType(identidad.id)"
                    >{{ identidad.txt }}</f7-button
                >
            </f7-segmented>
        </f7-block>
        <f7-list>
            <ul>
                <f7-list-input
                    v-if="!mysub.num"
                    class="labelbold"
                    :label="idenType + ' - ' + dictIdentities[idenType].spe"
                    type="text"
                    :placeholder="$t('commons.numeroDoc')"
                    :error-message="faltaEsteInput.rawIdentity ? $t('commons.campoObligatorio') : $t('commons.RUNseemsNotValid')"
                    :error-message-force="rutinvalido || faltaEsteInput.rawIdentity"
                    :value="rawIdentity"
                    @blur="formateaRut()"
                    @input="setvalorYBorrarError('rawIdentity', $event.target.value)"
                    clear-button
                >
                </f7-list-input>
                <f7-list-input
                    v-if="!mysub.nm"
                    :label="$t('commons.nombres')"
                    type="text"
                    :placeholder="$t('commons.nombres')"
                    :value="nombres"
                    @blur="checkEsteOblig('nombres')"
                    :error-message="$t('commons.campoObligatorio')"
                    :error-message-force="faltaEsteInput.nombres"
                    @input="setvalorYBorrarError('nombres', $event.target.value)"
                    clear-button
                >
                </f7-list-input>
                <f7-list-input
                    v-if="!mysub.ap1"
                    :label="$t('commons.primerApellido')"
                    type="text"
                    :placeholder="`${$t('commons.primerApellido')} 1`"
                    :value="apellido1"
                    @blur="checkEsteOblig('apellido1')"
                    :error-message="$t('commons.campoObligatorio')"
                    :error-message-force="faltaEsteInput.apellido1"
                    @input="setvalorYBorrarError('apellido1', $event.target.value)"
                    clear-button
                >
                </f7-list-input>
                <f7-list-input
                    v-if="!mysub.ap2"
                    :label="$t('commons.segundoApellido')"
                    type="text"
                    :placeholder="`${$t('commons.segundoApellido')} 2`"
                    :value="apellido2"
                    @input="apellido2 = $event.target.value"
                    clear-button
                >
                </f7-list-input>
                <f7-list-input
                    v-if="!mysub.sexo"
                    type="select"
                    :value="sexo"
                    :error-message="$t('commons.campoObligatorio')"
                    :error-message-force="faltaEsteInput.sexo"
                    @change="setvalorYBorrarError('sexo', $event.target.value)"
                    :placeholder="`${$t('commons.indiqueSexo')}...`"
                >
                    <option value="">{{ $t('commons.indiqueSexo') }}</option>
                    <option value="F">{{ $t('commons.femenino') }}</option>
                    <option value="M">{{ $t('commons.masculino') }}</option>
                    <option value="Intersex">{{ $t('commons.intersex') }}</option>
                    <span slot="label"
                        >{{ $t('commons.sexo') }}
                        <f7-link
                            :tooltip="$t('declareIdentity.porQueSexoBiologicoTooltip')"
                        >
                            ({{ $t("declareIdentity.porQueSexoBiologico") }})</f7-link
                        ></span
                    >
                </f7-list-input>
                <f7-list-item v-if="!mysub.fechanac" class="inputfechatitlefull" :header="$t('commons.fechaNac')">
                    <f7-row slot="footer">
                        <f7-col width="20">
                            <f7-input type="select" outline class="inputdia" @change="datesplit.dia = $event.target.value" :value="datesplit.dia">
                                <option v-for="dia in 31" :key="dia" :value="dia">{{ dia }}</option>
                            </f7-input>
                        </f7-col>
                        <f7-col width="50">
                            <f7-input type="select" outline class="inputmes" @change="datesplit.mes = $event.target.value" :value="datesplit.mes">
                                <option v-for="(mes, idx) in $dayjs.months()" :key="idx + 1" :value="idx + 1">{{ mes }}</option>
                            </f7-input>
                        </f7-col>
                        <f7-col width="30">
                            <f7-input class="inputano" type="number" outline @input="setfechano($event.target)" :value="datesplit.ano"> </f7-input>
                        </f7-col>
                    </f7-row>
                </f7-list-item>

                <select-comuna :address="address" @newAddress="llegandoAddress"></select-comuna>
            </ul>
        </f7-list>
        <f7-block class="margin-bottom"
            ><f7-button @click="declararIdentidad()" class="margin-bottom" fill raised large
                ><font-awesome-icon class="fa-lg" icon="file-import"></font-awesome-icon>{{ tercero ? $t("commons.continuar") : $t("commons.declararIdentidad") }}</f7-button
            ></f7-block
        >
    </f7-page>
</template>
<script>
import { mapState } from "vuex";
import { validate, format, clean } from "rut.js";
import selectComuna from "../components/selectComuna.vue";

import { commonmixin } from "../mixins/common";

export default {
    mixins: [commonmixin],
    components: { selectComuna },
    props: ["tercero", "mysub"],
    data() {
        return {
            datesplit: {
                ano: "",
                mes: "",
                dia: "",
            },
            idenType: "RUN",
            rawIdentity: null,
            apellido1: null,
            apellido2: null,
            nombres: null,
            address: {
                code: null,
                comuna: null,
                provincia: null,
                region: null,
                region_hr: null,
            },
            sexo: null,
            faltaEsteInput: {
                idenType: null,
                rawIdentity: null,
                apellido1: null,
                nombres: null,
                sexo: null,
            },
        };
    },
    computed: {
        ...mapState(["decl_id_pais"]),
        rutinvalido() {
            if (this.idenType == "RUN" && this.rawIdentity && (this.rawIdentity.length < 6 || !validate(this.rawIdentity))) {
                return true;
            } else {
                return false;
            }
        },

        dictIdentities() {
            return {
                RUN: {
                    id: "RUN",
                    txt: "RUN",
                    spe: this.$t("commons.RUNdescription"),
                },
                DNI: {
                    id: "DNI",
                    txt: "Otro DNI",
                    spe: this.$t("commons.DNIdescription"),
                },
                PAS: {
                    id: "PAS",
                    txt: this.$t("commons.pasaporte"),
                    spe: this.$t("commons.PASdescription"),
                },
            };
        }
    },
    methods: {
        llegandoAddress(newaddress) {
            this.address = Object.assign({}, newaddress);
        },
        setfechano(target) {
            // console.log('setenado')
            this.datesplit.ano = target.value;
            if (this.datesplit.ano > 1900) {
                // console.log('blurenado')
                target.blur();
            }
        },
        setvalorYBorrarError(cual, valor) {
            this[cual] = valor;
            this.faltaEsteInput[cual] = false;
        },
        checkEsteOblig(cual) {
            if (!this[cual]) {
                this.faltaEsteInput[cual] = true;
            } else {
                this.faltaEsteInput[cual] = false;
            }
        },
        moveToOtroIdenType(idenType) {
            this.idenType = idenType;
            this.formateaRut();
        },
        formateaRut() {
            if (this.rawIdentity) {
                if (this.idenType == "RUN") {
                    this.rawIdentity = format(this.rawIdentity);
                } else {
                    this.rawIdentity = clean(this.rawIdentity);
                }
                this.faltaEsteInput.rawIdentity = null;
            } else {
                this.faltaEsteInput.rawIdentity = true;
            }
        },
        fetchPaisesAndGo() {
            if (!this.$store.getters.paisesArraySorted.length) {
                this.$f7.dialog.preloader(this.$t("commons.cargandoPaises"));
                this.$firebase
                    .database()
                    .ref("paises")
                    .once("value")
                    .then(snps => {
                        this.$store.commit("setWhatTo", { what: "paises", to: snps.val() });
                        this.$f7.dialog.close();
                        this.$f7.views.main.router.navigate("/paises/");
                    })
                    .catch(error => {
                        this.$f7.dialog.close();
                        console.log(error);
                        this.$f7.dialog.alert(error.message, error.code);
                    });
            } else {
                this.$f7.views.main.router.navigate("/paises/");
            }
        },
        guardoNacionalidad(){
          if(this.mysub.nac){
            this.$store.commit("setWhatTo", { what: "decl_id_pais", to: this.mysub.nac });
          }
        },
        async declararIdentidad() {
            //check campos requeridos
            let requeridos = ["rawIdentity", "nombres", "apellido1", "sexo"];
            let maptosub = ["num", "nm", "ap1", "sexo"];
            let faltauno = requeridos.some((uncamp, idx) => {
                if (!this[uncamp] && !this.mysub[maptosub[idx]]) {
                    this.faltaEsteInput[uncamp] = true;
                    return true;
                } else {
                    return false;
                }
            });
            if (faltauno) {
                return this.$f7.dialog.alert(this.$t("commons.debeCompletarTodos"));
            }
            let fechax = this.mysub.fechanac;
            if (!this.mysub.fechanac) {
                //check valid fecha nacimiento
                let mesx = parseInt(this.datesplit.mes) < 10 ? `0${this.datesplit.mes}` : this.datesplit.mes;
                let diax = parseInt(this.datesplit.dia) < 10 ? `0${this.datesplit.dia}` : this.datesplit.dia;
                fechax = `${this.datesplit.ano}${mesx}${diax}`;
                if (!this.$dayjs(fechax, "YYYYMMDD").isValid()) {
                    return this.$f7.dialog.alert(this.$t("commons.fechaNacInvalid"));
                }
            }

            if (!this.address.code) {
                return this.$f7.dialog.alert(this.$t("declareIdentity.seleccioneComuna"), this.$t("declareIdentity.ingreseResidencia"));
            }
            //check rut valido
            if (!this.mysub.num && this.rutinvalido) {
                return this.$f7.dialog.alert(this.$t("commons.verifyRUN"), this.$t("commons.invalidRUN"));
            }

            //check edad
            if (!this.tercero && !this.mysub.fechanac) {
                let y = this.$dayjs(fechax);
                let duration = this.$dayjs().diff(y, "year");
                // if(duration<18){
                //   return this.$f7.dialog.alert('Lo sentimos. Solo adultos mayores de 18 años pueden crear una cuenta y declarar su identidad.','Menor de edad');
                // }
            }
            if (this.decl_id_pais === "XX") {
                return this.$f7.dialog.alert(this.$t("solicitarVerificacion.faltaNacionalidad"));
            }
            let haysub = this.mysub.id ? true : false;
            let dataToSend = {
                rawIdentity: this.rawIdentity,
                idenType: this.idenType,
                nac_iso2: this.decl_id_pais,
                nombres: this.nombres,
                apellido1: this.apellido1,
                apellido2: this.apellido2 || "",
                sexo: this.sexo,
                fechanac: fechax,
                code: this.address.code,
            };
            if (haysub) {
                dataToSend.mysub = this.mysub.id;
            }

            // console.log('procediendo a declarar',dataToSend);
            this.$f7.dialog.preloader(this.$t("commons.guardando"));
            await this.$firebase.auth().currentUser.reload();
            let user = this.$firebase.auth().currentUser;
            await user.getIdToken(true);
            let cualend = this.tercero ? "declaraTercero" : haysub ? "declaraIdentidad_mysub_fix" : "declaraIdentidad";
            return this.commonExecute(dataToSend, "mevacuno-" + cualend, !haysub)
                .then(respoesta => {
                    if (respoesta.error) {
                        throw new Error(respoesta.error.message || respoesta.error.code || respoesta.error);
                    }
                    //  console.log('res',respoesta.payload);
                    //guardar en mi PRIVX
                    if (!this.tercero) {
                        this.$store.commit("updateMyPrivx", respoesta.payload);
                        if (!haysub) {
                            let sunamr = this.apellido1 + " " + (this.apellido2 || "") + ", " + this.nombres;
                            this.$store.commit("updateUserInfo", { cual: "displayName", valor: sunamr });
                        }
                        this.$f7.dialog.close();
                        this.$f7.toast.show({
                            text: this.$t("declareIdentity.declaracionRecibidaOK"),
                            closeTimeout: 3500,
                        });
                        this.$emit("declaracionok");
                    } else {
                        this.$f7.dialog.close();
                        //mergeObjTo
                        let lojgs = {};
                        lojgs[respoesta.payload.id] = respoesta.payload;
                        this.$store.commit("mergeObjTo", { what: "misTerceros", to: lojgs });
                        this.$emit("creeTercero", respoesta.payload.id);
                    }
                    this.$f7.views.main.router.navigate('/perfil/');
                })
                .catch(error => {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                });
        },
    },
};
</script>
<style>
.labelbold .item-title.item-label {
    font-weight: bolder;
}
.chicox {
    color: #cecece !important;
}
.inputano input {
    width: 100% !important;
    text-align: center;
}
.inputmes select {
    width: 100% !important;
}
.inputdia select {
    width: 100% !important;
}
.inputfechatitlefull .item-title {
    width: 100% !important;
}
</style>
