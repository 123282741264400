<template>
    <f7-popup
        :opened="opened"
        @popup:opened="init"
        @popup:closed="handleClosePopup"
        :close-by-backdrop-click="false"
    >
        <f7-page>
            <f7-navbar :title="`${editingVacuna.id? $t('commons.editando') : $t('commons.agregando')} ${$t('commons.vacuna')}`">
                <f7-nav-right>
                    <f7-link @click="handleClosePopup">{{ $t('commons.cancelar') }}</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-block v-if="opened">
                <template>
                    <f7-list media-list>
                        <f7-list-item
                            class="margin-vertical"
                            smart-select
                            :smart-select-params="{
                                openIn: 'popup',
                                searchbar: { removeDiacritics: true },
                                searchbarPlaceholder: $t('homologacionCreate.buscarVacuna'),
                                popupCloseLinkText: $t('commons.cerrar'),
                                closeOnSelect: true,
                                setValueText: false,
                            }"
                            :disabled="!sortedVacunasHomologacionNac.length"
                            required
                        >
                            <div slot="header" class="header-style">{{ $t("homologacionCreate.queVacuna") }} <span class="text-color-red">*</span></div>
                            <div slot="inner">{{ editingVacuna.vac.name || "" }}</div>
                            <select @change="handleVacunaSelected($event.target.value)" :value="editingVacuna.vac.id" name="vacuna">
                                <option v-for="(vacuna, index) in sortedVacunasHomologacionNac" :key="index" :value="vacuna.vacunaPcId">
                                    {{ `${vacuna.vacunaPcName}` }}
                                </option>
                            </select>
                        </f7-list-item>
                        <f7-list-item>
                            <span slot="header" class="header-style">
                                {{ $t("homologacionCreate.cuandoVacuna") }} <span class="text-color-red">*</span>
                            </span>
                            <f7-row slot="footer" class="margin-top-half no-gap">
                                <f7-col width="20">
                                    <f7-input
                                        type="select"
                                        outline
                                        @change="splittedDate.dia = Number($event.target.value)"
                                        :value="splittedDate.dia"
                                    >
                                        <option v-for="dia in 31" :key="dia" :value="dia">{{ dia }}</option>
                                        
                                    </f7-input>
                                </f7-col>
                                <f7-col width="50">
                                    <f7-input
                                        type="select"
                                        outline
                                        @change="splittedDate.mes = Number($event.target.value)"
                                        :value="splittedDate.mes"
                                    >
                                        <option v-for="(mes, idx) in $dayjs.months()" :key="idx + 1" :value="idx + 1">{{ mes }}</option>
                                    </f7-input>
                                </f7-col>
                                <f7-col width="30">
                                    <f7-input
                                        type="select"
                                        outline
                                        @input = "splittedDate.ano = Number($event.target.value)"
                                        :value="splittedDate.ano"
                                    >
                                        <option v-for="(year, index) in vaccineYearsTillNow" :key="index" :value="year">{{ year }}</option>
                                    </f7-input>
                                </f7-col>
                            </f7-row>
                        </f7-list-item>
                        <f7-list-input
                            type="text"
                            :value="editingVacuna.establecimiento"
                            @input="editingVacuna.establecimiento = $event.target.value"
                        >
                            <span slot="label">
                                {{ $t('homologacionCreate.establecimientoVac') }} <span class="text-color-red">*</span>
                            </span>
                        </f7-list-input>
                        <!-- <f7-list-item
                            class="no-margin-top"
                            smart-select
                            :smart-select-params="{
                                openIn: 'popup',
                                searchbar: { removeDiacritics: true },
                                searchbarPlaceholder: $t('solicitudNacCreate.comunaEstablecimiento'),
                                popupCloseLinkText: $t('commons.cerrar'),
                                closeOnSelect: true,
                                setValueText: false,
                            }"
                            required
                        >
                            <div slot="header" class="header-style">{{ $t("solicitudNacCreate.comunaEstablecimiento") }} <span class="text-color-red">*</span></div>
                            <div slot="inner">
                                {{ editingVacuna.address.comuna ? editingVacuna.address.provincia.split('-')[2] + ' | ' + editingVacuna.address.region_hr : '' }}
                            </div>
                            <select @change="handleComunaSelected($event.target.value)" name="comuna">
                                <template v-for="region in comunas">
                                    <optgroup
                                        v-for="provincia in region.provincias"
                                        :key="region.region_number + provincia.name"
                                        :label="region.region + ': ' + provincia.name"
                                    >
                                        <option
                                            v-for="comuna in provincia.comunas"
                                            :selected="
                                                selectedComuna ==
                                                comuna.name + '|' + provincia.name + '|' + region.region
                                            "
                                            :key="comuna.code"
                                            :value="
                                                comuna.name + '|' + provincia.name + '|' + region.region_iso_3166_2 + '|' + comuna.code+ '|' + region.region
                                            "
                                        >
                                            {{ comuna.name }} ({{provincia.name}})
                                        </option>
                                    </optgroup>
                                </template>
                            </select>
                        </f7-list-item> -->
                        <f7-list-input
                            type="text"
                            :label="$t('homologacionCreate.nroLote')"
                            :value="editingVacuna.lote"
                            @input="editingVacuna.lote = $event.target.value"
                        ></f7-list-input>
                    </f7-list>
                    <f7-button
                        fill
                        large
                        @click="saveVacuna"
                        class="margin-bottom"
                    >
                        {{ $t("homologacionCreate.guardarVacuna") }}
                    </f7-button>
                    <f7-button
                        v-if="isDeletable"
                        outline
                        color="red"
                        @click="deleteVacuna"
                    >
                        {{ $t("homologacionCreate.eliminarVacuna") }}
                    </f7-button>
                </template>
            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
import { mapState,mapGetters } from "vuex";
import { commonmixin } from "../mixins/common";
import { comunasHelper } from "@/js/comunas.js";

export default {
    mixins: [commonmixin, comunasHelper],
    props: {
        solicitud:{
            type: Object,
            required: true
        },
        opened: {
            type: Boolean,
            required: true,
        },
        vacuna: {
            type: String,
            required: false,
            default: null,
        }
    },

    data() {
        return {
            editingVacuna: {
                id: null,
                fechavac: null,
                lote: null,
                establecimiento: null,
                // address: {
                //     code: null,
                //     comuna: null,
                //     provincia: null,
                //     region: null,
                //     region_hr: null,
                // },
                vac: {
                    id: null,
                    name: null
                },
            },

            splittedDate: {
                dia: null,
                mes: null,
                ano: null,
            },
        };
    },

    watch: {
        "splittedDate.dia": {
            handler() {
                this.validateDate();
            },
        },
        "splittedDate.mes": {
            handler() {
                this.validateDate();
            },
        },
    },

    computed: {
        ...mapState(["vacunasHomologacionNac"]),
        ...mapGetters(['sortedVacunasHomologacionNac']),
        isDeletable(){
            return this.vacuna
        },
        mergedDate() {
            if (!this.splittedDate.dia || !this.splittedDate.mes || !this.splittedDate.ano) {
                return null;
            }

            return `${this.pad(this.splittedDate.dia, 2)}/${this.pad(this.splittedDate.mes, 2)}/${this.splittedDate.ano}`;
        },
        vaccineYearsTillNow() {
            const currentYear = this.$dayjs().year();
            return Array.from(Array(currentYear - 1960 + 1).keys()).map(year => year + 1960);
        },
        // selectedComuna() {
        //     return this.editingVacuna.address.comuna ? this.editingVacuna.address.comuna +
        //             "|" +
        //             this.editingVacuna.address.provincia +
        //             "|" +
        //             this.editingVacuna.address.region
        //             : "a|b|c";
        // },
    },
    mounted(){
        
    },
    methods: {
        deleteVacuna() {
            this.$f7.dialog.confirm(
                this.$t("homologacionCreate.confirmEliminarText"),
                this.$t("homologacionCreate.eliminarVacuna"),
                () => {
                        this.borraVacuna()
                });
        },
        async borraVacuna(){
            try {
                //vamos a guardar
                 this.$f7.dialog.preloader(this.$t("commons.guardando"));
                let datous = await this.commonExecute({solid: this.solicitud.id,vacinfox:this.editingVacuna,del:true, tipo: "nac"},"mevacuno-actUponVacunaInSol",false);
                if (datous.error) {
                    throw new Error(datous.error.message || datous.error);
                }
                console.log("resposta",datous);

                //actUponVacunaInSol
                this.$f7.dialog.close();

                this.$emit("deleted", this.editingVacuna.id);
                this.handleClosePopup();
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message);
            }
        },
        async saveVacuna() {

            if (!this.mergedDate) {
                this.$f7.dialog.close();
                return this.$f7.dialog.alert(this.$t("homologacionCreate.fechaRequired"));
            }
            this.validateDate();

            this.editingVacuna.p = {
                iso2: 'CL',
                name: 'Chile',
            };

            if (!this.editingVacuna.vac.id) {
                this.$f7.dialog.close();
                return this.$f7.dialog.alert(this.$t("homologacionCreate.vacunaRequired"));
            }
            
            this.editingVacuna.fechavac = this.mergedDate;

            if (!this.editingVacuna.establecimiento) {
                this.$f7.dialog.close();
                return this.$f7.dialog.alert(this.$t("homologacionCreate.establecimientoRequired"));
            }

            // if (!this.editingVacuna.address.comuna) {
            //     this.$f7.dialog.close();
            //     return this.$f7.dialog.alert(this.$t("homologacionCreate.comunaRequired"));
            // }
            try {
                //vamos a guardar
                 this.$f7.dialog.preloader(this.$t("commons.guardando"));
                let datous = await this.commonExecute({solid: this.solicitud.id,vacinfox:this.editingVacuna,del:null, tipo: "nac"},"mevacuno-actUponVacunaInSol",false);
                if (datous.error) {
                    throw new Error(datous.error.message || datous.error);
                }

                //actUponVacunaInSol
                this.$f7.dialog.close();
                this.$emit("modified", datous.payload);
                this.handleClosePopup();
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message);
            }

        },

        init() {
            if (this.solicitud.vacs &&  this.solicitud.vacs[this.vacuna]) {
                this.editingVacuna = JSON.parse(JSON.stringify(this.solicitud.vacs[this.vacuna]));
            }

            if (this.editingVacuna.fechavac) {
                this.splittedDate = {
                    dia: Number(this.editingVacuna.fechavac.split("/")[0]),
                    mes: Number(this.editingVacuna.fechavac.split("/")[1]),
                    ano: Number(this.editingVacuna.fechavac.split("/")[2]),
                };
            }
            this.fetchDataIfRequired();
        },
        async fetchDataIfRequired(){
            try {
                let promholder=[];
                //vacunas
                if (!this.sortedVacunasHomologacionNac.length) {
                    promholder.push( this.fetchVacunas() );
                }

                await Promise.all( promholder );

            } catch (error) {
                this.$f7.dialog.close();
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        handleClosePopup() {
            this.$emit("popup-closed");
            this.editingVacuna = {
                id: null,
                fechavac: null,
                lote: null,
                establecimiento: null,
                // address: {
                //     code: null,
                //     comuna: null,
                //     provincia: null,
                //     region: null,
                //     region_hr: null,
                // },
                vac: {
                    id: null,
                    name: null
                },
            };
            this.splittedDate = {
                dia: null,
                mes: null,
                ano: null,
            };
        },

        // Form handlers
        handleVacunaSelected(vacId) {
            const vacuna = this.sortedVacunasHomologacionNac.find(vacuna => vacuna.vacunaPcId === vacId);

            if (!vacuna) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(this.$t("homologacionCreate.vacunaNotFound"));
                return;
            }

            this.$set(this.editingVacuna.vac, "id", vacId);
            this.$set(this.editingVacuna.vac, "name", vacuna.vacunaPcName);
        },
        handleYearSelected(target) {
            this.splittedDate.ano = Number(target.value);
            if (this.splittedDate.ano > 1950) {
                target.blur();
            }
        },

        // handleComunaSelected(comuna) {
        //     let linfo = comuna.split("|");
        //     this.editingVacuna.address = {
        //         comuna: linfo[0],
        //         region: linfo[2],
        //         provincia: `${linfo[2]}-${linfo[1]}`,
        //         region_hr: linfo[4],
        //         code: linfo[3],
        //     };
        // },

        // Fetchers
        async fetchVacunas() {
            let snapshot = await this.$firebase.database().ref("vacunas").orderByChild("show_hmlg_nac").equalTo(true).once("value");
            this.$store.commit("setWhatTo", { what: "vacunasHomologacionNac", to: snapshot.val() || {} });
        },

        // Validators
        validateDate() {
            if (this.mergedDate && !this.$dayjs(this.mergedDate, "DD/MM/YYYY", true).isValid() ) {
                console.log("mergedDate", this.mergedDate);
                this.$f7.dialog.close();
                return this.$f7.dialog.alert(this.$t("homologacionCreate.fechaInvalida"));
            }
            else if( this.$dayjs().isBefore( this.$dayjs(this.mergedDate, "DD/MM/YYYY"),'day' )){
                this.$f7.dialog.close();
                return this.$f7.dialog.alert(this.$t("homologacionCreate.fechaFuturoInvalida"));
            }
            else if(this.splittedDate.dia && this.splittedDate.mes && this.splittedDate.ano && this.splittedDate.ano<1950){
                this.$f7.dialog.close();
                return this.$f7.dialog.alert(this.$t("homologacionCreate.fechaPasadoInvalida"));
            }
        },

        pad(num, size) {
            num = num.toString();

            if (num.length > 1) {
                return num;
            }

            while (num.length < size) num = "0" + num;
            return num;
        }
    },
}
</script>

<style>
.header-style {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    vertical-align: top;
}
</style>