<template>
    <f7-button @click="naveta()" fill large class="logclvuni"
        ><img class="bannergov" src="../static/logos/banner_gov.svg" alt="banner gov"/><img
            src="../static/logos/clave_unica_white.svg"
            alt="Logo clave unica"
    /></f7-button>
</template>
<script>
export default {
    computed: {
        clientid() {
            return this.$store.state.dev ? "083ae7a890b440df8e15745b1a5e52d0" : "1e89b0b11b0e417bbd199a3aac7717ee";
        },
        callbackurl() {
            return this.$store.state.dev ? `https://${window.location.hostname}/` : `https://${window.location.hostname}`;
        },
        curstate() {
            return this.$f7.utils.id();
        },
        url() {
            return `https://accounts.claveunica.gob.cl/openid/authorize/?client_id=${
                this.clientid
            }&response_type=code&scope=openid run name&redirect_uri=${encodeURIComponent(this.callbackurl)}&state=${this.curstate}`;
        },
    },

    methods: {
        naveta() {
            if (this.$store.state.dev) {
                return this.$f7.dialog.confirm(
                    "Ud se encuentra en ambiente de desarrollo. Será dirigido al sandbox de clave única (debe usar crendenciales de prueba.)",
                    "Ambiente desarrollo",
                    () => {
                        this.gotocalvn();
                    }
                );
            } else {
                this.gotocalvn();
            }
        },
        async gotocalvn() {
            localStorage.setItem("clp_state", this.curstate);
            this.$f7.dialog.preloader(this.$t("commons.navegandoClaveUnica", { claveUnica: "Clave Única" }));
            window.location.href = this.url;
        },
    },
};
</script>
<style>
.logclvuni {
    padding: 7px;
    background: #093f75 !important;
}
.bannergov {
    position: absolute;
    top: -4px;
    left: 0px;
}
</style>
