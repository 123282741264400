var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-popup',{attrs:{"opened":_vm.opened,"close-by-backdrop-click":false},on:{"popup:opened":_vm.init,"popup:closed":_vm.handleClosePopup}},[_c('f7-page',[_c('f7-navbar',{attrs:{"title":`${_vm.$t('commons.agregando')} ${_vm.$t('commons.vacuna')}`}},[_c('f7-nav-right',[_c('f7-link',{on:{"click":_vm.handleClosePopup}},[_vm._v(_vm._s(_vm.$t('commons.cancelar')))])],1)],1),_vm._v(" "),(_vm.loadingVacunas)?_c('f7-block',{staticClass:"text-align-center"},[_c('f7-preloader')],1):(_vm.opened)?_c('f7-block',{staticClass:"no-margin-top padding-top"},[_c('f7-list',{staticClass:"no-margin-top no-padding-top"},[_c('f7-list-item',{staticClass:"margin-vertical",attrs:{"smart-select":"","smart-select-params":{
                        openIn: 'popup',
                        searchbar: { removeDiacritics: true },
                        searchbarPlaceholder: _vm.$t('homologacionCreate.buscarVacuna'),
                        popupCloseLinkText: _vm.$t('commons.cerrar'),
                        closeOnSelect: true,
                        setValueText: false,
                    },"disabled":!_vm.sortedVacunasHomologacionNac.length,"required":""}},[_c('div',{staticClass:"header-style",attrs:{"slot":"header"},slot:"header"},[_vm._v(_vm._s(_vm.$t("homologacionCreate.queVacuna"))+" "),_c('span',{staticClass:"text-color-red"},[_vm._v("*")])]),_vm._v(" "),_c('div',{attrs:{"slot":"inner"},slot:"inner"},[_vm._v(_vm._s(_vm.vacuna.name || ""))]),_vm._v(" "),_c('select',{attrs:{"name":"vacuna"},domProps:{"value":_vm.vacuna.name},on:{"change":function($event){_vm.vacuna.name = $event.target.value}}},_vm._l((_vm.sortedVacunasHomologacionNac),function(vacuna,index){return _c('option',{key:index,domProps:{"value":vacuna.vacunaPcName}},[_vm._v("\n                            "+_vm._s(`${vacuna.vacunaPcName}`)+"\n                        ")])}),0)])],1),_vm._v(" "),_c('f7-button',{staticClass:"margin-bottom",attrs:{"fill":"","large":""},on:{"click":_vm.saveVacuna}},[_vm._v("\n                "+_vm._s(_vm.$t("homologacionCreate.guardarVacuna"))+"\n            ")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }