<template>
    <div class="locale-changer">
        <f7-list class="no-bg" no-hairlines>
            <f7-list-input
                type="select"
                placeholder="placeholder"
                :value="$i18n.locale"
                @input="setLocale($event.target.value)"
            >
                <template #media>
                    <font-awesome-icon class="fa-2x primary-color" :icon="['fas', 'language']"/>
                </template>
                <option
                    :class="`color-${selectColor}`"
                    :value="locale.code"
                    v-for="locale in locales"
                    :key="locale.code"
                >
                    {{ locale.name }}
                </option>
            </f7-list-input>
        </f7-list>
    </div>
</template>
<script>
import localforage from "localforage";
import { getSupportedLocales } from "@/i18n/config/utils.js";

export default {
    name: "locale-changer",
    props: {
        selectColor: {
            type: String,
            required: false,
            default: "black",
            validator: function (value) {
                return ['white', 'black'].indexOf(value) !== -1
            }
        }
    },
    data() {
        return {
            locales: getSupportedLocales(),
        };
    },
    methods: {
        setLocale(locale) {
            localforage
                .setItem("locale", locale)
                .then(() => {
                    return localforage.getItem('locale');
                })
                .then(value => {
                    this.$i18n.locale = value;

                    if (locale !== "es") {
                        this.$f7.dialog.alert(this.$t("commons.traduccionAutomaticaAlert"));
                    }
                });
        }
    }
};
</script>

<style scoped>
.no-bg {
    --f7-list-bg-color: transparent;
}
.color-black {
    color: black;
}
.color-white {
    color: white;
}
.color-primary {
    color: var(--f7-theme-color);
}
</style>
