<template>
    <f7-page @page:beforein="getmystatus()" name="miesquema">
        <!-- Top Navbar -->
        <f7-navbar :title="$t('miEsquema.navbarTitle')" :subtitle="`${campanaEnFoco.nombre}`" back-link="Back"></f7-navbar>

        <f7-block class="text-align-center" v-if="!forcedSchemaId"><f7-preloader></f7-preloader><br />{{ $t("commons.cargando") }}</f7-block>

        <template v-if="forcedSchemaId">
            <f7-list v-if="agendando_a_tercero" class="titlebold no-margin" media-list>
                <f7-list-item
                    :header="$t('miEsquema.agendandoPara')"
                    :title="`${mitercero.apellido1} ${mitercero.apellido2}, ${mitercero.nombres} `"
                    :subtitle="fraseIdentity(mitercero)"
                    :text="`${suage(mitercero.fechanac)} ${$t('commons.anos')}`"
                >
                    <img src="../static/heros/avatar.jpg" class="untercerox" slot="media" />
                </f7-list-item>
            </f7-list>
            <f7-block class="margin-vertical">
                {{ $t("miEsquema.esquemaAsignadoPara") }} <span class="text-color-primary">{{ campanaEnFoco.nombre }}</span> {{ $t("commons.requiere") }}
                <b>{{ numDiasEnEsquema }} {{ $tc("miEsquema.serie", numDiasEnEsquema) }}</b> {{ $t("miEsquema.deVacunas") }}
                {{ $tc("miEsquema.laHora", numDiasEnEsquema) }} {{ $t("miEsquema.aAgendar") }}
            </f7-block>

            <f7-block v-for="(diacal, indx) in diasAMostarArray" :key="diacal.id">
                <f7-list class="no-margin-bottom ">
                    <f7-list-item
                        class="titlebold limorada sinmarleft"
                        :footer="
                            indx != 0
                                ? diacal.dto
                                    ? $t('miEsquema.entreDiasDesdeSerie', { dnum: diacal.dnum, dto: diacal.dto })
                                    : $t('miEsquema.alDia', { dnum: diacal.dnum })
                                : null
                        "
                        ><span slot="title">{{ indx == 0 ? $t("miEsquema.serieInicial") : $t("miEsquema.nroSerie", { number: indx + 1 }) }}</span>
                        <font-awesome-icon class="limorada fa-lg" slot="media" icon="calendar-day"></font-awesome-icon>
                    </f7-list-item>
                    <f7-list-item
                        v-if="
                            !diacal.agendado &&
                                !diacal.recibido &&
                                diacal.previox &&
                                (!diasAMostrar[diacal.previox] || (!diasAMostrar[diacal.previox].agendado && !diasAMostrar[diacal.previox].recibido))
                        "
                        class="text-color-gray"
                        :title="$t('miEsquema.seleccioneSeriePrevia')"
                    ></f7-list-item>
                </f7-list>

                <f7-list class="no-margin" v-if="diacal.recibido || diacal.agendado">
                    <f7-list-item
                        v-if="diacal.recibido"
                        :title="$dayjs(diacal.recibido.d).format('dddd D [de] MMMM [de] YYYY')"
                        :footer="`${diacal.vacunado_center.name}, ${diacal.vacunado_center.address.name}, ${diacal.vacunado_center.address.region}`"
                    >
                        <div v-if="!diacal.recibido.rni" class="text-align-center" slot="media">
                            <h3 class="no-margin">{{ $dayjs.unix(diacal.recibido.f).format("HH:mm") }}</h3>
                        </div>
                    </f7-list-item>
                    <f7-list-item
                        v-else
                        :title="$dayjs(diacal.agendado.fch).format('dddd D [de] MMMM [de] YYYY')"
                        :footer="`${diacal.agendado_center.name}, ${diacal.agendado_center.address.name}, ${diacal.agendado_center.address.region}`"
                    >
                        <div class="text-align-center" slot="media">
                            <h3 class="no-margin">{{ diacal.agendado.hin }}</h3>
                        </div>
                        <f7-button
                            slot="after"
                            @click="eliminaragmt(diacal.agendado)"
                            v-if="!diacal.afeta_a || !diasAMostrar[diacal.afeta_a] || !diasAMostrar[diacal.afeta_a].agendado"
                            color="gray"
                            ><font-awesome-icon class="fa-lg" icon="trash-alt"></font-awesome-icon
                        ></f7-button>
                    </f7-list-item>
                </f7-list>
                <f7-list
                    v-else-if="
                        (!diacal.agendado && !diacal.recibido && !diacal.previox) ||
                            (diacal.previox &&
                                diasAMostrar[diacal.previox] &&
                                (diasAMostrar[diacal.previox].agendado || diasAMostrar[diacal.previox].recibido))
                    "
                    class="no-margin-vertical"
                >
                    <f7-list-item
                        class="nowhitespacetitle"
                        v-if="
                            !diacal.agendado &&
                                !diacal.recibido &&
                                diacal.previox && diasAMostrar[diacal.previox] && diasAMostrar[diacal.previox].recibido
                        "
                        :footer="
                            `${diasAMostrar[diacal.previox].vacunado_center.address.name}, ${
                                diasAMostrar[diacal.previox].vacunado_center.address.region
                            }`
                        "
                        :title="$t('miEsquema.buscarHoraEn', { vacunatorio: diasAMostrar[diacal.previox].vacunado_center.name })"
                        link="#"
                        @click="buscaHorayoCentro(diacal.id, diasAMostrar[diacal.previox].vacunado_center.id, null)"
                    >
                    </f7-list-item>
                    <f7-list-item
                        class="nowhitespacetitle"
                        v-else-if="
                            !diacal.agendado &&
                                !diacal.recibido &&
                                diacal.previox && diasAMostrar[diacal.previox] && diasAMostrar[diacal.previox].agendado
                        "
                        :footer="
                            `${diasAMostrar[diacal.previox].agendado_center.address.name}, ${
                                diasAMostrar[diacal.previox].agendado_center.address.region
                            }`
                        "
                        :title="$t('miEsquema.buscarHoraEn', { vacunatorio: diasAMostrar[diacal.previox].agendado_center.name})"
                        link="#"
                        @click="buscaHorayoCentro(diacal.id, diasAMostrar[diacal.previox].agendado_center.id, null)"
                    >
                    </f7-list-item>

                    <f7-list-item
                        v-if="!diacal.agendado && !diacal.recibido"
                        :after="
                            diacal.previox &&
                            diasAMostrar[diacal.previox] &&
                            (diasAMostrar[diacal.previox].agendado || diasAMostrar[diacal.previox].recibido)
                                ? $t('miEsquema.buscarHoraEnOtroCentro')
                                : $t('miEsquema.buscarHora')
                        "
                        link="#"
                        @click="buscaHorayoCentro(diacal.id, null, null)"
                    >
                    </f7-list-item>
                </f7-list>
                <f7-block v-if="diacal.recibido" class="horaokx no-margin-vertical"
                    >{{ $t("miEsquema.vacunaRecibida") }} <font-awesome-icon icon="syringe"></font-awesome-icon
                ></f7-block>
                <f7-block v-else-if="diacal.agendado && diacal.agendado.prov" class="horaprovix no-margin-vertical"
                    >{{ $t("miEsquema.provisorio") }} <font-awesome-icon icon="pause-circle"></font-awesome-icon
                ></f7-block>
                <f7-block v-else-if="diacal.agendado" class="horaokx no-margin-top"
                    >{{ $t("miEsquema.horaAgendada") }} <font-awesome-icon icon="check"></font-awesome-icon
                ></f7-block>
            </f7-block>
        </template>

        <f7-popup class="choosecenter" :opened="popupEligeCentroOpened" @popup:closed="popupEligeCentroOpened = false">
            <elige-centro
                v-if="preabreEligeCentro"
                @aboraseleccion="abortandoselect"
                @eligiocentrogo="llegacentrocore"
                :esquemaid="forcedSchemaId"
                :diaid="requestedDiaId"
            ></elige-centro>
        </f7-popup>

        <f7-popup class="selectorxhora" :opened="calenpopupOpened" @popup:closed="cierraselector()">
            <eligehora
                v-if="preabreSelectorHora"
                @cierrame="calenpopupOpened = false"
                @elige="eligiendofecha"
                :forcedmax="payloadParaAgendamiento.forcedmax"
                :forcedmin="payloadParaAgendamiento.forcedmin"
                :fechaSelectDayId="payloadParaAgendamiento.diaid"
                :maxexpEsquema="payloadParaAgendamiento.maxexpEsquema"
                :forcedSchemaId="forcedSchemaId"
                :vacunatorioid="payloadParaAgendamiento.vacunatorioid"
            ></eligehora>
        </f7-popup>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { commonmixin } from "../mixins/common";
import eligeCentro from "../pages/eligecentro.vue";
import eligehora from "../pages/eligehora.vue";
export default {
    mixins: [commonmixin],
    components: { eligehora, eligeCentro },
    data() {
        return {
            diasAMostrar: {},
            forcedSchemaId: null,
            popupEligeCentroOpened: false,
            preabreEligeCentro: false,
            requestedDiaId: null,
            //validar de aqui a abajo
            payloadParaAgendamiento: {
                vacunatorioid: null,
                diaid: null,
                forcedmin: null,
                forcedmax: null,
                maxexpEsquema: null,
            },
            calenpopupOpened: false,
            preabreSelectorHora: false,
        };
    },
    computed: {
        ...mapState(["user", "chosenCampanaID", "agendando_a_tercero"]),
        campanaEnFoco() {
            return this.$store.getters.oneCampana(this.chosenCampanaID);
        },
        diasAMostarArray() {
            if (!this.forcedSchemaId) {
                return [];
            }
            return Object.values(this.diasAMostrar);
        },
        numDiasEnEsquema() {
            return this.diasAMostarArray.length;
        },
        mitercero() {
            return this.$store.getters.mitercero(this.agendando_a_tercero);
        },
    },
    methods: {
        async eliminaragmt(agnedamiento) {
            try {
                let agendamientoid = agnedamiento.id;
                this.$f7.dialog.preloader(this.$t("agenda.cancelandoAgendamiento"));
                let datous = await this.commonExecute({ agendamientoid }, "mevacuno-eliminahora", false);
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                //borrar demisagendamientos
                this.$f7.dialog.close();
                this.$store.commit("delObje", { what: "agenda", cual: agendamientoid });
                let diainfo = this.diasAMostrar[agnedamiento.dia];
                // console.log('a',diainfo)
                let updates = {
                    agendado: null,
                    agendado_center: null,
                };
                this.diasAMostrar[agnedamiento.dia] = Object.assign({}, this.diasAMostrar[agnedamiento.dia], updates);
                this.avisaOk("Agendamiento cancelado");
                if (agnedamiento.dia == "Día 0") {
                    this.requestedDiaId = null;
                    this.forcedSchemaId = null;
                    this.abreEligeCentro();
                }
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        navegarAaGENDA() {
            this.$store.commit("setWhatTo", { what: "activeTab", to: "/agenda/" });
        },
        eligiendofecha(eleccion) {
            // console.log('eligiendo',eleccion);//todo VER COMO Mapeo a la interface Uusaira
            let diainfo = this.diasAMostrar[eleccion.dia];
            // console.log('a',diainfo)
            let updates = {
                agendado: eleccion,
                agendado_center: this.$store.state.vacunatorios[eleccion.cid],
            };
            this.diasAMostrar[eleccion.dia] = Object.assign({}, this.diasAMostrar[eleccion.dia], updates);
            this.cierraselector();
            // this.$set(this.agendamientos,eleccion.diaid,eleccion);
            //  //ver si afecta a uno
            //  let suinfox=this.calsesquemaFoco.find(unitem=>{
            //    return unitem.id ==eleccion.diaid;
            //  });
            //  if(suinfox && suinfox.afeta_a){
            //      this.$set(this.agendamientos,suinfox.afeta_a,null);
            //  }
            // this.cierraselector();
        },
        cargardiasEsquemaObjCore(forcedSchemaId) {
            let dias = {};
            let previox = null;
            let arrayado = Object.values(Object.values(this.campanaEnFoco.esquemas[this.forcedSchemaId].cal));
            arrayado.forEach((undiax, indx) => {
                if (indx < arrayado.length - 1) {
                    undiax.afeta_a = arrayado[indx + 1].id;
                }
                undiax.previox = previox;
                previox = undiax.id;
                dias[undiax.id] = undiax;
            });
            this.diasAMostrar = Object.assign({}, dias);
        },
        buscaHorayoCentro(dayid, centroid, maxexpEsquema) {
            if (centroid) {
                //quiere que le muestren las horas disponbiles, GO!
                this.payloadParaAgendamiento.vacunatorioid = centroid;
                this.payloadParaAgendamiento.diaid = dayid;
                this.payloadParaAgendamiento.forcedmin = null;
                this.payloadParaAgendamiento.forcedmax = null;
                this.payloadParaAgendamiento.maxexpEsquema = maxexpEsquema;
                let metaifo = this.diasAMostrar[dayid];
                if (metaifo.previox) {
                    if (this.diasAMostrar[metaifo.previox].recibido) {
                        this.payloadParaAgendamiento.forcedmin = this.$dayjs(this.diasAMostrar[metaifo.previox].recibido.d)
                            .add(metaifo.dnum, "d")
                            .format("YYYYMMDD");
                        if (metaifo.dto) {
                            this.payloadParaAgendamiento.forcedmax = this.$dayjs(this.diasAMostrar[metaifo.previox].recibido.d)
                                .add(metaifo.dto, "d")
                                .format("YYYYMMDD");
                        } else {
                            this.payloadParaAgendamiento.forcedmax = this.payloadParaAgendamiento.forcedmin;
                        }
                    } else if (this.diasAMostrar[metaifo.previox].agendado) {
                        this.payloadParaAgendamiento.forcedmin = this.$dayjs(this.diasAMostrar[metaifo.previox].agendado.fch)
                            .add(metaifo.dnum, "d")
                            .format("YYYYMMDD");
                        if (metaifo.dto) {
                            this.payloadParaAgendamiento.forcedmax = this.$dayjs(this.diasAMostrar[metaifo.previox].agendado.fch)
                                .add(metaifo.dto, "d")
                                .format("YYYYMMDD");
                        } else {
                            this.payloadParaAgendamiento.forcedmax = this.payloadParaAgendamiento.forcedmin;
                        }
                    }
                }
                this.preabreSelectorHora = true;
                this.calenpopupOpened = true;
            } else {
                //abrir el buscador de centros
                this.requestedDiaId = dayid;
                this.abreEligeCentro();
            }
        },
        cierraselector() {
            this.calenpopupOpened = false;
            this.preabreSelectorHora = false;
            this.payloadParaAgendamiento.forcedmin = null;
            this.payloadParaAgendamiento.forcedmax = null;
            this.payloadParaAgendamiento.diaid = null;
            this.payloadParaAgendamiento.maxexpEsquema = null;
        },
        llegacentrocore(payload) {
            this.cierraEligeCentro();
            // console.log('llegando info centro core',payload);
            //cargar dias sinteticos
            let ditobusc = this.requestedDiaId;
            if (!this.forcedSchemaId) {
                this.forcedSchemaId = payload.esquemaid;
                this.cargardiasEsquemaObjCore(this.forcedSchemaId);
                ditobusc = payload.diaid;
            }
            this.buscaHorayoCentro(ditobusc, payload.vacunatorioid, payload.maxexpEsquema);
        },
        abortandoselect() {
            this.cierraEligeCentro();
            if (!this.forcedSchemaId) {
                return this.$f7.views.main.router.back();
            }
        },
        cierraEligeCentro() {
            this.popupEligeCentroOpened = false;
            this.preabreEligeCentro = false;
        },
        async getmystatus() {
            try {
                let datous = await this.commonExecute(
                    { campanaid: this.chosenCampanaID, tercero: this.agendando_a_tercero },
                    "mevacuno-mostrarAgendamientoCampana",
                    false
                );
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                let respuest = datous.payload;
                // console.log(respuest);
                if (!respuest.forcedSchemaId) {
                    //hay que enviarlo a elegir vacunatorio con la logica existente:
                    this.requestedDiaId = null;
                    this.forcedSchemaId = null;
                    this.abreEligeCentro();
                } else if (respuest.diasAMostrar) {
                    if (respuest.centersInfo && Object.values(respuest.centersInfo).length) {
                        this.$store.commit("mergeObjTo", { what: "vacunatorios", to: respuest.centersInfo });
                    }
                    this.forcedSchemaId = respuest.forcedSchemaId;
                    this.diasAMostrar = Object.assign({}, this.diasAMostrar, respuest.diasAMostrar);
                }
            } catch (error) {
                this.$f7.views.main.router.back();
                console.error(error);
                this.$f7.dialog.alert(error.message, "Error");
            }
        },
        abreEligeCentro() {
            this.preabreEligeCentro = true;
            this.popupEligeCentroOpened = true;
        },
    },
};
</script>
<style>
.limorada {
    background: #f3eaff;
    color: #52008e;
}
.limorada .item-footer {
    color: #292929 !important;
}
.horaokx {
    background: #c4ffd7;
    color: #01440a;
    font-weight: bold;
    text-align: right;
    line-height: 26px;
    height: 26px;
    font-size: 12px;
    margin: 0px;
}
.horaprovix {
    background: #fff8c4;
    color: #6d5203;
    font-weight: bold;
    text-align: right;
    line-height: 26px;
    height: 26px;
    font-size: 12px;
    margin: 0px;
}
.sinmarleft .item-inner {
    margin-left: 0px !important;
}
.sinmarleft .item-media {
    min-width: 30px !important;
}
</style>
