var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-page',{attrs:{"name":"miesquema"},on:{"page:beforein":function($event){return _vm.getmystatus()}}},[_c('f7-navbar',{attrs:{"title":_vm.$t('miEsquema.navbarTitle'),"subtitle":`${_vm.campanaEnFoco.nombre}`,"back-link":"Back"}}),_vm._v(" "),(!_vm.forcedSchemaId)?_c('f7-block',{staticClass:"text-align-center"},[_c('f7-preloader'),_c('br'),_vm._v(_vm._s(_vm.$t("commons.cargando")))],1):_vm._e(),_vm._v(" "),(_vm.forcedSchemaId)?[(_vm.agendando_a_tercero)?_c('f7-list',{staticClass:"titlebold no-margin",attrs:{"media-list":""}},[_c('f7-list-item',{attrs:{"header":_vm.$t('miEsquema.agendandoPara'),"title":`${_vm.mitercero.apellido1} ${_vm.mitercero.apellido2}, ${_vm.mitercero.nombres} `,"subtitle":_vm.fraseIdentity(_vm.mitercero),"text":`${_vm.suage(_vm.mitercero.fechanac)} ${_vm.$t('commons.anos')}`}},[_c('img',{staticClass:"untercerox",attrs:{"slot":"media","src":require("../static/heros/avatar.jpg")},slot:"media"})])],1):_vm._e(),_vm._v(" "),_c('f7-block',{staticClass:"margin-vertical"},[_vm._v("\n            "+_vm._s(_vm.$t("miEsquema.esquemaAsignadoPara"))+" "),_c('span',{staticClass:"text-color-primary"},[_vm._v(_vm._s(_vm.campanaEnFoco.nombre))]),_vm._v(" "+_vm._s(_vm.$t("commons.requiere"))+"\n            "),_c('b',[_vm._v(_vm._s(_vm.numDiasEnEsquema)+" "+_vm._s(_vm.$tc("miEsquema.serie", _vm.numDiasEnEsquema)))]),_vm._v(" "+_vm._s(_vm.$t("miEsquema.deVacunas"))+"\n            "+_vm._s(_vm.$tc("miEsquema.laHora", _vm.numDiasEnEsquema))+" "+_vm._s(_vm.$t("miEsquema.aAgendar"))+"\n        ")]),_vm._v(" "),_vm._l((_vm.diasAMostarArray),function(diacal,indx){return _c('f7-block',{key:diacal.id},[_c('f7-list',{staticClass:"no-margin-bottom"},[_c('f7-list-item',{staticClass:"titlebold limorada sinmarleft",attrs:{"footer":indx != 0
                            ? diacal.dto
                                ? _vm.$t('miEsquema.entreDiasDesdeSerie', { dnum: diacal.dnum, dto: diacal.dto })
                                : _vm.$t('miEsquema.alDia', { dnum: diacal.dnum })
                            : null}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(indx == 0 ? _vm.$t("miEsquema.serieInicial") : _vm.$t("miEsquema.nroSerie", { number: indx + 1 })))]),_vm._v(" "),_c('font-awesome-icon',{staticClass:"limorada fa-lg",attrs:{"slot":"media","icon":"calendar-day"},slot:"media"})],1),_vm._v(" "),(
                        !diacal.agendado &&
                            !diacal.recibido &&
                            diacal.previox &&
                            (!_vm.diasAMostrar[diacal.previox] || (!_vm.diasAMostrar[diacal.previox].agendado && !_vm.diasAMostrar[diacal.previox].recibido))
                    )?_c('f7-list-item',{staticClass:"text-color-gray",attrs:{"title":_vm.$t('miEsquema.seleccioneSeriePrevia')}}):_vm._e()],1),_vm._v(" "),(diacal.recibido || diacal.agendado)?_c('f7-list',{staticClass:"no-margin"},[(diacal.recibido)?_c('f7-list-item',{attrs:{"title":_vm.$dayjs(diacal.recibido.d).format('dddd D [de] MMMM [de] YYYY'),"footer":`${diacal.vacunado_center.name}, ${diacal.vacunado_center.address.name}, ${diacal.vacunado_center.address.region}`}},[(!diacal.recibido.rni)?_c('div',{staticClass:"text-align-center",attrs:{"slot":"media"},slot:"media"},[_c('h3',{staticClass:"no-margin"},[_vm._v(_vm._s(_vm.$dayjs.unix(diacal.recibido.f).format("HH:mm")))])]):_vm._e()]):_c('f7-list-item',{attrs:{"title":_vm.$dayjs(diacal.agendado.fch).format('dddd D [de] MMMM [de] YYYY'),"footer":`${diacal.agendado_center.name}, ${diacal.agendado_center.address.name}, ${diacal.agendado_center.address.region}`}},[_c('div',{staticClass:"text-align-center",attrs:{"slot":"media"},slot:"media"},[_c('h3',{staticClass:"no-margin"},[_vm._v(_vm._s(diacal.agendado.hin))])]),_vm._v(" "),(!diacal.afeta_a || !_vm.diasAMostrar[diacal.afeta_a] || !_vm.diasAMostrar[diacal.afeta_a].agendado)?_c('f7-button',{attrs:{"slot":"after","color":"gray"},on:{"click":function($event){return _vm.eliminaragmt(diacal.agendado)}},slot:"after"},[_c('font-awesome-icon',{staticClass:"fa-lg",attrs:{"icon":"trash-alt"}})],1):_vm._e()],1)],1):(
                    (!diacal.agendado && !diacal.recibido && !diacal.previox) ||
                        (diacal.previox &&
                            _vm.diasAMostrar[diacal.previox] &&
                            (_vm.diasAMostrar[diacal.previox].agendado || _vm.diasAMostrar[diacal.previox].recibido))
                )?_c('f7-list',{staticClass:"no-margin-vertical"},[(
                        !diacal.agendado &&
                            !diacal.recibido &&
                            diacal.previox && _vm.diasAMostrar[diacal.previox] && _vm.diasAMostrar[diacal.previox].recibido
                    )?_c('f7-list-item',{staticClass:"nowhitespacetitle",attrs:{"footer":`${_vm.diasAMostrar[diacal.previox].vacunado_center.address.name}, ${
                            _vm.diasAMostrar[diacal.previox].vacunado_center.address.region
                        }`,"title":_vm.$t('miEsquema.buscarHoraEn', { vacunatorio: _vm.diasAMostrar[diacal.previox].vacunado_center.name }),"link":"#"},on:{"click":function($event){return _vm.buscaHorayoCentro(diacal.id, _vm.diasAMostrar[diacal.previox].vacunado_center.id, null)}}}):(
                        !diacal.agendado &&
                            !diacal.recibido &&
                            diacal.previox && _vm.diasAMostrar[diacal.previox] && _vm.diasAMostrar[diacal.previox].agendado
                    )?_c('f7-list-item',{staticClass:"nowhitespacetitle",attrs:{"footer":`${_vm.diasAMostrar[diacal.previox].agendado_center.address.name}, ${
                            _vm.diasAMostrar[diacal.previox].agendado_center.address.region
                        }`,"title":_vm.$t('miEsquema.buscarHoraEn', { vacunatorio: _vm.diasAMostrar[diacal.previox].agendado_center.name}),"link":"#"},on:{"click":function($event){return _vm.buscaHorayoCentro(diacal.id, _vm.diasAMostrar[diacal.previox].agendado_center.id, null)}}}):_vm._e(),_vm._v(" "),(!diacal.agendado && !diacal.recibido)?_c('f7-list-item',{attrs:{"after":diacal.previox &&
                        _vm.diasAMostrar[diacal.previox] &&
                        (_vm.diasAMostrar[diacal.previox].agendado || _vm.diasAMostrar[diacal.previox].recibido)
                            ? _vm.$t('miEsquema.buscarHoraEnOtroCentro')
                            : _vm.$t('miEsquema.buscarHora'),"link":"#"},on:{"click":function($event){return _vm.buscaHorayoCentro(diacal.id, null, null)}}}):_vm._e()],1):_vm._e(),_vm._v(" "),(diacal.recibido)?_c('f7-block',{staticClass:"horaokx no-margin-vertical"},[_vm._v(_vm._s(_vm.$t("miEsquema.vacunaRecibida"))+" "),_c('font-awesome-icon',{attrs:{"icon":"syringe"}})],1):(diacal.agendado && diacal.agendado.prov)?_c('f7-block',{staticClass:"horaprovix no-margin-vertical"},[_vm._v(_vm._s(_vm.$t("miEsquema.provisorio"))+" "),_c('font-awesome-icon',{attrs:{"icon":"pause-circle"}})],1):(diacal.agendado)?_c('f7-block',{staticClass:"horaokx no-margin-top"},[_vm._v(_vm._s(_vm.$t("miEsquema.horaAgendada"))+" "),_c('font-awesome-icon',{attrs:{"icon":"check"}})],1):_vm._e()],1)})]:_vm._e(),_vm._v(" "),_c('f7-popup',{staticClass:"choosecenter",attrs:{"opened":_vm.popupEligeCentroOpened},on:{"popup:closed":function($event){_vm.popupEligeCentroOpened = false}}},[(_vm.preabreEligeCentro)?_c('elige-centro',{attrs:{"esquemaid":_vm.forcedSchemaId,"diaid":_vm.requestedDiaId},on:{"aboraseleccion":_vm.abortandoselect,"eligiocentrogo":_vm.llegacentrocore}}):_vm._e()],1),_vm._v(" "),_c('f7-popup',{staticClass:"selectorxhora",attrs:{"opened":_vm.calenpopupOpened},on:{"popup:closed":function($event){return _vm.cierraselector()}}},[(_vm.preabreSelectorHora)?_c('eligehora',{attrs:{"forcedmax":_vm.payloadParaAgendamiento.forcedmax,"forcedmin":_vm.payloadParaAgendamiento.forcedmin,"fechaSelectDayId":_vm.payloadParaAgendamiento.diaid,"maxexpEsquema":_vm.payloadParaAgendamiento.maxexpEsquema,"forcedSchemaId":_vm.forcedSchemaId,"vacunatorioid":_vm.payloadParaAgendamiento.vacunatorioid},on:{"cierrame":function($event){_vm.calenpopupOpened = false},"elige":_vm.eligiendofecha}}):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }