<template>
    <f7-page name="eligehora">
        <!-- Top Navbar -->
        <f7-navbar :title="$t('eligehora.horasDisponibles')" :subtitle="`${vacunatorioEnFoco.name}, ${vacunatorioEnFoco.address.name}`">
            <f7-nav-right>
                <f7-link popup-close>{{ $t('commons.cancelar') }}</f7-link>
            </f7-nav-right>
        </f7-navbar>

        <f7-block class="no-margin-vertical no-padding fullh">
            <f7-row class="fullh" no-gap>
                <f7-col class="fullhvarialbe_left" width="100" medium="40">
                    <f7-progressbar v-if="cargandoMes" infinite></f7-progressbar>
                    <div v-else class="prelholder"></div>
                    <div id="lekalendar" class="bg-color-white esteacl"></div>
                </f7-col>
                <f7-col class="fullhvarialbe_right" width="100" medium="60">
                    <f7-card :padding="false" class="fullh no-margin">
                        <f7-card-header class="darkerbackgris"
                            ><f7-button @click="cambiardia(false)"><font-awesome-icon icon="chevron-left"></font-awesome-icon></f7-button
                            ><span>{{ $dayjs(hoyx).format("dddd DD MMM") }}</span
                            ><f7-button @click="cambiardia(true)"><font-awesome-icon icon="chevron-right"></font-awesome-icon></f7-button
                        ></f7-card-header>
                        <f7-card-content class="listamenx" :padding="false">
                            <f7-list class="horasdisp no-margin-vertical">
                                <f7-list-item v-if="cargandoDia">{{ $t('commons.cargando') }}<f7-preloader></f7-preloader></f7-list-item>
                                <f7-list-item class="padding-top" v-else-if="!cargandoDia && !diaenFoco.length"
                                    ><f7-block class="margin-top text-align-center"
                                        ><font-awesome-icon class="fa-3x text-color-red" icon="calendar-times"></font-awesome-icon><br />
                                        <br />
                                        {{ $t("eligehora.noHayHorasEl", { date: $d($dayjs(hoyx), 'long') }) }}</f7-block
                                    ></f7-list-item
                                >
                                <f7-list-item
                                    v-for="unslot in diaenFoco"
                                    :key="unslot.i"
                                    :class="{ disabled: !unslot.disponibles }"
                                    :title="unslot.i"
                                >
                                    <font-awesome-icon
                                        :icon="unslot.disponibles ? 'clock' : 'times-circle'"
                                        :class="(unslot.disponibles ? 'text-color-primary' : 'text-color-red') + ` fa-lg `"
                                        slot="media"
                                    ></font-awesome-icon>
                                    <f7-button v-if="unslot.disponibles" @click="agendarHora(unslot)" color="teal" fill slot="after" raised
                                        >{{ $t("agendaTercero.agendar") }}</f7-button
                                    >
                                    <span v-else slot="after">{{ $t("eligehora.noDisponible") }}</span>
                                </f7-list-item>
                            </f7-list>
                        </f7-card-content>
                    </f7-card>
                </f7-col>
            </f7-row>
        </f7-block>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { commonmixin } from "../mixins/common";

export default {
    mixins: [commonmixin],
    props: ["vacunatorioid", "forcedSchemaId", "maxexpEsquema", "forcedmin", "forcedmax", "fechaSelectDayId"],
    data() {
        return {
            calendarInline: null,
            cargandoMes: false,
            cargandoDia: false,
            hoyx: this.$dayjs().format("YYYYMMDD"),
            diaenFoco: [],
        };
    },
    computed: {
        ...mapState(["user", "chosenCampanaID", "agendando_a_tercero"]),
        ...mapGetters(["tieneConflicto", "privxInfo"]),
        campanaEnFoco() {
            return this.$store.getters.oneCampana(this.chosenCampanaID);
        },
        maximofuturo() {
            if (this.forcedmax) {
                //sin considerar nada ni stock
                if (this.$dayjs().isSameOrAfter(this.forcedmax, "day")) {
                    return this.$dayjs()
                        .add(2, "w")
                        .format("YYYYMMDD");
                } else {
                    return this.forcedmax;
                }
            } else {
                let maxstock = String(this.maxexpEsquema);
                return this.campanaEnFoco.fin && this.campanaEnFoco.fin < maxstock ? this.campanaEnFoco.fin : maxstock;
            }
        },
        minimumDate() {
            let potencialminio;
            if (this.forcedmin) {
                potencialminio = this.$dayjs(this.forcedmin)
                    .subtract(1, "d")
                    .format("YYYYMMDD");
            } else {
                potencialminio = this.$dayjs().isAfter(this.campanaEnFoco.ini)
                    ? this.$dayjs()
                          .subtract(1, "d")
                          .format("YYYYMMDD")
                    : this.$dayjs(this.campanaEnFoco.ini)
                          .subtract(1, "d")
                          .format("YYYYMMDD");
            }

            if (this.$dayjs().isSameOrAfter(potencialminio, "day")) {
                potencialminio = this.$dayjs()
                    .subtract(1, "d")
                    .format("YYYYMMDD");
            }
            return potencialminio;
        },
        vacunatorioEnFoco() {
            return this.$store.state.vacunatorios[this.vacunatorioid];
        },
        semanamolde() {
            return this.$store.state.semanasMoldeByCenter[this.vacunatorioid] || {};
        },
    },
    created() {
        this.hoyx = this.forcedmin || this.$dayjs().format("YYYYMMDD");
    },
    mounted() {
        let monthNames = this.$dayjs.months();
        let disabledifngo = [
            {
                to: this.$dayjs(this.minimumDate).toDate(),
            },
        ];
        if (this.maximofuturo) {
            disabledifngo.push({
                from: this.$dayjs(this.maximofuturo)
                    .add(1, "d")
                    .toDate(),
            });
        }
        this.calendarInline = this.$f7.calendar.create({
            containerEl: "#lekalendar",
            locale: "es",
            value: [
                this.minimumDate
                    ? this.$dayjs(this.minimumDate)
                          .add(1, "d")
                          .toDate()
                    : new Date(),
            ],
            disabled: disabledifngo,
            events: [],
            renderToolbar: () => {
                return (
                    '<div class="toolbar calendar-custom-toolbar no-shadow">' +
                    '<div class="toolbar-inner darkerbackgris">' +
                    '<div class="left">' +
                    '<a href="#" class="link icon-only"><i class="material-icons">chevron_left</i></a>' +
                    "</div>" +
                    '<div class="center"></div>' +
                    '<div class="right">' +
                    '<a href="#" class="link icon-only"><i class="material-icons">chevron_right</i></a>' +
                    "</div>" +
                    "</div>" +
                    "</div>"
                );
            },
            on: {
                init: c => {
                    this.$$(".calendar-custom-toolbar .center").text(monthNames[c.currentMonth] + ", " + c.currentYear);
                    this.$$(".calendar-custom-toolbar .left .link").on("click", () => {
                        this.calendarInline.prevMonth();
                    });
                    this.$$(".calendar-custom-toolbar .right .link").on("click", () => {
                        this.calendarInline.nextMonth();
                    });
                },
                monthYearChangeStart: c => {
                    this.$$(".calendar-custom-toolbar .center").text(monthNames[c.currentMonth] + ", " + c.currentYear);
                    let corregido = c.currentMonth + 1;
                    let strigeado = corregido < 10 ? "0" + corregido : String(corregido);
                    this.gestEsteMes(String(c.currentYear), strigeado);
                },
                change: (c, v) => {
                    let nueox = this.$dayjs(v[0]).format("YYYYMMDD");
                    if (nueox != this.hoyx) {
                        this.hoyx = nueox;
                        this.cargarDia();
                    }
                },
            },
        });

        if (!this.$dayjs().isSame(this.hoyx, "M")) {
            this.calendarInline.setYearMonth(this.$dayjs(this.hoyx).format("YYYY"), this.$dayjs(this.hoyx).format("M") - 1);
            this.cargaInicial(false);
        } else {
            this.cargaInicial(true);
        }
    },
    methods: {
        async agendarHora(slote) {
            try {
                this.$f7.dialog.preloader(this.$t("eligehora.solicitandoAgendamiento"));
                let datous = await this.commonExecute(
                    {
                        diaid: this.fechaSelectDayId,
                        esquemaid: this.forcedSchemaId,
                        campanaid: this.chosenCampanaID,
                        tercero: this.agendando_a_tercero,
                        vacunatorio: this.vacunatorioid,
                        ano: this.$dayjs(this.hoyx).format("YYYY"),
                        mes: this.$dayjs(this.hoyx).format("MM"),
                        dia: this.$dayjs(this.hoyx).format("DD"),
                        hini: slote.i,
                    },
                    "mevacuno-agendarHora",
                    false
                );
                if (datous.error) {
                    throw new Error(datous.error.message);
                }

                //pedir la info si no existe
                let cumulator = [];
                let maptargets = {};
                if (!this.$store.state.campanas[datous.payload.cmp]) {
                    maptargets[datous.payload.cmp] = "campanas";
                    cumulator.push(
                        this.$firebase
                            .database()
                            .ref(`campanas/${datous.payload.cmp}`)
                            .once("value")
                    );
                }
                if (!this.$store.state.vacunatorios[datous.payload.cid]) {
                    // console.log('no enconro vacunatorio, pidiendo')
                    maptargets[datous.payload.cid] = "vacunatorios";
                    cumulator.push(
                        this.$firebase
                            .database()
                            .ref(`vacunatorios/${datous.payload.cid}`)
                            .once("value")
                    );
                }
                if (cumulator.length) {
                    let responsas = await Promise.all(cumulator);
                    responsas.forEach(unpotsnap => {
                        //console.log('guardnado en',maptargets[unpotsnap.key])
                        this.$store.commit("updtObjProperty", { what: maptargets[unpotsnap.key], idx: unpotsnap.key, to: unpotsnap.val() });
                    });
                }
                this.$f7.dialog.close();
                //  console.log('Respuesta AGENDAMIENTO',datous.payload);
                //fin pedir info si no existe
                this.$store.commit("updtObjProperty", { what: "agenda", to: datous.payload, idx: datous.payload.id });
                this.avisaOk(this.$t("eligehora.agendamientoExito"));
                this.$emit("elige", datous.payload);
            } catch (error) {
                if (error.message == "Acaban de tomar esta hora disponible, favor seleccione otra hora.") {
                    let indiexist = this.diaenFoco.findIndex(unfc => unfc.i == slote.i);
                    if (indiexist != -1) {
                        let nueo = {
                            i: slote.i,
                            t: slote.t,
                            disponibles: 0,
                        };
                        this.diaenFoco.splice(indiexist, 1, nueo);
                    }
                }
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
        async cargaInicial(conmes) {
            if (!Object.values(this.semanamolde).length) {
                await this.getsemanamolde();
            }
            let aidado = this.$dayjs(this.hoyx);
            let ano = aidado.format("YYYY");
            let mes = aidado.format("MM");
            let dia = aidado.format("DD");
            this.getEsteDia(ano, mes, dia);
            if (conmes) {
                this.gestEsteMes(ano, mes);
            }
        },
        async getsemanamolde() {
            // console.log('tratanod de cargar SEMANA MOLDE')
            try {
                this.$f7.dialog.preloader(this.$t("agenda.cargandoAgenda"));
                let pregamol = await this.$firebase
                    .database()
                    .ref(`agenda_molde/${this.vacunatorioid}/activa`)
                    .once("value");
                this.$f7.dialog.close();
                if (!pregamol.exists()) {
                    return this.$f7.dialog.alert(this.$t("eligehora.vacunatorioSinAgendaWeb"), this.$t("eligehora.sinAgendaPublicada"), () => {
                        this.$emit("cierrame");
                    });
                } else {
                    return this.$store.commit("updtObjProperty", { what: "semanasMoldeByCenter", idx: this.vacunatorioid, to: pregamol.val() });
                }
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message, "Error");
            }
        },

        async gestEsteMes(ano, mes) {
            this.cargandoMes = true;
            let datous = await this.commonExecute(
                { vacunatorioid: this.vacunatorioid, ano, mes, min: this.minimumDate, max: this.maximofuturo },
                "mevacuno-getslotsmes",
                false
            );
            if (datous.error) {
                throw new Error(datous.error.message);
            }
            this.pintarCal(ano, mes, datous.payload);
            this.cargandoMes = false;
        },
        async getEsteDia(ano, mes, dia) {
            this.cargandoDia = true;
            this.diaenFoco = [];
            let agendaosEseDia = await this.$firebase
                .database()
                .ref("agenda_tomadas/" + this.vacunatorioid + "/" + ano + "/" + mes + "/" + dia)
                .once("value");
            let agendadosDatao = agendaosEseDia.val() || {};
            //console.log('YA AGENDAOS',agendadosDatao)
            let diaid = this.$dayjs(`${ano}${mes}${dia}`).format("dd");
            let diabase = this.semanamolde[diaid] || {};
            let apintar = Object.values(diabase)
                .filter(hinidata => {
                    return this.$dayjs().isSameOrBefore(`${ano}${mes}${dia} ${hinidata.i} `, "YYYYMMDD HH:mm", "m");
                })
                .map(hinidata => {
                    hinidata.disponibles = 0;
                    //let si hay publicados
                    if (hinidata.c && hinidata.c.length) {
                        hinidata.c.forEach(unaconcurrencia => {
                            //ver si está agendada ya esta concurrencia
                            if (!agendadosDatao[hinidata.i] || !agendadosDatao[hinidata.i][`c_${unaconcurrencia.i}`]) {
                                hinidata.disponibles++;
                            }
                        });
                    }
                    return hinidata;
                });
            this.diaenFoco = apintar;
            //console.log('this.diaenFoco',this.diaenFoco);
            this.cargandoDia = false;
        },
        pintarCal(ano, mes, diasEsteMes) {
            let eventos = diasEsteMes
                .filter(dia => {
                    return (
                        this.$dayjs(ano + "-" + mes + "-" + dia).isSameOrBefore(this.maximofuturo, "d") &&
                        this.$dayjs(ano + "-" + mes + "-" + dia).isAfter(this.minimumDate, "d")
                    );
                })
                .map(dia => {
                    return {
                        date: this.$dayjs(ano + "-" + mes + "-" + dia).toDate(),
                        color: "#009688",
                    };
                });
            this.calendarInline.params.events = eventos;
            this.calendarInline.update();
        },
        cargarDia() {
            let aidado = this.$dayjs(this.hoyx);
            let ano = aidado.format("YYYY");
            let mes = aidado.format("MM");
            let dia = aidado.format("DD");
            this.getEsteDia(ano, mes, dia);
        },
        cambiardia(futuro) {
            if ((futuro && this.hoyx === this.maximofuturo) || (!futuro && this.hoyx === this.minimumDate)) {
                return false;
            }
            let nuevafecha;
            if (futuro) {
                nuevafecha = this.$dayjs(this.hoyx).add(1, "d");
            } else {
                nuevafecha = this.$dayjs(this.hoyx).subtract(1, "d");
            }
            if (nuevafecha.isBefore(this.hoyx, "month") || nuevafecha.isAfter(this.hoyx, "month")) {
                this.calendarInline.setYearMonth(nuevafecha.format("YYYY"), nuevafecha.format("M") - 1);
            }
            this.calendarInline.setValue([this.$dayjs(nuevafecha).toDate()]);
        },
    },
};
</script>
<style>
.listamenx {
    height: calc(100% - 48px) !important;
    overflow-y: auto;
}
.horasdisp li {
    border-top: 1px solid #cecece;
}
.horasdisp li .item-title {
    font-weight: bold;
    font-size: 24px;
}
.horasdisp li .item-media svg {
    margin: 0 auto;
}
.fullh {
    height: 100%;
}
.fullhvarialbe_left {
    height: 100%;
}
.fullhvarialbe_right {
    height: 100%;
}
@media only screen and (max-width: 960px) {
    .fullhvarialbe_left {
        height: 340px !important;
    }
    .fullhvarialbe_right {
        height: calc(100% - 340px) !important;
    }
}
.esteacl .calendar-day .calendar-day-event {
    width: 8px !important;
    height: 8px !important;
    border-radius: 4px !important;
}
.darkerbackgris {
    background: #efefef !important;
}
</style>
