<template>
    <f7-card>
        <f7-card-header>{{ camp.nombre }}</f7-card-header>
        <f7-card-content>
            <f7-list v-for="(esquema, eid) in campEsquemasFechasSorted.esquemas" :key="eid" class="nowrpwhtspac fixfamarg" media-list>
                <f7-list-item divider :title="`${$t('oneRecord.esquema')}: ${esquema.nombre}`"></f7-list-item>
                <div class="timeline margin-vertical">
                    <div v-for="(undia, indx) in esquema.fechas" :key="undia.id" class="timeline-item">
                        <div class="timeline-item-date text-align-center">{{ `${$t("oneRecord.dosis")} ` + (indx + 1) }}</div>
                        <div class="timeline-item-divider"></div>
                        <div class="timeline-item-content">
                            <f7-list class="no-margin-vertical" media-list>
                                <f7-list-item
                                    v-for="unreq in undia.req"
                                    :key="unreq.id"
                                    class="titlebold"
                                    :subtitle="unreq.completado ? unreq.completado.laboratorio : null"
                                    :title="
                                        unreq.completado
                                            ? `${unreq.completado.vacuna}`
                                            : $t('oneRecord.vacunaPendiente')
                                    "
                                    :header="unreq.completado ? $d($dayjs(unreq.completado.fecha), 'long') : null"
                                    :text="unreq.completado ? unreq.completado.centro : null"
                                    :footer="unreq.completado ? `${$t('oneRecord.lote')}: ${unreq.completado.lote}` : null"
                                >
                                    <font-awesome-icon
                                        v-if="unreq.completado"
                                        class="margin-left-half"
                                        color="teal"
                                        icon="check"
                                        slot="title"
                                    ></font-awesome-icon>
                                    <font-awesome-icon
                                        v-else
                                        class="margin-left-half"
                                        color="red"
                                        icon="exclamation-triangle"
                                        slot="title"
                                    ></font-awesome-icon>
                                </f7-list-item>
                            </f7-list>
                        </div>
                    </div>
                </div>
            </f7-list>
            <f7-list v-if="camp.refuerzos && Object.keys(camp.refuerzos).length" class="no-margin-top nowrpwhtspac fixfamarg" media-list>
                <f7-list-item divider :title="$tc('oneRecord.refuerzo', Object.keys(camp.refuerzos).length)"></f7-list-item>
                <div class="timeline margin-vertical">
                    <div v-for="(refz, indx) in sortedRefuerzos" :key="refz.id" class="timeline-item">
                        <div class="timeline-item-date text-align-center">{{ indx + 1 }}</div>
                        <div class="timeline-item-divider"></div>
                        <div class="timeline-item-content">
                            <f7-list class="no-margin-vertical" media-list>
                                <f7-list-item
                                    class="titlebold"
                                    :subtitle="refz.laboratorio"
                                    :title="refz.vacuna"
                                    :header="$dayjs(refz.fecha).format('DD MMM YYYY')"
                                    :text="refz.centro"
                                    :footer="`${$t('oneRecord.lote')}: ${refz.lote}`"
                                >
                                    <font-awesome-icon class="margin-left-half" color="teal" icon="check" slot="title"></font-awesome-icon>
                                </f7-list-item>
                            </f7-list>
                        </div>
                    </div>
                </div>
            </f7-list>
             <f7-list v-if="camp.internacionales && Object.keys(camp.internacionales).length" class="no-margin-top nowrpwhtspac fixfamarg" media-list>
                <f7-list-item divider :title="$tc('oneRecord.vacInter', Object.keys(camp.internacionales).length)"></f7-list-item>
                <div class="timeline margin-vertical">
                    <div v-for="(refz, indx) in sortedInternacionales" :key="refz.id" class="timeline-item">
                        <div class="timeline-item-date text-align-center">{{ indx + 1 }}</div>
                        <div class="timeline-item-divider"></div>
                        <div class="timeline-item-content">
                            <f7-list class="no-margin-vertical" media-list>
                                <f7-list-item
                                    class="titlebold"
                                    :subtitle="refz.laboratorio"
                                    :title="refz.vacuna"
                                    :header="$dayjs(refz.fecha).format('DD MMM YYYY')"
                                    :text="refz.centro"
                                    :footer="`${$t('oneRecord.lote')}: ${refz.lote} | ${refz.dd || '-'} | ${refz.xd || '-'}`"
                                >
                                    <font-awesome-icon class="margin-left-half" color="teal" icon="check" slot="title"></font-awesome-icon>
                                </f7-list-item>
                            </f7-list>
                        </div>
                    </div>
                </div>
            </f7-list>
        </f7-card-content>
        <f7-card-footer v-if="!notificarEstadoVacunasIngreso || (notificarEstadoVacunasIngreso && vacunasValidasToIngreso)">
            <f7-button color="red" @click="$emit('descargapdf', { campanaid: camp.id, evento: $event })" href="#" fill raised>
                <font-awesome-icon icon="file-pdf" class="fa-lg"></font-awesome-icon>
                    {{ $t("commons.descargar") }}
            </f7-button>
            <template v-if="(camp.id === '-MSxHZSB7GpMsVDfVtZb' || camp.id === '-MSm5MP2qjUBkMhulcNd') && this.viajero">
              <f7-button color="yellow" @click="$emit('veopcr')" href="#" fill raised ><font-awesome-icon icon="vial" class="fa-lg"></font-awesome-icon> PCR </f7-button>
            </template>
            <f7-button @click="$emit('abrepass', camp)" href="#" fill raised
                ><font-awesome-icon icon="qrcode" class="fa-lg"></font-awesome-icon> {{ $t("commons.ver") }}
            </f7-button>
        </f7-card-footer>
    </f7-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { commonmixin } from '../mixins/common'
export default {
  props:['camp'],
  mixins: [commonmixin],

    data() {
      return {
        viajero: false

      }
    },
    computed:{
      ...mapState(['viajeroActivo', "vacunasValidasToIngreso", "notificarEstadoVacunasIngreso"]),
      ...mapGetters(['privxInfo']),

      campEsquemasFechasSorted() {
        if (!this.camp.esquemas) return [];

        let esquemas = {};

        Object.keys(this.camp.esquemas).forEach(esquemaId => {
            const esquema = this.camp.esquemas[esquemaId];

            const formattedVacs = Object.values(esquema.fechas || {}).sort((a, b) => {
                return a.dnum - b.dnum;
            });

            esquemas[esquemaId] = {
                ...esquema,
                fechas: formattedVacs
            };
        });

        let campCopy = JSON.parse(JSON.stringify(this.camp));
        this.$set(campCopy, "esquemas", esquemas);

        return campCopy;
      },
      sortedRefuerzos() {
        return this.sortByDate(this.camp.refuerzos || {});
      },
      sortedInternacionales() {
        return this.sortByDate(this.camp.internacionales || {});
      },
    },
    methods:{

        sortByDate(vacs) {
            if (!vacs || Object.values(vacs || {}).length === 0) {
                return [];
            }

            return Object.values(vacs).sort((a, b) => {
                return this.$dayjs(b.fecha).isBefore(this.$dayjs(a.fecha)) ? 1 : -1;
            });
        }
    }
}
</script>
<style></style>
