<template>
  <f7-popup
    :opened="opened"
    @popup:closed="onPopupClosed"
    @popup:open="onPopupOpen"
    :close-by-backdrop-click="false"
  >
    <f7-page>
      <f7-navbar :title="$t('solicitudesMain.solicitarApostilla')">
        <f7-nav-right>
            <f7-link @click="onPopupClosed">{{ $t('commons.cancelar') }}</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-block>
        <p>
          {{ $t("solicitudesMain.infoPopupApostilla") }}
        </p>

        <f7-list>
          <f7-list-item
            class="margin-vertical"
            smart-select
            :smart-select-params="{
                openIn: 'popup',
                searchbar: { removeDiacritics: true },
                searchbarPlaceholder: $t('homologacionCreate.buscarPais'),
                popupCloseLinkText: $t('commons.cerrar'),
                closeOnSelect: true,
                virtualList: true,
                setValueText: false,
            }"
            :disabled="!paisesApostilla.length"
            required
          >
            <div slot="title">{{ $t("solicitudNacCreate.paisApostillamiento") }} <span class="text-color-red">*</span></div>
            <div slot="after">{{ pais.descripcion || "" }}</div>
            <select @change="handleCountrySelected($event.target.value)" :value="pais.id" name="pais">
                <option v-for="(p, index) in paisesApostilla" :key="index" :value="p.id">
                    {{ p.descripcion }}
                </option>
            </select>
          </f7-list-item>
        </f7-list>

        <f7-button
          class="margin-vertical"
          raised
          fill
          :disabled="!pais.id"
          @click="sendRequest()"
        >
          {{ $t("solicitudesMain.ingresarSolicitud") }}
        </f7-button>

        <p>
          <small><b>{{ $t("solicitudesMain.Importante") }}:</b></small>
          <small>{{ $t("solicitudesMain.detalleProcesoPosterior") }}</small>
        </p>
      </f7-block>
    </f7-page>
  </f7-popup>
</template>

<script>
import { commonmixin } from "../../../mixins/common";
export default {
  mixins: [commonmixin],

  props: {
    opened: Boolean,
    solicitud: Object,
  },

  data() {
    return {
      pais: {
        id: null,
        descripcion: null,
      },
    };
  },

  computed: {
    paisesApostilla() {
      return this.$store.getters.paisesApostillaArraySorted || [];
    },
  },

  methods: {
    onPopupClosed() {
      this.pais = {
        id: null,
        descripcion: null,
      };
      this.$emit("popup-closed");
    },

    async onPopupOpen() {
      try {
        if (!this.$store.getters.paisesApostillaArraySorted.length) {
          this.$f7.dialog.preloader(this.$t("commons.cargandoPaises"));
          const response = await this.commonExecute({}, "mevacuno-getListaPaisesApostilla");

          if (response.error) {
              throw new Error(response.error.message || response.error.code || response.error);
          }

          this.$store.commit("setWhatTo", { what: "paisesApostilla", to: response.payload });

          this.$f7.dialog.close();
        }
      } catch (error) {
        console.error(error.message);
        this.$f7.dialog.close();
        this.$f7.dialog.alert(error.message, error.code);
      }
    },

    async handleCountrySelected(id) {
      try {
          const country = this.paisesApostilla.find(country => country.id === id);
          if (!country) {
              this.$f7.dialog.close();
              this.$f7.dialog.alert(this.$t("homologacionCreate.paisNotFound"));
              return;
          }

          this.pais = {
              id: country.id,
              descripcion: country.descripcion,
          };
      } catch (error) {
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message, error.code);
      }
    },

    async sendRequest() {
      //validar que no tenga una solicitud de apostilla en curso
      if (this.solicitud.actApos) {
        this.$f7.dialog.alert(this.$t("solicitudesMain.solicitudApostillaEnCurso"));
        return;
      }

      //validar que no tenga en el historial una solicitud de apostilla para el mismo pais
      if (this.solicitud.apos) {
        const apostillaEnHistorial = Object.values(this.solicitud.apos).find(historial => historial.id === this.pais.id);
        if (apostillaEnHistorial) {
          this.$f7.dialog.alert(this.$t("solicitudesMain.solicitudApostillaEnHistorial", { pais: this.pais.descripcion }));
          return;
        }
      }
      
      this.$emit("send-request", { pais: this.pais, solicitud: this.solicitud });
    }
  },
}
</script>