<template>
    <f7-page @page:beforein="precargaterceros()" name="terceros">
        <!-- Top Navbar -->
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title subtitle="Agendamiento para terceros" title="Terceros"></f7-nav-title>
        </f7-navbar>

        <f7-block v-if="!privxInfo.identity || !privxInfo.verified"
            >{{ !privxInfo.identity ? $t("terceros.debeDeclarar") : $t("terceros.debeVerificar") }} {{ !privxInfo.verified ? $t("terceros.usandoClaveUnica", { staticText: "Clave Única" }) : "" }} {{ $t("terceros.enElMenu") }} "<font-awesome-icon icon="user-circle" /> <b>{{ $t("leftpanel.miPerfil") }}</b>", {{ $t("terceros.antesDe") }}</f7-block
        >
        <template v-else>
            <f7-block>{{ $t("agendaTercero.seleccioneTercero") }}</f7-block>
            <f7-block v-if="loadingterceros" class="text-align-center"><f7-preloader></f7-preloader><br />{{ $t("terceros.cargandoTerceros") }}</f7-block>
            <f7-card class="margin-vertical" v-for="untercero in misTerceros" :key="untercero.id">
                <f7-card-content>
                    <f7-list class="titlebold" media-list>
                        <f7-list-item
                            :title="`${untercero.apellido1} ${untercero.apellido2}, ${untercero.nombres} `"
                            :subtitle="fraseIdentity(untercero)"
                            :text="`${suage(untercero.fechanac)} años`"
                        >
                            <img src="../static/heros/avatar.jpg" class="untercerox" slot="media" />
                        </f7-list-item>
                    </f7-list>
                </f7-card-content>
                <f7-card-footer><span></span> <f7-button @click="creoterceri(untercero.id)" raised fill>{{ $t("terceros.elegirCamp") }}</f7-button></f7-card-footer>
            </f7-card>

            <f7-block v-if="misTerceros.length" class="margin-vertical text-align-center forcedheight"
                ><font-awesome-icon icon="flag-checkered"></font-awesome-icon
            ></f7-block>
        </template>

        <f7-fab v-if="privxInfo.identity && privxInfo.verified" @click="crearNuevoTercero()" position="right-bottom" slot="fixed" color="teal">
            <font-awesome-icon class="fa-lg" icon="user-plus"></font-awesome-icon>
        </f7-fab>

        <f7-popup :opened="crearContacto" @popup:closed="crearContacto = false">
            <f7-view name="inpop">
                <f7-page>
                    <f7-navbar :title="$t('commons.declararIdentidad')" :subtitle="$t('tercero.aUnTercero')">
                        <f7-nav-right>
                            <f7-link popup-close>{{ $t("commons.cerrar") }}</f7-link>
                        </f7-nav-right>
                    </f7-navbar>
                    <declare-identity :mysub="{}" :tercero="true" @creeTercero="creoterceri" v-if="crearContacto"></declare-identity>
                </f7-page>
            </f7-view>
        </f7-popup>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import declareIdentity from "../components/declareIdentity.vue";
import { commonmixin } from "../mixins/common";

export default {
    mixins: [commonmixin],
    components: {
        declareIdentity,
    },
    data() {
        return {
            crearContacto: false,
            loadingterceros: false,
        };
    },
    computed: {
        ...mapState(["user", "dev"]),
        ...mapGetters(["tieneConflicto", "privxInfo", "misTerceros"]),
        noTengoTerceros() {
            return !this.misTerceros || !Object.keys(this.misTerceros).length;
        },
    },
    methods: {
        async precargaterceros() {
            if (this.noTengoTerceros) {
                try {
                    this.loadingterceros = true;
                    let potercers = await this.$firebase
                        .database()
                        .ref("agndmts_teceros")
                        .orderByChild("by")
                        .equalTo(this.user.uid)
                        .once("value");
                    if (potercers.exists()) {
                        this.$store.commit("setObjTo", { what: "misTerceros", to: potercers.val() });
                    }
                    this.loadingterceros = false;
                } catch (error) {
                    this.loadingterceros = false;
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            }
        },
        crearNuevoTercero() {
            //console.log('Creando nuevo tercero');
            this.crearContacto = true;
        },
        creoterceri(idtercero) {
            this.crearContacto = false;
            //console.log('Creo esta ID de tercero, ahora llevar a elegir campana',idtercero);
            this.$store.commit("setWhatTo", { what: "agendando_a_tercero", to: idtercero });
            this.$f7.views.main.router.navigate("/campanas/");
        },
    },
};
</script>
<style>
.untercerox {
    width: 40px;
}
</style>
