import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);
//store mevacuno.cl
export const store = new Vuex.Store({
    state: {
        activeTab: "/",
        agenda: {},
        appVersion: "1.44.2",
        chao: false,
        dev: process.env.NODE_ENV === "production" ? false : true,
        usingEmulators: process.env.USE_FUNCTIONS_EMULATORS === "true",
        emulatorsUrl:
            process.env.FUNCTIONS_EMULATOR_HOST && process.env.FUNCTIONS_EMULATOR_PORT
                ? `http://${process.env.FUNCTIONS_EMULATOR_HOST}:${process.env.FUNCTIONS_EMULATOR_PORT}/mevacuno-pro${
                      process.env.NODE_ENV === "production" ? "" : "-dev"
                  }/us-central1`
                : null,
        apiUrl: `https://us-central1-mevacuno-pro${process.env.NODE_ENV === "production" ? "" : "-dev"}.cloudfunctions.net`,
        paises: {},
        paisesApostilla: [],
        decl_id_pais: "XX",
        agendando_a_tercero: null,
        chosenCampanaID: null,
        agendando_vaccenterid: null,
        agendando_cart: null,
        campanas: {},
        forms: {},
        hoy: null,
        loggingin: false,
        lastFullLoad: {},
        cerrandoSesion: false,
        misTerceros: {},
        misVacunas: {},
        myContactInfo: {
            phone: {},
            email: {},
            direccion: {},
            fetched: null,
        },
        myCampanaStatusCode: {},
        seguimientosPending: {},
        semanasMoldeByCenter: {},
        user: {
            privx: {},
        },
        vacunas: {},
        vacunatorios: {},
        internacional: false,
        tieneSolicitudPendiente: false,
        viajeroActivo: false,

        // Homologación de vacunas
        vacunasHomologacion: {},
        vacunasHomologacionNac: {},
        tieneSolicitudEnConflicto: false,
        fullVacExtranjeras: false,
        vacunasValidasToIngreso: false,
        notificarEstadoVacunasIngreso: false,
        regiones: {},
    },
    getters: {
        getApiUrl: state => {
            return state.usingEmulators && state.emulatorsUrl ? state.emulatorsUrl : state.apiUrl;
        },
        usrtrxDatabaseUrl: state => {
            return state.dev ? "https://usrtrx-mvcn-dev.firebaseio.com" : "https://usrtrx-mvcn.firebaseio.com";
        },
        campanasPubs: state => {
            let prearreglado = Object.values(state.campanas) || [];
            let filtradas = prearreglado.filter(unacamp => {
                return unacamp.pubfin && unacamp.pubfin >= state.hoy;
            });
            filtradas.sort((a, b) => {
                if (a.ini > b.ini) {
                    return 1;
                } else if (a.ini < b.ini) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return filtradas;
        },
        campanas: state => {
            let prearreglado = Object.values(state.campanas) || [];
            prearreglado.sort((a, b) => {
                if (a.ini > b.ini) {
                    return 1;
                } else if (a.ini < b.ini) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return prearreglado;
        },
        oneCampana: state => {
            return id => {
                return state.campanas[id];
            };
        },
        agendaSorted: state => {
            let prearreglado = Object.values(state.agenda) || [];
            prearreglado.sort((a, b) => {
                if (a.fch > b.fch) {
                    return 1;
                } else if (a.fch < b.fch) {
                    return -1;
                } else {
                    if (a.hin > b.hin) {
                        return 1;
                    } else if (a.hin < b.hin) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            });
            return prearreglado;
        },
        getUno: state => {
            return (fuente, id) => {
                return state[fuente][id];
            };
        },
        paisesArraySorted: state => {
            let prearreglado = Object.values(state.paises) || [];
            prearreglado.sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                } else if (a.name < b.name) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return prearreglado;
        },
        paisesApostillaArraySorted: state => {
            let prearreglado = state.paisesApostilla || [];
            prearreglado.sort((a, b) => {
                if (a.descripcion > b.descripcion) {
                    return 1;
                } else if (a.descripcion < b.descripcion) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return prearreglado;
        },
        unPais: state => {
            return id => {
                return state.paises[id] || null;
            };
        },
        privxInfo: state => {
            return state.user.privx || {};
        },
        mitercero: state => {
            return id => {
                return state.misTerceros[id];
            };
        },
        misTerceros: state => {
            let prearreglado = Object.values(state.misTerceros) || [];
            prearreglado.sort((a, b) => {
                if (a.apellido1 > b.apellido1) {
                    return 1;
                } else if (a.apellido1 < b.apellido1) {
                    return -1;
                } else {
                    if (a.apellido2 > b.apellido2) {
                        return 1;
                    } else if (a.apellido2 < b.apellido2) {
                        return -1;
                    } else {
                        if (a.nombres > b.nombres) {
                            return 1;
                        } else if (a.nombres < b.nombres) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }
                }
            });
            return prearreglado;
        },
        misVacunas: state => {
            let prearreglado = Object.keys(state.misVacunas || {}).map(unakey => {
                let es = state.misVacunas[unakey];
                es.id = unakey;
                return es;
            });

            prearreglado.sort((a, b) => {
                if (a.ultim > b.ultim) {
                    return 1;
                } else if (a.ultim < b.ultim) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return prearreglado;
        },
        seguimientosPending: state => {
            let prearreglado = Object.values(state.seguimientosPending) || [];
            prearreglado.sort((a, b) => {
                if (a.dt > b.dt) {
                    return 1;
                } else if (a.dt < b.dt) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return prearreglado;
        },
        sortedVacunasHomologacion: state => {
            return Object.values(state.vacunasHomologacion).sort((a, b) => a.vacunaPcName.localeCompare(b.vacunaPcName));
        },
        sortedVacunasHomologacionNac: state => {
            return Object.values(state.vacunasHomologacionNac).sort((a, b) => a.vacunaPcName.localeCompare(b.vacunaPcName));
        },
        tieneConflicto: state => {
            let privx = state.user.privx || {};
            return privx.conflicto;
        },
        vacunas: state => {
            let prearreglado = Object.values(state.vacunas) || [];
            prearreglado.sort((a, b) => {
                if (a.namenorm > b.namenorm) {
                    return 1;
                } else if (a.namenorm < b.namenorm) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return prearreglado;
        },
        vacuna: state => {
            return id => {
                return state.vacunas[id];
            };
        },
        sortedRegiones: state => {
            let prearreglado = Object.values(state.regiones) || [];
            prearreglado.sort((a, b) => {
                if (a.orden > b.orden) {
                    return 1;
                } else if (a.orden < b.orden) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return prearreglado;
        }
    },
    mutations: {
        SET_INTER: (state, estado) => {
            state.internacional = estado;
        },
        setWhatTo: (state, pyld) => {
            state[pyld.what] = pyld.to;
            state.lastFullLoad[pyld.what] = Date.now();
        },
        pushToArray: (state, pyld) => {
            state[pyld.array].push(pyld.value);
        },
        delObje: (state, pyld) => {
            let clonao = Object.assign({}, state[pyld.what]);
            delete clonao[pyld.cual];
            state[pyld.what] = Object.assign({}, clonao);
        },
        removeFromArray: (state, pyld) => {
            let indiexist = state[pyld.array].findIndex(unitem => unitem[pyld.attr] == pyld.value);
            if (indiexist != -1) {
                state[pyld.array].splice(indiexist, 1);
            }
        },
        updtObjProperty: (state, pyld) => {
            let nueo = {};
            if (!pyld.idx) {
                nueo[pyld.to.id] = pyld.to;
            } else {
                nueo[pyld.idx] = pyld.to;
            }
            state[pyld.what] = Object.assign({}, state[pyld.what], nueo);
        },
        mergeObjTo: (state, pyld) => {
            state[pyld.what] = Object.assign({}, state[pyld.what], pyld.to);
        },
        setObjTo: (state, pyld) => {
            state[pyld.what] = Object.assign({}, pyld.to);
            state.lastFullLoad[pyld.what] = Date.now();
        },
        removeFromSimpleArray(state, pyld) {
            let index = state[pyld.array].findIndex(item => item === pyld.value);
            if (index !== -1) {
                state[pyld.array].splice(index, 1);
            }
        },
        updateMyPrivx: (state, newPrivx) => {
            if (!state.user.privx) {
                let neo = {
                    privx: newPrivx,
                };
                state.user = Object.assign({}, state.user, neo);
            } else {
                state.user.privx = Object.assign({}, state.user.privx, newPrivx);
            }
        },
        updateUserInfo: (state, p) => {
            state.user[p.cual] = p.valor;
        },
        updateUserPrefContactAddress: (state, address) => {
            let newAddress = Object.assign({}, address);
            state.user.privx.address = newAddress;
        },
        agregarInfoContacto: (state, p) => {
            state.myContactInfo[p.cual] = Object.assign({}, state.myContactInfo[p.cual], p.neo);
        },
        eliminarInfoContacto: (state, p) => {
            let previas_ese_tipo_contacto = Object.assign({}, state.myContactInfo[p.cual] || {});
            delete previas_ese_tipo_contacto[p.id];
            state.myContactInfo[p.cual] = Object.assign({}, previas_ese_tipo_contacto);
        },
        definirContactoFavorito: (state, p) => {
            if (!state.user.privx.contc_pref) {
                let nueo = {
                    contc_pref: {},
                };
                nueo.contc_pref[p.cual] = p.valor;
                state.user.privx = Object.assign({}, state.user.privx, nueo);
            } else if (!state.user.privx.contc_pref[p.cual]) {
                let nueo2 = {};
                nueo2[p.cual] = p.valor;
                state.user.privx.contc_pref = Object.assign({}, state.user.privx.contc_pref, nueo2);
            } else {
                state.user.privx.contc_pref[p.cual] = p.valor;
            }
        },
        updateSocialName: (state, socialName) => {
            state.user.privx.socialName = socialName;
        },
    },
    actions: {
        setInter: ({ commit }, estado) => {
            commit("SET_INTER", estado);
        },
    },
});
