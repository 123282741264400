<template>
    <f7-login-screen :opened="loginOpened" id="my-login-screen">
        <f7-view>
            <f7-page :class="`mylogin back${bg}`" login-screen>
                <f7-block class="text-align-center no-margin-vertical padding-top mevaclogo">
                    <img class="dedos" src="../static/logos/banner_mevacuno.png" />
                </f7-block>

                <f7-block class="login-bccly no-margin-vertical text-align-center">
                    <span v-if="chao">{{ $t("commons.sesionCerrada") }}</span>
                    <span v-else-if="loading">{{ $t("commons.cargando") }}</span>
                    <span v-else-if="cerrandoSesion">{{ $t("commons.cerrandoSesion") }}</span>
                </f7-block>

                <f7-row v-if="chao"><f7-col></f7-col></f7-row>
                <f7-row class="altox" v-else-if="loading">
                    <f7-col
                        ><f7-button class="padtop" large><f7-preloader></f7-preloader></f7-button
                    ></f7-col>
                </f7-row>
                <f7-row class="altox" v-else-if="cerrandoSesion">
                    <f7-col
                        ><f7-button class="padtop" large><f7-preloader></f7-preloader></f7-button
                    ></f7-col>
                </f7-row>

                <f7-block v-if="!loading && !cerrandoSesion">
                    <f7-card class="elevation-5">
                        <f7-card-content class="no-padding">
                            <f7-toolbar tabbar top style="border-radius: 10px">
                                <f7-link tab-link="#tab-1" tab-link-active class="overflow-tab padding-horizontal-half">{{
                                    $t("login.claveUnicaOCedula")
                                }}</f7-link>
                                <f7-link tab-link="#tab-2" class="overflow-tab padding-horizontal-half">{{ $t("login.correo") }}</f7-link>
                            </f7-toolbar>
                            <f7-tabs class="padding-vertical">
                                <f7-tab id="tab-1" class="page-content padding" tab-active>
                                    <f7-block class="text-align-center margin-bottom">
                                        <div class="text-color-primary bold">
                                            {{ $t("login.loginClaveUnicaText") }}
                                        </div>
                                        <clave-unica class="margin-top"></clave-unica>
                                    </f7-block>
                                    <f7-block class="text-align-center text-color-primary margin-top bold no-margin-bottom">
                                        <div class="text-color-primary bold display-flex align-items-center justify-content-center">
                                            {{ $t("login.orSerialNumberText") }}
                                            <f7-button popover-open=".info-ced-identidad">
                                                <font-awesome-icon icon="info-circle" />
                                            </f7-button>
                                        </div>
                                        <f7-button :class="{ disabled: loggingin }" class="margin-top" @click="loginSerie = true" large fill>{{
                                            $t("commons.serialNumber")
                                        }}</f7-button>
                                    </f7-block>
                                </f7-tab>
                                <f7-tab id="tab-2" class="page-content padding">
                                    <div>
                                        <f7-block class="text-align-center text-color-primary bold no-margin-bottom">{{
                                            $t("login.loginWithEmailAndPassword")
                                        }}</f7-block>

                                        <f7-list media-list class="logininputs no-margin-top">
                                            <f7-list-input
                                                :label="$t('commons.email')"
                                                :value="email"
                                                type="email"
                                                @input="email = $event.target.value"
                                                :clear-button="!loggingin"
                                                :readonly="loggingin"
                                            >
                                                <font-awesome-icon icon="at" class="fa-2x " slot="media" />
                                            </f7-list-input>
                                            <f7-list-input
                                                :label="$t('commons.password')"
                                                :value="password"
                                                @keyup.native.enter="ingresarConemailpass()"
                                                type="password"
                                                @input="password = $event.target.value"
                                                :clear-button="!loggingin"
                                                :readonly="loggingin"
                                            >
                                                <font-awesome-icon icon="key" class="fa-2x " slot="media" />
                                            </f7-list-input>
                                        </f7-list>

                                        <f7-block calss="margin-top text-align-center">
                                            <f7-button @click="ingresarConemailpass()" large fill class="margin-vertical"
                                                ><f7-preloader color="white" v-if="loggingin"></f7-preloader
                                                ><span v-else>{{ $t("login.acceder") }}</span></f7-button
                                            >
                                            <f7-link class="fullwidth margin-bottom-half" @click="forgotMyPass()" :class="{ disabled: loggingin }">{{
                                                $t("login.recuperarPassword")
                                            }}</f7-link></f7-block
                                        >

                                        <f7-block>
                                            <p class="text-align-center gray margin-bottom-half">
                                                {{ $t("login.noTienesCuentaText") }}
                                            </p>
                                            <f7-button :class="{ disabled: loggingin }" @click="accountCreatroOpened = true" outline>{{
                                                $t("login.createAccountButton")
                                            }}</f7-button>
                                        </f7-block>
                                    </div>
                                </f7-tab>
                            </f7-tabs>
                        </f7-card-content>
                    </f7-card>
                </f7-block>

                <f7-block class="text-align-center no-margin-bottom">
                    <f7-button fill color="teal" @click="goToGeoVac"
                        ><font-awesome-icon class="margin-right-half" icon="map-marked-alt"></font-awesome-icon>Vacunatorios Disponibles
                    </f7-button>
                </f7-block>

                <f7-block class="text-align-center no-margin-bottom"
                    ><f7-link @click="terminosOpened = true" :tooltip="$t('login.termsTooltip')"
                        ><font-awesome-icon class="margin-right-half" icon="info-circle"></font-awesome-icon>{{ $t("commons.terms") }}</f7-link
                    ></f7-block
                >

                <f7-block class="text-align-center no-margin-bottom"
                    ><f7-link
                        external
                        target="_blank"
                        href="https://s3.amazonaws.com/gobcl-prod/public_files/Campa%C3%B1as/Vacunas-para-Chile/archivos/MANUAL_MEVACUNO.CL_final.pdf"
                        ><font-awesome-icon class="margin-right-half" icon="book-open"></font-awesome-icon
                        >{{ $t("login.manualUsuarioTooltip") }}</f7-link
                    ></f7-block
                >

                <language-selector></language-selector>

                <f7-list class="no-margin-vertical">
                    <f7-block-footer> {{ eldomin }} &copy; 2021 </f7-block-footer>
                    <img src="../static/heros/loginback_2.jpg" class="hidemax" />
                    <img src="../static/heros/loginback_3.jpg" class="hidemax" />
                </f7-list>
                <f7-block class="text-align-center">
                    <f7-chip :media-bg-color="dev ? 'red' : 'primary'">
                        <span slot="text"
                            ><span class="bold">{{ $store.state.appVersion }}</span></span
                        >
                        <font-awesome-icon :icon="dev ? 'code' : 'syringe'" slot="media"></font-awesome-icon>
                    </f7-chip>
                </f7-block>
            </f7-page>

            <f7-popup :opened="videoOpened" @popup:closed="videoOpened = false">
                <f7-page>
                    <f7-navbar :title="$t('login.videoTutorial')">
                        <f7-nav-right>
                            <f7-link popup-close>{{ $t("commons.cerrar") }}</f7-link>
                        </f7-nav-right>
                    </f7-navbar>
                    <f7-block v-if="videoOpened">
                        <Video :url="videoURL"></Video>
                    </f7-block>
                </f7-page>
            </f7-popup>

            <f7-popup class="terminos-popip" :opened="terminosOpened" @popup:closed="terminosOpened = false">
                <f7-page>
                    <f7-navbar :title="$t('login.termsTooltip')">
                        <f7-nav-right>
                            <f7-link popup-close>{{ $t("commons.cerrar") }}</f7-link>
                        </f7-nav-right>
                    </f7-navbar>
                    <terminos></terminos>
                </f7-page>
            </f7-popup>

            <f7-popover class="info-ced-identidad">
                <img style="width: 100%" src="../static/img/cedula.jpeg" />
            </f7-popover>

            <f7-popup
                class="popnewaccount fixmedia inputpadlef"
                swipe-to-close
                swipe-handler=".swipe-handler"
                :opened="accountCreatroOpened"
                @popup:closed="accountCreatroOpened = false"
            >
                <f7-page>
                    <f7-navbar class="swipe-handler" :title="$t('login.createAccount')" :subtitle="eldomin">
                        <f7-nav-right>
                            <f7-link popup-close>{{ $t("commons.cerrar") }}</f7-link>
                        </f7-nav-right>
                    </f7-navbar>

                    <div style="height: 100%">
                        <f7-block class="text-align-justify">
                            <div v-html="$sanitize($t('login.necesitaCorreo', { domain: eldomin }))"></div>
                        </f7-block>
                        <f7-list no-hairlines-md>
                            <f7-list-input
                                :label="$t('commons.email')"
                                type="email"
                                :value="email"
                                placeholder="sucorreo@personal.cl"
                                @input="email = $event.target.value"
                                :clear-button="!creating"
                                :readonly="creating"
                            >
                                <font-awesome-icon icon="at" class="fa-2x " slot="media" />
                            </f7-list-input>

                            <f7-list-input
                                :label="$t('commons.newPassword')"
                                :type="verpass ? 'text' : 'password'"
                                :placeholder="`Contraseña para ${eldomin}`"
                                :value="password"
                                @keyup.native.enter="creteAccount()"
                                @input="password = $event.target.value"
                                :clear-button="!creating"
                                :readonly="creating"
                            >
                                <font-awesome-icon icon="key" class="fa-2x " slot="media" />

                                <f7-link slot="content" @click="verpass = !verpass" class="padding margin-top-half text-color-gray"
                                    ><font-awesome-icon :icon="verpass ? 'eye' : 'eye-slash'" class="fa-lg"
                                /></f7-link>
                            </f7-list-input>
                        </f7-list>
                        <f7-block>
                            <f7-button @click="creteAccount()" fill large
                                ><f7-preloader color="white" v-if="creating"></f7-preloader
                                ><span v-else>{{ $t("login.createAccountOnly") }}</span></f7-button
                            >
                        </f7-block>
                    </div>
                </f7-page>
            </f7-popup>

            <f7-popup
                class="popnewaccount fixmedia inputpadlef"
                swipe-to-close
                swipe-handler=".swipe-handler"
                :opened="loginSerie"
                @popup:closed="limpioLoginSerie"
            >
                <f7-page>
                    <f7-navbar class="swipe-handler" :title="$t('login.nroSerie')" :subtitle="eldomin">
                        <f7-nav-right>
                            <f7-link popup-close>{{ $t("commons.cerrar") }}</f7-link>
                        </f7-nav-right>
                    </f7-navbar>

                    <div style="height: 100%">
                        <f7-block class="text-align-justify">
                            <div v-html="$sanitize($t('login.paraAcceder', { domain: eldomin }))"></div>
                        </f7-block>
                        <f7-list no-hairlines-md>
                            <f7-list-input
                                label="RUN"
                                type="text"
                                :value="run"
                                placeholder="12345678-9"
                                :error-message-force="rutinvalido"
                                @input="run = $event.target.value"
                                :clear-button="!creatingSerie"
                                :readonly="creatingSerie"
                            >
                                <font-awesome-icon icon="edit" class="fa-2x " slot="media" />
                            </f7-list-input>

                            <f7-list-input
                                :label="$t('perfil.nroSerieDocumento')"
                                type="text"
                                :placeholder="$t('commons.serialNumber')"
                                :value="docSerie"
                                @keyup.native.enter="loginWithSerie()"
                                @input="docSerie = $event.target.value"
                                :error-message="$t('commons.formatSerieDoc')"
                                validate
                                :onValidate="isvalid => setInputDocValid(isvalid)"
                                pattern="[Aa0-9.]*"
                                :clear-button="!creatingSerie"
                                :readonly="creatingSerie"
                            >
                                <font-awesome-icon icon="id-card" class="fa-2x " slot="media" />
                            </f7-list-input>
                        </f7-list>
                        <f7-block>
                            <f7-button @click="loginWithSerie()" fill large>{{ $t("login.acceder") }}</f7-button>
                        </f7-block>
                    </div>
                </f7-page>
            </f7-popup>
        </f7-view>
    </f7-login-screen>
</template>
<script>
import { mapState } from "vuex";
import { validate, format, clean } from "rut.js";
import claveUnica from "./claveUnica.vue";
import terminos from "../components/terminos.vue";
import LanguageSelector from "../components/LanguageSelector.vue";
import Video from "../components/video.vue";
export default {
    components: {
        claveUnica,
        terminos,
        LanguageSelector,
        Video,
    },
    props: ["loginOpened", "loading", "dev"],
    data() {
        return {
            loginSelectedOption: null,
            videoOpened: false,
            bg: 1,
            accountCreatroOpened: false,
            terminosOpened: false,
            email: "",
            password: "",
            verpass: false,
            veryfying: false,
            creating: false,
            loginSerie: false,
            creatingSerie: false,
            run: "",
            docSerie: "",
            inter: false,
            docSerieValid: true,
            videoURL: "https://youtu.be/Qli4N4qNtts",
        };
    },
    computed: {
        ...mapState(["loggingin", "cerrandoSesion", "chao", "internacional"]),
        eldomin() {
            return window.location.hostname;
        },
        rutinvalido() {
            if (!validate(this.run)) {
                return true;
            } else {
                return false;
            }
        },
        esInter() {
            let estadoInter = localStorage.getItem("inter");
            if (estadoInter && estadoInter === "true") {
                this.inter = true;
            } else {
                this.inter = false;
            }
            return this.inter;
        },
        dictio() {
            return {
                "auth/invalid-email": {
                    code: this.$t("commons.emailInvalido"),
                    message: this.$t("commons.emailInvalidoText"),
                },
                "auth/wrong-password": {
                    code: this.$t("commons.passwordInvalida"),
                    message: this.$t("commons.passwordInvalidaText"),
                },
                "auth/user-not-found": {
                    code: this.$t("commons.userNotFound"),
                    message: this.$t("commons.userNotFoundText"),
                },
                "auth/weak-password": {
                    code: this.$t("commons.weakPassword"),
                    message: this.$t("commons.weakPasswordText"),
                },
                "auth/email-already-in-use": {
                    code: this.$t("commons.emailExiste"),
                    message: this.$t("commons.emailExisteText"),
                },
            };
        },
    },
    watch: {
        loginOpened() {
            this.accountCreatroOpened = false;
            this.loginSerie = false;
            this.$store.commit("setWhatTo", { what: "loggingin", to: false });
            this.creating = false;
            this.creatingSerie = false;
        },
    },
    mounted() {
        setTimeout(() => this.animaback(), 4000);
        this.videoURL = "https://youtu.be/Qli4N4qNtts";
    },
    methods: {
        printError(error) {
            if (this.dictio[error.code]) {
                if (error.code == "auth/email-already-in-use") {
                    this.$f7.dialog
                        .create({
                            title: this.dictio[error.code].code,
                            text: this.email + " " + this.dictio[error.code].message,
                            buttons: [
                                {
                                    text: "Enviar link",
                                    onClick: () => {
                                        this.goforgotpass(this.email);
                                    },
                                },
                                {
                                    text: this.$t("commons.cancelar"),
                                },
                            ],
                            verticalButtons: true,
                        })
                        .open();
                } else {
                    this.$f7.dialog.alert(this.dictio[error.code].message, this.dictio[error.code].code);
                }
            } else {
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        async goforgotpass(email) {
            const actionCodeSettings = {
                url: window.location.origin,
                handleCodeInApp: false,
            };
            this.$f7.dialog.preloader(this.$t("commons.enviando"));
            try {
                await this.$firebase.auth().sendPasswordResetEmail(email, actionCodeSettings);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(this.$t("login.reviseCorreo", { email }));
            } catch (error) {
                if (error.code == "auth/quota-exceeded") {
                    try {
                        let verifixame = this.$firebase.functions().httpsCallable("mevacuno-sendPassWordResetEmail_nx");
                        let respo = await verifixame({
                            dev: this.$store.state.dev,
                            email,
                        });
                        if (respo.data.error) {
                            throw new Error(respo.data.error.message || respo.data.error.code || respo.data.error);
                        }
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(this.$t("login.ifEmailExists"), this.$t("login.checkEmail", { email }));
                    } catch (error) {
                        this.$f7.dialog.close();
                        this.printError(error);
                    }
                } else {
                    this.$f7.dialog.close();
                    this.printError(error);
                }
            }
        },
        async forgotMyPass() {
            this.$f7.dialog.prompt(this.$t("login.leHaremosLlegar"), this.$t("login.indiqueEmail"), async email => {
                if (!email) {
                    return this.$f7.dialog.alert(this.$t("login.mustProvideEmail"));
                }
                this.goforgotpass(email);
            });
        },
        animaback() {
            if (this.loginOpened) {
                this.bg = this.bg == 3 ? 1 : this.bg + 1;
                setTimeout(() => this.animaback(), 4000);
            }
        },
        creteAccount() {
            if (!this.email || !this.password) {
                return this.$f7.dialog.alert(this.$t("login.mustProvideEmailAndPassword"));
            }
            this.creating = true;
            this.$f7.dialog.preloader(this.$t("commons.creatingAccount"));
            this.$firebase
                .auth()
                .createUserWithEmailAndPassword(this.email, this.password)
                .then(() => {
                    this.accountCreatroOpened = false;
                })
                .catch(error => {
                    this.$f7.dialog.close();
                    this.creating = false;
                    this.printError(error);
                    console.log(error);
                });
        },
        async loginWithSerie() {
            try {
                if (!this.docSerieValid) {
                    return this.$f7.dialog.alert(this.$t("commons.formatSerieDoc"), this.$t("commons.errorFormatSerieDoc"));
                } else if (!this.run || !this.docSerie) {
                    return this.$f7.dialog.alert(this.$t("commons.mustProvideRUN"), this.$t("commons.faltanDatos"));
                } else if (this.rutinvalido) {
                    return this.$f7.dialog.alert(this.$t("commons.verifyRUN"), this.$t("commons.invalidRUN"));
                }
                this.creatingSerie = true;
                this.$f7.dialog.preloader(this.$t("commons.verifyingNroSerie"));
                let endpoint = "mevacuno-loginNumeroSerie";
                let verifico = this.$firebase.functions().httpsCallable(endpoint);
                let result = await verifico({
                    dev: this.$store.state.dev,
                    rut: this.run,
                    serie: this.docSerie,
                });
                let dataresult = result.data;
                if (dataresult.error) {
                    this.creatingSerie = false;
                    throw new Error(dataresult.error.message);
                }
                if (dataresult.payload) {
                    await this.$firebase
                        .auth()
                        .signInWithCustomToken(dataresult.payload)
                        .then(() => {
                            this.loginSerie = false;
                        })
                        .catch(error => {
                            this.creatingSerie = false;
                            throw new Error("Falló el login con número serie. Intente nuevamente");
                        });
                }
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
        ingresarConemailpass() {
            if (!this.email || !this.password) {
                return this.$f7.dialog.alert(this.$t("login.mustProvideEmailAndPassword"));
            }
            this.$store.commit("setWhatTo", { what: "loggingin", to: true });
            return this.$firebase
                .auth()
                .signInWithEmailAndPassword(this.email, this.password)
                .catch(error => {
                    this.$store.commit("setWhatTo", {
                        what: "loggingin",
                        to: false,
                    });
                    this.printError(error);
                    console.log(error);
                });
        },
        setInter(estado) {
            if (estado) {
                this.$f7.dialog.alert(this.$t("login.onlyForeignersText"), this.$t("login.onlyForeignersTitle"), () => {
                    this.$store.commit("setWhatTo", {
                        what: "internacional",
                        to: estado,
                    });
                    localStorage.setItem("inter", estado);
                    this.inter = estado;
                });
            } else {
                this.$store.commit("setWhatTo", {
                    what: "internacional",
                    to: false,
                });
                localStorage.setItem("inter", false);
                this.inter = false;
            }
        },
        setInputDocValid(isvalid) {
            this.docSerieValid = isvalid;
        },
        limpioLoginSerie() {
            this.loginSerie = false;
            this.docSerie = "";
            this.run = "";
        },
        goToGeoVac() {
            window.open("https://diprece.minsal.cl/wp-content/uploads/2024/02/puntos-vacunacion.html");
        },
    },
};
</script>
<style>
.mevaclogo .dedos {
    width: 500px;
    max-width: 100%;
}

.mevaclogo {
    position: relative;
}
.masklogo {
    width: 140px;
    border-radius: 30px;
}
.mylogin.page {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    /* transition: background 0.5s linear; */
    transition: background-image 0.5s linear;
}
.mylogin.back1.page {
    background-image: url("../static/heros/loginback_1.jpg");
}

.mylogin.back2.page {
    background-image: url("../static/heros/loginback_2.jpg");
}

.mylogin.back3.page {
    background-image: url("../static/heros/loginback_3.jpg");
}
.login-screen-content {
    background: unset;
}
.login-bccly {
    font-size: 16px;
}

.hidemax {
    display: none !important;
}
.mylogin .padtop svg {
    margin-top: 9px;
}
.mylogin .gray {
    color: #696969;
}
.altox {
    height: 116px;
}

.logininputs input {
    padding-left: 8px !important;
    border-radius: 9px;
    border-color: #0b4e66;
    border: 2px solid #0b4e66;
}

.logininputs .item-input-wrap input {
    background: #fff;
}

.logininputs .item-media svg {
    margin: 0 auto !important;
    color: #4e4e4e;
}

.fixmedia .item-media svg {
    margin: 0 auto !important;
}

.inputpadlef input {
    padding-left: 5px !important;
}

.backbluex {
    background: #1069c4;
    text-transform: unset !important;
    color: #fff;
}
</style>
