<template>
    <f7-page name="certificado">
        <f7-navbar :subtitle="$t('leftpanel.comprobanteVacunacion')" :title="eldomin">
            <img slot="left" class="logosmall" src="../static/logos/minsalsaquared.jpg" />
            <f7-nav-right>
                <f7-link popup-close>{{ $t("commons.cancelar") }}</f7-link>
            </f7-nav-right>
        </f7-navbar>
        <f7-block class="text-align-center margin-top">
            <div class="fechax bold">{{ patientId }}</div>
            <div class="sdf">{{ privxInfo.apellido1 }} {{ privxInfo.apellido2 || "" }}, {{ privxInfo.nombres }}</div>
            <div class="sdf">{{ $d($dayjs(privxInfo.fechanac), 'twoDigits') }}</div>
            <div v-if="camp.completado" class="fechax">{{ $t('commons.completo') }} <font-awesome-icon color="teal" icon="check-double"></font-awesome-icon></div>
            <div v-else class="fechax">{{ $t("commons.enCurso") }} <font-awesome-icon color="purple" icon="check"></font-awesome-icon></div>
            <div class="lugarx">{{ camp.nombre }}</div>
            <qrcode-vue :value="stringToQode" size="280" level="L" class="margin-top text-align-center"></qrcode-vue>
        </f7-block>
        <f7-card>
            <f7-card-header>{{ camp.nombre }}</f7-card-header>
            <f7-card-content>
                <f7-list v-for="(esquema, eid) in camp.esquemas" :key="eid" class="nowrpwhtspac fixfamarg" media-list>
                    <f7-list-item divider :title="`${$t('oneRecord.esquema')}: ${esquema.nombre}`"></f7-list-item>
                    <div class="timeline margin-vertical">
                        <div v-for="(undia, indx) in Object.values(esquema.fechas)" :key="undia.id" class="timeline-item">
                            <div class="timeline-item-date text-align-center">{{ `${$t("oneRecord.dosis")} ` + (indx + 1) }}</div>
                            <div class="timeline-item-divider"></div>
                            <div class="timeline-item-content">
                                <f7-list class="no-margin-vertical" media-list>
                                    <f7-list-item
                                        v-for="unreq in undia.req"
                                        :key="unreq.id"
                                        class="titlebold"
                                        :subtitle="unreq.completado ? unreq.completado.laboratorio : null"
                                        :title="unreq.completado ? `${unreq.completado.vacuna}` : $t('commons.vacunaPendiente')"
                                        :header="unreq.completado ? $dayjs(unreq.completado.fecha).format('DD MMM YYYY') : null"
                                        :text="unreq.completado ? unreq.completado.centro : null"
                                        :footer="unreq.completado ? `Lote: ${unreq.completado.lote}` : null"
                                    >
                                        <font-awesome-icon
                                            v-if="unreq.completado"
                                            class="margin-left-half"
                                            color="teal"
                                            icon="check"
                                            slot="title"
                                        ></font-awesome-icon>
                                        <font-awesome-icon
                                            v-else
                                            class="margin-left-half"
                                            color="red"
                                            icon="exclamation-triangle"
                                            slot="title"
                                        ></font-awesome-icon>
                                    </f7-list-item>
                                </f7-list>
                            </div>
                        </div>
                    </div>
                    <f7-list-item
                        v-if="false"
                        :class="{ 'skeleton-text skeleton-effect-blink': !vac_meta }"
                        class="titlebold"
                        :title="vac_meta ? `${vac_meta.vacunaPcName} (${vac_meta.laboratorioName})` : $t('commons.cargandoVacuna')"
                        :subtitle="camp_meta ? camp_meta.nombre : $t('commons.cargandoCampana')"
                    >
                        <font-awesome-icon slot="media" class="fa-2x text-color-purple" icon="syringe"></font-awesome-icon>
                    </f7-list-item>
                </f7-list>
                <f7-list v-if="camp.refuerzos && Object.keys(camp.refuerzos).length" class="no-margin-top nowrpwhtspac fixfamarg" media-list>
                    <f7-list-item divider :title="$tc('oneRecord.refuerzo', Object.keys(camp.refuerzos).length)"></f7-list-item>
                    <div class="timeline margin-vertical">
                        <div v-for="(refz, indx) in Object.values(camp.refuerzos)" :key="refz.id" class="timeline-item">
                            <div class="timeline-item-date text-align-center">{{ indx + 1 }}</div>
                            <div class="timeline-item-divider"></div>
                            <div class="timeline-item-content">
                                <f7-list class="no-margin-vertical" media-list>
                                    <f7-list-item
                                        class="titlebold"
                                        :subtitle="refz.laboratorio"
                                        :title="refz.vacuna"
                                        :header="$dayjs(refz.fecha).format('DD MMM YYYY')"
                                        :text="refz.centro"
                                        :footer="`${$t('oneRecord.lote')}: ${refz.lote}`"
                                    >
                                        <font-awesome-icon class="margin-left-half" color="teal" icon="check" slot="title"></font-awesome-icon>
                                    </f7-list-item>
                                </f7-list>
                            </div>
                        </div>
                    </div>
                </f7-list>
                <f7-list
                    v-if="camp.internacionales && Object.keys(camp.internacionales).length"
                    class="no-margin-top nowrpwhtspac fixfamarg"
                    media-list
                >
                    <f7-list-item divider :title="$tc('oneRecord.vacInter', Object.keys(camp.internacionales).length)"></f7-list-item>
                    <div class="timeline margin-vertical">
                        <div v-for="(refz, indx) in Object.values(camp.internacionales)" :key="refz.id" class="timeline-item">
                            <div class="timeline-item-date text-align-center">{{ indx + 1 }}</div>
                            <div class="timeline-item-divider"></div>
                            <div class="timeline-item-content">
                                <f7-list class="no-margin-vertical" media-list>
                                    <f7-list-item
                                        class="titlebold"
                                        :subtitle="refz.laboratorio"
                                        :title="refz.vacuna"
                                        :header="$dayjs(refz.fecha).format('DD MMM YYYY')"
                                        :text="refz.centro"
                                        :footer="`${$t('oneRecord.lote')}: ${refz.lote} | ${refz.dd || '-'} | ${refz.xd || '-'}`"
                                    >
                                        <font-awesome-icon class="margin-left-half" color="teal" icon="check" slot="title"></font-awesome-icon>
                                    </f7-list-item>
                                </f7-list>
                            </div>
                        </div>
                    </div>
                </f7-list>
            </f7-card-content>
        </f7-card>
    </f7-page>
</template>
<script>
import QrcodeVue from "qrcode.vue";
import { commonmixin } from "../mixins/common";
import { mapGetters } from "vuex";
import paisesIso2 from "../paises.json";

export default {
    components: {
        QrcodeVue,
    },
    mixins: [commonmixin],
    props: ["camp"],
    computed: {
        stringToQode() {
            let url = `https://scanmevacuno.gob.cl/?f=${this.camp.neoqr}&c=${this.$store.state.dev ? 1 : 0}`;
            if (this.$store.state.internacional) {
                url = `https://scanmevacuno.gob.cl/?f=${this.camp.neoqr}&c=${this.$store.state.dev ? 1 : 0}&ext=1`;
            }
            return url;
        },
        ...mapGetters(["privxInfo"]),
        eldomin() {
            return window.location.hostname;
        },

        patientId() {
            const splittedPatientId = this.camp.pax.split(" ");

            const identityType = splittedPatientId[0];

            if (identityType === "RUN") {
                return this.camp.pax;
            }

            const identity = splittedPatientId[1];
            const splittedIdentity = identity.split("_");

            let cleanIdentity;

            if (splittedIdentity.length === 1) {
                cleanIdentity = splittedIdentity[0];
            } else {
                const lastPart = splittedIdentity[splittedIdentity.length - 1];

                if (this.isIso2(lastPart)) {
                    splittedIdentity.pop();
                    cleanIdentity = splittedIdentity.join("_");
                } else {
                    cleanIdentity = identity;
                }
            }

            return `${identityType} ${cleanIdentity}`;
        }
    },

    methods: {
        isIso2(string) {
            return paisesIso2[string] ? true : false;
        }
    }
};
</script>
<style>
.tipovacx {
    height: 30px !important;
    line-height: 30px;
    background: #d1faff !important;
}
.tipovacx span {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: #000;
}
.logosmall {
    width: 40px;
    padding-left: 10px;
}
.fechax {
    font-size: 25px;
}
</style>
