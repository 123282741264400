<template>
    <f7-page name="encuestas" @page:beforein="fetcheaMisPendientes()">
        <!-- Top Navbar -->
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title :subtitle="$t('encuestas.seguimientoPostVacunacion')" :title="$t('encuestas.encuestas')"></f7-nav-title>
        </f7-navbar>

        <f7-block class="text-align-left" v-if="(!privxInfo.fechanac || !privxInfo.nac_iso2) && privxInfo.verified">{{ $t("commons.debeVerificar") }}"<font-awesome-icon icon="user-circle" /> <b>{{ $t("leftpanel.miPerfil") }}</b>" {{ $t("commons.antesDeConsultarCampana") }}</f7-block>

        <f7-block v-else-if="loadingPendings" class="text-align-center"
            ><f7-preloader></f7-preloader><br />{{ $t("encuestas.cargandoFormularios") }}</f7-block
        >
        <f7-block class="text-align-left" v-else-if="privxInfo.conflicto !== undefined">{{ $t("commons.debeResolverConflicto") }}"<font-awesome-icon icon="user-circle" /> <b>{{ $t("leftpanel.miPerfil") }}</b>" {{ $t("commons.antesDeConsultarCampana") }}</f7-block>

        <f7-block v-else-if="!privxInfo.verified"
            >{{ $t("commons.debeVerificar") }} "<font-awesome-icon icon="user-circle" /> <b>{{ $t("leftpanel.miPerfil") }}</b>" {{ $t("encuestas.antesDeResponder") }}</f7-block
        >
        <f7-block v-else-if="tieneConflicto"
            >{{ $t("commons.debeResolverConflicto") }} "<font-awesome-icon icon="user-circle" /> <b>{{ $t("leftpanel.miPerfil") }}</b>"
            {{ $t("encuestas.antesDeResponder") }}</f7-block
        >
        <f7-block v-else-if="!seguimientosPending.length">
            {{ $t("encuestas.noHayEncuestas") }}</f7-block
        >
        <f7-block v-else>
            {{ $t("encuestas.encuestasISP") }}</f7-block
        >
        <f7-card v-for="unpendint in seguimientosPending" :key="unpendint.id">
            <f7-card-header class="bold">{{ forms[unpendint.f].name }}</f7-card-header>
            <f7-card-content>
                <f7-list class="evlado">
                    <f7-list-item :header="$t('commons.seguimientoPara')" :footer="getMyidentityFromPaxId(unpendint.a)" :title="unpendint.b">
                        <img src="../static/heros/avatar.jpg" slot="media" />
                    </f7-list-item>
                </f7-list>
                <f7-block class="margin-top">
                    {{ $t("encuestas.seSolicitaResponder") }}
                    <span class="bold">{{ $dayjs(unpendint.dt).format("dddd D [de] MMMM [de] YYYY") }}</span>, 
                    {{ $t("encuestas.correspondienteAlDia", { dia: unpendint.dp, desde: !unpendint.hd ? $t('commons.primera') : $t('commons.ultima') }) }}
                </f7-block>
            </f7-card-content>
            <f7-card-footer
                ><span></span
                ><f7-button @click="responderFomrulario(unpendint)" v-if="$dayjs().isSameOrAfter(unpendint.dt, 'date')" fill raised large
                    >{{ $t("commons.responder") }}</f7-button
                ><span v-else
                    >
                    {{ $t("encuestas.podraResponderEn", { dias: -$dayjs().startOf("day").diff(unpendint.dt, "day"), s: -$dayjs().startOf("day").diff(unpendint.dt, "day") == 1 ? "" : "s" }) }}
                    </span
                ></f7-card-footer
            >
        </f7-card>

        <f7-popup class="resp-seguimint" :opened="!!respondedorAbierto" @popup:closed="respondedorAbierto = null">
            <resp-seuim v-if="respondedorAbierto" @respondeok="borrapendetn" :smt="respondedorAbierto"></resp-seuim>
        </f7-popup>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { commonmixin } from "../mixins/common";
import respSeuim from "../components/respSeguim.vue";
export default {
    mixins: [commonmixin],
    components: { respSeuim },
    data() {
        return {
            loadingPendings: false,
            respondedorAbierto: null,
        };
    },
    computed: {
        ...mapState(["user", "dev", "forms"]),
        ...mapGetters(["tieneConflicto", "privxInfo", "seguimientosPending"]),
    },
    methods: {
        borrapendetn(idAborrar) {
            this.$store.commit("delObje", { what: "seguimientosPending", cual: idAborrar });
            this.respondedorAbierto = null;
        },
        responderFomrulario(pendt) {
            this.respondedorAbierto = Object.assign({}, pendt);
        },
        async fetcheaMisPendientes() {
            try {
                if (
                    this.privxInfo.verified &&
                    !this.tieneConflicto &&
                    (!this.seguimientosPending.length || !this.$store.state.lastFullLoad.seguimientosPending)
                ) {
                    if((!this.user.privx.fechanac || !this.user.privx.nac_iso2) && this.user.privx.verified){
                        let error =  "PROFILE_INCOMPLETE"
                        throw error;
                    }
                    this.loadingPendings = true;
                    // console.log(this.privxInfo)
                    //  console.log('a',`${this.privxInfo.idenType}_${this.privxInfo.identity}`)
                    let datous = await this.$firebase
                        .database()
                        .ref(`segs`)
                        .orderByChild("a")
                        .equalTo(`${this.privxInfo.idenType}_${this.privxInfo.identity}`)
                        .once("value");
                    let segimtspendigs = {};
                    if (datous.exists()) {
                        let formsYaPedidos = {};
                        let promsPideForms = [];
                        datous.forEach(prounsegpend => {
                            let unsegpend = prounsegpend.val();
                            // console.log(unsegpend.dt,this.$dayjs(unsegpend.dt,'YYYYMMDD').add(2,'days').format('YYYYMMDD'));
                            //console.log(this.$dayjs().format('YYYYMMDD'));
                            if (
                                this.$dayjs(unsegpend.dt)
                                    .add(2, "days")
                                    .isSameOrAfter(this.$dayjs(), "date") &&
                                !unsegpend.w
                            ) {
                                segimtspendigs[prounsegpend.key] = unsegpend;
                                if (!this.$store.state.forms[unsegpend.f] && !formsYaPedidos[unsegpend.f]) {
                                    formsYaPedidos[unsegpend.f] = true;
                                    promsPideForms.push(
                                        this.$firebase
                                            .database()
                                            .ref(`formularios/${unsegpend.f}`)
                                            .once("value")
                                    );
                                }
                            }
                        });
                        if (promsPideForms.length) {
                            let promResolver = await Promise.all(promsPideForms);
                            promResolver.forEach(unpotsnap => {
                                let formsToADD = {};
                                if (unpotsnap.exists()) {
                                    formsToADD[unpotsnap.key] = unpotsnap.val();
                                }
                                if (Object.keys(formsToADD).length) {
                                    this.$store.commit("mergeObjTo", { what: "forms", to: formsToADD });
                                }
                            });
                        }
                        // console.log('Seguimientos pendx', segimtspendigs);
                        // console.log('forms de seguimiento',this.forms);
                        this.$store.commit("setObjTo", { what: "seguimientosPending", to: segimtspendigs });
                    }
                    this.loadingPendings = false;
                }
                //ver si viene unaredirecx
                let url = new URL(window.location.href);
                 let mvcsid = url.searchParams.get("mvcsid");
                    let a = url.searchParams.get("a");
                    let b = url.searchParams.get("b");
                    if (mvcsid && a && b) {
                        window.history.replaceState({}, document.title, "/");
                        //no esta logeado, ir a pedirselas y mostrale el modal
                        this.$f7.dialog.preloader(this.$t("commons.cargandoFormEsavi"));
                        let pideesavi = this.$firebase.functions().httpsCallable("mevacuno-pideEsaviAResponder");
                        let result = await pideesavi({ dev: this.$store.state.dev, segid: mvcsid, uid: a, dp: b });
                        let daouts = result.data;
                        if (daouts.error) {
                            throw new Error(daouts.error.message);
                        }
                        let fomrulario = daouts.payload.formulario;
                        let formstoadd = {};
                        formstoadd[fomrulario.id] = fomrulario;
                        this.$store.commit("mergeObjTo", { what: "forms", to: formstoadd });
                        let sgmt = daouts.payload.sgmt;
                        this.$f7.dialog.close();
                        this.respondedorAbierto = Object.assign({}, sgmt);
                    }
            } catch (error) {
                this.loadingPendings = false;
                console.log(error);
                if(error == "PROFILE_INCOMPLETE"){
                        this.$f7.dialog.alert(this.$t("commons.debeCompletarFechaNacimiento")+  this.$t("leftpanel.miPerfil") , this.$t("commons.permissionDenied"));
                }else{
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            }
        },
    },
};
</script>
<style>
.evlado img {
    width: 40px;
}
</style>
