<template>
    <f7-page name="eligecentro">
        <!-- Top Navbar -->
        <f7-navbar :title="$t('eligecentro.vacunatorios')" :subtitle="$t('eligecentro.cercaDe', { comuna: fullAddres.comuna, provincia: fullAddres.provincia })">
            <f7-nav-right>
                <f7-link @click="$emit('aboraseleccion')">{{ $t('commons.cancelar') }}</f7-link>
            </f7-nav-right>
        </f7-navbar>

        <f7-block v-if="loading" class="text-align-center">
            <f7-preloader></f7-preloader>
            <f7-block class="text-align-center">{{ $t('listarvacunats.cargandoVacunatorios') }}</f7-block>
        </f7-block>
        <f7-list v-else class="vacunatorios nowrpwhtspac no-margin-top" media-list>
            <f7-list-item divider class="whnowrap" :title="$t('eligecentro.vacsDisponibles')"></f7-list-item>
            <f7-list-item
                v-if="!data.length"
                class="sinstockx no-margin-bottom"
                :subtitle="$t('eligecentro.comunaSinAgendamientoWeb', { comuna: fullAddres.comuna })"
            >
                <span slot="media" class="fa-stack fa-2x">
                    <i class="fas fa-syringe fa-stack-1x"></i>
                    <i class="fas fa-ban fa-stack-2x" style="color:Tomato"></i>
                </span>
            </f7-list-item>
            <f7-list-item
                v-for="vacunatorio in data"
                :key="vacunatorio.id"
                :title="vacunatorio.mt.name"
                :class="{ noweb: !vacunatorio.canweb }"
                :subtitle="`${vacunatorio.mt.direccion}, ${vacunatorio.mt.address.name}`"
            >
                <f7-button fill v-if="vacunatorio.canweb" @click="irACalendarizar(vacunatorio)" slot="after">{{ $t("listarvacunats.verHoras") }}</f7-button>
                <span v-else slot="after"><font-awesome-icon class="fa-lg" icon="hospital-user"></font-awesome-icon> {{ $t("listarvacunats.soloPresencial") }}</span>

                <span v-if="!vacunatorio.canweb" slot="text">
                    {{ $t("eligecentro.soloStockPresencial") }}
                </span>
            </f7-list-item>
        </f7-list>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { comunasHelper } from "@/js/comunas.js";
import { commonmixin } from "../mixins/common";

export default {
    props: ["esquemaid", "diaid"],
    mixins: [comunasHelper, commonmixin],
    data() {
        return {
            loaded: false,
            loading: true,
            data: [],
        };
    },
    computed: {
        ...mapState(["user", "chosenCampanaID", "agendando_a_tercero"]),
        ...mapGetters(["privxInfo"]),
        fullAddres() {
            let suaddress = this.agendando_a_tercero
                ? this.$store.getters.mitercero(this.agendando_a_tercero).contc_pref.address
                : this.privxInfo.contc_pref.address;
            return this.getOneComFull(suaddress.code, suaddress.provincia.split("-")[2], suaddress.region);
        },
    },
    mounted() {
        // console.log('Direccion full del solicitante es',this.fullAddres)
        // console.log('es a tercero',this.agendando_a_tercero)
        let fulltercero = this.$store.getters.mitercero(this.agendando_a_tercero);
        // console.log('full identyt tecero',fulltercero)
        this.cargaCentrs();
    },
    methods: {
        cargaCentrs() {
            if (!this.loaded) {
                this.loading = true;
                // console.log('chosenCampanaID',this.chosenCampanaID)
                return this.commonExecute(
                    { campanaid: this.chosenCampanaID, tercero: this.agendando_a_tercero, esquemaid: this.esquemaid, diaid: this.diaid },
                    "mevacuno-vacsConStockComun",
                    false
                )
                    .then(datous => {
                        if (datous.error) {
                            throw new Error(datous.error.message);
                        }
                        // console.log('res',datous);

                        //cargar vacunatorios info si llega algo
                        let centrosarrya = datous.payload.centros || [];
                        let vacunatoriosToADD = {};
                        centrosarrya.forEach(uncentrinf => {
                            vacunatoriosToADD[uncentrinf.mt.id] = uncentrinf.mt;
                        });
                        if (Object.values(vacunatoriosToADD).length) {
                            this.$store.commit("mergeObjTo", { what: "vacunatorios", to: vacunatoriosToADD });
                        }

                        this.data = datous.payload.centros;
                        this.loading = false;
                        this.loaded = true;
                    })
                    .catch(error => {
                        this.loading = false;
                        console.error(error);
                        this.$f7.dialog.alert(error.message, error.code || "Error");
                    });
            }
        },
        esquemaOptimo(vacunatorio) {
            let esquemgas = vacunatorio.esqms || {};
            let esquemaOptimo = Object.keys(esquemgas)
                .map(esquemaid => {
                    let esueminfo = esquemgas[esquemaid];
                    esueminfo.id = esquemaid;
                    return esueminfo;
                })
                .reduce((res, obj) => {
                    return obj.maxExp < res.maxExp ? obj : res;
                });
            return esquemaOptimo;
        },
        irACalendarizar(vacunatorio) {
            this.$store.commit("setWhatTo", { what: "agendando_vaccenterid", to: vacunatorio.id });
            let selection = {
                vacunatorioid: vacunatorio.id,
            };
            if (!this.esquemaid) {
                let esquemaoptimo = this.esquemaOptimo(vacunatorio);
                selection.esquemaid = esquemaoptimo.id;
                selection.maxexpEsquema = esquemaoptimo.maxExp;
                selection.diaid = "Día 0";
            } else {
                selection.esquemaid = this.esquemaid;
                selection.maxexpEsquema = vacunatorio.esqms[this.esquemaid].maxExp;
                selection.diaid = this.diaid;
            }
            this.$emit("eligiocentrogo", selection);
        },
    },
};
</script>
<style>
.vacunatorios li {
    border-top: 1px dotted #dedede;
}
.list.vacunatorios .item-text {
    overflow: auto !important;
    height: unset !important;
    -webkit-line-clamp: unset !important;
    max-height: unset !important;
}
.vacunatorios .item-title-row {
    max-height: 25px !important;
}
.sinstockx {
    margin: 0px -16px 16px -16px;
    padding: 5px 16px;
    background: #fff2df;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
}
.noweb .item-text {
    background: #fff0f0;
    margin: 3px -16px !important;
    padding: 4px 16px;
    color: #750000;
    font-size: 13px;
    text-align: justify;
}
</style>
