<template>
    <f7-card class="margin-vertical">
        <f7-card-header class="bold text-color-primary">{{ campana.nombre }}</f7-card-header>
        <f7-card-content>
            <p class="no-margin text-color-primary">
                <font-awesome-icon
                    class="fa-lg"
                    :class="{
                        'text-color-gray': !$dayjs().isSameOrAfter(campana.ini, 'd'),
                        'text-color-teal': $dayjs().isSameOrAfter(campana.ini, 'd'),
                    }"
                    :icon="!$dayjs().isSameOrAfter(campana.ini, 'd') ? 'arrow-alt-circle-right' : 'calendar-check'"
                ></font-awesome-icon>
                {{ getMeDurationString(campana) }}
            </p>
            <div v-if="mode == 'no_elegible'" class="noelegible">
                {{ $t("oneCampana.noElegibleSegunCriterio") }}
            </div>
            <div v-if="outofrange" class="noelegible">
                {{ $t("oneCampana.outOfRange", { dias: outofrange.diasrango, antesODespues: outofrange.dir == "bfr" ? $t("oneCampana.despuesDeHaberCompletado") : $t("oneCampana.antesDelInicioDe"), campana: $store.state.campanas[outofrange.campanaid] ? $store.state.campanas[outofrange.campanaid].nombre : outofrange.campanaid}) }}
            </div>
            <template v-if="!outofrange">
                <div v-if="mode == 'reevaluar_eligibilidad'" class="reeelegible">
                    {{ $t("oneCampana.huboCambioDeCriterio") }}
                </div>

                <div v-if="mode == 'ver_mis_encurso'">{{ descr }}</div>
                <f7-button v-if="computando" href="#" class="disabled" outline large raised>
                    <f7-preloader></f7-preloader>
                </f7-button>
                <f7-button
                    v-else-if="mode == 'no_elegible' && criterios.criterios"
                    color="red"
                    @click="attemptToAnser()"
                    href="#"
                    outline
                    large
                    raised
                    >
                    {{ $t("oneCampana.revisarRespuestas", { misOSus: agendando_a_tercero ? $t("oneCampana.sus") : $t("oneCampana.mis") }) }}
                </f7-button>
                <f7-button v-else-if="mode == 'quiero_vacunarme'" color="primary" @click="attemptToAnser()" href="#" fill large raised>{{
                    agendando_a_tercero ? $t("oneCampana.quiereVacunarse") : $t("oneCampana.quieroVacunarme")
                }}</f7-button>
                <f7-button v-else-if="mode == 'ver_mis_encurso'" color="teal" @click="$emit('entrar', campana.id)" href="#" outline large raised
                    >{{ $t("oneCampana.verEsquema", { suOMi: agendando_a_tercero ? $t("oneCampana.su") : $t("oneCampana.mi") }) }}
                    </f7-button
                >
                <f7-button v-else-if="mode == 'reevaluar_eligibilidad'" color="primary" @click="attemptToAnser()" href="#" outline large raised
                    >{{ $t("oneCampana.reevaluarEligibilidad") }}</f7-button
                >
                <f7-block
                    v-else-if="mode == 'sin_stock' || mode == 'stock_ok'"
                    class="text-align-center margin-vertical bold yaelegible padding-vertical-half"
                    ><font-awesome-icon class="text-color-teal fa-lg" icon="thumbs-up"></font-awesome-icon>
                    {{ agendando_a_tercero ? $t("oneCampana.yaCumple", { name: mitercer.nombres }) : $t("oneCampana.udYaCumple") }}
                    </f7-block
                >
                <f7-block v-if="mode == 'sin_stock'" class="margin-vertical text-align-center">
                    <span class="fa-stack fa-2x">
                        <i class="fas fa-syringe fa-stack-1x"></i>
                        <i class="fas fa-ban fa-stack-2x" style="color:Tomato"></i> </span
                    ><br />
                    {{ $t("oneCampana.noHayVacunatoriosEnEsteMomento") }}
                </f7-block>
                <f7-block v-else-if="mode == 'stock_ok'" class="margin-vertical text-align-center">
                    {{ $t("oneCampana.consulteDispComuna") }}<br />
                    <f7-button color="teal" @click="$emit('entrar', campana.id)" href="#" fill large raised>{{ $t("oneCampana.verVacunatorios") }}</f7-button>
                </f7-block>
            </template>
        </f7-card-content>
        <template v-if="campana.gtw && campana.gtw.bfr">
            <f7-card-footer v-for="(dias, campanaid) in campana.gtw.bfr" :key="campanaid"
                ><span v-if="$store.state.campanas[campanaid]"
                    >{{ $t("oneCampana.atencionDebeEsperar", { campana: $store.state.campanas[campanaid].nombre, dias: dias }) }}</span
                ><span v-else>{{ $t("commons.cargando") }}</span></f7-card-footer
            >
        </template>
    </f7-card>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { commonmixin } from "../mixins/common";

export default {
    props: ["campana"],
    mixins: [commonmixin],
    data() {
        return {
            computando: true,
            conerror: false,
            tipos: ["quiero_vacunarme", "ver_mis_encurso", "no_elegible", "reevaluar_eligibilidad", "sin_stock", "stock_ok"],
            criterios: {},
            descr: "",
            outofrange: null,
        };
    },
    computed: {
        ...mapState(["user", "agenda", "agendando_a_tercero"]),
        ...mapGetters(["privxInfo"]),
        targetSubject() {
            return this.agendando_a_tercero || this.user.uid;
        },
        mitercero() {
            return this.$store.getters.mitercero(this.agendando_a_tercero);
        },
        agendamientosExistentes() {
            //primer ver que no tenga un agendamiento en curso para esta campaña
            return Object.values(this.agenda).filter(ungnd => {
                return ungnd.cmp == this.campana.id && ungnd.for == this.targetSubject && this.$dayjs().isSameOrBefore(ungnd.fch, "day");
            });
        },
        mode() {
            return this.$store.state.myCampanaStatusCode[this.campana.id] || "quiero_vacunarme";
        },
    },
    watch: {
        agendando_a_tercero(neoval) {
            this.computaestado();
        },
    },
    mounted() {
        this.computaestado();
        // console.log('campana',this.campana);
    },
    methods: {
        attemptToAnser() {
            let soend = {
                campana: this.campana,
                tercero: this.agendando_a_tercero,
                criterios: this.criterios,
            };
            this.$emit("responder", soend);
        },
        async computaestado() {
            try {
                // console.log('RECOMPUTANDO')
                //priemro ver si tengo agendamientos existentes
                if (this.agendamientosExistentes.length) {
                    this.$store.commit("updtObjProperty", { what: "myCampanaStatusCode", to: "ver_mis_encurso", idx: this.campana.id });

                    this.computando = false;
                    return false;
                }
                //Inicio proceso llamando reglas y mis respuestas
                let privxInfoTarget = !this.agendando_a_tercero ? this.privxInfo : this.$store.state.misTerceros[this.agendando_a_tercero];
                let allshots = [
                    this.$firebase
                        .database()
                        .ref("campanas_criterios/" + this.campana.id)
                        .once("value"),
                    this.$firebase
                        .database()
                        .ref("campcritusr/" + this.campana.id + "/" + this.targetSubject)
                        .once("value"),
                    this.commonExecute(
                        {
                            campanaid: this.campana.id,
                            nac_iso2: privxInfoTarget.nac_iso2,
                            lp: `${privxInfoTarget.idenType}_${privxInfoTarget.identity}`,
                        },
                        "mevacuno-statusMyCampana",
                        false
                    ),
                ];
                let datax = await Promise.all(allshots);
                this.criterios = Object.assign({}, datax[0].val() || {});
                let myanswers = datax[1].val() || {};
                let datous = datax[2];
                // console.log('Respuesta estado',datous)
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                if (datous.payload) {
                    //console.log('Iniciado')
                    //ya inicio vacunacion
                    this.$store.commit("updtObjProperty", { what: "myCampanaStatusCode", to: "ver_mis_encurso", idx: this.campana.id });
                    if (datous.faltax) {
                        this.descr = this.$t("oneCampana.esquemaIniciado");
                    } else {
                        this.descr = this.$t("oneCampana.haRecibidoTodasLasDosis");
                    }
                    this.computando = false;
                    return false;
                }
                if (datous.outofrange) {
                    this.outofrange = datous.outofrange;
                    if (!this.$store.state.campanas[datous.outofrange.campanaid]) {
                        //909090
                        let campaninfo = await this.$firebase
                            .database()
                            .ref(`campanas/${datous.outofrange.campanaid}`)
                            .once("value");
                        let campsToAdd = {};
                        if (campaninfo.exists()) {
                            campsToAdd[datous.outofrange.campanaid] = campaninfo.val();
                        }
                        this.$store.commit("mergeObjTo", { what: "campanas", to: campsToAdd });
                    }
                    this.computando = false;
                } else {
                    let versionCriteriosActuales = this.campana.vers_crit;
                    let declaradionidentidadTime = !this.agendando_a_tercero
                        ? this.privxInfo.declAt
                        : this.$store.state.misTerceros[this.agendando_a_tercero].declAt;
                    //ver si no tiene ningun tipo de criterios
                    if (!this.criterios.criterios && !this.criterios.incluage && !this.criterios.usuarios) {
                        //NO HAY NINGUN CRITERIO, ES ELEGIBLE, VER STOCK
                        this.computarStock();
                    } else {
                        //hay algun tipo de criterios
                        //ver si puedo leer mi nodo local
                        if (myanswers.idnt && myanswers.pvr == declaradionidentidadTime && versionCriteriosActuales == myanswers.vr) {
                            //si, ver eligibilidad de su nodo local nomas
                            if (myanswers.elig) {
                                this.computarStock();
                            } else {
                                this.$store.commit("updtObjProperty", { what: "myCampanaStatusCode", to: "no_elegible", idx: this.campana.id });
                                this.computando = false;
                            }
                        } else {
                            //no vigentes o no he respondido
                            //Necesito responder preguntas?
                            if (this.criterios.criterios) {
                                //HAY preguntas
                                if (!myanswers.idnt) {
                                    //no tiene respuestas
                                    this.$store.commit("updtObjProperty", {
                                        what: "myCampanaStatusCode",
                                        to: "quiero_vacunarme",
                                        idx: this.campana.id,
                                    });

                                    this.computando = false;
                                } else if (versionCriteriosActuales !== myanswers.vr) {
                                    //cambiaron los criterios
                                    //pedirle responder nuevos
                                    this.$store.commit("updtObjProperty", {
                                        what: "myCampanaStatusCode",
                                        to: "reevaluar_eligibilidad",
                                        idx: this.campana.id,
                                    });
                                    this.computando = false;
                                } else {
                                    //quiere decir que lo que cambio fueron mis propiedades de usuario
                                    //mandarlas a procesar mis mismass respuesta previas, pero va a tomar nuevas propiedaes de usuario
                                    this.mandaRespuestas(myanswers.ans || {}, this.campana.id, false);
                                }
                            } else {
                                //si es sin preguntas
                                //o no tengo respuesat, o no vigentes, mandar respuestas al servidor (vacias, es sin crietrio);
                                this.mandaRespuestas({}, this.campana.id, false);
                            }
                        }
                    }
                }
            } catch (error) {
                this.conerror = true;
                this.computando = false;
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
        async computarStock() {
            try {
                // console.log('Computando stock ahora');
                let datous = await this.commonExecute(
                    { campanaid: this.campana.id, tercero: this.agendando_a_tercero },
                    "mevacuno-vacsConStockComun",
                    false
                );
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                let stockfund = datous.payload || {};
                let centros = stockfund.centros || [];
                if (!centros.length) {
                    this.$store.commit("updtObjProperty", { what: "myCampanaStatusCode", to: "sin_stock", idx: this.campana.id });
                } else {
                    this.$store.commit("updtObjProperty", { what: "myCampanaStatusCode", to: "stock_ok", idx: this.campana.id });
                }
                //console.log('RESPUESTA DE ENCUENTRO DE STOCK',stockfund);
                this.computando = false;
            } catch (error) {
                this.conerror = true;
                this.computando = false;
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
        async mandaRespuestas(tosend, campanaid, conpreloader) {
            try {
                // console.log('ENVIANDO RESPUESTAS');
                if (conpreloader) {
                    this.$f7.dialog.preloader(this.$t("oneCampana.guardandoDeclaracion"));
                }
                let datous = this.commonExecute(
                    { campanaid: campanaid, tercero: this.agendando_a_tercero, answers: tosend },
                    "mevacuno-checkEligibility",
                    false
                );
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                let soyelegible = datous.payload;
                //console.log('soy elegible',soyelegible);
                this.$emit("cierraEligibility");
                if (!soyelegible) {
                    this.computando = false;
                    if (conpreloader) {
                        this.$f7.dialog.close();
                    }
                    this.$store.commit("updtObjProperty", { what: "myCampanaStatusCode", to: "no_elegible", idx: this.campana.id });
                    // this.$f7.dialog.alert('Muchas gracias por su interés en la vacunación. Aún no es elegible en base al esquema de priorizaciones. Le enviaremos un e-mail cuando se abra para su categoría.','Favor esperar.');
                } else {
                    this.computarStock();
                }
            } catch (error) {
                this.conerror = true;
                this.computando = false;
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
        getMeDurationString(campana) {
            if (!campana.ini) {
                return "Fecha de inicio aún no configurada";
            } else if (campana.fin) {
                let dayini = this.$dayjs(campana.ini);
                let dayfin = this.$dayjs(campana.fin);
                let mesini = this.$dayjs(campana.ini).format("MMMM");
                let mesfin = this.$dayjs(campana.fin).format("MMMM");
                let anoini = this.$dayjs(campana.ini).format("YYYY");
                let anofin = this.$dayjs(campana.fin).format("YYYY");

                return this.$t("oneCampana.desdeElAl", { fechaInicio: `${dayini.format("DD")} ${mesini != mesfin ? `de ${mesini}` : ``} ${anoini != anofin ? `de ${anoini}` : ``}`, fechaTermino: dayfin.format("DD [de] MMMM [de] YYYY") });
            } else {
                return this.$t("oneCampana.desdeEl", { fecha: this.$d(this.$dayjs(campana.ini), "long") });
            }
        },
    },
};
</script>
<style>
.yaelegible {
    background: #e0f2f1;
}

.noelegible {
    margin: 0px -16px 16px -16px;
    padding: 5px 16px;
    background: #ffe9e9;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
}

.reeelegible {
    margin: 0px -16px 16px -16px;
    padding: 5px 16px;
    background: #fffee9;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
}
</style>
