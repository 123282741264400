<template>
    <div>
        <f7-block v-if="solicitudPrevia && !solicitudPrevia.rjt && !solicitudPrevia.wh" class="no-margin no-padding">
           <span class="solencuros"> {{ $t("solicitudesLoader.enCurso") }}</span>
        </f7-block>
        <f7-block class="no-margin no-padding">
            <f7-block v-if="cargandoSolicitudes" class="text-align-center"><f7-preloader></f7-preloader></f7-block>
            <f7-button v-else-if="!solicitudPrevia" @click="verififcOnline()" fill large raised>{{ $t("solicitudesLoader.solicitar") }}</f7-button>
            <f7-button v-else-if="!solicitudPrevia.wh" @click="verififcOnline()" color="teal" fill large raised
                >{{ $t("solicitudesLoader.continuarSolicitud") }}</f7-button>
            <f7-list class="noulpadleft" media-list v-else>
                <f7-list-item
                    class="reasonrejectnowr"
                    :header="$t('solicitudesLoader.enviadaEl', { date: $d($dayjs.unix(solicitudPrevia.wh), 'long') })"
                    :title="$t('solicitudesLoader.solicitudOnline')"
                >
                <div slot="subtitle" v-html="$sanitize(solicitudPrevia.rjt) || null"></div>
                    <f7-chip v-if="!solicitudPrevia.rjt" color="orange" slot="after">{{ $t("solicitudesLoader.enEvaluacion") }}</f7-chip>
                    <f7-chip v-else color="red" slot="after">{{ $t("solicitudesLoader.rechazada") }}</f7-chip>
                </f7-list-item>
            </f7-list>
            <f7-button v-if="solicitudPrevia && solicitudPrevia.rjt" @click="verififcOnline()" fill raised
                >{{ $t("solicitudesLoader.solicitarNueva") }}</f7-button
            >
        </f7-block>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { commonmixin } from "../mixins/common";

export default {
    props: ["user"],
    mixins: [commonmixin],

    data() {
        return {
            cargandoSolicitudes: true,
            solicitudPrevia: null,
        };
    },
    mounted() {
        this.getSolicitudes();
    },
    computed: {
        ...mapState(["dev"]),
    },
    methods: {
        async getSolicitudes() {
            try {
                let dbtrxs = this.$firebase_coreapp.database(this.$store.getters.usrtrxDatabaseUrl);

                this.cargandoSolicitudes = true;
                let snphot = await dbtrxs.ref(`solicit_verif_open/${this.$store.state.dev ? "d" : "p"}/${this.user.uid}`).once("value");
                this.solicitudPrevia = snphot.val();

                this.$store.commit("setWhatTo", { what: "tieneSolicitudPendiente", to: this.solicitudPrevia });

               // console.log(this.solicitudPrevia, "solicitudPrevia");
                this.cargandoSolicitudes = false;
            } catch (error) {
                this.cargandoSolicitudes = false;
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
        async verififcOnline() {
            try {
                this.$f7.dialog.preloader(this.$t("commons.cargando"));
                let respoesta = await this.commonExecute({}, "mevacuno-creaNuevaSolicitVerif", false);
                if (respoesta.error) {
                    throw new Error(respoesta.error.message || respoesta.error.code || respoesta.error);
                }
               // console.log("res", respoesta.payload);

                this.$f7.views.main.router.navigate("/solicitar-verificacion/", {
                    props: respoesta.payload,
                });
                this.$f7.dialog.close();
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
    },
};
</script>
<style>

.solencuros{
    font-size: 12px;
    border-radius: 22px;
    background: #ffeeaa;
    color: #443002;
    padding: 4px 10px!important;
}
.reasonrejectnowr .item-subtitle{
    white-space: unset!important;
}
.noulpadleft.list ul, .noulpadleft.list .item-content{
    padding-left: 0px!important;
}
</style>
