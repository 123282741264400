<template>
    <f7-page @page:beforein="cargaAgenda()" name="agenda">
        <!-- Top Navbar -->
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title :subtitle="$t('commons.gestioneHoras')" :title="$t('agenda.agendamiento')"></f7-nav-title>
        </f7-navbar>
        <f7-block class="text-align-left" v-if="(!user.privx.fechanac || !user.privx.nac_iso2) && user.privx.verified">{{ $t("commons.debeVerificar") }}"<font-awesome-icon icon="user-circle" /> <b>{{ $t("leftpanel.miPerfil") }}</b>" {{ $t("commons.antesDeConsultarCampana") }}</f7-block>

        <f7-block v-else-if="!privxInfo.identity"
            >{{ $t("agenda.debeDeclararEnMenu") }} "<font-awesome-icon icon="user-circle" /> <b>{{ $t("leftpanel.miPerfil") }}</b>" {{ $t("agenda.antesDeAgendar") }}</f7-block
        >
        <f7-block v-else-if="tieneConflicto"
            >{{ $t("commons.debeResolverConflicto") }} "<font-awesome-icon icon="user-circle" /> <b>{{ $t("leftpanel.miPerfil") }}</b>" {{ $t("agenda.antesDeAgendar") }}</f7-block
        >

        <template v-else>
            <f7-block class="margin-vertical">
                <f7-block-title>{{ $t("agenda.misProximosAgendamientos") }}</f7-block-title>
            </f7-block>
            <f7-block v-if="cargandoAgenda" class="text-align-center"> <f7-preloader></f7-preloader><br />{{ $t("agenda.cargandoAgenda") }}</f7-block>
            <f7-block class="text-align-center" v-else-if="!futuras.length">
                {{ $t("agenda.ustedNoTieneHoras") }}
            </f7-block>
            <one-agenda
                v-for="cita in futuras"
                @confirma="confirma"
                @kancela="abortacita"
                @abrephonos="abrePhonesPopover"
                :key="cita.id"
                :cita="cita"
            ></one-agenda>
            <f7-block v-if="futuras.length" class="margin-vertical text-align-center forcedheight"
                ><font-awesome-icon icon="flag-checkered"></font-awesome-icon
            ></f7-block>
        </template>

        <f7-fab
            v-if="privxInfo.identity && !tieneConflicto && !cargandoAgenda"
            @click="verhistorico()"
            position="right-bottom"
            slot="fixed"
            color="teal"
        >
            <font-awesome-icon class="fa-2x" icon="history"></font-awesome-icon>
        </f7-fab>
        <f7-popover class="phonesPopover">
            <f7-list>
                <f7-list-item
                    v-for="(unphone, phkey) in phonesInfoPopover"
                    :key="phkey"
                    @click="callPhone(unphone)"
                    link="#"
                    popover-close
                    :title="unphone"
                ></f7-list-item>
            </f7-list>
        </f7-popover>

        <f7-sheet class="historysheet" :opened="sheetOpened" @sheet:closed="sheetOpened = false">
            <f7-toolbar>
                <div class="left"><f7-block>{{ $t("agenda.misAgendamientosPasados") }}</f7-block></div>
                <div class="right">
                    <f7-link sheet-close>{{ $t("commons.cerrar") }}</f7-link>
                </div>
            </f7-toolbar>
            <!-- Scrollable sheet content -->
            <f7-page-content>
                <one-agenda
                    v-for="cita in pasadas"
                    @confirma="confirma"
                    @kancela="abortacita"
                    @abrephonos="abrePhonesPopover"
                    :key="cita.id"
                    :cita="cita"
                ></one-agenda>
                <f7-block class="text-align-center" v-if="!pasadas.length">{{ $t("agenda.noHayAgendamientosPasados") }}</f7-block>
            </f7-page-content>
        </f7-sheet>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import oneAgenda from "../components/oneAgenda";
import { commonmixin } from "../mixins/common";

export default {
    components: {
        oneAgenda,
    },
    mixins: [commonmixin],

    data() {
        return {
            cargandoAgenda: true,
            phonesInfoPopover: {},
            sheetOpened: false,
        };
    },
    computed: {
        ...mapState(["user"]),
        ...mapGetters(["tieneConflicto", "privxInfo", "agendaSorted"]),
        futuras() {
            return this.agendaSorted.filter(unag => {
                return !this.$dayjs().isAfter(`${unag.fch}`, "D") && !unag.rex;
            });
        },
        pasadas() {
            return this.agendaSorted.filter(unag => {
                return this.$dayjs().isAfter(`${unag.fch}`, "D") || unag.rex;
            });
        },
    },
    methods: {
        verhistorico() {
            // console.log('ver historico');
            this.sheetOpened = true;
        },
        async confirma(agendamientoid) {
            try {
                this.$f7.dialog.preloader(this.$t("agenda.confirmandoAgendamiento"));
                let datous = await this.commonExecute({ agendamientoid }, "mevacuno-confirmarHora", false);
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                //borrar demisagendamientos
                this.$f7.dialog.close();
                //actualizar el agendamiento
                let agendnmClone = Object.assign({}, this.$store.state.agenda[agendamientoid]);
                agendnmClone.cnf = datous.payload;
                let tosave = {};
                tosave[agendamientoid] = agendnmClone;
                this.$store.commit("mergeObjTo", { what: "agenda", to: tosave });
                this.avisaOk(this.$t("agenda.agendamientoConfirmado"));
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },

        async abortacita(agendamientoid) {
            try {
                this.$f7.dialog.preloader(this.$t("agenda.cancelandoAgendamiento"));
                let datous = await this.commonExecute({ agendamientoid }, "mevacuno-eliminahora", false);
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                //borrar demisagendamientos
                this.$f7.dialog.close();
                this.$store.commit("delObje", { what: "agenda", cual: agendamientoid });
                this.avisaOk(this.$t("agenda.agendamientoCancelado"));
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        callPhone(phonnum) {
            document.location.href = "tel:" + phonnum;
        },
        abrePhonesPopover({ estosPhones, targeto }) {
            // console.log(targeto,estosPhones)
            this.phonesInfoPopover = Object.assign({}, estosPhones);
            this.$f7.popover.open(".phonesPopover", `.${targeto}`, true);
        },
        async cargaAgenda() {
            if (!this.agendaSorted.length && !this.tieneConflicto) {
                try {
                    if((!this.user.privx.fechanac || !this.user.privx.nac_iso2) && this.user.privx.verified){
                        let error =  "PROFILE_INCOMPLETE"
                        throw error;
                    }
                    this.cargandoAgenda = true;
                    let datax = await this.$firebase
                        .database()
                        .ref("agndmts_users")
                        .orderByChild("by")
                        .equalTo(this.user.uid)
                        .once("value");
                    this.cargandoAgenda = false;
                    // console.log('agenda',datax.val())
                    if (datax.exists()) {
                        let campanasToGet = [];
                        let requestedCampanas = {};

                        let vacunatoriosToGet = [];
                        let requestedVacunatorios = {};

                        let requestedMisTerceros = false;
                        let sudoAgregatorTerceros = [];
                        datax.forEach(porsnap => {
                            let unacitasnap = porsnap.val();
                            if (!this.$store.state.campanas[unacitasnap.cmp] && !requestedCampanas[unacitasnap.cmp]) {
                                requestedCampanas[unacitasnap.cmp] = true;
                                campanasToGet.push(
                                    this.$firebase
                                        .database()
                                        .ref(`campanas/${unacitasnap.cmp}`)
                                        .once("value")
                                );
                            }
                            if (!this.$store.state.vacunatorios[unacitasnap.cid] && !requestedVacunatorios[unacitasnap.cid]) {
                                requestedVacunatorios[unacitasnap.cid] = true;
                                vacunatoriosToGet.push(
                                    this.$firebase
                                        .database()
                                        .ref(`vacunatorios/${unacitasnap.cid}`)
                                        .once("value")
                                );
                            }
                            if (unacitasnap.for != unacitasnap.by) {
                                if (!this.$store.state.misTerceros[unacitasnap.for] && !requestedMisTerceros) {
                                    requestedMisTerceros = true;
                                    sudoAgregatorTerceros.push(
                                        this.$firebase
                                            .database()
                                            .ref("agndmts_teceros")
                                            .orderByChild("by")
                                            .equalTo(this.user.uid)
                                            .once("value")
                                    );
                                }
                            }
                        });
                        this.$store.commit("setWhatTo", { what: "agenda", to: datax.val() });
                        let powerAgregator = [Promise.all(campanasToGet), Promise.all(vacunatoriosToGet), Promise.all(sudoAgregatorTerceros)];
                        let cooler = await Promise.all(powerAgregator);
                        if (cooler[0]) {
                            let campsToAdd = {};
                            cooler[0].forEach(unacampx => {
                                if (unacampx.exists()) {
                                    campsToAdd[unacampx.key] = unacampx.val();
                                }
                            });
                            this.$store.commit("mergeObjTo", { what: "campanas", to: campsToAdd });
                        }
                        if (cooler[1]) {
                            let vacunatoriosToAdd = {};
                            cooler[1].forEach(unvacsnap => {
                                if (unvacsnap.exists()) {
                                    vacunatoriosToAdd[unvacsnap.key] = unvacsnap.val();
                                }
                            });
                            this.$store.commit("mergeObjTo", { what: "vacunatorios", to: vacunatoriosToAdd });
                        }
                        if (cooler[2] && cooler[2][0]) {
                            if (cooler[2][0].exists()) {
                                this.$store.commit("setObjTo", { what: "misTerceros", to: cooler[2][0].val() });
                            }
                        }
                    }
                } catch (error) {
                    this.cargandoAgenda = false;
                    console.error(error);
                    if(error == "PROFILE_INCOMPLETE"){
                        this.$f7.dialog.alert(this.$t("commons.debeCompletarFechaNacimiento")+  this.$t("leftpanel.miPerfil") , this.$t("commons.permissionDenied"));
                    }else{
                        this.$f7.dialog.alert(error.message, error.code);
                    }
                }
            } else {
                this.cargandoAgenda = false;
            }
        },
    },
};
</script>
<style>
.forcedheight {
    height: 100px !important;
}
</style>
