<template>
  <f7-page name='c19'>
    <f7-navbar>
      <f7-nav-left>
          <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title :subtitle="$t('leftpanel.ingresoPais')" title="C19"></f7-nav-title>
    </f7-navbar>
    <f7-block v-if="!user.emailVerified" class="padding-vertical no-margin-top text-align-center error_clp">
      <p v-html="$sanitize($t('perfil.verifiedEmailNeeded'))"></p>
    </f7-block>
    <f7-block v-else-if="tieneConflicto" class="padding-vertical no-margin-top text-align-center error_clp">
      <p v-html="$sanitize($t('perfil.identidadEnConflicto'))"></p>
    </f7-block>
    <f7-block v-else>
      <f7-button @click="evalRedirect()" large raised fill class="margin-bottom">
        <font-awesome-icon class="fa-lg" icon="plane-departure"/> C19
      </f7-button>
    </f7-block>
  </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { commonmixin } from "../mixins/common";
import localforage from "localforage";
export default{
  mixins: [commonmixin],
  computed: {
    ...mapState(['user']),
    ...mapGetters(["tieneConflicto", "privxInfo"]),
  },
  methods: {
    gotoMenux(urlx) {
      this.$store.commit('setWhatTo',{what:'activeTab', to: urlx})
      this.$f7.views.main.router.navigate(urlx)
    },
    async evalRedirect() {
      let pidetk = this.$firebase.functions().httpsCallable("mevacuno-gentknc19");
      let result = await pidetk({ dev: this.$store.state.dev,c19a: 'state',c19c: 'uid' });
      // console.log('llega respuesta')
      let daouts = result.data;
      if (daouts.error) {
          throw new Error(daouts.error.message);
      }
      let tkn = daouts.payload.token;
      let target = daouts.payload.redirecter;
      this.$f7.dialog.confirm(`Usted será redirigido a ${target}. Desea continuar?`,`Redirección a ${target}`, async ()=> {
          await localforage.removeItem("c19x");
          let url = `https://${target}/#/?mvc=${tkn}`;
          window.open(url, '_blank');
          return null;
      });
    }
  }
};
</script>