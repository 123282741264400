<template>
    <f7-popup
        :opened="opened"
        @popup:opened="init"
        @popup:closed="handleClosePopup"
        :close-by-backdrop-click="false"
    >
        <f7-page>
            <f7-navbar :title="`${editingVacuna.id? $t('commons.editando') : $t('commons.agregando')} ${$t('commons.vacuna')}`">
                <f7-nav-right>
                    <f7-link @click="handleClosePopup">{{ $t('commons.cancelar') }}</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-block v-if="opened">
                <template>
                    <f7-list media-list>
                        <f7-list-item
                            class="margin-vertical"
                            smart-select
                            :smart-select-params="{
                                openIn: 'popup',
                                searchbar: { removeDiacritics: true },
                                searchbarPlaceholder: $t('homologacionCreate.buscarPais'),
                                popupCloseLinkText: $t('commons.cerrar'),
                                closeOnSelect: true,
                                virtualList: true,
                                setValueText: false,
                            }"
                            :disabled="!countries.length"
                            required
                        >
                            <div slot="title">{{ $t("homologacionCreate.quePais") }} <span class="text-color-red">*</span></div>
                            <div slot="after">{{ editingVacuna.p.name || "" }}</div>
                            <select @change="handleCountrySelected($event.target.value)" :value="editingVacuna.p.iso2" name="pais">
                                <option v-for="(pais, index) in countries" :key="index" :value="pais.iso2">
                                    {{ pais.name }}
                                </option>
                            </select>
                        </f7-list-item>
                        <f7-list-item
                            class="margin-vertical"
                            smart-select
                            :smart-select-params="{
                                openIn: 'popup',
                                searchbar: { removeDiacritics: true },
                                searchbarPlaceholder: $t('homologacionCreate.buscarVacuna'),
                                popupCloseLinkText: $t('commons.cerrar'),
                                closeOnSelect: true,
                                setValueText: false,
                            }"
                            :disabled="!sortedVacunasHomologacion.length"
                            required
                        >
                            <div slot="title">{{ $t("homologacionCreate.queVacuna") }} <span class="text-color-red">*</span></div>
                            <div slot="after">{{ editingVacuna.vac.name || "" }}</div>
                            <select @change="handleVacunaSelected($event.target.value)" :value="editingVacuna.vac.id" name="vacuna">
                                <option v-for="(vacuna, index) in sortedVacunasHomologacion" :key="index" :value="vacuna.vacunaPcId">
                                    {{ `${vacuna.laboratorioName} - ${vacuna.vacunaPcName}` }}
                                </option>
                            </select>
                        </f7-list-item>
                        <f7-list-item>
                            <span slot="header">
                                {{ $t("homologacionCreate.cuandoVacuna") }} <span class="text-color-red">*</span>
                            </span>
                            <f7-row slot="footer" class="margin-top-half">
                                <f7-col width="20">
                                    <f7-input
                                        type="select"
                                        outline
                                        @change="splittedDate.dia = Number($event.target.value)"
                                        :value="splittedDate.dia"
                                    >
                                        <option v-for="dia in 31" :key="dia" :value="dia">{{ dia }}</option>
                                    </f7-input>
                                </f7-col>
                                <f7-col width="50">
                                    <f7-input
                                        type="select"
                                        outline
                                        class="inputmes"
                                        @change="splittedDate.mes = Number($event.target.value)"
                                        :value="splittedDate.mes"
                                    >
                                        <option v-for="(mes, idx) in $dayjs.months()" :key="idx + 1" :value="idx + 1">{{ mes }}</option>
                                    </f7-input>
                                </f7-col>
                                <f7-col width="30">
                                    <f7-input
                                        type="select"
                                        outline
                                        class="inputano"
                                        @change="splittedDate.ano = Number($event.target.value)"
                                        :value="splittedDate.ano"
                                    >
                                        <option v-for="(year, index) in vaccineYearsTillNow" :key="index" :value="year">{{ year }}</option>
                                    </f7-input>
                                </f7-col>
                            </f7-row>
                        </f7-list-item>
                        <f7-list-input
                            type="text"
                            :label="$t('homologacionCreate.establecimientoVac')"
                            :value="editingVacuna.establecimiento"
                            @input="editingVacuna.establecimiento = $event.target.value"
                        ></f7-list-input>
                        <f7-list-input
                            type="text"
                            :label="$t('homologacionCreate.nroLote')"
                            :value="editingVacuna.lote"
                            @input="editingVacuna.lote = $event.target.value"
                        ></f7-list-input>
                    </f7-list>
                    <f7-button
                        fill
                        large
                        @click="saveVacuna"
                        class="margin-bottom"
                    >
                        {{ $t("homologacionCreate.guardarVacuna") }}
                    </f7-button>
                    <f7-button
                        v-if="isDeletable"
                        outline
                        color="red"
                        @click="deleteVacuna"
                    >
                        {{ $t("homologacionCreate.eliminarVacuna") }}
                    </f7-button>
                </template>
            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
import { mapState,mapGetters } from "vuex";
import { commonmixin } from "../mixins/common";

export default {
     mixins: [commonmixin],
    props: {
        solicitud:{
            type: Object,
            required: true
        },
        opened: {
            type: Boolean,
            required: true,
        },
        vacuna: {
            type: String,
            required: false,
            default: null,
        }
    },

    data() {
        return {
            editingVacuna: {
                id: null,
                fechavac: null,
                lote: null,
                establecimiento: null,
                p: {
                    iso2: null,
                    name: null,
                },
                vac: {
                    id: null,
                    name: null,
                    labName: null,
                },
            },

            splittedDate: {
                dia: null,
                mes: null,
                ano: null,
            },
        };
    },

    watch: {
        "splittedDate.dia": {
            handler() {
                this.validateDate();
            },
        },
        "splittedDate.mes": {
            handler() {
                this.validateDate();
            },
        },
    },

    computed: {
        ...mapState(["vacunasHomologacion"]),
        ...mapGetters(['sortedVacunasHomologacion']),
        isDeletable(){
            return this.vacuna
        },
        countries() {
            if (!this.$store.getters.paisesArraySorted.length) {
                return [];
            }

            return this.$store.getters.paisesArraySorted.filter(pais => pais.iso2 !== "CL");
        },
        mergedDate() {
            if (!this.splittedDate.dia || !this.splittedDate.mes || !this.splittedDate.ano) {
                return null;
            }

            return `${this.pad(this.splittedDate.dia, 2)}/${this.pad(this.splittedDate.mes, 2)}/${this.splittedDate.ano}`;
        },
        vaccineYearsTillNow() {
            const currentYear = this.$dayjs().year();
            return Array.from(Array(currentYear - 2019 + 1).keys()).map(year => year + 2019);
        },
    },
    mounted(){
        console.log('MONTANDO POPUP EDITOR VACUNAS')
    },
    methods: {
        deleteVacuna() {
            this.$f7.dialog.confirm(
                this.$t("homologacionCreate.confirmEliminarText"),
                this.$t("homologacionCreate.eliminarVacuna"),
                () => {
                        this.borraVacuna()
                });
        },
        async borraVacuna(){
            try {
                //vamos a guardar
                 this.$f7.dialog.preloader(this.$t("commons.guardando"));
                let datous = await this.commonExecute({solid: this.solicitud.id,vacinfox:this.editingVacuna,del:true},"mevacuno-actUponVacunaInSol",false);
                if (datous.error) {
                    throw new Error(datous.error.message || datous.error);
                }
                console.log("resposta",datous);

                //actUponVacunaInSol
                this.$f7.dialog.close();

                this.$emit("deleted", this.editingVacuna.id);
                    this.handleClosePopup();
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message);
            }
        },
        async saveVacuna() {

            if (!this.mergedDate) {
                this.$f7.dialog.close();
                return this.$f7.dialog.alert(this.$t("homologacionCreate.fechaRequired"));
            }
             this.validateDate();

            if (!this.editingVacuna.p.iso2) {
                this.$f7.dialog.close();
                return this.$f7.dialog.alert(this.$t("homologacionCreate.paisRequired"));
            }
            if (!this.editingVacuna.vac.id) {
                this.$f7.dialog.close();
                return this.$f7.dialog.alert(this.$t("homologacionCreate.vacunaRequired"));
            }
                this.editingVacuna.fechavac = this.mergedDate;
            try {
                //vamos a guardar
                 this.$f7.dialog.preloader(this.$t("commons.guardando"));
                let datous = await this.commonExecute({solid: this.solicitud.id,vacinfox:this.editingVacuna,del:null},"mevacuno-actUponVacunaInSol",false);
                if (datous.error) {
                    throw new Error(datous.error.message || datous.error);
                }
                console.log("resposta",datous);

                //actUponVacunaInSol
                this.$f7.dialog.close();
                this.$emit("modified", datous.payload);
                this.handleClosePopup();
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message);
            }

        },

        init() {
            if (this.solicitud.vacs &&  this.solicitud.vacs[this.vacuna]) {
                this.editingVacuna = JSON.parse(JSON.stringify(this.solicitud.vacs[this.vacuna]));
            }

            if (this.editingVacuna.fechavac) {
                this.splittedDate = {
                    dia: Number(this.editingVacuna.fechavac.split("/")[0]),
                    mes: Number(this.editingVacuna.fechavac.split("/")[1]),
                    ano: Number(this.editingVacuna.fechavac.split("/")[2]),
                };
            }
            this.fetchDataIfRequired();
        },
        async fetchDataIfRequired(){
            try {
                let promholder=[];
                //countries
                if (!this.$store.getters.paisesArraySorted.length) {
                    promholder.push( this.fetchCountries() );
                }
                //vacunas
                if (!this.sortedVacunasHomologacion.length) {
                    promholder.push( this.fetchVacunas() );
                }

                await Promise.all( promholder );

            } catch (error) {
                this.$f7.dialog.close();
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        handleClosePopup() {
            this.$emit("popup-closed");
            this.editingVacuna = {
                id: null,
                fechavac: null,
                lote: null,
                establecimiento: null,
                p: {
                    iso2: null,
                    name: null,
                },
                vac: {
                    id: null,
                    name: null,
                    labName: null,
                },
            };
            this.splittedDate = {
                dia: null,
                mes: null,
                ano: null,
            };
        },

        // Form handlers
        handleCountrySelected(iso2) {
            const country = this.countries.find(country => country.iso2 === iso2);
            console.log("country",country);
            if (!country) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(this.$t("homologacionCreate.paisNotFound"));
                return;
            }

            this.$set(this.editingVacuna.p, "iso2", country.iso2);
            this.$set(this.editingVacuna.p, "name", country.name);
        },
        handleVacunaSelected(vacId) {
            const vacuna = this.sortedVacunasHomologacion.find(vacuna => vacuna.vacunaPcId === vacId);
            console.log("vacuna",vacuna);
            if (!vacuna) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(this.$t("homologacionCreate.vacunaNotFound"));
                return;
            }

            this.$set(this.editingVacuna.vac, "id", vacId);
            this.$set(this.editingVacuna.vac, "name", vacuna.vacunaPcName);
            this.$set(this.editingVacuna.vac, "labName", vacuna.laboratorioName);
        },
        handleYearSelected(target) {
            this.splittedDate.ano = Number(target.value);
            if (this.splittedDate.ano > 1900) {
                target.blur();
            }
        },

        // Fetchers
        async fetchCountries() {
            let snapshot = await this.$firebase.database().ref("paises").once("value")
            this.$store.commit("setWhatTo", { what: "paises", to: snapshot.val() });
        },
        async fetchVacunas() {
            let snapshot = await this.$firebase.database().ref("vacunas").orderByChild("show_hmlg").equalTo(true).once("value");
            this.$store.commit("setWhatTo", { what: "vacunasHomologacion", to: snapshot.val() || {} });
        },

        // Validators
        validateDate() {
            if (this.mergedDate && !this.$dayjs(this.mergedDate, "DD/MM/YYYY", true).isValid() ) {
                console.log("mergedDate", this.mergedDate);
                this.$f7.dialog.close();
                return this.$f7.dialog.alert(this.$t("homologacionCreate.fechaInvalida"));
            }
            else if( this.$dayjs().isBefore( this.$dayjs(this.mergedDate, "DD/MM/YYYY"),'day' )){
                this.$f7.dialog.close();
                return this.$f7.dialog.alert(this.$t("homologacionCreate.fechaFuturoInvalida"));
            }
            else if(this.splittedDate.dia && this.splittedDate.mes && this.splittedDate.ano && this.splittedDate.ano<2019){
                this.$f7.dialog.close();
                return this.$f7.dialog.alert(this.$t("homologacionCreate.fechaPasadoInvalida"));
            }
        },

        pad(num, size) {
            num = num.toString();

            if (num.length > 1) {
                return num;
            }

            while (num.length < size) num = "0" + num;
            return num;
        }
    },
}
</script>