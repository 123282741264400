<template>
    <f7-block>
        <f7-block class="text-align-justify">
            <h4>Términos y condiciones</h4>
            <p>
                El presente documento fija los términos y condiciones para el acceso y uso de la plataforma MeVacuno del Ministerio de Salud,
                disponible en el sitio web mevacuno.gob.cl
            </p>
            <h4>1. Antecedentes:</h4>
            <p>
                La plataforma digital MeVacuno (en adelante, la “plataforma”) es un sistema de información desarrollado y administrado por el
                Departamento de Inmunizaciones de la Subsecretaría de Salud Pública, para el proceso validación de la información de vacunas contra
                SARS-CoV-2 administradas en el extranjero; acceder a la continuidad de los esquemas de vacunación en los establecimientos locales y;
                obtener el certificado de vacunación de Chile e internacional.
            </p>
            <p>
                Al respecto, las prerrogativas del Ministerio de Salud se enmarcan en el ejercicio de la función que corresponde al Estado de
                garantizar el libre e igualitario acceso a las acciones de promoción, protección y recuperación de la salud y, rehabilitación de las
                personas; así como coordinar, controlar y ejecutar tales acciones. Lo anterior, conforme a los artículos 1° y 4° Nº 4 y 5 del DFL Nº
                1, de 2005, del Ministerio de Salud, que facultan a esta Secretaría de Estado a “efectuar la vigilancia en salud pública y evaluar la
                situación de salud de la población” y “tratar datos personales o sensibles con el fin de proteger la salud de la población o para la
                determinación y otorgamiento de beneficios de salud.” Lo anterior, en concordancia con lo dispuesto en el Título II “De las
                enfermedades transmisibles” del Código Sanitario.
            </p>
            <p>
                Para los efectos de la plataforma, el usuario será aquel titular de datos personales que voluntariamente se registre en
                www.mevacuno.gob.cl.
            </p>
            <p>
                La cuenta de usuario es personal e intransferible; está limitada estrictamente para el uso personal del usuario y; su titular será el
                único responsable de la actividad de su cuenta.
            </p>
            <p>
                El tratamiento de datos personales en la plataforma guardará relación con las tareas y finalidades estadísticas y de seguimiento de
                los eventos de vacunación y, para el registro y trazabilidad de los usuarios.
            </p>
            <p>
                La identidad, nombre y clave de acceso del usuario serán empleados con el sólo fin de verificar la identidad del titular y procurar su
                acceso a la información.
            </p>
            <p>
                La plataforma utiliza cookies de rendimiento o analíticas para optimizar los servicios web, mejorar la experiencia del usuario y
                recopilar datos estadísticos (Google Analytics es un servicio analítico de web prestado por Google, Inc.).
            </p>
            <p>Toda comunicación entre el servidor y la aplicación ocurre en canales cifrados que utilizan el protocolo TLS.</p>
            <p>Algunos de los servicios presentados en o a través de la plataforma son desarrollados y/o entregados por terceros.</p>
            <p>La ley y los términos y condiciones de acceso y uso de la plataforma constituyen el acuerdo completo y total entre las partes.</p>
            <p>
                El usuario podrá ingresar sus requerimientos a oirs.minsal.cl, indicando claramente los fundamentos fácticos y/o jurídicos de su
                solicitud.
            </p>
            <h4>2. Registro.</h4>
            <p>El titular de datos personales podrá acceder a la plataforma registrándose en mevacuno.gob.cl.</p>
            <p>
                El registro es voluntario y se realizará mediante Clave Única; R.U.N y número de serie de su cédula de identidad chilena o; correo
                electrónico y contraseña.
            </p>
            <p>Por el sólo hecho de registrarse el usuario autoriza el tratamiento de sus datos personales.</p>
            <p>Para dar de baja la cuenta debe comunicarse con el Ministerio de Salud por las vías ya establecidas para esto.</p>
            <h4>3. Servicio ofrecido por la plataforma.</h4>
            <p>
                La plataforma ofrece el acceso a la información sobre las vacunas administradas al usuario en el extranjero y, el proceso de
                verificación y validación de vacunas COVID-19 en Chile.
            </p>
            <p>
                No tiene por objetivo resolver necesidades médicas de emergencia. Si el usuario tiene una emergencia médica deberá asistir al centro
                de salud más cercano o llamar a Salud Responde al 600 360 7777.
            </p>
            <p>El uso de la plataforma, como herramienta tecnológica de información y comunicación, presenta riesgos potenciales ligados a:</p>
            <ul>
                <li>La privacidad y confidencialidad en la relación profesional/usuario mediante interfaces.</li>
                <li>Pérdida de datos por compresión para el aumento de la velocidad de transmisión.</li>
            </ul>

            <p>
                La Subsecretaría de Salud Pública trabaja día a día para reducir al mínimo los riesgos asociados y su posible impacto en el usuario.
            </p>
            <p>
                La Subsecretaría de Salud Pública se reserva el derecho a modificar; adaptar; suprimir; suspender y/o ampliar, en cualquier momento y
                sin aviso previo al usuario, los servicios y el contenido de la plataforma. Cualquier nuevo servicio y/o modificación; adaptación;
                supresión y/o suspensión de los servicios vigentes, será informado en la plataforma y el usuario será responsable de informarse de
                ellos.
            </p>
            <h4>4. Derechos y obligaciones del usuario.</h4>
            <p>Para el acceso y uso de la plataforma el usuario se obliga a:</p>
            <ul>
                <li>
                    Entregar a través del formulario digital aquellos antecedentes y documentos que sean razonables y necesarios a fin de proporcionar
                    información completa, vigente, fidedigna e inteligible sobre su situación real.
                </li>
                <li>Actualizar sus datos personales y documentos relacionados que sean erróneos, inexactos, equívocos o incompletos.</li>
                <li>Emplear la plataforma de acuerdo con la ley y los términos y condiciones de acceso y uso.</li>
                <li>
                    Acceder a los servicios y/o contenidos mediante los procedimientos establecidos para ello o, en su defecto, los que generalmente
                    se empleen en Internet.
                </li>
                <li>No poner en riesgo a la plataforma, sus servicios y/o contenidos.</li>
                <li>Informarse sobre el estado de tramitación de su solicitud de homologación.</li>
                <li>Informarse de cualquier modificación introducida a las condiciones de acceso y uso de la plataforma.</li>
            </ul>

            <p>
                El usuario se hará responsable de los perjuicios y daños causados por su infracción, sea por acción u omisión, de los términos y
                condiciones de uso y acceso de la plataforma y de la ley. La infracción dará lugar al derecho del administrador de suspender la cuenta
                del usuario. El administrador podrá suspender la cuenta del usuario cuya actividad fuese considerada violatoria de los términos y
                condiciones de acceso y uso de la plataforma; la ley; la Política de seguridad, privacidad y confidencialidad de los datos personales.
                Asimismo, el administrador podrá suspender la cuenta del usuario cuya actividad fuese considerada ofensiva, ilegal o atentatoria
                contra los derechos de terceros y/o, violatoria de la seguridad de otros usuarios. La entrega de información errónea, inexacta,
                equívoca o incompleta podría resultar en causales de infracción y sanciones para el usuario; como la prevista en el artículo 210 del
                Código Penal. Por este acto el usuario acepta que la Subsecretaría de Salud Pública no se hará responsable de los daños y perjuicios
                que aquel pueda sufrir con ocasión o por causa de la plataforma, la incapacidad de utilizarla o los resultados de su uso. El usuario
                exime de cualquier responsabilidad a la Subsecretaría de Salud Pública y a los terceros que desarrollen y/o entreguen servicios en o a
                través de la plataforma y; renuncia a todo derecho a interponer cualquier acción en su contra.
            </p>

            <h4>5. Prohibiciones</h4>
            <p>
                El usuario deberá emplear la plataforma de acuerdo con la ley y los términos y condiciones de uso y acceso. En consecuencia, le estará
                prohibido entre otras cosas y sin que la siguiente enumeración sea taxativa:
            </p>
            <ul>
                <li>Emplear la plataforma para fines distintos de los establecidos en los términos y condiciones de uso y acceso.</li>
                <li>
                    Llevar a cabo adaptaciones o modificaciones a la plataforma que puedan afectar el uso y/o la operación para la que ha sido
                    concebida.
                </li>
                <li>
                    Reproducir; copiar; modificar; adaptar; vender; licenciar; distribuir; compartir; descompilar; falsificar; descifrar o alterar la
                    plataforma, su contenido o diseño.
                </li>
                <li>
                    Realizar cualquier acción que pueda dañar; interferir perjudicialmente; interceptar subrepticiamente o expropiar cualquier
                    sistema, datos o información.
                </li>
                <li>
                    Realizar cualquier acción o uso de dispositivo, software u otro medio que pueda interferir en las actividades, operaciones y/o
                    bases de datos de la Subsecretaría de Salud Pública.
                </li>
                <li>Atentar; violar o infringir las leyes de propiedad intelectual e industrial.</li>
                <li>
                    Utilizar cualquier medio automatizado de extracción de datos para acceder, consultar y/o recolectar información relativa a los
                    contenidos o funciones de la plataforma.
                </li>
                <li>
                    Efectuar cualquier actividad que pueda desactivar, sobrecargar o deteriorar los servidores de la Subsecretaría de Salud Pública o
                    de sus prestadores de servicios; así como afectar el buen funcionamiento de la plataforma.
                </li>
            </ul>

            <h4>6. Sobre el acceso y uso de la plataforma.</h4>
            <p>La plataforma ofrece un servicio gratuito.</p>
            <p>
                Es de cargo del usuario proveerse del acceso a la red Internet y obtener e implementar los equipos (hardware), programas (software) u
                otros elementos y/o instrumentos de comunicaciones que le permitan el acceso a la red y el adecuado uso de la plataforma.
            </p>
            <p>
                La Subsecretaría de Salud Pública podrá suspender temporalmente el servicio por motivos de mantención de los sistemas; incidente de
                ciberseguridad; problemas técnicos; corte operacional; corte de energía eléctrica y/o; caso fortuito o fuerza mayor.
            </p>
            <p>
                La Subsecretaría de Salud Pública se reserva la facultad de denegar o retirar, en cualquier momento y sin aviso previo, el acceso a la
                plataforma, sus servicios y/o contenidos.
            </p>
            <p>
                Por este acto el usuario acepta que la Subsecretaría de Salud Pública y/o los terceros que presten servicios en o a través de la
                plataforma, no incurrirán en responsabilidad alguna por la imposibilidad de usar los servicios que presta la plataforma; la demora en
                la obtención de los contenidos; el fracaso al guardar la información; la interferencia en la comunicación y/o los errores u omisiones
                producto de problemas de telecomunicación, técnicos o de los sistemas computacionales. Lo anterior, cualquiera sea su causa.
            </p>
            <p>El empleo de la plataforma está limitado estrictamente para el uso personal del usuario.</p>
            <h4>7. Política de seguridad, privacidad y confidencialidad de los datos personales.</h4>
            <p>
                La Subsecretaría de Salud Pública ha tomado las medidas técnicas necesarias para velar por la seguridad de la plataforma y, los
                canales de comunicación se realicen bajo protocolos de cifrado seguro, resguardando la confidencialidad y la protección de los datos
                personales de los usuarios.
            </p>
            <p>
                La seguridad de esta aplicación está garantizada en conformidad a lo señalado en las Políticas de Seguridad del Ministerio de Salud
                que se encuentran disponibles en el sitio web del MINSAL (disponibles en https://www.minsal.cl/seguridad_de_la_informacion/) que, para
                todos los efectos legales se entienden íntegramente reproducidas y forman parte del presente documento. Aplican las siguientes
                políticas aprobadas mediante la resolución exenta N°544 de 15 de julio de 2021:
            </p>
            <ul>
                <li>Política general de seguridad de la información</li>
                <li>Política de seguridad para el control de acceso lógico</li>
                <li>Política de seguridad para la clasificación y manejo de información</li>
                <li>Política de desarrollo de sistemas</li>
                <li>Política de identificación y autenticación de usuarios</li>
                <li>Política para la continuidad de la seguridad de información</li>
                <li>Política protección de datos personales</li>
            </ul>
            <p>
                Política de respaldo de información y software El tratamiento de datos personales por la Subsecretaría de Salud Pública respeta la
                confidencialidad y privacidad de la información del usuario de acuerdo con lo dispuesto en el artículo 19 Nº 4 de la Constitución
                Política de la República y las demás normas aplicables; en particular, las leyes Nº 19.628 y N° 20.584 en lo referente a los derechos
                de los usuarios.
            </p>

            <p>
                La Subsecretaría de Salud Pública se compromete a realizar todos los esfuerzos institucionales a fin de gestionar la seguridad de los
                datos personales que el usuario registre en sitio web mevacuno.gob.cl., en base a metodologías y técnicas estándares que permitan
                niveles adecuados de confidencialidad.
            </p>

            <p>
                El Ministerio de Salud podrá comunicar a otros organismos del Estado los datos personales de los usuarios, conforme a lo establecido
                por la ley N° 19.628.
            </p>

            <p>
                El Ministerio de Salud podrá, en caso de ser requerido judicialmente al efecto, comunicar los datos personales de los usuarios que le
                sean solicitados.
            </p>

            <p>
                La información registrada en la plataforma será almacenada y replicada en una nube bajo la completa administración de la Subsecretaría
                de Salud Pública.
            </p>

            <p>
                El registro de clave única a través del portal es administrado y su resguardo es realizado por una entidad distinta al Ministerio a
                través del Ministerio de la Secretaría General de la Presidencia y su fin responde a un mecanismo de autenticación de dos factores que
                requiere tanto un nombre de usuario como una contraseña para acceder a los servicios en línea de la plataforma.
            </p>

            <h4>8. Propiedad intelectual e industrial.</h4>

            <p>La Subsecretaría de Salud Pública es el titular exclusivo de los derechos de propiedad intelectual sobre la plataforma.</p>
            <p>
                Todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparezcan en sitio web son de propiedad de la
                Subsecretaría de Salud Pública; sin que pueda entenderse que el uso o acceso a la plataforma atribuye al usuario derecho alguno sobre
                las citadas marcas, nombres comerciales o signos distintivos de cualquier clase.
            </p>
            <h4>9. Limitación de responsabilidad</h4>
            <p>El acceso y uso de la plataforma es de exclusiva responsabilidad del usuario.</p>
            <p>
                La información, documentos, contenidos u otros que el usuario reporte, ingrese, publique, transmita o retransmita en la plataforma son
                de su exclusiva responsabilidad.
            </p>
            <p>
                El usuario será responsable de los daños y perjuicios causados con ocasión o a causa de su acceso y/o uso de la plataforma y/o, de la
                información que ingrese a ésta.
            </p>
            <p>
                Por este acto el usuario acepta que la Subsecretaría de Salud Pública y/o los terceros que presten servicios y/o entreguen contenidos
                en o a través de la plataforma, no serán responsables de los daños y perjuicios causados con ocasión o a causa de su acceso y/o uso de
                la plataforma y/o, de la información que ingrese a ésta.
            </p>
            <p>
                Las partes acuerdan que los registros computacionales de la plataforma y sus servicios o, de terceros que presten servicios a través
                de la plataforma, constituirán prueba suficiente del acceso y uso de la plataforma por el usuario.
            </p>
            <h4>10. Competencia</h4>
            <p>
                La ley aplicable será la de la República de Chile. El usuario y la Subsecretaría de Salud Pública fijan su domicilio en Santiago de
                Chile y, por este acto acuerdan someterse a la jurisdicción de sus tribunales de justicia.
            </p>
            <h4>11. Declaración jurada</h4>
            <p>{{ meText }}</p>
        </f7-block>
    </f7-block>
</template>
<script>
export default {
    computed: {
        eldomin() {
            return window.location.hostname;
        },
        meText() {
            if (!this.$store.state.user || !Object.keys(this.$store.state.user.privx || {}).length) {
                return "Yo, usuario de MeVacuno, he evaluado cuidadosamente la exactitud, la exhaustividad y la pertinencia de los términos y condiciones de acceso y uso de la plataforma MeVacuno y, por este acto declaro mi aceptación y su cumplimiento.";
            }

            return (
                "Yo, " +
                this.$store.state.user.privx.nombres +
                " " +
                this.$store.state.user.privx.apellido1 +
                " " +
                this.$store.state.user.privx.apellido2 +
                " N° " +
                this.$store.state.user.privx.identity +
                ", he evaluado cuidadosamente la exactitud, la exhaustividad y la pertinencia de los términos y condiciones de acceso y uso de la plataforma MeVacuno y, por este acto declaro mi aceptación y su cumplimiento."
            );
        },
    },
};
</script>
