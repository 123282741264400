<template>
    <f7-page name="vacunas-inter-create" @page:beforein="cargaUrlsArchivos()">
        <f7-navbar :title="$t('homologacionCreate.soliciTitle')" :subtitle="$t('homologacionCreate.soliciSubtitle')" back-link="Back">
            <f7-nav-right>
                <f7-block>
                    <f7-button v-if="!hideEditButton && ['En espera', 'En conflicto'].includes(solicitud.est)" @click="cambiarEstadoAEditar('En edición')" fill small>
                        <font-awesome-icon class="fa-md" icon="pencil-alt"></font-awesome-icon>
                        {{ this.$t("homologacionCreate.startEditing") }}
                    </f7-button>
                </f7-block>
            </f7-nav-right>
        </f7-navbar>

        <f7-row no-gap>
            <f7-col v-if="!isEditing" width="100" medium="70" class="padding-horizontal-half">
                <f7c-alert type="info" class="margin">
                    {{ getInfoEstadoSolicitud(solicitud.est) }}
                </f7c-alert>
            </f7-col>
            <input type="file" id="myfilerx-input" ref="comprobanteVacuna" @change="filesChange($event)" accept="image/*,application/pdf" />
            <f7-col width="100" medium="70">
                <f7-block class="padding-horizontal-half">
                    <h3 class="display-flex align-items-center justify-content-space-between">
                        <span>
                            {{
                                viewOnly ?
                                    $t("homologacionCreate.declaradas") :
                                    $t("homologacionCreate.declaracion")
                            }}
                        </span>
                    </h3>
                    <span v-if="!sortedVacunas.length">
                        {{ $t("homologacionCreate.ningunaVacunaDeclarada") }}
                    </span>
                    <f7-list inset media-list class="no-margin-horizontal">
                        <f7-list-item
                            v-for="(vacuna, key) in sortedVacunas"
                            :key="key"
                            :link="isEditing?'#':null"
                            @click.native="editVacuna(vacuna)"
                        >
                            <span v-if="!checkVacunaStatus(vacuna, ['Aceptada', 'Rechazada'])" slot="header">
                                {{ $t("homologacionCreate.fechaVacunacion") }}: {{ vacuna.fechavac || "-" }}
                            </span>
                            <b v-else slot="header" :class="vacuna.est.resol === 'Aceptada' ? 'text-color-green' : 'text-color-red'">
                                <font-awesome-icon
                                    class="fa-md"
                                    :icon="vacuna.est.resol === 'Aceptada' ? 'check-circle' : 'times-circle'"
                                ></font-awesome-icon>
                                {{ vacuna.est.resol === "Aceptada" ? $t("homologacionCreate.aceptada") : $t("homologacionCreate.rechazada") }}
                            </b>
                            <b v-if="vacuna.est && vacuna.est.toPaxid" slot="footer">{{ $t("homologacionCreate.registradaPaciente", { pid: vacuna.est.toPaxid }) }}</b>

                            <b slot="title">{{
                                vacuna.vac.id ? `${vacuna.vac.name} - ${vacuna.vac.labName}` : `${$t("homologacionCreate.vacuna")}: -`
                            }}</b>

                            <span v-if="!checkVacunaStatus(vacuna, ['Aceptada', 'Rechazada'])" slot="subtitle" style="font-size: 13px;">
                                {{ $t("homologacionCreate.paisDeAdministracion") }}: {{ vacuna.p.name || "-" }}<br />
                                {{ $t("homologacionCreate.establecimiento") }}: {{ vacuna.establecimiento || "-" }}<br />
                                {{ $t("homologacionCreate.lote") }}: {{ vacuna.lote || "-" }}<br />
                            </span>
                            <span v-else slot="subtitle" style="font-size: 13px;">
                                {{
                                    $t("homologacionCreate.vacunaStatusWithDate", {
                                        decision:
                                            vacuna.est.resol === "Aceptada"
                                                ? $t("homologacionCreate.aceptada").toLowerCase()
                                                : $t("homologacionCreate.rechazada").toLowerCase(),
                                        date: $d($dayjs.unix(vacuna.est.when), "twoDigitsWithTime"),
                                    })
                                }}
                            </span>
                            <b v-if="checkVacunaStatus(vacuna, ['Rechazada'])" slot="footer" class="text-color-red">
                                {{ $t("homologacionCreate.motivo") }}: {{ vacuna.est.motiv || "-" }}
                            </b>

                            <span v-if="isEditing && !checkVacunaStatus(vacuna, 'Aceptada')" slot="after">
                                <font-awesome-icon class="fa-sm" icon="pencil-alt"></font-awesome-icon>
                                {{ $t("commons.editar") }}
                            </span>
                        </f7-list-item>
                    </f7-list>
                    <f7-row>
                        <f7-col width="100" medium="50"></f7-col>
                        <f7-col width="100" medium="50">
                            <f7-button v-if="isEditing" @click="editVacuna(null)" icon-material="add" outline class="display-flex align-items-center">
                                {{ $t("homologacionCreate.agregarVacuna") }}
                            </f7-button>
                        </f7-col>
                    </f7-row>
                    <h3 class="no-margin-horizontal overflow-text">
                        {{
                            viewOnly ?
                                $t("homologacionCreate.addedDocuments") :
                                $t("homologacionCreate.addDocumentsTextt")
                        }}
                    </h3>
                    <f7c-alert v-if="tieneVacunaUnionEuropea && solicitud.est !== 'Aceptada'" type="info">
                        {{ $t("homologacionCreate.unionEuropeaDocsText") }}
                    </f7c-alert>
                    <p v-if="!Object.values(solicitud.files || {}).length">{{ $t("homologacionCreate.ningunDocumento") }}</p>
                    <f7-list class="fixfamarg no-margin-top" media-list v-else>
                        <f7-list-item v-for="unfile in solicitud.files" :key="unfile.id" :title="unfile.name">
                            <f7-preloader slot="after" v-if="loadingFiles"></f7-preloader>
                            <div v-else-if="unfile.url" slot="after" class="display-flex align-items-center">
                                <f7-link @click="handleViewFile(unfile)" :tooltip="$t('homologacionCreate.verArchivo')">
                                    <font-awesome-icon class="fa-lg" icon="eye"></font-awesome-icon>
                                </f7-link>
                                <f7-link v-if="isEditing && unfile.wh > solicitud.last_edit" @click="handleDeleteFile(unfile)" class="margin-left text-color-red" :tooltip="$t('homologacionCreate.eliminarArchivo')">
                                    <font-awesome-icon class="fa-lg" icon="trash"></font-awesome-icon>
                                </f7-link>
                            </div>
                            <font-awesome-icon
                                class="fa-lg"
                                slot="media"
                                :icon="unfile.strdname.split('.')[1] == 'pdf' ? 'file-pdf' : 'file-image'"
                            ></font-awesome-icon>
                        </f7-list-item>
                    </f7-list>
                    <f7-row>
                        <f7-col width="100" medium="50"></f7-col>
                        <f7-col width="100" medium="50">
                            <f7-button v-if="isEditing" @click="elegirFiler()" icon-material="add" outline class="display-flex align-items-center">
                                {{ $t("homologacionCreate.agregarFilerx") }}
                            </f7-button>
                        </f7-col>
                    </f7-row>

                    <f7-block-title class="display-flex align-items-center justify-content-space-between margin-top">
                        <span>
                            {{
                                viewOnly ?
                                    $t("homologacionCreate.notasEnSolicitud") :
                                    $t("homologacionCreate.agregaTusNotas")
                            }}
                        </span>
                    </f7-block-title>
                    <f7-card outline class="margin-top">
                        <f7-block class="padding">
                            <h3>{{ $t("solicitudMessenger.defaultTitle") }}</h3>
                            <f7-block class="text-align-center" v-if="loadingFiles"><f7-preloader></f7-preloader></f7-block>
                            <span v-else-if="!msgsSolicitudEnEspera.length" class="text-color-gray">
                                {{ $t("solicitudMessenger.noHayNotas") }}
                            </span>
                            <f7-list media-list class="messenger-notes">
                                <f7-list-item class="list-item-no-padding" v-for="(msg, index) in msgsSolicitudEnEspera" :key="index" :title="msg.text">
                                    <div slot="header">
                                        <span>{{ msg.sender_uid == user.uid ? $t("solicitudMessenger.tu") : $t("solicitudMessenger.validator") }}</span>
                                    </div>
                                    <div slot="footer">
                                        <span>{{
                                            $t("solicitudMessenger.enviadoEl", { date: $d($dayjs.unix(msg.wh / 1000), "twoDigitsWithTime") })
                                        }}</span>
                                    </div>
                                </f7-list-item>
                            </f7-list>
                            <template v-if="isEditing">
                                <f7-list media-list>
                                    <f7-list-input
                                        class="list-item-no-padding text-area-size"
                                        outline
                                        type="textarea"
                                        clear-button
                                        maxlength="500"
                                        :placeholder="$t('solicitudMessenger.writeYourNoteHere')"
                                        :value="msg_text"
                                        @input="msg_text = $event.target.value"
                                    >
                                    </f7-list-input>
                                </f7-list>
                                <f7-button
                                    @click="handleMsgSent()"
                                    :disabled="loadingSendMsg"
                                    outline
                                    icon-material="add"
                                    class="display-flex align-items-center"
                                >
                                    {{ loadingSendMsg ? $t("solicitudMessenger.adding") : $t("solicitudMessenger.addNote") }}
                                </f7-button>
                            </template>
                        </f7-block>
                    </f7-card>

                    <f7-block v-if="isEditing">
                        <f7-button @click="cambiarEstadoAEditar('En espera')" fill large raised>
                            <f7-icon material="send"></f7-icon>
                            {{ $t("homologacionCreate.guardarCambios") }}
                        </f7-button>
                    </f7-block>
                </f7-block>
            </f7-col>
            <f7-col width="100" medium="30">
                <f7-block class="padding-horizontal-half segunda-col">
                    <template>
                        <small class="display-block margin-bottom-half text-color-gray">
                            Documentos a adjuntar:<br><br>
                            <ul>
                                <li>
                                    <div v-html="$sanitize($t('homologacionCreate.ex1_text'))"></div>
                                </li>
                            </ul>
                        </small>
                        <f7-link @click="viewExampleImg({ name: 'ex_passport.jpg', url: '../../static/img/ex_passport.jpg' })">
                            <img
                                src="../../static/img/ex_passport.jpg"
                                alt="Ejemplo de documentación"
                                class="vaccine-card-img-thumb margin-bottom-half"
                            >
                        </f7-link>

                        <small class="display-block margin-bottom-half text-color-gray">
                            <ul>
                                <li>
                                    <div v-html="$sanitize($t('homologacionCreate.ex22_text'))"></div>
                                </li>
                            </ul>
                        </small>
                        <f7-link @click="viewExampleImg({ name: 'ex_vaccine_card.jpg', url: '../../static/img/ex_vaccine_card.jpg' })">
                            <img
                                src="../../static/img/ex_vaccine_card.jpg"
                                alt="Ejemplo de carnet de vacunación"
                                class="vaccine-card-img-thumb margin-bottom-half"
                            >
                        </f7-link>
                    </template>
                </f7-block>
            </f7-col>
        </f7-row>

        <edit-vacuna-popup
            :opened="modalEditingVacunaOpened"
            :vacuna="editingVacuna"
            :isDeletable="sortedVacunas.length > 1"
            @popup-closed="modalEditingVacunaOpened = false"
            @modified="handleVacunaModified"
            @deleted="handleVacunaDeleted"
            :solicitud="solicitud"
        ></edit-vacuna-popup>

        <f7-popover class="popover-vaccine-card-example">
            <img src="../../static/img/eu-vaccine-card.jpg" alt="Ejemplo de carnet de vacunación" class="vaccine-card-img">
        </f7-popover>
    </f7-page>
</template>

<script>
import { mapState } from "vuex";
import { commonmixin } from "../../mixins/common";

import EditVacunaPopup from "../../components/EditVacunaPopup.vue";
import Alert from "../../components/utils/Alert.vue";

export default {
    mixins: [commonmixin],
    components: {
        EditVacunaPopup,
        "f7c-alert": Alert,
    },
    props: {
        sol: Object,
        viewOnly: Boolean,
    },
    data() {
        return {
            modalEditingVacunaOpened: false,
            editingVacuna: null,
            loadingSendMsg: false,
            msgsSolicitudEnEspera: [],
            msg_text: "",
            solicitud: {},
            //carga archivos
            progressdialog: null,
            task: null,
            fileUploadingName: null,
            loadingFiles: false,
            hideEditButton: false,
        };
    },

    computed: {
        ...mapState(["user", "tieneSolicitudPendiente"]),

        sortedVacunas() {
            return Object.values(this.solicitud.vacs || {}).sort((a, b) => {
                if (a.fechavac < b.fechavac) return -1;
                if (a.fechavac > b.fechavac) return 1;
                return 0;
            });
        },
        isEditing() {
            return this.solicitud.est === "En edición";
        },
        tieneVacunaUnionEuropea() {
            return this.sortedVacunas.some(v => {
                if (v.p && v.p.ue === true) return true;
            });
        },
    },
    mounted() {
        this.solicitud = Object.assign({}, this.sol);
        console.log("sol received", this.sol);
        console.log("viewOnly", this.viewOnly);
    },
    methods: {
        async cargaUrlsArchivos() {
            try {
                if (this.solicitud.files) {
                    console.log("cargando URLs archivos");
                    this.loadingFiles = true;

                    let resposta = await this.commonExecute({ solid: this.solicitud.id }, "mevacuno-getAllURLsOneSol", false);
                    if (resposta.error) {
                        throw new Error(resposta.error.message || resposta.error.code || resposta.error);
                    }
                    if(resposta.payload.filesNotExisting && resposta.payload.filesNotExisting.length){
                        resposta.payload.filesNotExisting.forEach(unaMissingFileId => {
                            if(this.solicitud.files && this.solicitud.files[unaMissingFileId]){
                                this.$delete(this.solicitud.files, unaMissingFileId);
                            }
                        });
                    }

                    resposta.payload.files.forEach(unpotfile => {
                        this.solicitud.files[unpotfile.id] = Object.assign({}, unpotfile);
                    });

                    this.msgsSolicitudEnEspera = Object.values(resposta.payload.mensajesx || {}).sort((a, b) => {
                        if (a.wh < b.wh) return 1;
                        if (a.wh > b.wh) return -1;
                        return 0;
                    });

                    //mensajesx
                    this.loadingFiles = false;
                }
            } catch (error) {
                this.loadingFiles = false;
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code);
            }

            /*
                chelo aqui vas

                -> borrar vacunas
                -> agregar notas
                -> enviar a revision
                -> al enviar a revision, flagear para que EVA sepa

            */
        },
        elegirFiler() {
            this.$$("#myfilerx-input").click();
        },
        async filesChange(e) {
            let file = e.target.files[0];
            if (file) {
                console.log(file);
                let permfile = await this.pedirPermAndUpload({ filereceive: file });
                this.subirlefile({ filereceive: file, permfile });
                this.$refs.comprobanteVacuna.value = null;
            }
        },
        handleViewFile(file) {
            const fileExt = file.name.split(".").pop();

            if (fileExt === "pdf") {
                window.open(file.url, '_blank')
            } else {
                this.$f7.photoBrowser.create({
                    photos: [file.url],
                    type: "popup",
                    toolbar: false,
                    popupCloseLinkText: this.$t("commons.cerrar"),
                }).open();
            }
        },
        async handleDeleteFile(file) {
            this.$f7.dialog.confirm(
                this.$t("homologacionCreate.seEliminaraArchivo"),
                this.$t("commons.areYouSure"),
                async () => {
                    try {
                        this.$f7.dialog.preloader(this.$t("homologacionCreate.eliminando"));
                        const response = await this.commonExecute({
                            solid: this.solicitud.id,
                            fileid: file.id,
                            dev: this.$store.state.dev,
                        }, "mevacuno-delFileFromSolHom");
                        if (response.error) {
                            throw new Error(response.error.message || response.error.code || response.error);
                        }

                        //eliminar de la lista
                        this.$delete(this.solicitud.files, file.id);

                        console.log("response eliminacion", response);

                        this.$f7.dialog.close();
                    } catch (error) {
                        console.error(error.message);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(error.message, error.code);
                    }
                });
        },
        async pedirPermAndUpload({ filereceive }) {
            try {
                this.$f7.dialog.preloader(this.$t("solicitarVerificacion.preparandoCarga"));
                let filename = filereceive.name;
                console.log("filename", filename);
                let extenx = filename
                    .split(".")
                    .pop()
                    .toLowerCase();
                console.log("extenx", extenx);
                let resposta = await this.commonExecute({ solid: this.solicitud.id, filename, ext: extenx }, "mevacuno-getUpldPermHomolog", false);
                if (resposta.error) {
                    throw new Error(resposta.error.message || resposta.error.code || resposta.error);
                }
                await this.$firebase.auth().currentUser.reload();
                let user = this.$firebase.auth().currentUser;
                await user.getIdToken(true);
                this.$f7.dialog.close();
                return resposta.payload;
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        subirlefile({ filereceive, permfile }) {
            var metadata = {
                contentType: filereceive.type,
            };
            this.fileUploadingName = permfile;
            this.progressdialog = this.$f7.dialog.progress(this.$t("solicitarVerificacion.cargandoFiler"), 0);
            this.progressdialog.setText("0%");
            this.task = this.$firebase
                .storage()
                .ref(`verifs_${this.$store.state.dev ? "d" : "p"}/${this.user.uid}/${this.solicitud.id}/${permfile}`)
                .put(filereceive, metadata);
            this.task.on("state_changed", this.alsnapshot, this.alerror, this.altermino);
        },
        alsnapshot(snapshot) {
            let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            let nentero = Math.round(percentage);
            if (!isNaN(nentero)) {
                this.progressdialog.setProgress(nentero);
                this.progressdialog.setText(nentero + "%");
            }
        },
        alerror(err) {
            console.log(err);
            this.progressdialog.close();
            this.$f7.dialog.alert(err.code, this.$t("solicitarVerificacion.errorEnCarga"));
        },
        altermino() {
            console.log(this.task, "se subio el archivo!");
            this.progressdialog.close();
            this.$f7.dialog.preloader(this.$t("solicitarVerificacion.procesando"));
            console.log("terminó la carga de forma exitosa");
            return this.commonExecute(
                { solid: this.solicitud.id, fileid: this.fileUploadingName.split(".")[0] },
                "mevacuno-getUrlDescargaFile",
                false
            )
                .then(respoesta => {
                    this.$f7.dialog.close();
                    if (respoesta.error) {
                        throw new Error(respoesta.error.message || respoesta.error.code || respoesta.error);
                    }
                    else if(respoesta.noSaved){
                        if(this.solicitud.files && this.solicitud.files[respoesta.noSaved]){
                            this.$delete(this.solicitud.files, respoesta.noSaved);
                            return this.$f7.dialog.alert('File not saved. Try again','Error');
                        }
                    }
                    console.log("File available at", respoesta);
                    let files = {};
                    files[respoesta.payload.id] = respoesta.payload;
                    if (!this.solicitud.files) {
                        this.solicitud = Object.assign({}, this.solicitud, { files });
                    } else {
                        this.solicitud.files = Object.assign({}, this.solicitud.files, files);
                    }
                })
                .catch(error => {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                });
        },
        async cambiarEstadoAEditar(nuevoestado) {
            try {
                this.$f7.dialog.preloader(this.$t("commons.cargando"));
                let datous = await this.commonExecute({ solid: this.solicitud.id, target: nuevoestado }, "mevacuno-cambioEstadoSolicitud", false);
                if (datous.error) {
                    if(datous.hideEdit){
                        this.hideEditButton = true;
                    }
                    else if(datous.missingFiles && datous.missingFiles.length){
                        datous.missingFiles.forEach(unaMissingFileId => {
                            if(this.solicitud.files && this.solicitud.files[unaMissingFileId]){
                                this.$delete(this.solicitud.files, unaMissingFileId);
                            }
                        });
                    }
                    throw new Error(datous.error.message || datous.error);
                }
                console.log("resposta", datous);
                this.solicitud.est = nuevoestado;
                this.$f7.dialog.close();
                if (nuevoestado === "En espera") {
                    this.$f7.views.main.router.back();
                }
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message);
            }
        },
        //validar abajo
        async handleMsgSent() {
            if (!this.msg_text.trim().length) {
                this.$f7.dialog.alert(this.$t("solicitudMessenger.debeEscribir"));
                return;
            }
            try {
                this.loadingSendMsg = true;
                let datous = await this.commonExecute({ solid: this.solicitud.id, msg: this.msg_text }, "mevacuno-mandarMensajeHomolog", false);
                if (datous.error) {
                    throw new Error(datous.error.message || datous.error);
                }
                console.log("resposta", datous);
                let nuevoMnesjae = datous.payload;
                this.msgsSolicitudEnEspera.unshift(nuevoMnesjae);
                this.msg_text = "";
                this.loadingSendMsg = false;
            } catch (error) {
                this.loadingSendMsg = false;
                console.error(error);
                this.$f7.dialog.alert(error.message);
            }
        },

        // Vacuna handlers
        editVacuna(vacuna) {
            const isAccepted = vacuna && vacuna.est && vacuna.est.resol === "Aceptada";

            if(this.isEditing && (!isAccepted || !vacuna)){
                 console.log('esta editando, levanta')
                this.editingVacuna = vacuna ? vacuna.id : null;
                this.modalEditingVacunaOpened = true;
            }
        },
        handleVacunaModified(vacuna) {
            if (!this.solicitud.vacs) {
                let tosave = {};
                tosave[vacuna.id] = vacuna;
                this.$set(this.solicitud, "vacs", tosave);
            } else {
                this.$set(this.solicitud.vacs, vacuna.id, vacuna);
            }
            this.editingVacuna = null;
        },
        handleVacunaDeleted(id) {
            this.$delete(this.solicitud.vacs, id);
        },
        checkVacunaStatus(vacuna, statuses) {
            return vacuna.est && statuses.includes(vacuna.est.resol);
        },
        viewExampleImg(file) {
            this.handleViewFile(file);
        },
        getInfoEstadoSolicitud(estado) {
            if (estado == "En espera")
                return this.$t("solicitudesMain.estadoEsperaInfo");
            if (estado == "Aceptada")
                return this.$t("solicitudesMain.estadoAceptadaInfo");
            if (estado == "En conflicto")
                return  this.$t("solicitudesMain.estadoEnConflictoInfo");
            if (estado == "En edición")
                return  this.$t("solicitudesMain.estadoEdicionInfo");
            if (estado == "En revisión")
                return  this.$t("solicitudesMain.estadoRevisionInfo");
            if (estado == "Cerrada")
                return  this.$t("solicitudesMain.estadoCerradaInfo");
            return "";
        },
    },
};
</script>
<style>
#myfilerx-input {
    display: none;
}
.messenger-notes {
    max-height: 150px;
    overflow-y: scroll;
}
.vaccine-card-img {
    height: 100%;
    width: 100%;
}
.popover-vaccine-card-example {
    --f7-popover-width: 600px;
}
.vaccine-card-img-thumb {
    max-width: 100%;
}
@media (max-width: 768px) {
    .vaccine-card-img-thumb {
        max-width: 100%;
    }
    .segunda-col {
        --f7-block-margin-vertical: 0;
    }
}
.overflow-text {
    --f7-block-title-white-space: normal;
}
</style>
