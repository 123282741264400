<template>
    <f7-page @page:beforein="antesdeInPage()" name="perfil">
        <!-- Top Navbar -->
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title :subtitle="$t('perfil.gestionIdentidad')" :title="$t('leftpanel.miPerfil')"></f7-nav-title>
        </f7-navbar>

        <f7-block v-if="!user.email" class="padding-vertical no-margin-top text-align-center error_clp">
            <h3><font-awesome-icon class="fa-lg" icon="mail-bulk" />{{ $t("perfil.personalEmail") }}</h3>
            <p v-html="$sanitize($t('perfil.verifiedEmailNeeded'))"></p>
            <f7-button @click="promptemail()" large fill class="margin-bottom" raised>{{ $t("perfil.myEmail") }}</f7-button>
        </f7-block>

        <f7-block v-else-if="!user.emailVerified" class="padding-vertical no-margin-top text-align-center warning_clp">
            <h3><font-awesome-icon class="fa-lg" icon="mail-bulk" />{{ $t("perfil.pendienteVerificacion") }}</h3>
            <p v-html="$sanitize($t('perfil.reviseCorreo', { email: user.email }))"></p>
            <p>
                {{ $t("perfil.mistypedEmail") }}
                <f7-link @click="promptemail()">{{ $t("perfil.anotherEmail") }}</f7-link>
            </p>
            <f7-button @click="verifyme()" large fill class="margin-bottom" raised>{{ $t("perfil.resendVerification") }}</f7-button>
            <p>
                {{ $t("perfil.mailAlreadyValidated") }}
                <f7-link @click="refreshme()">{{ $t("perfil.refreshSite") }}</f7-link>
            </p>
        </f7-block>

        <template v-else>
            <f7-block class="text-align-center" v-if="procesandoIdentity"
                ><f7-preloader></f7-preloader><br />{{ $t("perfil.cargandoIdentidad") }}</f7-block
            >

            <f7-block v-else-if="!mysub.id && (!privxInfo || !privxInfo.verified)" class="no-margin no-padding">
                <f7-block v-if="privxInfo.identity && tieneConflicto">
                    <f7-block class="padding no-margin-vertical text-align-center error_clp">
                        <h3 class="margin-vertical-half">
                            <font-awesome-icon class="fa-lg" icon="exclamation-circle" />
                            {{ $t("perfil.identidadEnConflicto") }}
                        </h3>
                        <p v-html="$sanitize($t('perfil.redeclaredIdentity', { identity: `${privxInfo.idenType}: ${identidadPro}` }))"></p>
                    </f7-block>
                </f7-block>
                <f7-block v-if="!tieneSolicitudPendiente" class="padding no-margin-vertical text-align-center warning_clp">
                    <h3 class="margin-vertical-half">
                        <font-awesome-icon class="fa-lg" icon="exclamation-circle" />
                        {{ $t("perfil.verificarIdentidad") }}
                    </h3>
                    <p>{{ $t("perfil.completarPerfilDeclarandoIdentidad") }}</p>
                </f7-block>

                <!-- Borrar estos diccionarios
                backVerification
                backVerificationForeigners
                backVerificationMenores

                y estas variables
                selectedVerifyMode
                selectedExtranjeroMode
                selectedMenoresMode
                 -->

                <f7-block class="no-padding no-margin-top">
                    <f7-row no-gap>
                        <f7-col width="100" medium="50">
                            <f7-card>
                                <f7-card-content>
                                    <f7-list media-list>
                                        <f7-list-item>
                                            <img slot="media" style="width: 90px" src="../static/logos/gobsmall.jpg" />
                                            <clave-unica></clave-unica>
                                            <p v-html="$sanitize($t('perfil.mecanismoClaveUnica'))" class="explic"></p>
                                        </f7-list-item>
                                    </f7-list>
                                </f7-card-content>
                            </f7-card>
                        </f7-col>

                        <f7-col width="100" medium="50">
                            <f7-card>
                                <f7-card-content>
                                    <f7-list media-list>
                                        <f7-list-item>
                                            <img slot="media" style="width: 90px" src="../static/img/cedula.jpeg" />
                                            <f7-button class="no-margin-top" @click="declaracionIdentidadSerie = true" large fill>{{
                                                $t("commons.serialNumber")
                                            }}</f7-button>
                                            <p v-html="$sanitize($t('perfil.mecanismoNroSerie'))" class="explic"></p>
                                        </f7-list-item>
                                    </f7-list>
                                </f7-card-content>
                            </f7-card>
                        </f7-col>
                    </f7-row>

                    <f7-card>
                        <f7-card-content>
                            <f7-list media-list>
                                <f7-list-item>
                                    <img slot="media" style="width: 90px" src="../static/heros/verify_identity.jpg" />
                                    <solicitudes-loader :user="user"></solicitudes-loader>
                                    <p v-html="$sanitize($t('perfil.mecanismoSolicitudVerificacionIdentidad'))" class="explic"></p>
                                </f7-list-item>
                            </f7-list>
                        </f7-card-content>
                    </f7-card>

                    <f7-block
                        v-if="
                            (tieneSolicitudPendiente && tieneSolicitudPendiente.wh && !tieneSolicitudPendiente.rjt) ||
                                (!procesandoIdentity && privxInfo.identity && !tieneConflicto)
                        "
                    >
                        <f7-list media-list>
                            <f7-list-item>
                                <img slot="media" style="width: 130px" src="../static/heros/interax.jpg" />
                                <p class="explic">
                                    {{ $t("perfil.deseaHomologar") }}
                                    <b
                                        ><f7-link @click="gotoMenux('/homologacion/')">{{ $t("perfil.homologacionCaps") }}</f7-link></b
                                    >
                                    {{ $t("perfil.deVacunas") }}
                                </p>
                            </f7-list-item>
                        </f7-list>
                    </f7-block>
                </f7-block>
            </f7-block>

            <f7-block v-else-if="mysub.id && (!privxInfo.identity || !privxInfo.verified || !privxInfo.fechanac || !privxInfo.nac_iso2) " class="no-margin no-padding">
                <f7-list class="no-margin" media-list>
                    <f7-list-item
                        :title="`${mysub.nm || ''} ${mysub.ap1 || ''} ${mysub.ap2 || ''}`"
                        :subtitle="mysub.idt == 'RUN' ? 'RUN ' + formatRut(`${mysub.num}${mysub.dv}`) : `${mysub.idt} ${mysub.num}-${mysub.dv}`"
                        :text="mysub.fechanac ? $dayjs(mysub.fechanac).format('DD/MM/YYYY') : $t('perfil.fechaNacNoDeclarada')"
                    >
                        <img class="sudoavtrx" :src="'../static/heros/avatar.jpg'" slot="media" />
                        <font-awesome-icon icon="check" slot="after" class="text-color-teal"></font-awesome-icon>
                    </f7-list-item>
                </f7-list>

                <f7-block class="padding no-margin-vertical text-align-center warning_clp">
                    <h3 class="margin-vertical-half">
                        <font-awesome-icon class="fa-lg" icon="exclamation-circle" />
                        {{ $t("perfil.identidadIncompleta") }}
                    </h3>
                    <p>
                        {{ $t("perfil.faltanCampos") }}
                    </p>
                    <f7-button @click="completarIdentidad()" large fill raised>{{ $t("perfil.completarIdentidad") }}</f7-button>
                </f7-block>
            </f7-block>
            <f7-list v-else class="no-margin-top margin-bottom">
                <ul>
                    <f7-list-item
                        :header="$t('perfil.documentoIdentidad', { identity: privxInfo.idenType || mysub.idt || '-' })"
                        :title="identidadPro || `${mysub.num}-${mysub.dv}` || $t('perfil.sinDocumento')"
                        :after="privxInfo.nac_iso2 || mysub.nac || '-'"
                    ></f7-list-item>
                    <f7-list-item :header="$t('commons.nombres')" :title="privxInfo.nombres || mysub.nm || $t('perfil.noDefinidos')"></f7-list-item>
                    <f7-list-item :title="privxInfo.socialName || '-'">
                        <span slot="header">
                            {{ $t('commons.socialName') }}
                            <f7-link :tooltip="$t('commons.socialNameTooltip')" class="no-ripple">
                                <font-awesome-icon icon="info-circle" />
                            </f7-link>
                        </span>
                        <template #after>
                            <f7-button @click="handleEditSocialName"  small class="btn-edit-text">
                                {{ $t("commons.editar") }}
                            </f7-button>
                        </template>
                    </f7-list-item>
                    <f7-list-item
                        :header="$t('commons.primerApellido')"
                        :title="privxInfo.apellido1 || mysub.ap1 || $t('perfil.noDefinido')"
                    ></f7-list-item>
                    <f7-list-item :header="$t('commons.segundoApellido')" :title="privxInfo.apellido2 || mysub.ap2 || '-'"></f7-list-item>
                    <f7-list-item
                        :header="$t('commons.fechaNac')"
                        :title="
                            privxInfo.fechanac || mysub.fechanac
                                ? $dayjs(privxInfo.fechanac || mysub.fechanac).format('DD/MM/YYYY')
                                : $t('perfil.noDefinido')
                        "
                    ></f7-list-item>
                    <select-comuna
                        v-if="privxInfo.contc_pref && privxInfo.contc_pref.address"
                        :address="privxInfo.contc_pref.address"
                        :editar="true"
                        @newAddress="nuevaResidencia"
                    ></select-comuna>
                    <select-comuna v-else :address="neoaddress" :editar="true" @newAddress="nuevaResidencia"></select-comuna>
                    <f7-list-item
                        :title="displayGender"
                        popover-open=".popover-mutagenero"
                        link="#"
                        :after="$t('commons.editar')"
                    >
                        <span slot="header">
                            {{ $t("commons.identidadDeGenero") }}
                            <f7-link :tooltip="$t('commons.identidadDeGeneroTooltip')" class="no-ripple">
                                <font-awesome-icon icon="info-circle" />
                            </f7-link>
                        </span>
                    </f7-list-item>
                    <!-- <f7-list-item v-else header="Comuna de Residencia" title="No definida"></f7-list-item> -->
                </ul>
            </f7-list>

            <f7-block class="no-margin-top text-align-center" v-if="!procesandoIdentity && privxInfo.identity && !tieneConflicto"
                ><p>
                    {{ $t("perfil.cometioError") }}
                    <f7-link @click="renovarIdentidad()"> {{ $t("perfil.aqui") }} </f7-link> .
                </p></f7-block
            >
            <template v-if="!procesandoIdentity && !tieneConflicto && privxInfo.identity && !privxInfo.verified">
                <f7-block class="no-margin-bottom margin-top">{{ $t("perfil.verificarUsandoClaveUnica") }}</f7-block>
                <f7-block class="margin-top">
                    <clave-unica></clave-unica>
                </f7-block>
            </template>

            <gestion-contactos v-if="privxInfo.identity && !tieneConflicto && privxInfo.fechanac && privxInfo.nac_iso2"></gestion-contactos>
        </template>

        <!-- <f7-popup :opened="declaracionIdentidadOpened" @popup:closed="declaracionIdentidadOpened = false">
            <f7-view name="inpop">
                <f7-page>
                    <f7-navbar :title="$t('commons.declararIdentidad')">
                        <f7-nav-right>
                            <f7-link popup-close>{{ $t("commons.cerrar") }}</f7-link>
                        </f7-nav-right>
                    </f7-navbar>
                    <declare-identity :mysub="mysub" v-if="declaracionIdentidadOpened" @declaracionok="declaroOk"></declare-identity>
                </f7-page>
            </f7-view>
        </f7-popup> -->

        <f7-popup :opened="declaracionIdentidadSerie" @popup:closed="limpiaDeclaraSerie">
            <f7-view name="inpop">
                <f7-page>
                    <f7-navbar :title="$t('commons.declararIdentidad')">
                        <f7-nav-right>
                            <f7-link popup-close>{{ $t("commons.cerrar") }} </f7-link>
                        </f7-nav-right>
                    </f7-navbar>
                    <div style="height: 100%">
                        <f7-list no-hairlines-md>
                            <f7-list-input
                                label="RUN"
                                type="text"
                                :value="run"
                                placeholder="12345678-9"
                                :error-message-force="rutinvalido"
                                @input="run = $event.target.value"
                                :clear-button="!verifSerie"
                                :readonly="verifSerie"
                            >
                                <font-awesome-icon icon="edit" class="fa-2x " slot="media" />
                            </f7-list-input>

                            <f7-list-input
                                :label="$t('perfil.nroSerieDocumento')"
                                type="text"
                                :placeholder="$t('perfil.nroSerieDocumento')"
                                :value="docSerie"
                                @keyup.native.enter="declaraConSerieDoc()"
                                @input="docSerie = $event.target.value"
                                :error-message="$t('commons.formatSerieDoc')"
                                validate
                                :onValidate="(isvalid) => setInputDocValid(isvalid)"
                                pattern="[Aa0-9.]*"
                                :clear-button="!verifSerie"
                                :readonly="verifSerie"
                            >
                                <font-awesome-icon icon="id-card" class="fa-2x " slot="media" />
                            </f7-list-input>
                        </f7-list>
                        <f7-block>
                            <f7-button @click="declaraConSerieDoc()" fill large>{{ $t("perfil.verificarIdentidad") }}</f7-button>
                        </f7-block>
                    </div>
                </f7-page>
            </f7-view>
        </f7-popup>

        <f7-popover class="popover-mutagenero">
            <f7-list>
                <f7-list-item
                        v-for="item in generos"
                        :key="item.id"
                        :title="$t(item.name)"
                        @click="handleNewGenero(item.id)"
                        radio
                        :checked="item.id == privxInfo.genero"
                    ></f7-list-item>
            </f7-list>
        </f7-popover>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";

import solicitudesLoader from "../components/solicitudesLoader.vue";

import gestionContactos from "../components/gestionContactos.vue";
import { commonmixin } from "../mixins/common";
import selectComuna from "../components/selectComuna.vue";
import claveUnica from "../components/botonClaveUnica.vue";
import { validate, format } from "rut.js";
import localforage from "localforage";
import declareIdentity from "../components/declareIdentity.vue";

export default {
    mixins: [commonmixin],
    components: {
        gestionContactos,
        selectComuna,
        claveUnica,
        solicitudesLoader,
        declareIdentity,
    },
    data() {
        return {
            activeGenero: null,
            selectedMenoresMode: null,
            selectedExtranjeroMode: null,
            selectedVerifyMode: null,
            procesandoIdentity: true,
            mostrarGestionContactos: false,
            declaracionIdentidadOpened: false,
            declaracionIdentidadExtranjero: false,
            declaracionIdentidadSerie: false,
            run: "",
            docSerie: "",
            verifSerie: false,
            mysub: {},
            dictionSolResType: {
                aprobada: { t: "Aprobada", c: "green" },
                rechazada: { t: "Rechazada", c: "red" },
            },
            neoaddress: {
                comuna: null,
                region: null,
                provincia: null,
                region_hr: null,
                code: null,
            },
            docSerieValid: true,
            generos: [
                { id: "M", name: "commons.masculino" },
                { id: "F", name: "commons.femenino" },
                { id: "TM", name: "commons.transMasculino" },
                { id: "TF", name: "commons.transFemenino" },
                { id: "NB", name: "commons.noBinario" },
                { id: "NR", name: "commons.noRevelado"},
                { id: "O", name: "commons.otro" },
            ],
        };
    },
    computed: {
        ...mapState(["user", "internacional", "tieneSolicitudPendiente"]),
        ...mapGetters(["tieneConflicto", "privxInfo"]),
        identidadPro() {
            if (this.privxInfo.idenType == "RUN") {
                return format(this.privxInfo.identity);
            } else {
                const splittedIdentity = this.privxInfo.identity.split("_");
                return splittedIdentity[0];
            }
        },
        rutinvalido() {
            if (!validate(this.run)) {
                return true;
            } else {
                return false;
            }
        },
        displayGender() {
            return this.privxInfo.genero ? this.$t(this.generos.find((x) => x.id == this.privxInfo.genero).name) : "-";
        },
    },
    methods: {
        gotoMenux(urlx) {
            this.$store.commit("setWhatTo", { what: "activeTab", to: urlx });
            this.$f7.views.main.router.navigate(urlx);
        },
        declaroOk() {
            this.declaracionIdentidadOpened = false;
            this.declaracionIdentidadExtranjero = false;
            if (this.internacional) {
                return this.$f7.views.main.router.navigate("/carnetinter/");
            } else {
                return this.$f7.views.main.router.navigate("/carnet/");
            }
            // return this.$f7.dialog.alert('Ahora puede ir al menú de Campañas para evaluar si es candidato a vacunarse en este momento','Identidad declarada correctamente',()=>{
            //   this.$store.commit('setWhatTo',{what:'activeTab', to: '/campanas/'});
            //   return this.$f7.views.main.router.navigate('/campanas/');
            // });
        },
        async refreshme() {
            await this.refresUser();
            let user = this.$firebase.auth().currentUser;
            if (!user.emailVerified) {
                window.location.reload(true);
            } else {
                await this.processIdentityStatus();
                let url = new URL(window.location.href);
                return this.evalRetornoC19({ user, url });
            }
        },
        async evalRetornoC19({ url, user }) {
            let c19a = url.searchParams.get("c19a") || "state"; //state
            let c19b = url.searchParams.get("c19b"); //timestamp
            let c19c = url.searchParams.get("c19c") || "uid"; //uid
            const timeShiftAllowed = 5;
            if (!user && c19b) {
                //no esta autenticado, solo localforagear si dentro de plazo rango
                let ahorax = this.$dayjs();
                let thenx = this.$dayjs.unix(c19b);
                if (ahorax.diff(thenx, "minute") < timeShiftAllowed) {
                    let c19x = {
                        a: c19a,
                        b: c19b,
                        c: c19c,
                    };
                    await localforage.setItem("c19x", c19x);
                }
            } else if (user && !c19b) {
                //si esta logeado, ver si hubiese c19 localforageado
                let c19x = await localforage.getItem("c19x");
                if (c19x) {
                    c19a = c19x.a;
                    c19b = c19x.b;
                    c19c = c19x.c;
                }
            }
            if (c19b && user && user.email && user.emailVerified) {
                //ver si fue reciente
                let ahorax = this.$dayjs();
                let thenx = this.$dayjs.unix(c19b);
                if (ahorax.diff(thenx, "minute") < timeShiftAllowed + 1) {
                    let pidetk = this.$firebase.functions().httpsCallable("mevacuno-gentknc19");
                    let result = await pidetk({ dev: this.$store.state.dev, c19a, c19c });
                    let daouts = result.data;
                    if (daouts.error) {
                        throw new Error(daouts.error.message);
                    }
                    let tkn = daouts.payload.token;
                    let target = daouts.payload.redirecter;
                    //  console.log('LETOKEN ES ',tkn)
                    // return "/encuestas/";
                    return this.$f7.dialog.confirm(`Usted será redirigido a ${target}. Desea continuar?`, `Redirección a ${target}`, () => {
                        window.location.href = `https://${target}?mvc=${tkn}`;
                        return null;
                    });
                } else {
                    console.log("expired c19 request");
                    //esta expirado, borrar
                    await localforage.removeItem("c19x");
                }
            }
        },
        promptemail() {
            return this.$f7.dialog.prompt(this.$t("perfil.emailPersonalYUnico"), this.$t("perfil.indiqueSuEmail"), email => {
                if (!email) {
                    return this.$f7.dialog.alert(this.$t("perfil.emailDebeSerValido"));
                }
                this.setmyemail(email);
            });
        },
        async setmyemail(email) {
            try {
                this.$f7.dialog.preloader(this.$t("perfil.actualizandoEmail"));
                await this.$firebase.auth().currentUser.updateEmail(email);
                let sendverif = this.$firebase.functions().httpsCallable("mevacuno-sendVerificationEmail_kle");
                await sendverif({ dev: this.$store.state.dev });
                await this.refresUser();
                this.$f7.dialog.close();
                return this.$f7.dialog.alert(this.$t("perfil.reviseCorreoVerificar", { email }), this.$t("perfil.verificar"));
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error);
                if (error.code == "auth/requires-recent-login") {
                    this.$f7.dialog.alert(this.$t("perfil.inicieSesionNuevamente"), this.$t("perfil.accionSensible"));
                } else if (error.code == "auth/email-already-in-use") {
                    this.$f7.dialog.alert(this.$t("perfil.correoYaTieneCuenta"), this.$t("perfil.emailEnUso"));
                }else if (error.code == "auth/invalid-email") {
                    this.$f7.dialog.alert(this.$t("gestionContactos.emailNotValid", { email: email }),this.$t("gestionContactos.emailInvalido"));
                } else {
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            }
        },
        async verifyme() {
            try {
                this.$f7.dialog.preloader(this.$t("perfil.reenviandoLink"));
                //  let sendverif = this.$firebase.functions().httpsCallable('mevacuno-sendVerificationEmail_kle');
                //   await sendverif({dev:this.$store.state.dev});
                let respoesta = await this.commonExecute({}, "mevacuno-sendVerificationEmail_kle", false);
                //  console.log(respoesta);
                if (respoesta.error) {
                    throw new Error(respoesta.error.message || respoesta.error.code || respoesta.error);
                }
                localStorage.setItem("verific_pendiente", true);
                this.$f7.dialog.close();
                return this.$f7.dialog.alert(this.$t("perfil.reviseCorreoParaVerificar", { email: this.user.email }), this.$t("perfil.verificar"));
            } catch (error) {
                console.log(error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
        async refresUser() {
            await this.$firebase.auth().currentUser.reload();
            let user = this.$firebase.auth().currentUser;
            await user.getIdToken(true);
            let locluser = {
                displayName: user.displayName,
                email: user.email,
                emailVerified: user.emailVerified,
                photoURL: user.photoURL,
                phoneNumber: user.phoneNumber,
                isAnonymous: user.isAnonymous,
                uid: user.uid,
                providerData: user.providerData,
                privx: this.privxInfo || {},
            };
            //console.log('neoemail',locluser.email)
            this.$store.commit("setObjTo", { what: "user", to: locluser });
        },
        nuevaResidencia(neows) {
            //  console.log(neows);
            this.$f7.dialog.preloader(this.$t("perfil.actualizandoResidencia"));
            return this.commonExecute({ comunaid: neows.code }, "mevacuno-setNewResidencia", false)
                .then(respoesta => {
                    if (respoesta.error) {
                        throw new Error(respoesta.error.message || respoesta.error.code || respoesta.error);
                    }
                    //console.log('res',respoesta.payload);
                    let clonedpifx = Object.assign({}, this.privxInfo);
                    if (!clonedpifx.contc_pref) {
                        clonedpifx.contc_pref = {};
                    }
                    clonedpifx.contc_pref.address = respoesta.payload;
                    clonedpifx.declat = respoesta.declat;
                    this.$store.commit("updateMyPrivx", clonedpifx);
                    this.$f7.dialog.close();
                    this.$f7.toast.create({
                        text: this.$t("perfil.residenciaActualizada"),
                        position: 'center',
                        closeTimeout: 2000,
                    }).open();
                })
                .catch(error => {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                });
        },
        renovarIdentidad() {
            this.$f7.dialog.confirm(this.$t("perfil.confirmeRenunciar"), this.$t("perfil.confirme"), () => {
                //puede declarar otra identidad si no ha sido verificada aun, y si no hay verificaciones en curso

                this.$f7.dialog.preloader(this.$t("perfil.renunciandoIdentidad"));
                return this.commonExecute({}, "mevacuno-renunciarIdentidad", false)
                    .then(respoesta => {
                        if (respoesta.error) {
                            throw new Error(respoesta.error.message || respoesta.error.code || respoesta.error);
                        }
                        // console.log('res',respoesta.payload);
                        //guardar en mi PRIVX CHELOPX
                        let toborrar = {
                            idenType: null,
                            identity: null,
                            rawIdentity: null,
                            nombres: null,
                            sexo: null,
                            apellido1: null,
                            apellido2: null,
                            nac_iso2: null,
                            declAt: null,
                            fechanac: null,
                            verified: null,
                            conflicto: null,
                        };
                        let minuevoprivx = Object.assign({}, this.privxInfo, toborrar);
                        this.$store.commit("updateMyPrivx", minuevoprivx);
                        this.$store.commit("setWhatTo", {
                            what: "internacional",
                            to: false,
                        });
                        this.$f7.dialog.close();
                    })
                    .catch(error => {
                        this.$f7.dialog.close();
                        console.log(error);
                        this.$f7.dialog.alert(error.message, error.code || "Error");
                    });
            });
        },
        antesdeInPage() {
            this.processIdentityStatus();
        },
        async processIdentityStatus() {
            try {
                if (!this.privxInfo.identity || !this.user.privx.fechanac || !this.user.privx.nac_iso2) {
                    // console.log('procesando identidad');
                    this.procesandoIdentity = true;
                    //ver si tengo CU_UID-SUB
                    let datous = await this.commonExecute({}, "mevacuno-getpotsubs_neo", false);
                    if (datous.error) {
                        throw new Error(datous.error.message || datous.error.code || datous.error);
                    }
                    this.procesandoIdentity = false;
                    // console.log('MY POT',datous.payload);
                    this.mysub = Object.assign({}, datous.payload);
                } else {
                    this.procesandoIdentity = false;
                }
            } catch (error) {
                this.procesandoIdentity = false;
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
        async declaraConSerieDoc() {
            try {
                if(!this.docSerieValid){
                    return this.$f7.dialog.alert(this.$t("commons.formatSerieDoc"), this.$t("commons.errorFormatSerieDoc"));
                }else if (!this.run || !this.docSerie) {
                    return this.$f7.dialog.alert(this.$t("commons.mustProvideRUN"), this.$t("commons.faltanDatos"));
                } else if (this.rutinvalido) {
                    return this.$f7.dialog.alert(this.$t("commons.verifyRUN"), this.$t("commons.invalidRUN"));
                }
                this.verifSerie = true;
                this.$f7.dialog.preloader(this.$t("commons.verifyingNroSerie"));
                let endpoint = "mevacuno-verificaNumeroSerie";
                let verifico = this.$firebase.functions().httpsCallable(endpoint);
                let result = await verifico({
                    dev: this.$store.state.dev,
                    rut: this.run,
                    serie: this.docSerie,
                });
                let dataresult = result.data;
                if (dataresult.error) {
                    this.verifSerie = false;
                    throw new Error(dataresult.error.message);
                }
                // if(dataresult.payload){
                //   this.$store.commit('updateMyPrivx',dataresult.payload);
                // }
                await this.refreshme();
                this.declaracionIdentidadSerie = false;
                this.verifSerie = false;
                this.run = "";
                this.docSerie = "";
                this.$f7.dialog.close();
            } catch (e) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(e.message, e.code || "Error");
            }
        },
        completarIdentidad() {
            try {
                console.log("sub", this.mysub);
                this.$f7.views.main.router.navigate("/declara-identidad/", {
                    props: { mysub: this.mysub },
                });
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
        setInputDocValid(isvalid){
            this.docSerieValid = isvalid
        },
        limpiaDeclaraSerie(){
            this.declaracionIdentidadSerie = false;
            this.run = "";
            this.docSerie = "";
        },
        handleEditSocialName() {
            this.$f7.dialog.prompt(this.$t("perfil.ingreseSocialName"), this.$t("perfil.editarSocialName"), 
                async (name) => {
                    try{
                        let newname = name.trim();
                        if (newname === '') {
                            return this.$f7.dialog.alert(this.$t("commons.noVacio"), this.$t("errors.error"));
                        }

                        //ckeck que solo sean letras números y espacios
                        if (!/^[a-zA-Z0-9\s]*$/.test(newname)) {
                            return this.$f7.dialog.alert(this.$t("commons.soloLetrasNumeros"), this.$t("errors.error"));
                        }

                        //validar que no sean más de 120 caracteres
                        if (newname.length > 120) {
                            return this.$f7.dialog.alert(this.$t("commons.max120"), this.$t("errors.error"));
                        }

                        this.$f7.dialog.preloader(this.$t("commons.guardando"));
                        let endpoint = "mevacuno-editarSocialName";
                        let edita = this.$firebase.functions().httpsCallable(endpoint);
                        let result = await edita({
                            socialName: newname,
                        });
                        let dataresult = result.data;
                        if (dataresult.error) {
                            throw new Error(dataresult.error.message);
                        }
                        let clonedpifx = Object.assign({}, this.privxInfo);
                        clonedpifx.socialName= newname;
                        this.$store.commit("updateMyPrivx", clonedpifx);
                        this.$f7.dialog.close();

                        this.$f7.toast.create({
                            text: this.$t("perfil.nombreSocialActualizado"),
                            position: 'center',
                            closeTimeout: 2000,
                        }).open();
                    } catch (e) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(e.message, e.code || "Error");
                    }
                    
                }, () => {}, 
                this.user.privx.socialName
            );
        },
        async handleNewGenero(genero) {
            try {
                this.$f7.popover.close(".popover-mutagenero", true);
                this.$f7.dialog.preloader(this.$t("commons.guardando"));
                let endpoint = "mevacuno-editarGenero";
                let edita = this.$firebase.functions().httpsCallable(endpoint);
                let result = await edita({
                    genero: genero,
                });
                let dataresult = result.data;
                if (dataresult.error) {
                    throw new Error(dataresult.error.message);
                }
                let clonedpifx = Object.assign({}, this.privxInfo);
                clonedpifx.genero= genero;
                this.$store.commit("updateMyPrivx", clonedpifx);
                this.$f7.dialog.close();
                this.$f7.toast.create({
                    text: this.$t("perfil.identidadGeneroActualizada"),
                    position: 'center',
                    closeTimeout: 2000,
                }).open();
            } catch (e) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(e.message, e.code || "Error");
            }
        },
    },
};
</script>
<style>
.sudoavtrx {
    width: 40px;
}
.explic {
    font-size: 14px;
}
.btn-edit-text {
    text-transform: none;
    font-size: 14px;
    color: #0000008A;
}
.popover-mutagenero {
    width: 300px;
}
</style>
