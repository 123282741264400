<template>
    <f7-page @page:beforein="escribephone()" name="solicitar-verificacion">
        <f7-navbar :title="$t('solicitarVerificacion.verificacionIdentidad')" :subtitle="$t('solicitarVerificacion.enLinea')" back-link="Back">
            <f7-nav-right v-if="prev_send">
                <f7-link @click="reuseData()" tooltip="Utilizar datos de última solicitud"
                    ><font-awesome-icon icon="copy"></font-awesome-icon
                ></f7-link>
            </f7-nav-right>
        </f7-navbar>

        <f7-block class="margin-vertical">1.- {{ $t("solicitarVerificacion.nacionalidad") }}.</f7-block>
        <f7-list class="no-margin-vertical" inset>
            <f7-list-item
                :header="$t('declareIdentity.paisDeNacionalidad')"
                :title="$store.getters.unPais(decl_id_pais) ? $store.getters.unPais(decl_id_pais).name : $t('commons.seleccionar')"
                @click="fetchPaisesAndGo()"
                link="#"
                ><font-awesome-icon icon="pen" slot="after"
            /></f7-list-item>
        </f7-list>
        <f7-block class="no-margin-bottom margin-top">
            <p>2.- {{ $t("solicitarVerificacion.tipoDocumentoIdentidad") }}</p>
            <f7-segmented raised tag="p">
                <f7-button
                    v-for="identidad in dictIdentities"
                    :key="identidad.id"
                    :active="idenType == identidad.id"
                    @click="moveToOtroIdenType(identidad.id)"
                    >{{ identidad.txt }}</f7-button
                >
            </f7-segmented>
        </f7-block>
        <f7-block class="margin-vertical">3.- {{ $t("solicitarVerificacion.datosDemograficos") }}.</f7-block>
        <f7-list class="margin-vertical" inset>
            <ul>
                <f7-list-input
                    class="labelbold"
                    :label="idenType + ' - ' + dictIdentities[idenType].spe"
                    type="text"
                    :placeholder="$t('commons.numeroDoc')"
                    :error-message="faltaEsteInput.rawIdentity ? $t('commons.campoObligatorio') : $t('commons.RUNseemsNotValid')"
                    :error-message-force="rutinvalido || faltaEsteInput.rawIdentity"
                    :value="rawIdentity"
                    @blur="formateaRut()"
                    @input="setvalorYBorrarError('rawIdentity', $event.target.value)"
                    clear-button
                >
                </f7-list-input>
                <f7-list-input
                    :label="$t('commons.nombres')"
                    type="text"
                    :placeholder="$t('commons.nombres')"
                    :value="nombres"
                    @blur="checkEsteOblig('nombres')"
                    :error-message="$t('commons.campoObligatorio')"
                    :error-message-force="faltaEsteInput.nombres"
                    @input="setvalorYBorrarError('nombres', $event.target.value)"
                    clear-button
                >
                </f7-list-input>
                <f7-list-input
                    :label="$t('commons.primerApellido')"
                    type="text"
                    :placeholder="`${$t('commons.apellido')} 1`"
                    :value="apellido1"
                    @blur="checkEsteOblig('apellido1')"
                    :error-message="$t('commons.campoObligatorio')"
                    :error-message-force="faltaEsteInput.apellido1"
                    @input="setvalorYBorrarError('apellido1', $event.target.value)"
                    clear-button
                >
                </f7-list-input>
                <f7-list-input
                    :label="$t('commons.segundoApellido')"
                    type="text"
                    :placeholder="`${$t('commons.apellido')} 2`"
                    :value="apellido2"
                    @input="apellido2 = $event.target.value"
                    clear-button
                >
                </f7-list-input>
                <f7-list-input
                    type="select"
                    :value="sexo"
                    :error-message="$t('commons.campoObligatorio')"
                    :error-message-force="faltaEsteInput.sexo"
                    @change="setvalorYBorrarError('sexo', $event.target.value)"
                    :placeholder="$t('commons.indiqueSexo')"
                >
                    <option value="">{{ $t("commons.indiqueSexo") }}</option>
                    <option value="F">{{ $t("commons.femenino") }}</option>
                    <option value="M">{{ $t("commons.masculino") }}</option>
                    <option value="Intersex">{{ $t("commons.intersex") }}</option>
                    <span slot="label"
                        >{{ $t("commons.sexo") }}
                        <f7-link :tooltip="$t('declareIdentity.porQueSexoBiologicoTooltip')">
                            ({{ $t("declareIdentity.porQueSexoBiologico") }})</f7-link
                        ></span
                    >
                </f7-list-input>

                <f7-list-item class="inputfechatitlefull" :header="$t('commons.fechaNac')">
                    <f7-row slot="footer">
                        <f7-col width="20">
                            <f7-input type="select" outline class="inputdia" @change="datesplit.dia = $event.target.value" :value="datesplit.dia">
                                <option v-for="dia in 31" :key="dia" :value="dia">{{ dia }}</option>
                            </f7-input>
                        </f7-col>
                        <f7-col width="50">
                            <f7-input type="select" outline class="inputmes" @change="datesplit.mes = $event.target.value" :value="datesplit.mes">
                                <option v-for="(mes, idx) in $dayjs.months()" :key="idx + 1" :value="idx + 1">{{ mes }}</option>
                            </f7-input>
                        </f7-col>
                        <f7-col width="30">
                            <f7-input class="inputano" type="number" outline @input="setfechano($event.target)" :value="datesplit.ano"> </f7-input>
                        </f7-col>
                    </f7-row>
                </f7-list-item>

                <select-comuna :address="address" @newAddress="llegandoAddress"></select-comuna>
            </ul>
        </f7-list>

        <f7-block class="no-margin-bottom">4.- {{ $t("solicitarVerificacion.adjunteImagenSosteniendo") }}</f7-block>
        <input type="file" ref="filedoc" id="myimg-input-doc" @change="filesChange($event)" accept="image/jpg,image/jpeg,image/png,application/pdf" />
        <input
            type="file"
            ref="filefoto"
            id="myimg-input-foto"
            @change="filesChange($event)"
            accept="image/jpg,image/jpeg,image/png,application/pdf"
        />
        <f7-list inset class="no-margin-top">
            <f7-list-item :header="$t('solicitarVerificacion.fotografia')">
                <f7-link slot="media" @click="docuurl ? $refs.docuurl_pb.open() : null">
                    <img class="respald" :src="docuurl || '../static/heros/verify_identity_doc.jpg'"
                /></f7-link>
                <f7-button @click="tomarFoto('docu')" slot="after" fill>{{
                    docuurl ? $t("solicitarVerificacion.cambiarDoc") : $t("solicitarVerificacion.cargar")
                }}</f7-button>
            </f7-list-item>
            <f7-list-item :header="$t('solicitarVerificacion.fotoUdSosteniendo')">
                <f7-link slot="media" @click="photourl ? $refs.photourl_pb.open() : null">
                    <img class="respald" :src="photourl || '../static/heros/verify_identity.jpg'"
                /></f7-link>
                <f7-button @click="tomarFoto('foto')" slot="after" fill>{{
                    photourl ? $t("solicitarVerificacion.cambiarImagen") : $t("solicitarVerificacion.cargar")
                }}</f7-button>
            </f7-list-item>
        </f7-list>

        <f7-block class="no-margin-bottom">5.- {{ $t("solicitarVerificacion.expliqueSituacion") }}.</f7-block>

        <f7-list class="no-margin-top">
            <f7-list-item
                class="overflow-text"
                radio
                radio-icon="start"
                name="solver-situax"
                value="se_vacuno_en_chile"
                :checked="tipoSituacion === 'se_vacuno_en_chile'"
                @change="tipoSituacion = $event.target.value"
                :title="$t('solicitarVerificacion.meVacuneEnChile')"
            ></f7-list-item>
            <f7-list-item
                class="overflow-text"
                radio
                radio-icon="start"
                name="solver-situax"
                value="homologa_extranjero"
                :checked="tipoSituacion === 'homologa_extranjero'"
                @change="tipoSituacion = $event.target.value"
                :title="$t('solicitarVerificacion.voyAViajarAChile')"
            ></f7-list-item>
            <f7-list-item
                class="overflow-text"
                radio
                radio-icon="start"
                name="solver-situax"
                value="otro"
                :checked="tipoSituacion === 'otro'"
                @change="tipoSituacion = $event.target.value"
                :title="$t('solicitarVerificacion.otroMotivo')"
            ></f7-list-item>
            <f7-list-input
                :label="$t('solicitarVerificacion.estadoSituacionActual')"
                type="textarea"
                :placeholder="$t('solicitarVerificacion.expliqueSituacionAqui')"
                :value="freetext"
                @input="freetext = $event.target.value"
            >
            </f7-list-input>
        </f7-list>

        <f7-block class="no-margin-bottom">6.- {{ confindiqu }} {{ $t("solicitarVerificacion.nroTelefonoContacto") }}.</f7-block>
        <f7-list class="no-margin-top">
            <f7-list-input
                :label="$t('solicitarVerificacion.nroTelefono')"
                type="text"
                :value="lephone"
                @input="lephone = $event.target.value"
                placeholder="+56"
                clear-button
            >
            </f7-list-input>
        </f7-list>

        <f7-list class="autorizacion" media-list>
            <f7-list-item
                checkbox
                :checked="acepta"
                @change="acepta = $event.target.checked"
                name="autorizacion"
                :title="$t('solicitarVerificacion.autorizacion')"
                :subtitle="$t('solicitarVerificacion.autorizo')"
            ></f7-list-item>
            <f7-list-item
                checkbox
                :checked="aceptoTerminos"
                @change="aceptoTerminos = $event.target.checked"
            >
                <span slot="title">He leído y acepto los <f7-link @click.stop="terminosOpened = true" class="no-ripple">términos y condiciones</f7-link></span>
            </f7-list-item>
        </f7-list>

        <f7-popup class="terminos-popip" :opened="terminosOpened" @popup:closed="terminosOpened = false">
            <f7-page>
                <f7-navbar :title="$t('login.termsTooltip')">
                    <f7-nav-right>
                        <f7-link popup-close>{{ $t("commons.cerrar") }}</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <terminos></terminos>
            </f7-page>
        </f7-popup>
        <f7-block>
            <f7-button @click="solicitar()" fill raised>{{ $t("solicitarVerificacion.enviarSolicitud") }}</f7-button>
        </f7-block>
        <f7-photo-browser :photos="[photourl]" theme="dark" ref="photourl_pb"></f7-photo-browser>
        <f7-photo-browser :photos="[docuurl]" theme="dark" ref="docuurl_pb"></f7-photo-browser>
    </f7-page>
</template>
<script>
import { mapState } from "vuex";
import { commonmixin } from "../mixins/common";
import { validate, format, clean } from "rut.js";
import selectComuna from "../components/selectComuna.vue";
import terminos from "../components/terminos.vue";

export default {
    mixins: [commonmixin],
    components: { selectComuna, terminos },
    props: ["id", "foto", "foto_url", "docu", "docu_url", "descr", "wh", "prev_send"],
    data() {
        return {
            lephone: "+56",
            confindiqu: this.$t("solicitarVerificacion.indiqueUn"),
            acepta: false,
            aceptoTerminos: false,
            terminosOpened: false,
            freetext: "",
            photourl: null,
            docuurl: null,
            photoName: null,
            docuName: null,
            progressdialog: null,
            task: null,
            tipoSituacion: null,
            //ahora info declarada
            idenType: "RUN",
            rawIdentity: "",
            apellido1: null,
            apellido2: null,
            nombres: null,
            sexo: null,
            address: {
                code: null,
                comuna: null,
                provincia: null,
                region: null,
                region_hr: null,
            },
            datesplit: {
                ano: "",
                mes: "",
                dia: "",
            },
            faltaEsteInput: {
                idenType: null,
                rawIdentity: null,
                apellido1: null,
                nombres: null,
                sexo: null,
            },
            uploadenFocoCual: "foto",
            uploadenFocoExt: "jpg",
        };
    },
    computed: {
        ...mapState(["user", "dev", "decl_id_pais"]),
        privxInfo() {
            return this.user.privx || {};
        },
        rutinvalido() {
            if (this.idenType == "RUN" && this.rawIdentity && (this.rawIdentity.length < 6 || !validate(this.rawIdentity))) {
                return true;
            } else {
                return false;
            }
        },
        dictIdentities() {
            return {
                RUN: {
                    id: "RUN",
                    txt: "RUN",
                    spe: this.$t("commons.RUNdescription"),
                },
                PAS: {
                    id: "PAS",
                    txt: "Pasaporte",
                    spe: this.$t("commons.PASdescription"),
                },
                DNI: {
                    id: "DNI",
                    txt: "Otro",
                    spe: this.$t("commons.DNIdescription"),
                },
            };
        },
    },
    created() {
        if (this.prev_send) {
            console.log("viene preploaedad", this.prev_send);
        }
    },
    methods: {
        reuseData() {
            if (this.prev_send) {
                this.idenType = this.prev_send.idenType;
                this.rawIdentity = this.prev_send.rawIdentity;
                this.apellido1 = this.prev_send.apellido1;
                this.apellido2 = this.prev_send.apellido2 || "";
                this.nombres = this.prev_send.nombres;
                this.sexo = this.prev_send.sexo;
                let daterxd = this.$dayjs(this.prev_send.fechax);
                this.datesplit = {
                    ano: daterxd.format("YYYY"),
                    mes: parseInt(daterxd.format("M")) - 1,
                    dia: daterxd.format("D"),
                };
                this.$store.commit("setWhatTo", { what: "decl_id_pais", to: this.prev_send.nacionalidad });
            }
        },
        async pedirPermAndUpload({ filereceive }) {
            try {
                this.$f7.dialog.preloader(this.$t("solicitarVerificacion.preparandoCarga"));
                let filename = filereceive.name;
                let extenx = filename
                    .split(".")
                    .pop()
                    .toLowerCase();
                console.log("extenx", extenx);
                this.uploadenFocoExt = extenx;
                let resposta = await this.commonExecute({ tipo: this.uploadenFocoCual, ext: this.uploadenFocoExt }, "mevacuno-getUploadPerm", false);
                if (resposta.error) {
                    throw new Error(resposta.error.message || resposta.error.code || resposta.error);
                }
                await this.$firebase.auth().currentUser.reload();
                let user = this.$firebase.auth().currentUser;
                await user.getIdToken(true);
                this.$f7.dialog.close();
                return resposta.payload;
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        cargaFotos() {
            if (this.foto_url) {
                this.photourl = this.foto_url;
            }
            if (this.docu_url) {
                this.docuurl = this.docu_url;
            }
        },
        llegandoAddress(newaddress) {
            this.address = Object.assign({}, newaddress);
        },
        setfechano(target) {
            // console.log('setenado')
            this.datesplit.ano = target.value;
            if (this.datesplit.ano > 1900) {
                // console.log('blurenado')
                target.blur();
            }
        },
        checkEsteOblig(cual) {
            if (!this[cual]) {
                this.faltaEsteInput[cual] = true;
            } else {
                this.faltaEsteInput[cual] = false;
            }
        },
        setvalorYBorrarError(cual, valor) {
            this[cual] = valor;
            this.faltaEsteInput[cual] = false;
        },
        moveToOtroIdenType(idenType) {
            this.idenType = idenType;
            console.log("nueva idnetype es", this.idenType);
            this.formateaRut();
        },
        formateaRut() {
            if (this.rawIdentity) {
                if (this.idenType == "RUN") {
                    this.rawIdentity = format(this.rawIdentity);
                }
                this.faltaEsteInput.rawIdentity = false;
            } else {
                this.faltaEsteInput.rawIdentity = true;
            }
        },
        fetchPaisesAndGo() {
            if (!this.$store.getters.paisesArraySorted.length) {
                this.$f7.dialog.preloader(this.$t("commons.cargandoPaises"));
                this.$firebase
                    .database()
                    .ref("paises")
                    .once("value")
                    .then(snps => {
                        this.$store.commit("setWhatTo", { what: "paises", to: snps.val() });
                        this.$f7.dialog.close();
                        this.$f7.views.main.router.navigate("/paises/");
                    })
                    .catch(error => {
                        this.$f7.dialog.close();
                        console.log(error);
                        this.$f7.dialog.alert(error.message, error.code);
                    });
            } else {
                this.$f7.views.main.router.navigate("/paises/");
            }
        },
        declaroOk() {
            console.log("declarado ok");
        },
        escribephone() {
            let contprefs = this.privxInfo.contc_pref || {};
            if (contprefs.phone) {
                this.lephone = contprefs.phone;
                this.confindiqu = "Confirme su";
            }
            console.log("PROPS", this.id, this.descr);

            if (this.descr) {
                this.freetext = this.descr;
            }

            this.cargaFotos();
        },

        solicitar() {
            if (!this.acepta || !this.aceptoTerminos) {
                return this.$f7.dialog.alert(this.$t("solicitarVerificacion.alert1Text"), this.$t("solicitarVerificacion.alert1Title"));
            }
            if (!this.photourl) {
                return this.$f7.dialog.alert(this.$t("solicitarVerificacion.alert2Text"), this.$t("solicitarVerificacion.alert2Title"));
            }
            if (!this.docuurl) {
                return this.$f7.dialog.alert(this.$t("solicitarVerificacion.alert3Text"), this.$t("solicitarVerificacion.alert2Title"));
            }
            if (!this.lephone || this.lephone == "+56" || this.lephone.length < 6) {
                return this.$f7.dialog.alert(this.$t("solicitarVerificacion.alert4Text"), this.$t("solicitarVerificacion.alert4Title"));
            }

            if (!this.tipoSituacion || (this.tipoSituacion == "otro" && !this.freetext.trim())) {
                return this.$f7.dialog.alert(this.$t("solicitarVerificacion.alert5Text"), this.$t("solicitarVerificacion.alert5Title"));
            }
            this.lephone = String(this.lephone);

            let requeridos = ["rawIdentity", "nombres", "apellido1", "sexo"];
            let faltauno = requeridos.some((uncamp, idx) => {
                if (!this[uncamp]) {
                    this.faltaEsteInput[uncamp] = true;
                    return true;
                } else {
                    return false;
                }
            });
            if (faltauno) {
                return this.$f7.dialog.alert(this.$t("commons.debeCompletarTodos"));
            }
            if (!this.datesplit.ano || !this.datesplit.mes || !this.datesplit.dia) {
                return this.$f7.dialog.alert(this.$t("commons.debeIndicarFechaNac"));
            }
            //check valid fecha nacimiento
            let mesx = parseInt(this.datesplit.mes) < 10 ? `0${this.datesplit.mes}` : this.datesplit.mes;
            let diax = parseInt(this.datesplit.dia) < 10 ? `0${this.datesplit.dia}` : this.datesplit.dia;
            let fechax = `${this.datesplit.ano}${mesx}${diax}`;
            if (!this.$dayjs(fechax, "YYYYMMDD").isValid()) {
                return this.$f7.dialog.alert(this.$t("commons.fechaNacInvalid"));
            }

            if (this.idenType == "RUN" && this.rutinvalido) {
                return this.$f7.dialog.alert(this.$t("solicitarVerificacion.runPareceTenerError"), this.$t("solicitarVerificacion.runInvalid"));
            }
            if (this.decl_id_pais === "XX") {
                return this.$f7.dialog.alert(this.$t("solicitarVerificacion.faltaNacionalidad"));
            }
            if (this.rawIdentity.includes("_")) {
                return this.$f7.dialog.alert(this.$t("solicitarVerificacion.caracteresInvalidos"));
            }
            let dataToSend = {
                freetext: this.freetext,
                lephone: this.lephone,
                rawIdentity: this.rawIdentity,
                idenType: this.idenType,
                apellido1: this.apellido1,
                apellido2: this.apellido2,
                nombres: this.nombres,
                sexo: this.sexo,
                comuna: this.address.code,
                nacionalidad: this.decl_id_pais,
                fechax,
                tipoSituacion: this.tipoSituacion,
            };
            console.log("dataToSend", dataToSend);
            console.log("solicitando verification");
            this.$f7.dialog.preloader(this.$t("commons.guardando"));
            return this.commonExecute({ send: dataToSend }, "mevacuno-creaNuevaSolicitVerif", false)
                .then(respoesta => {
                    if (respoesta.error) {
                        throw new Error(respoesta.error.message || respoesta.error.code || respoesta.error);
                    }
                    //si no hay error, darle OK, y volver
                    this.$f7.dialog.close();
                    this.$f7.toast.show({
                        text: "Solicitud enviada OK.",
                        closeTimeout: 3500,
                    });
                    this.$f7.views.main.router.back();
                })
                .catch(error => {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                });
        },
        tomarFoto(cual) {
            this.uploadenFocoCual = cual;
            if (cual == "docu") {
                this.$$("#myimg-input-doc").click();
            } else {
                this.$$("#myimg-input-foto").click();
            }
        },
        async filesChange(e) {
            const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];

            try {
                let file = e.target.files[0];

                if (!file) {
                    return;
                }

                if (allowedFileTypes.indexOf(file.type) === -1) {
                    throw new Error(this.$t("solicitarVerificacion.tipoArchivoInvalido"));
                }

                if (this.uploadenFocoCual == "docu") {
                    this.docuName = file.name;
                    if (this.photoName == file.name) {
                        this.docuName = null;
                        this.$refs.filedoc.value = null;
                        throw new Error(this.$t("solicitarVerificacion.imagenesDuplicadas") || null || null);
                    }
                } else {
                    this.photoName = file.name;
                    if (this.docuName == file.name) {
                        this.photoName = null;
                        this.$refs.filefoto.value = null;
                        throw new Error(this.$t("solicitarVerificacion.imagenesDuplicadas") || null || null);
                    }
                }
                if (file) {
                    console.log(file);
                    let permfile = await this.pedirPermAndUpload({ filereceive: file });
                    this.subirfoto({ filereceive: file, permfile });
                }
            } catch (error) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        alsnapshot(snapshot) {
            let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            let nentero = Math.round(percentage);
            if (!isNaN(nentero)) {
                this.progressdialog.setProgress(nentero);
                this.progressdialog.setText(nentero + "%");
            }
        },
        alerror(err) {
            console.log(err);
            this.progressdialog.close();
            this.$f7.dialog.alert(err.code, this.$t("solicitarVerificacion.errorEnCarga"));
        },
        altermino() {
            console.log(this.task, "se subio la foto!");
            this.progressdialog.close();
            this.$f7.dialog.preloader(this.$t("solicitarVerificacion.procesando"));
            //todo procesar foto
            console.log("terminó la carga de forma exitosa");
            //getUrlDescargaImgn
            // uploadenFocoCual:'foto',
            //uploadenFocoExt:'jpg',

            return this.commonExecute({ solicitudid: this.id, cual: this.uploadenFocoCual }, "mevacuno-getUrlDescargaImgn", false)
                .then(respoesta => {
                    this.$f7.dialog.close();
                    if (respoesta.error) {
                        throw new Error(respoesta.error.message || respoesta.error.code || respoesta.error);
                    }
                    if (respoesta.noSaved) {
                        return this.$f7.dialog.alert("File not saved", "Please try again");
                    } else {
                        console.log("File available at", respoesta);
                        if (this.uploadenFocoCual == "foto") {
                            this.photourl = respoesta.payload;
                        } else if (this.uploadenFocoCual == "docu") {
                            this.docuurl = respoesta.payload;
                        }
                    }

                    // this.$firebase.database().ref('solicit_verif_open/'+this.user.uid+'/imagen').set(downloadURL);
                })
                .catch(error => {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                });
        },
        subirfoto({ filereceive, permfile }) {
            var metadata = {
                contentType: filereceive.type,
            };
            // uploadenFocoCual:'foto',
            this.progressdialog = this.$f7.dialog.progress(this.$t("solicitarVerificacion.cargandoImagen"), 0);
            this.progressdialog.setText("0%");
            this.task = this.$firebase
                .storage()
                .ref(`verifs_${this.dev ? "d" : "p"}/${this.user.uid}/${this.id}/${permfile}`)
                .put(filereceive, metadata);
            this.task.on("state_changed", this.alsnapshot, this.alerror, this.altermino);
        },
    },
};
</script>
<style>
.respald {
    width: 80px;
    height: auto;
    max-width: 80px;
}

.list.autorizacion .item-subtitle {
    white-space: unset !important;
}
#myimg-input-doc {
    display: none;
}
#myimg-input-foto {
    display: none;
}
.labelbold .item-title.item-label {
    font-weight: bolder;
}
.inputfechatitlefull .item-title {
    width: 100% !important;
}
.overflow-text .item-title {
    white-space: normal;
}
</style>
