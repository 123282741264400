<template>
    <div>
        <f7-block class="no-margin-bottom">
            {{ $t("gestionContactos.misCorreos") }}
        </f7-block>
        <f7-list class="no-margin-top marfixicons">
            <f7-list-item :footer="$t('gestionContactos.emailDeLogin') + (prefferedEmail == user.email ? ` | ${$t('gestionContactos.preferido')}` : '')" :title="user.email">
                <div slot="after" class="linkpad">
                    <f7-link
                        v-if="prefferedEmail != user.email"
                        color="gray"
                        @click="gestionContacto('pin', 'email', user.uid)"
                        :tooltip="$t('gestionContactos.marcarPreferido')"
                        ><font-awesome-icon icon="thumbtack" class="fa-lg"
                    /></f7-link>
                </div>
                <font-awesome-icon v-if="prefferedEmail == user.email" slot="media" icon="thumbtack" class="fa-lg fa-rotate-270" />
                <font-awesome-icon v-else slot="media" icon="at" class="fa-lg" />
            </f7-list-item>
            <f7-list-item
                v-for="(email, id) in myContactInfo.email"
                :key="id"
                :footer="prefferedEmail == email.n ? $t('gestionContactos.preferido') : null"
                :title="email.n"
            >
                <div slot="after" class="linkpad">
                    <f7-link
                        v-if="prefferedEmail != email.n"
                        @click="gestionContacto('pin', 'email', id)"
                        color="gray"
                        :tooltip="$t('gestionContactos.marcarPreferido')"
                        ><font-awesome-icon icon="thumbtack" class="fa-lg"
                    /></f7-link>
                    <f7-link color="gray" @click="gestionContacto('delete', 'email', id)"
                        ><font-awesome-icon icon="trash-alt" class="fa-lg linkmarleft"
                    /></f7-link>
                </div>
                <font-awesome-icon v-if="prefferedEmail == email.n" slot="media" icon="thumbtack" class="fa-lg fa-rotate-270" />
                <font-awesome-icon v-else slot="media" icon="at" class="fa-lg" />
            </f7-list-item>
            <f7-list-item v-if="gettingContactInfo" :title="$t('commons.cargando')"><f7-preloader slot="after"></f7-preloader></f7-list-item>
        </f7-list>
        <f7-block><f7-button @click="promptEmail()" fill raised>{{ $t('gestionContactos.agregarCorreo') }}</f7-button></f7-block>

        <f7-block class="no-margin-bottom">
            {{ $t('gestionContactos.misNumeros') }}
        </f7-block>
        <f7-list class="no-margin-top marfixicons" :class="{ 'skeleton-text skeleton-effect-blink': gettingContactInfo }">
            <f7-list-item v-if="gettingContactInfo" :title="$t('gestionContactos.cargandoContactos')"></f7-list-item>
            <f7-list-item
                class="nowhitespacetitle"
                v-else-if="!myContactInfo.phone || !Object.keys(myContactInfo.phone).length"
                :title="$t('gestionContactos.noHaIndicadoNumeros')"
            ></f7-list-item>
            <f7-list-item
                v-for="(phone, id) in myContactInfo.phone"
                :key="id"
                :footer="contc_pref.phone == phone.n ? $t('gestionContactos.preferido') : null"
                :title="phone.n"
            >
                <div slot="after" class="linkpad">
                    <f7-link
                        v-if="contc_pref.phone != phone.n"
                        @click="gestionContacto('pin', 'phone', id)"
                        color="gray"
                        :tooltip="$t('gestionContactos.marcarPreferido')"
                        ><font-awesome-icon icon="thumbtack" class="fa-lg"
                    /></f7-link>
                    <f7-link color="gray" @click="gestionContacto('delete', 'phone', id)"
                        ><font-awesome-icon icon="trash-alt" class="fa-lg linkmarleft"
                    /></f7-link>
                </div>
                <font-awesome-icon v-if="contc_pref.phone == phone.n" slot="media" icon="thumbtack" class="fa-lg fa-rotate-270" />
                <font-awesome-icon v-else slot="media" icon="phone-volume" class="fa-lg" />
            </f7-list-item>
        </f7-list>
        <f7-block><f7-button fill @click="promtpPhone()" raised>{{ $t("gestionContactos.agregarNumero") }}</f7-button></f7-block>
    </div>
</template>
<script>
import { mapState } from "vuex";
import * as EmailValidator from "email-validator";
import { commonmixin } from "../mixins/common";

export default {
    mixins: [commonmixin],

    data() {
        return {
            gettingContactInfo: true,
        };
    },
    computed: {
        ...mapState(["user", "myContactInfo"]),
        privxInfo() {
            return this.user.privx || {};
        },
        contc_pref() {
            return this.privxInfo.contc_pref || {};
        },
        prefferedEmail() {
            return this.contc_pref.email || this.user.email;
        },
    },
    created() {
        if (!this.myContactInfo.fetched) {
            this.getmycontactInfo();
        } else {
            this.gettingContactInfo = false;
        }
    },
    methods: {
        getmycontactInfo() {
            // console.log('Solicitando info de contacto')
            return this.$firebase
                .database()
                .ref("contact_info/" + this.user.uid)
                .once("value")
                .then(snpsho => {
                    let recibid = snpsho.val() || {};
                    // console.log('recibid',recibid);
                    this.$store.commit("setObjTo", {
                        what: "myContactInfo",
                        to: {
                            phone: recibid.phone || {},
                            email: recibid.email || {},
                            direccion: recibid.direccion || {},
                            fetched: true,
                        },
                    });
                    // console.log('info de contacto ok');
                    this.gettingContactInfo = false;
                })
                .catch(error => {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                });
        },
        promptEmail() {
            this.$f7.dialog.prompt(this.$t("gestionContactos.indiqueCorreo"), this.$t("gestionContactos.nuevoEmail"), newvalue => {
                if (!newvalue || !EmailValidator.validate(newvalue)) {
                    return this.$f7.dialog.alert(this.$t("gestionContactos.emailNotValid", { email: newvalue }), this.$t("gestionContactos.emailInvalido"));
                }
                this.gestionContacto("add", "email", newvalue);
            });
        },
        promtpPhone() {
            this.$f7.dialog.prompt(
                this.$t("gestionContactos.indiqueTelefono"),
                this.$t("gestionContactos.nuevoTelefono"),
                newvalue => {
                    if (!newvalue || newvalue.length < 7) {
                        return this.$f7.dialog.alert(this.$t("gestionContactos.telefonoSeemsNotValid", { phone: newvalue }), this.$t("gestionContactos.telefonoInvalid"));
                    }
                    this.gestionContacto("add", "phone", newvalue);
                },
                () => {},
                "+56"
            );
        },
        gestionContacto(tipoact, tipoel, element) {
            let stringed_element = String(element);
            // console.log('procediendo a ejecutar',tipoact,tipoel,element);
            let dataToSend = {
                tipoact,
                tipoel,
                element: stringed_element,
            };
            this.$f7.dialog.preloader(this.$t("commons.guardando"));
            return this.commonExecute(dataToSend, "mevacuno-gestInfoContacto", false)
                .then(respoesta => {
                    if (respoesta.error) {
                        throw new Error(respoesta.error.message || respoesta.error.code || respoesta.error);
                    }
                    // console.log('res',respoesta.payload);
                    let potnuevaid = respoesta.payload || null;
                    //si es un ADD, esta es la id:
                    if (tipoact == "add") {
                        let toadd = {
                            neo: {},
                            cual: tipoel,
                        };
                        toadd.neo[potnuevaid] = {
                            n: element,
                        };
                        this.$store.commit("agregarInfoContacto", toadd);
                        //solo agregar como favorito si NO habia otro antes (solo chequear fonos)
                        if (tipoel == "phone" && (!this.myContactInfo.phone || !Object.keys(this.myContactInfo.phone).length)) {
                            this.$store.commit("definirContactoFavorito", { cual: tipoel, valor: element });
                        }
                    } else if (tipoact == "pin") {
                        //get el valor
                        if (this.user.uid !== element) {
                            let elvalor = this.myContactInfo[tipoel][element].n;
                            this.$store.commit("definirContactoFavorito", { cual: tipoel, valor: elvalor });
                        } else {
                            this.$store.commit("definirContactoFavorito", { cual: tipoel, valor: null });
                        }
                    } else if (tipoact == "delete") {
                        this.$store.commit("eliminarInfoContacto", { cual: tipoel, id: element });
                        if (potnuevaid) {
                            //en este caso potnuevaid es el valor del contacto
                            //al borrarlo asigno otro preferido
                            this.$store.commit("definirContactoFavorito", { cual: tipoel, valor: potnuevaid });
                        } else {
                            this.$store.commit("definirContactoFavorito", { cual: tipoel, valor: null });
                        }
                    }

                    this.$f7.dialog.close();
                    this.$f7.toast.show({
                        text: this.$t("commons.cambioOK"),
                        closeTimeout: 3500,
                    });
                })
                .catch(error => {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                });
        },
    },
};
</script>
<style>
.linkmarleft {
    margin-left: 25px;
}
.marfixicons .item-media svg {
    margin: 0 auto;
}
</style>
