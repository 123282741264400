<template>
    <f7-app :params="f7params">
        <!-- Left panel with cover effect when hidden -->
        <f7-panel left cover theme-dark :visible-breakpoint="960">
            <f7-view>
                <lepanel></lepanel>
            </f7-view>
        </f7-panel>

        <!-- Your main view, should have "view-main" class -->
        <f7-view main class="safe-areas" url="/"></f7-view>

        <login :loginOpened="loginOpened" @loginscreen:closed="loading = false" :dev="dev" :loading="loading"></login>

        <f7-popup class="resp-seguimint" :opened="!!respondedorAbierto" @popup:closed="respondedorAbierto = null">
            <resp-seuim v-if="respondedorAbierto" @respondeok="respondedorAbierto = null" :smt="respondedorAbierto"></resp-seuim>
        </f7-popup>
    </f7-app>
</template>
<script>
import login from "./login.vue";
import lepanel from "./lepanel.vue";
import { mapState } from "vuex";
import routes from "../js/routes.js";
import respSeuim from "../components/respSeguim.vue";
import idleTimer from "idle-timer";
import localforage from "localforage";
export default {
    components: {
        login,
        lepanel,
        respSeuim,
    },
    data() {
        return {
            // Framework7 Parameters
            f7params: {
                name: "MeVacuno", // App name
                theme: "md", // Automatic theme detection
                // App routes
                routes: routes,
                dialog: {
                    // change default "OK" button text
                    buttonCancel: "Cancelar",
                },
            },
            // Login screen data
            loginOpened: true,
            loading: true,
            respondedorAbierto: null,
            inactiveTime: null,
        };
    },
    computed: {
        ...mapState(["dev", "internacional"]),
    },
    methods: {
        gotoMenux(urlx) {
            this.$store.commit("setWhatTo", { what: "activeTab", to: urlx });
            this.$f7.views.main.router.navigate(urlx);
        },
        logmeout() {
            this.loading = false;
            this.$store.commit("setWhatTo", { what: "cerrandoSesion", to: true });
            return this.$firebase
                .auth()
                .signOut()
                .then(() => {
                    // Sign-out successful.
                    window.location.reload(true);
                })
                .catch(error => {
                    // An error happened.
                    console.log(error, "error logging out");
                    this.$f7.dialog.alert(error.message, error.code);
                });
        },
        async evalRetornoC19({url,user}){
                //console.log('evaluando retorno c19');
                let c19a = url.searchParams.get("c19a") || 'state';//state
                let c19b = url.searchParams.get("c19b");//timestamp
                let c19c = url.searchParams.get("c19c") || 'uid';//uid
                const timeShiftAllowed = 5;
                if(!user && c19b){
                   // console.log('user no logeado, pero c19b presnete, evalaundo vigencia');
                    //no esta autenticado, solo localforagear si dentro de plazo rango
                    let ahorax= this.$dayjs();
                    let thenx = this.$dayjs.unix(c19b);
                    if(ahorax.diff(thenx,'minute')< timeShiftAllowed ){
                       // console.log('vigente, se guarda en localforage')
                        let c19x={
                            a: c19a,
                            b: c19b,
                            c: c19c
                        }
                        await localforage.setItem("c19x", c19x);
                    }
                }
                else if(user && !c19b){
                   // console.log('user logeado pero sin c19b, buscando c19b en localforage')
                    //si esta logeado, ver si hubiese c19 localforageado
                    let c19x = await localforage.getItem("c19x");
                    if(c19x){
                      //  console.log('hay en localforage, cargando')
                        c19a = c19x.a;
                        c19b = c19x.b;
                        c19c = c19x.c;
                    }
                }
                if(c19b && user && user.email && user.emailVerified){
                    //ver si fue reciente
                    let ahorax= this.$dayjs();
                    let thenx = this.$dayjs.unix(c19b);
                    //console.log('evaluando si fue reciente, tengo email verificado')
                    if(ahorax.diff(thenx,'minute')< (timeShiftAllowed+1)  ){
                       // console.log('c19b dentro de rango')
                            let pidetk = this.$firebase.functions().httpsCallable("mevacuno-gentknc19");
                            let result = await pidetk({ dev: this.$store.state.dev,c19a,c19c });
                           // console.log('llega respuesta')
                            let daouts = result.data;
                            if (daouts.error) {
                                throw new Error(daouts.error.message);
                            }
                            let tkn = daouts.payload.token;
                            let target = daouts.payload.redirecter;
                          //  console.log('LETOKEN ES ',tkn)
                       // return "/encuestas/";
                        return this.$f7.dialog.confirm(`Usted será redirigido a ${target}. Desea continuar?`,`Redirección a ${target}`, async ()=> {
                            await localforage.removeItem("c19x");
                            window.location.href = `https://${target}/#/?mvc=${tkn}`;
                            return null;
                        });
                    }
                    else{
                       // console.log('expired c19 request, borando de localforage');
                        //esta expirado, borrar
                        await localforage.removeItem("c19x");
                    }
                }
        },
        async evaluaCallback(user) {
            let crudo = user?false:true;
            try {
                let url = new URL(window.location.href);
                 //inertecpt c19
                await this.evalRetornoC19({url,user});

                let code = url.searchParams.get("code");
                let state = url.searchParams.get("state");
                let verifix = url.searchParams.get("verifix");
                let cle = url.searchParams.get("cle");

                if (code && state) {
                    let mystate = localStorage.getItem("clp_state");
                    if (mystate != state) {
                        throw new Error(this.$t("errors.stateSolicitudNoCorresponde"));
                    }
                    this.$f7.dialog.preloader(this.$t("commons.verificandoClaveUnica", { claveUnica: "Clave Única" }));
                    //console.log('recibe callback');
                    //eliminar
                    window.history.replaceState({}, document.title, "/");
                    //iniciar solicitud verificacionidenidad
                    if (!crudo && this.$firebase.auth().currentUser) {
                        await this.$firebase.auth().currentUser.reload();
                        let user = this.$firebase.auth().currentUser;
                        await user.getIdToken(true);
                    }
                    let endpoint = crudo ? "mevacuno-loginClaveUnica_core" : "mevacuno-verificaClaveUnica_core";
                    let sendverif = this.$firebase.functions().httpsCallable(endpoint);
                    let result = await sendverif({ dev: this.$store.state.dev, domx: window.location.hostname, code, state });

                    let daouts = result.data;
                    if (daouts.error) {
                        throw new Error(`${this.$t("errors.verificarClaveUnica", { claveUnica: "Clave Única" })}: ${daouts.error.message}`);
                    }
                    // console.log(daouts.payload);
                    if (crudo) {
                        await this.$firebase.auth().signInWithCustomToken(daouts.payload);
                    } else {
                        if (daouts.payload) {
                            this.$store.commit("updateMyPrivx", daouts.payload); //9090
                        }
                        await this.refresUser();
                        this.$f7.dialog.close();
                    }
                } else if (verifix && cle) {
                    this.$f7.dialog.preloader(this.$t("commons.verificandoEmail"));
                    let verifixame = this.$firebase.functions().httpsCallable("mevacuno-manual_Verifix_kle");
                    let resultasc = await verifixame({ dev: this.$store.state.dev, verifix, cle });
                    window.history.replaceState({}, document.title, "/");
                    let daoutsdsd = resultasc.data;
                    if (daoutsdsd.error) {
                        throw new Error(daoutsdsd.error.message || daoutsdsd.error.code || daoutsdsd.error);
                    }
                    if (this.$firebase.auth().currentUser) {
                        await this.$firebase.auth().currentUser.reload();
                        let locxuser = this.$firebase.auth().currentUser;
                        await locxuser.getIdToken(true);
                    }
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(this.$t("commons.ahoraPuedeIniciarSesion"), this.$t("commons.verificacionEmailExitosa"));
                } else {
                    let mvcsid = url.searchParams.get("mvcsid");
                    let a = url.searchParams.get("a");
                    let b = url.searchParams.get("b");
                    if (mvcsid && a && b) {
                        //quiere tratar de responder una encuesta de seguimiento
                        if (!crudo) {
                            //esta logeado, dalre una redireccion tras logearse in
                            return "/encuestas/";
                        } else {
                            window.history.replaceState({}, document.title, "/");
                            //no esta logeado, ir a pedirselas y mostrale el modal
                            this.$f7.dialog.preloader(this.$t("commons.cargandoFormEsavi"));
                            let pideesavi = this.$firebase.functions().httpsCallable("mevacuno-pideEsaviAResponder");
                            let result = await pideesavi({ dev: this.$store.state.dev, segid: mvcsid, uid: a, dp: b });
                            let daouts = result.data;
                            if (daouts.error) {
                                throw new Error(daouts.error.message);
                            }
                            let fomrulario = daouts.payload.formulario;
                            let formstoadd = {};
                            formstoadd[fomrulario.id] = fomrulario;
                            this.$store.commit("mergeObjTo", { what: "forms", to: formstoadd });
                            let sgmt = daouts.payload.sgmt;
                            this.$f7.dialog.close();
                            this.respondedorAbierto = Object.assign({}, sgmt);
                        }
                    } else {
                        let psxrst = url.searchParams.get("psxrst");
                        let xeml = url.searchParams.get("xeml");
                        let whx = url.searchParams.get("whx");
                        window.history.replaceState({}, document.title, "/");
                        if (psxrst && xeml && whx && crudo) {
                            this.restpass(psxrst, xeml, whx);
                        }
                    }
                }
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
        restpass(psxrst, xeml, whx) {
            return this.$f7.dialog.prompt(this.$t("commons.ingreseNuevaPassword"), async neuao => {
                let passx = neuao.trim();
                try {
                    if (!passx) {
                        return this.$f7.dialog.alert(this.$t("errors.passwordVacia"), this.$t("errors.error"), () => {
                            this.restpass(psxrst, xeml, whx);
                        });
                    }
                    this.$f7.dialog.preloader(this.$t("commons.restableciendoPassword"));
                    let reseteapassx = this.$firebase.functions().httpsCallable("mevacuno-manual_passrefix");
                    let result = await reseteapassx({ dev: this.$store.state.dev, psxrst, xeml, whx, passx });
                    let daouts = result.data;
                    if (daouts.error) {
                        throw new Error(daouts.error.message);
                    }
                    this.$f7.dialog.close();
                    return this.$f7.dialog.alert(
                        this.$t("commons.passwordRestablecida"),
                        this.$t("commons.passwordRestablecidaTitle")
                    );
                } catch (error) {
                    this.$f7.dialog.close();
                    console.log(error);
                    return this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            });
        },
        async refresUser() {
            await this.$firebase.auth().currentUser.reload();
            let user = this.$firebase.auth().currentUser;
            await user.getIdToken(true);
            let locluser = {
                displayName: user.displayName,
                email: user.email,
                emailVerified: user.emailVerified,
                photoURL: user.photoURL,
                phoneNumber: user.phoneNumber,
                isAnonymous: user.isAnonymous,
                uid: user.uid,
                providerData: user.providerData,
                privx: this.privxInfo || {},
            };
            this.$store.commit("setObjTo", { what: "user", to: locluser });
        },
        async wearUserandGo(user) {
            try {
                let avisoverificacionexitosa = false;
                //antes de cualquier cosa, ver si es un user de pass y no tiene email verificado
                if (user.email && !user.emailVerified) {
                    await this.$firebase.auth().currentUser.reload();
                    user = this.$firebase.auth().currentUser;
                    await user.getIdToken(true);
                    let url = new URL(window.location.href);
                    let verifix = url.searchParams.get("verifix");
                    let cle = url.searchParams.get("cle");
                    if (verifix && cle) {
                        let verifixame = this.$firebase.functions().httpsCallable("mevacuno-manual_Verifix_kle");
                        await verifixame({ dev: this.$store.state.dev, verifix, cle });
                        window.history.replaceState({}, document.title, "/");
                        await this.$firebase.auth().currentUser.reload();
                        user = this.$firebase.auth().currentUser;
                        await user.getIdToken(true);
                        avisoverificacionexitosa = true;
                    } else {
                        let sendverif = this.$firebase.functions().httpsCallable("mevacuno-sendVerificationEmail_kle");
                        await sendverif({ dev: this.$store.state.dev, go: true });
                        localStorage.setItem("verific_pendiente", true);
                    }
                } else if (user.email && user.emailVerified) {
                    let verific_pendiente = localStorage.getItem("verific_pendiente");
                    if (verific_pendiente) {
                        await this.$firebase.auth().currentUser.reload();
                        user = this.$firebase.auth().currentUser;
                        await user.getIdToken(true);
                        localStorage.removeItem("verific_pendiente");
                    }
                }

                let snapshot = await this.$firebase.database().ref("privx/" + user.uid).once("value");
                this.$f7.dialog.close();
                let potRedirect = await this.evaluaCallback(user);
                //request data
                let suprivx = snapshot.val() || {};
                // User is signed in.
                let locluser = {
                    displayName: user.displayName,
                    email: user.email,
                    emailVerified: user.emailVerified,
                    photoURL: user.photoURL,
                    phoneNumber: user.phoneNumber,
                    isAnonymous: user.isAnonymous,
                    uid: user.uid,
                    providerData: user.providerData,
                    privx: suprivx,
                };

                this.$store.commit("setObjTo", { what: "user", to: locluser });

                if (suprivx.verified) {
                    let extranjero = suprivx.extranjero || false;
                    this.$store.commit("setWhatTo", { what: "internacional", to: extranjero });
                }

                if (suprivx.homopend) {
                    const collection = this.dev ? "solicitudes_homologacion_dev" : "solicitudes_homologacion";

                    const snapshot = await this.$firebase.firestore().collection(collection).where("uid", "==", user.uid).where("est", "==", "En conflicto").get();

                    this.$store.commit("setWhatTo", { what: "tieneSolicitudEnConflicto", to: snapshot.docs.length > 0 });
                }

                // si tiene conflicto o no ha declarado identidad
                if (potRedirect) {
                    this.gotoMenux(potRedirect);
                } else if (locluser.privx.conflicto || !locluser.privx.identity) {
                    this.gotoMenux("/perfil/");
                } else {
                    if (this.internacional) {
                        this.gotoMenux("/carnetinter/");
                    } else {
                        this.gotoMenux("/carnet/");
                    }
                }
                this.loginOpened = false;
                this.$store.commit("setWhatTo", { what: "loggingin", to: false });

                if (avisoverificacionexitosa) {
                    this.$f7.dialog.alert(this.$t("commons.verificacionEmailExitosa"));
                }
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error);
                this.loading = false;
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        async gestionSignIn() {
            await this.$firebase.auth().setPersistence(this.$firebase.auth.Auth.Persistence.SESSION);
            this.reseteam();
        },
        reseteam() {
            this.inactiveTime = idleTimer({
                // function to fire after idle
                callback: this.logmeout,
                // function to fire when active
                activeCallback: () => {
                    console.log("-");
                },
                // Amount of time in milliseconds before becoming idle. default 60000
                idleTime: 1800000,
            });
        },
    },
    mounted() {
        this.$f7ready(f7 => {
            localforage.getItem("locale").then(locale => {
                    if (locale) {
                        this.$i18n.locale = locale;
                    }
                })
                .catch(err => {
                    console.error(err)
                });

            

            this.$firebase.auth().onAuthStateChanged(user => {
                let inter = false;
                let interLS = localStorage.getItem("inter");
                if (interLS && interLS === "true") {
                    inter = true;
                }
                this.$store.commit("setWhatTo", { what: "internacional", to: inter });
                if (user) {
                    this.gestionSignIn();
                    this.wearUserandGo(user);
                } else {
                    this.loginOpened = true;
                    this.$store.commit("setObjTo", { what: "user", to: {} });
                    this.loading = false;
                    this.evaluaCallback(user);
                    if (this.inactiveTime) {
                        this.inactiveTime.destroy();
                    }
                }
            });
        });
    },
};
</script>

<style></style>
