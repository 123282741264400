<template>
    <f7-page name="qr_temp">
        <f7-navbar :subtitle="$t('homologacionIndex.qrTemporal')" :title="eldomin">
            <img slot="left" class="logosmall" src="../static/logos/minsalsaquared.jpg" />
            <f7-nav-right>
                <f7-link popup-close>{{ $t("commons.cancelar") }}</f7-link>
            </f7-nav-right>
        </f7-navbar>
        <f7-block class="text-align-center margin-top">
            <div class="fechax bold">{{ patientId }}</div>
            <div class="sdf">
                {{ privxInfo.apellido1 || camp.pat.apellido1 }} {{ privxInfo.apellido2 || camp.pat.apellido2 || "" }},
                {{ privxInfo.nombres || camp.pat.nombres }}
            </div>
            <div class="sdf">{{ $d($dayjs(privxInfo.fechanac || camp.pat.fechanac), "twoDigits") }}</div>

            <div class="lugarx">{{ camp.nombre }}</div>
            <qrcode-vue :value="stringToQode" size="320" level="L" class="margin-top text-align-center"></qrcode-vue>
        </f7-block>
    </f7-page>
</template>
<script>
import QrcodeVue from "qrcode.vue";
import { commonmixin } from "../mixins/common";
import { mapGetters } from "vuex";
import paisesIso2 from "../paises.json";

export default {
    components: {
        QrcodeVue,
    },
    mixins: [commonmixin],
    props: ["camp"],
    computed: {
        stringToQode() {
            let url = `https://scanmevacuno.gob.cl/?f=${this.camp.neoqr}&c=${this.$store.state.dev ? 1 : 0}&tm=${this.camp.hash}`;
            return url;
        },
        ...mapGetters(["privxInfo"]),
        eldomin() {
            return window.location.hostname;
        },

        patientId() {
            const splittedPatientId = this.camp.pax.split(" ");

            const identityType = splittedPatientId[0];

            if (identityType === "RUN") {
                return this.camp.pax;
            }

            const identity = splittedPatientId[1];
            const splittedIdentity = identity.split("_");

            let cleanIdentity;

            if (splittedIdentity.length === 1) {
                cleanIdentity = splittedIdentity[0];
            } else {
                const lastPart = splittedIdentity[splittedIdentity.length - 1];

                if (this.isIso2(lastPart)) {
                    splittedIdentity.pop();
                    cleanIdentity = splittedIdentity.join("_");
                } else {
                    cleanIdentity = identity;
                }
            }

            return `${identityType} ${cleanIdentity}`;
        },
    },

    methods: {
        isIso2(string) {
            return paisesIso2[string] ? true : false;
        },
    },
};
</script>
<style>
.tipovacx {
    height: 30px !important;
    line-height: 30px;
    background: #d1faff !important;
}
.tipovacx span {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: #000;
}
.logosmall {
    width: 40px;
    padding-left: 10px;
}
.fechax {
    font-size: 25px;
}
</style>
