<template>
    <LazyYoutube ref="player" :src="videoURL" :autoplay="true" />
</template>

<script>
import { LazyYoutube } from "vue-lazytube";
export default {
    components: {
        LazyYoutube,
    },
    props: ["url"],
    data() {
        return {
            play: false,
            stop: true,
            videoURL: "",
        };
    },
    mounted() {
        this.videoURL = this.url;
    },
};
</script>

<style></style>
