<template>
    <f7-page name="about">
        <!-- Top Navbar -->
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title :subtitle="eldomin" :title="$t('leftpanel.about')"></f7-nav-title>
        </f7-navbar>

        <f7-list>
            <f7-list-item link="#" @click="terminosOpened = true" :title="$t('about.terminosYCondicionesDeUso')"></f7-list-item>
            <f7-list-item link="#" @click="popupOpened = true" :title="$t('about.politicaPrivacidadDatos')"></f7-list-item>
        </f7-list>

        <f7-popup class="demo-popup" :opened="popupOpened" @popup:closed="popupOpened = false">
            <f7-page>
                <f7-navbar :title="$t('about.privacidadDatos')">
                    <f7-nav-right>
                        <f7-link popup-close>{{ $t('commons.cerrar') }}</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <politica></politica>
            </f7-page>
        </f7-popup>
        <f7-popup class="terminos-popip" :opened="terminosOpened" @popup:closed="terminosOpened = false">
            <f7-page>
                <f7-navbar :title="$t('commons.terms')">
                    <f7-nav-right>
                        <f7-link popup-close>{{ $t('commons.cerrar') }}</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <terminos></terminos>
            </f7-page>
        </f7-popup>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import politica from "../components/politica.vue";
import terminos from "../components/terminos.vue";
export default {
    components: { politica, terminos },
    data() {
        return {
            popupOpened: false,
            terminosOpened: false,
        };
    },
    computed: {
        ...mapState(["user", "dev"]),
        ...mapGetters(["tieneConflicto", "privxInfo"]),
        eldomin() {
            return window.location.hostname;
        },
    },
    methods: {},
};
</script>
<style></style>
