
import { clean, format } from 'rut.js';
import { load } from 'recaptcha-v3';


export const commonmixin = {
  data(){
    return {
      recaptcha: null,
      avisaCambiosOk: null
    }
  },
  methods:{
    getCleanRut(rut){
      return clean(rut);
    },
    getymyageobj(fechanac){
      let y = this.$dayjs(fechanac);
      let edadanos = this.$dayjs().diff(y,'year');
        let nueva=this.$dayjs(fechanac).add(edadanos, 'y');
         let edadmeses = this.$dayjs().diff(nueva,'month');
         let almes = this.$dayjs(fechanac).add(edadanos, 'y').add(edadmeses, 'M');
        let edaddias = this.$dayjs().diff(almes,'d');
        return {
          d: edaddias,
          m: edadmeses,
          y: edadanos
        };
    },
    getMyidentityFromPaxId(patientid){
      let splitead=patientid.split('_')
      if(splitead[0]=='RUN'){
        return `${splitead[0]} ${format(splitead[1])}`;
      }
      else {
        return `${splitead[0]} ${splitead[1]}`
      }
    },
    fraseIdentity(untercero){
      if(untercero.idenType=='RUN'){
         return `${untercero.idenType} ${format(untercero.rawIdentity)}`;
       }
       else {
         return `${untercero.idenType} ${untercero.identity}`
       }
     },
     suage(fechanac){
       const date2 = this.$dayjs(fechanac)
       const date1 = this.$dayjs()
     let cuanto =  date1.diff(date2,'y');
     return cuanto
     },
    formatRut(rut){
      return format(rut);
    },
    striphtml(alimpiar){
      return alimpiar.replace(/(<([^>]+)>)/gi, "").replace('&nbsp;',' ')
    },
    normalize(stringotonomral) {
      let a = String(stringotonomral).trim().toUpperCase(); //asegurar que sea string o forzar string, y hacer mayuscula
      let b = a.split(".").join(""); //sacar puntos
      let c = b.split("-").join(""); //sacar dashes
      let d = c.split(" ").join(""); //sacar espacios
      return d;
  },
    avisaOk(optiotext=null){
      if(!this.avisaCambiosOk){
        this.avisaCambiosOk = this.$f7.toast.create({
         text: optiotext || this.$t("commons.cambiosGuardados"),
         position: 'center',
         closeTimeout: 2000,
       });
     }
     this.avisaCambiosOk.open();
    },
    async commonExecute(dataToSend,cual,conCaptcha = false){
      if(conCaptcha){
        this.recaptcha = await load(process.env.CAPTCHA_KEY);
        dataToSend.captchaToken = await this.recaptcha.execute('declaraIdentidad');
      }
      dataToSend.dev = this.$store.state.dev;
      let fxtocall = this.$firebase.functions().httpsCallable(cual);
      let result = await fxtocall(dataToSend);
      return result.data;
    },
    async getMeThese(arrayOfStoreStateNames){
      let promesex=[];
      let indexOfRequests=[];
      arrayOfStoreStateNames.forEach(unelem => {
          if(!this.$store.state[unelem] || !Object.keys(this.$store.state[unelem]).length){
            indexOfRequests.push(unelem);
            promesex.push(this.$firebase.database().ref(unelem).once('value'));
          }
      });
      if(!promesex.length){
        return true;
      }
      this.$f7.dialog.preloader(this.$t("commons.cargandoInfo"));
      let multisnaps = await Promise.all(promesex);
      multisnaps.forEach((onesnap,idx) => {
        this.$store.commit('setWhatTo',{what: indexOfRequests[idx], to: onesnap.val() || {}})
      });
      this.$f7.dialog.close();
    }
  }
}