<template>
    <f7-page @page:beforein="loadMyVacunas(false)" name="carnet">
        <!-- Top Navbar -->
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title :title="$t('carnet.comprobante')" :subtitle="$t('carnet.deVacunacion')"></f7-nav-title>
            <f7-nav-right>
                <f7-block
                    ><f7-link @click="loadMyVacunas(true)"><font-awesome-icon icon="sync-alt"></font-awesome-icon></f7-link
                ></f7-block>
            </f7-nav-right>
        </f7-navbar>
        <f7-progressbar v-if="cargandoMisvacunas" infinite></f7-progressbar>
        <f7-block v-if="!privxInfo.identity"
            >{{ $t("commons.debeVerificar") }}"<font-awesome-icon icon="user-circle" /> <b>{{ $t("leftpanel.miPerfil") }}</b>" {{ $t("carnet.antesDeConsultarHistorial") }}</f7-block
        >
        <f7-block v-else-if="tieneConflicto"
            >{{ $t("commons.debeResolverConflicto") }}"<font-awesome-icon icon="user-circle" /> <b>{{ $t("leftpanel.miPerfil") }}</b>"
            {{ $t("carnet.antesDeConsultarHistorial") }}</f7-block
        >
        <f7-block v-else-if="!privxInfo.verified"
            >{{ $t("carnetInter.paraPoderConsultar") }}</f7-block
        >
        <template v-else>
            <f7-block class="text-align-center" v-if="cargandoMisvacunas && !misVacunas.length"
                ><f7-preloader></f7-preloader><br />{{ $t("carnet.cargandoMisVacunas") }}</f7-block
            >
            <f7-block v-if="!cargandoMisvacunas && !misVacunas.length"> {{ $t("carnet.noTieneRegistrosDeVacunacion", { domain: eldomin }) }}</f7-block>
            <one-record @descargapdf="abrepopoerfirmas" @abrepass="verPass" @veopcr="verPCR" v-for="unvac in misVacunas" :key="unvac.id" :camp="unvac"></one-record>
        </template>

        <f7-block
            v-if="privxInfo.identity && privxInfo.verified && !tieneConflicto && !misVacunas.length"
            class="warning_clp padding text-align-center"
        >
            {{ $t("carnetInter.paraIngresarAChile") }}
            <f7-button large fill raised external class="permitebreak" @click="goToREDCap()">{{ $t("carnetInter.validarCovid") }}</f7-button>
            <br />
            {{ $t("carnet.revisarEstadoSolicitud") }}
            <f7-button color="red" @click="estadoVacunaExtranjero($event)" href="#" fill raised>{{ $t("carnet.estadoSolicitud") }}</f7-button>
        </f7-block>

        <f7-block class="warning_clp padding text-align-center">
            {{ $t("carnet.formularioSoporte") }}
            <br />
            {{ $t("carnet.saludResponde", { staticText: "Salud Responde" }) }} <f7-link external href="tel:6003607777">600 360 7777</f7-link>. {{ $t("carnet.chileAtiende", { staticText: "Chile Atiende" }) }} <f7-link external href="tel:101">101</f7-link>
        </f7-block>

        <f7-popup class="qr-popup" :opened="qrvisoropened" @popup:closed="closeviewer()">
            <passviewer v-if="passforvisor" :camp="passforvisor"></passviewer>
        </f7-popup>

        <f7-popover class="estado-vac-inter-popup">
            <f7-list media-list>
                <template v-if="vacInter.cargando">
                    <f7-list-item :title="$t('commons.cargando')" :subtitle="$t('carnet.estadoSolicitud')" :footer="$t('carnet.vacunasInternacionales')"
                        ><f7-preloader slot="after"></f7-preloader>
                    </f7-list-item>
                </template>
                <template v-else-if="vacInter.noidentidad">
                    <f7-list-item :title="$t('carnet.vacunasInternacionales')" :header="$t('carnet.estadoSolicitud')"></f7-list-item>
                    <f7-list-item><f7-button>{{ $t('carnet.sinIdentidad') }}</f7-button></f7-list-item>
                    <f7-list-item
                        class="warning_clp text-align-center aviso13"
                        :footer="$t('carnetInter.debeDeclarar')"
                    ></f7-list-item>
                </template>
                <template v-else-if="vacInter.timeout">
                    <f7-list-item :title="$t('carnet.vacunasInternacionales')" :header="$t('carnet.estadoSolicitud')"></f7-list-item>
                    <f7-list-item><f7-button>{{ $t('commons.intenteMasTarde') }}</f7-button></f7-list-item>
                    <f7-list-item
                        class="warning_clp text-align-center aviso13"
                        :footer="$t('carnet.algoSalioMal')"
                    ></f7-list-item>
                </template>
                <template v-else-if="vacInter.validada">
                    <f7-list-item :title="$t('carnet.vacunasInternacionales')" :header="$t('carnet.estadoSolicitud')"></f7-list-item>
                    <f7-list-item><f7-button>{{ $t('carnet.solicitudAprobada') }}</f7-button></f7-list-item>
                    <f7-list-item class="warning_clp text-align-center aviso13" :footer="$t('carnet.aprobadaEl', { date: $d($dayjs(vacInter.fechaValidada), 'short') })"></f7-list-item>
                </template>
                <template v-else-if="vacInter.temporal">
                    <f7-list-item :title="$t('carnet.vacunasInternacionales')" :header="$t('carnet.estadoSolicitud')"></f7-list-item>
                    <f7-list-item><f7-button>{{ $t('carnet.validadoTemporalmente') }}</f7-button></f7-list-item>
                    <f7-list-item
                        class="warning_clp text-align-center aviso13"
                        :footer="$t('carnet.validadoTemporalmenteEl', { date: $d($dayjs(vacInter.fechaTemporal), 'short') })"
                    ></f7-list-item>
                </template>
                <template v-else-if="vacInter.adicional">
                    <f7-list-item :title="$t('carnet.vacunasInternacionales')" :header="$t('carnet.estadoSolicitud')"></f7-list-item>
                    <f7-list-item><f7-button>{{ $t('carnet.pendienteInformacion') }}</f7-button></f7-list-item>
                    <f7-list-item
                        class="warning_clp text-align-center aviso13"
                        :footer="$t('carnet.pendienteInformacionAdicional')"
                    ></f7-list-item>
                </template>
                <template v-else-if="vacInter.existe">
                    <f7-list-item :title="$t('carnet.vacunasInternacionales')" :header="$t('carnet.estadoSolicitud')"></f7-list-item>
                    <f7-list-item><f7-button>{{ $t('commons.pendiente') }}</f7-button></f7-list-item>
                    <f7-list-item
                        class="warning_clp text-align-center aviso13"
                        :footer="$t('carnet.suSolicitudEstaPendiente', { date: $d($dayjs(vacInter.fechaIngreso), 'longWithTime') })"
                    ></f7-list-item>
                </template>
                <template v-else>
                    <f7-list-item :title="$t('carnet.vacunasInternacionales')" :header="$t('carnet.estadoSolicitud')"></f7-list-item>
                    <f7-list-item><f7-button>{{ $t('carnet.noIngresada') }}</f7-button></f7-list-item>
                    <f7-list-item
                        class="warning_clp text-align-center aviso13"
                        :footer="$t('carnet.noTieneSolicitudVigente')"
                    ></f7-list-item>
                </template>
            </f7-list>
        </f7-popover>
        
        <f7-popup class="estado-pcr-popup">
          <f7-list accordion-list class="no-margin">
            <f7-list-item  v-if="cargandoPCR" :title="$t('commons.cargando')">
              <f7-preloader slot="after"></f7-preloader>
            </f7-list-item>
            <f7-list-item v-if="!cargandoPCR && !labtomas.length" :title="$t('commons.sinResultados')"></f7-list-item>

            <f7-list-item accordion-item class="tomaslinfoxs" v-for="(unatoma,idx) in labtomas" :key="idx" :header="unatoma.laboratorio" :title="unatoma.resultado_muestra || $t('commons.pendiente')" :footer="unatoma.fecha_toma_muestra">
              <f7-accordion-content>
                <f7-list>
                  <f7-list-item v-for="(valuer,proper) in unatoma" :key="proper" :header="proper" :title="String(valuer)"></f7-list-item>
                </f7-list>
              </f7-accordion-content>
            </f7-list-item>
          </f7-list>
        </f7-popup>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import passviewer from "../pages/certificado";
import oneRecord from "../components/oneRecord.vue";
import { commonmixin } from "../mixins/common";
import axios from "axios";

export default {
    components: {
        passviewer,
        oneRecord,
    },
    mixins: [commonmixin],

    data() {
      return {
        cargandoMisvacunas: true,
        qrvisoropened: false,
        passforvisor: null,
        solicitandoFirma: false,
        certPrevio:{
          cid: null,
          cargando: true,
          generando: false,
          generado: true,
          vigente: true
        },
        vacInter:{
          cargando: true,
          existe: false,
          fechaIngreso: null,
          validada: false,
          fechaValidada: null,
          temporal: false,
          fechaTemporal: null,
          adicional: false
        },
        vamos: false,
        cargandoPCR: true,
        labtomas: []
      };
    },
    computed: {
        ...mapState(["user", "dev"]),
        ...mapGetters(["tieneConflicto", "privxInfo", "misVacunas"]),
        eldomin() {
            return window.location.hostname;
        },
    },
    methods: {
        goToREDCap() {
            this.$f7.dialog.confirm(this.$t('carnetInter.soloParaArribos'), this.$t('carnetInter.arriboAChile'), () => {
                window.open("https://validacionvacunacion.minsal.cl/surveys/?s=HMPA3HH74CPFNH44");
            });
        },
        async abrepopoerfirmas({ campanaid, evento }) {
            this.generame(campanaid);
        },
        async generame(campanaid) {
            try {
                this.$f7.popover.close(".descargas-certs-popup");
                this.$f7.dialog.preloader(this.$t('commons.generandoDoc'));
                let idToken = await this.$firebase.auth().currentUser.getIdToken();
                let envx = this.$store.state.dev ? "dev" : "prod";
                let paxid = `${this.privxInfo.idenType}_${this.privxInfo.identity}`;
                let response = await axios.get(`${this.$store.getters.getApiUrl}/prosex-exporter_extranjeros/corxpt/certfx_new_ext/${campanaid}/${paxid}/${envx}`, {
                    headers: { Authorization: "Bearer " + idToken },
                    responseType: "blob", // important
                });
                this.$f7.dialog.close();
                // console.log(response.data);
                if (response.data.type == "application/json") {
                    var textPromise = await response.data.text();
                    console.log("error", textPromise);
                    return this.$f7.dialog.alert(textPromise, "Error");
                }
                const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
                let fileLink = document.createElement("a");
                document.body.appendChild(fileLink);
                fileLink.href = fileURL;
                fileLink.setAttribute("download", "mevacuno.pdf");
                fileLink.classList.add("external");
                fileLink.click();
                window.URL.revokeObjectURL(fileURL);
                this.avisaOk("Descarga OK");
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error, error.message, error.response, error?.response?.data);
                this.$f7.dialog.alert(error.response?.data?.message || error.message);
            }
        },
        async loadMyVacunas(force) {
            try {
                if (this.privxInfo.identity && this.privxInfo.verified) {
                    let pid = `${this.privxInfo.idenType}_${this.privxInfo.identity}`;
                    this.cargandoMisvacunas = true;
                    let datous = await this.commonExecute({ pid, ext: true }, "mevacuno-getmisrecords_neoqr_ext", false);
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    //  console.log('payload',datous.payload);
                    this.cargandoMisvacunas = false;
                    this.$store.commit("setObjTo", { what: "misVacunas", to: datous.payload });
                } else {
                    this.cargandoMisvacunas = false;
                }
            } catch (error) {
                this.cargandoMisvacunas = false;
                console.log(error);
                return this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
        verPass(rv) {
            // console.log('Pidiento abrir pase',rv)
            this.passforvisor = rv;
            this.qrvisoropened = true;
        },
        closeviewer() {
            this.qrvisoropened = false;
            this.passforvisor = null;
        },
        async estadoVacunaExtranjero(evento) {
            //{campanaid:camp.id,evento:$event}
            this.vacInter = Object.assign(
                {},
                {
                    cargando: true,
                    noidentidad: false,
                    timeout: false,
                    existe: false,
                    fechaIngreso: null,
                    validada: false,
                    fechaValidada: null,
                    temporal: false,
                    fechaTemporal: null,
                    adicional: false,
                }
            );
            this.$f7.popover.open(".estado-vac-inter-popup", evento.target);
            try {
                let pid = `${this.privxInfo.identity}`;
                let ptid = `${this.privxInfo.idenType}`;
                if (pid === "undefined") {
                    this.vacInter = Object.assign(
                        {},
                        {
                            cargando: false,
                            noidentidad: true,
                            timeout: false,
                            existe: false,
                            fechaIngreso: null,
                            validada: false,
                            fechaValidada: null,
                            temporal: false,
                            fechaTemporal: null,
                            adicional: false,
                        }
                    );
                } else {
                    let respuesta = await this.commonExecute({ pid, ptid }, "prosex-getStatusViajeros", false);
                    if (respuesta.error) {
                        throw new Error(datous.error.message);
                    }
                    console.log(respuesta);
                    this.vacInter = Object.assign(
                        this.vacInter,
                        {
                            cargando: false,
                            timeout: false,
                            existe: false,
                            fechaIngreso: null,
                            validada: false,
                            fechaValidada: null,
                            temporal: false,
                            fechaTemporal: null,
                            adicional: false,
                        },
                        respuesta.payload
                    );
                }
            } catch (error) {
                console.log(error);
                return this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
        async verPCR(){
          try{
            if(this.privxInfo.identity && this.privxInfo.verified){
              
              let datos = await this.commonExecute({idenType: this.privxInfo.idenType, identity: this.privxInfo.identity},'admin-estadoPCRCliente',false);
              if(datos.error){
                throw new Error(datos.error.message);
              }
              this.$f7.popover.open('.estado-pcr-popup');
              this.cargandoPCR = true;
              this.labtomas = [];
              this.labtomas= datos.payload;
              this.cargandoPCR = false;
            }
          }catch(error){
            if(error.message === 'max')
              this.$f7.dialog.alert(error.response?.data?.message || this.$t("carnet.limitePCR"));
            this.cargandoPCR = false;
          }
        }
    },
};
</script>
<style>
.backogrix {
    background: #f5f4f4;
}
.tipovacx {
    height: 25px !important;
    background: #d1faff !important;
}
.tipovacx span {
    width: 100%;
    text-align: center;
}
.item-divider.minidivider {
    height: 20px;
}
.aviso13 {
    font-size: 13px;
}
.descargas-certs-popup {
    min-height: 300px !important;
}
.estado-vac-inter-popup {
    min-height: 200px !important;
}
.estado-pcr-popup{
   max-height: 400px;
  overflow-y: auto;
}
.tomaslinfoxs.accordion-item .item-link{
  background: #e6fffc!important;
}
</style>
