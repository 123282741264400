<template>
    <f7-popup 
        :opened="opened"
        @popup:opened="init"
        @popup:closed="handleClosePopup"
        :close-by-backdrop-click="false"
    >
        <f7-page>
            <f7-navbar :title="`${$t('commons.agregando')} ${$t('commons.vacuna')}`">
                <f7-nav-right>
                    <f7-link @click="handleClosePopup">{{ $t('commons.cancelar') }}</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-block v-if="loadingVacunas" class="text-align-center">
                <f7-preloader></f7-preloader>
            </f7-block>

            <f7-block v-else-if="opened" class="no-margin-top padding-top">
                <f7-list class="no-margin-top no-padding-top">
                    <f7-list-item
                        class="margin-vertical"
                        smart-select
                        :smart-select-params="{
                            openIn: 'popup',
                            searchbar: { removeDiacritics: true },
                            searchbarPlaceholder: $t('homologacionCreate.buscarVacuna'),
                            popupCloseLinkText: $t('commons.cerrar'),
                            closeOnSelect: true,
                            setValueText: false,
                        }"
                        :disabled="!sortedVacunasHomologacionNac.length"
                        required
                    >
                        <div slot="header" class="header-style">{{ $t("homologacionCreate.queVacuna") }} <span class="text-color-red">*</span></div>
                        <div slot="inner">{{ vacuna.name || "" }}</div>
                        <select @change="vacuna.name = $event.target.value" :value="vacuna.name" name="vacuna">
                            <option v-for="(vacuna, index) in sortedVacunasHomologacionNac" :key="index" :value="vacuna.vacunaPcName">
                                {{ `${vacuna.vacunaPcName}` }}
                            </option>
                        </select>
                    </f7-list-item>
                    <!-- <f7-list-input
                        type="text"
                        :value="vacuna.lote"
                        @input="vacuna.lote = $event.target.value"
                    >
                        <span slot="label">
                            {{ $t('homologacionCreate.nroLote') }} <span class="text-color-red">*</span>
                        </span>
                    </f7-list-input> -->
                </f7-list>
                <f7-button
                    fill
                    large
                    @click="saveVacuna"
                    class="margin-bottom"
                >
                    {{ $t("homologacionCreate.guardarVacuna") }}
                </f7-button>
            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
import { mapState,mapGetters } from "vuex";
import { commonmixin } from "../mixins/common";

export default {
    mixins: [commonmixin],
    props: {
        opened: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loadingVacunas: false,
            vacuna: {
                name: null,
            }
        }
    },
    computed: {
        ...mapState(["vacunasHomologacionNac"]),
        ...mapGetters(['sortedVacunasHomologacionNac']),
    },
    methods: {
        async init() {
            if (!this.sortedVacunasHomologacionNac.length) {
                this.loadingVacunas = true;
                let snapshot = await this.$firebase.database().ref("vacunas").orderByChild("show_hmlg_nac").equalTo(true).once("value");
                this.$store.commit("setWhatTo", { what: "vacunasHomologacionNac", to: snapshot.val() || {} });
                this.loadingVacunas = false;
            }
        },
        saveVacuna() {
            if (!this.vacuna.name) {
                this.$f7.dialog.alert(this.$t("homologacionCreate.vacunaRequired"));
                return;
            }

            // if (!this.vacuna.lote) {
            //     this.$f7.dialog.alert(this.$t("homologacionCreate.loteRequired"));
            //     return;
            // }
            this.$emit("add-vacuna", this.vacuna);
            this.handleClosePopup();
        },
        handleClosePopup() {
            this.$emit("popup-closed");
            this.vacuna = {
                name: null
            };
        }
    }
}
</script>