const twoDigits = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
};

const twoDigitsWithTime = {
    ...twoDigits,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
};

const short = {
    year: "numeric",
    month: "short",
    day: "numeric",
};

const long = {
    year: "numeric",
    month: "long",
    day: "numeric",
};

const longWithTime = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
};

const dayOnly = {
    day: "numeric",
};

const monthOnly = {
    month: "long",
};

const yearOnly = {
    year: "long",
};


export const dateTimeFormats = {
    ar: { short, long, twoDigits, longWithTime, dayOnly, monthOnly, yearOnly, twoDigitsWithTime },
    de: { short, long, twoDigits, longWithTime, dayOnly, monthOnly, yearOnly, twoDigitsWithTime },
    en: { short, long, twoDigits, longWithTime, dayOnly, monthOnly, yearOnly, twoDigitsWithTime },
    es: { short, long, twoDigits, longWithTime, dayOnly, monthOnly, yearOnly, twoDigitsWithTime },
    fr: { short, long, twoDigits, longWithTime, dayOnly, monthOnly, yearOnly, twoDigitsWithTime },
    hi: { short, long, twoDigits, longWithTime, dayOnly, monthOnly, yearOnly, twoDigitsWithTime },
    he: { short, long, twoDigits, longWithTime, dayOnly, monthOnly, yearOnly, twoDigitsWithTime },
    it: { short, long, twoDigits, longWithTime, dayOnly, monthOnly, yearOnly, twoDigitsWithTime },
    ja: { short, long, twoDigits, longWithTime, dayOnly, monthOnly, yearOnly, twoDigitsWithTime },
    ko: { short, long, twoDigits, longWithTime, dayOnly, monthOnly, yearOnly, twoDigitsWithTime },
    nl: { short, long, twoDigits, longWithTime, dayOnly, monthOnly, yearOnly, twoDigitsWithTime },
    pl: { short, long, twoDigits, longWithTime, dayOnly, monthOnly, yearOnly, twoDigitsWithTime },
    pt: { short, long, twoDigits, longWithTime, dayOnly, monthOnly, yearOnly, twoDigitsWithTime },
    ru: { short, long, twoDigits, longWithTime, dayOnly, monthOnly, yearOnly, twoDigitsWithTime },
    zh: { short, long, twoDigits, longWithTime, dayOnly, monthOnly, yearOnly, twoDigitsWithTime },
};

const supportedLocales = {
    ar: "العربية",
    de: "Deutsch",
    en: "English",
    es: "Español",
    fr: "Francais",
    hi: "हिन्दी, हिंदी",
    he: "עברית",
    it: "Italiano",
    ja: "日本語 (にほんご)",
    ko: "한국어",
    nl: "Nederlands",
    pl: "język polski",
    pt: "Português",
    ru: "русский",
    zh: "中文",
};

export function getSupportedLocales() {
    let annotatedLocales = [];

    for (const code of Object.keys(supportedLocales)) {
        annotatedLocales.push({
            code,
            name: supportedLocales[code],
        });
    }

    return annotatedLocales;
}

export function loadLocaleMessages() {
    const locales = require.context("../", true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

function getBrowserLocale(options = {}) {
    const defaultOptions = { countryCodeOnly: false };

    const opt = { ...defaultOptions, ...options };

    const navigatorLocale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language;

    if (!navigatorLocale) {
        return undefined;
    }

    const trimmedLocale = opt.countryCodeOnly ? navigatorLocale.trim().split(/-|_/)[0] : navigatorLocale.trim();

    return trimmedLocale;
}

function supportedLocalesInclude(locale) {
    return Object.keys(supportedLocales).includes(locale);
}

export function getStartingLocale() {
    const browserLocale = getBrowserLocale({ countryCodeOnly: true });

    if (supportedLocalesInclude(browserLocale)) {
        return browserLocale;
    } else {
        return "es";
    }
}

export default {
    dateTimeFormats,
    getStartingLocale,
    loadLocaleMessages,
};
