var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-page',[_c('f7-navbar',{attrs:{"subtitle":_vm.$t('respSeguim.formEsavi'),"title":_vm.formulario.name}},[_c('f7-nav-right',[_c('f7-link',{attrs:{"popup-close":""}},[_vm._v(_vm._s(_vm.$t("commons.cancelar")))])],1)],1),_vm._v(" "),_c('f7-list',{staticClass:"evlado no-margin-top"},[_c('f7-list-item',{attrs:{"header":_vm.$t('commons.seguimientoPara'),"footer":_vm.getMyidentityFromPaxId(_vm.smt.a),"title":_vm.smt.b}},[_c('img',{attrs:{"slot":"media","src":require("../static/heros/avatar.jpg")},slot:"media"})])],1),_vm._v(" "),_c('f7-block',{staticClass:"text-align-center",domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.formulario.instrucciones))}}),_vm._v(" "),_vm._l((_vm.laspreguntas),function(preg){return _c('div',{key:preg.id},[(!preg.depde || _vm.canBeShown(preg.depde))?_c('f7-card',[_c('f7-card-header',{staticClass:"bold"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$sanitize(preg.encabezado))}}),_vm._v(" "+_vm._s(preg.req ? "*" : ""))]),_vm._v(" "),_c('f7-card-content',[(preg.tipo == 'elige_many')?_c('f7-list',{staticClass:"nowhitespacetitle"},_vm._l((preg.alternativas),function(alt){return _c('f7-list-item',{key:alt.id,attrs:{"checked":_vm.myanswers[preg.id] && _vm.myanswers[preg.id][alt.id],"checkbox":"","value":alt.id,"title":alt.tx},on:{"change":function($event){return _vm.selectionaOption(preg.id, alt.id, $event.target.checked)}}})}),1):_vm._e(),_vm._v(" "),(preg.tipo == 'elige_una')?_c('f7-list',{staticClass:"nowhitespacetitle"},_vm._l((preg.alternativas),function(alt){return _c('f7-list-item',{key:alt.id,attrs:{"name":`elradio_${preg.id}`,"checked":_vm.myanswers[preg.id] == alt.id,"radio":"","value":alt.id,"title":alt.tx},on:{"change":function($event){return _vm.setprimitive(preg.id, alt.id)}}})}),1):_c('f7-list',[(preg.tipo == 'input_fecha')?_c('f7-list-input',{attrs:{"type":"datepicker","label":"Indique fecha","outline":"","placeholder":"","readonly":"","calendar-params":{
                            dateFormat: 'dd/mm/yyyy',
                            value: [],
                            disabled:
                                preg.rangemin && preg.rangemax
                                    ? [
                                          {
                                              to: _vm.$dayjs(preg.rangemin)
                                                  .subtract(1, 'day')
                                                  .toDate(),
                                          },
                                          {
                                              from: _vm.$dayjs(preg.rangemax)
                                                  .add(1, 'day')
                                                  .toDate(),
                                          },
                                      ]
                                    : preg.rangemin
                                    ? [
                                          {
                                              to: _vm.$dayjs(preg.rangemin)
                                                  .subtract(1, 'day')
                                                  .toDate(),
                                          },
                                      ]
                                    : preg.rangemax
                                    ? [
                                          {
                                              from: _vm.$dayjs(preg.rangemax)
                                                  .add(1, 'day')
                                                  .toDate(),
                                          },
                                      ]
                                    : [],
                            locale: 'es',
                            closeOnSelect: true,
                        }},on:{"change":function($event){return _vm.setDate(preg.id, $event.target.value)}}}):_vm._e(),_vm._v(" "),(preg.tipo == 'input_numero')?_c('f7-list-input',{attrs:{"label":"Valor","type":"number","outline":"","value":_vm.myanswers[preg.id],"min":preg.rangemin != null ? preg.rangemin : null,"max":preg.rangemax != null ? preg.rangemax : null,"validate":"","clear-button":""},on:{"input":function($event){return _vm.setprimitive(preg.id, $event.target.value)}}}):_vm._e(),_vm._v(" "),(preg.tipo == 'input_texto')?_c('f7-list-input',{attrs:{"label":"Valor","type":preg.txtarea ? 'textarea' : 'text',"outline":"","value":_vm.myanswers[preg.id],"clear-button":""},on:{"input":function($event){return _vm.setprimitive(preg.id, $event.target.value)}}}):_vm._e()],1)],1)],1):_vm._e()],1)}),_vm._v(" "),_c('f7-block',[_c('f7-button',{attrs:{"fill":"","large":"","raised":"","color":"teal"},on:{"click":function($event){return _vm.respondeEsavi()}}},[_vm._v("Enviar respuestas")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }