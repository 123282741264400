<template>
    <f7-page @page:beforein="getSolicitudes()" name="solicitudes-main">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title :title="$t('solicitudesMain.navTitle')" :subtitle="$t('solicitudesMain.navSubtitle')"></f7-nav-title>
            <f7-nav-right v-if="privxInfo.verified">
                <f7-link @click="getMySolicitudes" icon-material="refresh" :tooltip="$t('solicitudesMain.refresh')"></f7-link>
            </f7-nav-right>
        </f7-navbar>

        <f7-block class="text-align-left" v-if="(!privxInfo.fechanac || !privxInfo.nac_iso2) && privxInfo.verified"
            >{{ $t("commons.debeVerificar") }}"<font-awesome-icon icon="user-circle" /> <b>{{ $t("leftpanel.miPerfil") }}</b
            >" {{ $t("commons.antesDeConsultarCampana") }}
        </f7-block>
        <f7-block class="text-align-left" v-else-if="privxInfo.conflicto !== undefined"
            >{{ $t("commons.debeResolverConflicto") }}"<font-awesome-icon icon="user-circle" /> <b>{{ $t("leftpanel.miPerfil") }}</b
            >" {{ $t("commons.antesDeConsultarCampana") }}
        </f7-block>
        <f7-block v-else-if="!privxInfo.verified && (!tieneSolicitudPendiente || !tieneSolicitudPendiente.wh || tieneSolicitudPendiente.rjt)">
            {{ $t("homologacionIndex.debeValidarIdentidad") }}"<font-awesome-icon icon="user-circle" /> <b>{{ $t("leftpanel.miPerfil") }}</b
            >".
        </f7-block>

        <template v-else>
            <f7-block class="text-align-center padding" v-if="loading">
                <f7-preloader></f7-preloader>
            </f7-block>

            <template v-else-if="solicitudes.length">
                <f7-block>
                    <f7-row class="display-flex justify-content-flex-end">
                        <f7-button fill small raised popover-open=".popover-options">
                            <font-awesome-icon icon="plus" class="fa-md"/>
                            {{ $t("solicitudesMain.nuevaSolicitud") }}
                        </f7-button>
                    </f7-row>
                </f7-block>

                <f7-block v-if="solicitudesActivas.length">
                    <f7-block-title class="no-margin-horizontal">{{ $t("solicitudesMain.enCurso") }}</f7-block-title>
                    <f7-list media-list inset class="margin-bottom-half fixfamarg no-margin-horizontal">
                        <f7-list-item
                            v-for="solicitud in solicitudesActivas"
                            :key="solicitud.id"
                            :header="$t('homologacionIndex.ingresadaEl', { date: $d($dayjs.unix(solicitud.wh), 'twoDigitsWithTime') })"
                            link="#"
                            @click="entraSolicitud(solicitud)"
                        >
                            <font-awesome-icon
                                v-if="solicitud.est === 'En edición'"
                                slot="media"
                                class="fa-2x text-color-blue margin-top-half pulse-effect"
                                icon="pen-square"
                            ></font-awesome-icon>
                            <font-awesome-icon
                                v-if="solicitud.est === 'Por firmar'"
                                slot="media"
                                class="fa-2x text-color-blue margin-top-half"
                                icon="file-signature"
                            ></font-awesome-icon>
                            <font-awesome-icon
                                v-else-if="solicitud.est === 'En espera'"
                                slot="media"
                                class="fa-2x text-color-primary margin-top-half"
                                icon="clock"
                            ></font-awesome-icon>
                            <font-awesome-icon
                                v-else-if="solicitud.est === 'En conflicto'"
                                slot="media"
                                class="fa-2x text-color-orange margin-top-half"
                                icon="exclamation-circle"
                            ></font-awesome-icon>
                            <font-awesome-icon
                                v-else-if="solicitud.est === 'En revisión'"
                                slot="media"
                                class="fa-2x text-color-blue margin-top-half"
                                icon="eye"
                            ></font-awesome-icon>
                            <b slot="title">
                                {{ $t("solicitudesMain.tipo_" + solicitud.tipo) }}
                            </b>
                            <div slot="footer" class="display-flex align-items-center">
                                <div v-if="solicitud.est === 'En conflicto'" class="dot margin-right-half"></div>
                            </div>
                            <div slot="footer">
                                {{ getEstadoSolicitud(solicitud) }}
                                <span v-if="solicitud.est === 'En edición'">
                                    &bull;
                                    <b>{{ $t("homologacionIndex.enEdicionRemember") }}</b>
                                </span>
                            </div>
                            <span slot="after">{{ $t("homologacionIndex.viewAndEdit") }}</span>
                        </f7-list-item>
                    </f7-list>
                </f7-block>

                <f7-block v-if="solicitudesCerradas.length">
                    <f7-block-title class="no-margin-horizontal no-margin-top">{{ $t("solicitudesMain.historial") }}</f7-block-title>
                    <!-- <f7-list media-list inset class="margin-bottom-half fixfamarg no-margin-horizontal">
                        <ul> -->
                            <solicitud-historial-item
                                v-for="solicitud in solicitudesCerradas"
                                :key="solicitud.id"
                                :solicitud="solicitud"
                                @view-solicitud="entraSolicitud"
                                @cert-solicitud="generaCertificado"
                                @open-apostilla="setPopupApostilla"
                                @download-apostilla="downloadApostilla">
                            </solicitud-historial-item>
                        <!-- </ul>
                    </f7-list> -->
                </f7-block>
            </template>

            <f7-block v-else>
                {{ $t("solicitudesMain.noTieneSolicitudes") }}
                <f7-button fill raised popover-open=".popover-options" class="margin-top">
                    {{ $t("solicitudesMain.crearSolicitud") }}
                </f7-button>
            </f7-block>
        </template>

        <f7-popover class="popover-options">
            <f7-list media-list class="ajuste-contenido">
                <f7-list-item 
                    @click="creaSolicitud('int')" 
                    popover-close
                    class="item-custom"
                    link="#"
                >
                    <div class="item-title">{{ $t('solicitudesMain.titleSolicitudInter') }}</div>
                 
                    <div class="item-subtitle">{{ $t('solicitudesMain.subtitleSolicitudInter') }}</div>
                </f7-list-item>
                <f7-list-item 
                    @click="creaSolicitud('nac')" 
                    popover-close
                    class="item-custom"
                    link="#"
                >
   
                    <div class="item-title">{{ $t('solicitudesMain.titleSolicitudNacional') }}</div>
                    <div class="item-subtitle">{{ $t('solicitudesMain.subtitleSolicitudNacional') }} <b>{{ $t('solicitudesMain.subtitleSolicitudNacional3')  }}</b></div>
                </f7-list-item>
            </f7-list>
        </f7-popover>

        <solicita-apostilla-popup
            :opened="popupApostillaOpen"
            :solicitud="solicitudSelected"
            @popup-closed="popupApostillaOpen = false"
            @send-request="handleCreateApostilla">
        </solicita-apostilla-popup>
    </f7-page>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { commonmixin } from "../../mixins/common";
import solicitudHistorialItem from './components/solicitud-historial-item.vue';
import solicitaApostillaPopup from './components/solicita-apostilla-popup.vue';

export default {
    mixins: [commonmixin],

    components: {
        "solicitud-historial-item": solicitudHistorialItem,
        "solicita-apostilla-popup": solicitaApostillaPopup
    },

    data() {
        return {
            loading: false,
            solicitudes: [],
            selectTipoSolicitud: false,
            forceClosePopover: false,
            popupApostillaOpen: false,
            solicitudSelected: null,
        }
    },
    computed: {
        ...mapState(["tieneSolicitudPendiente"]),
        ...mapGetters(["privxInfo"]),
        solicitudesActivas() {
            return this.solicitudes.filter(s => s.est != "Cerrada" && s.est != "Aceptada") || [];
        },
        solicitudesCerradas() {
            return this.solicitudes.filter(s => s.est == "Aceptada" || s.est == "Cerrada") || [];
        }
    },

    methods: {
        async getSolicitudes() {
            try {
                if (this.privxInfo.verified) {
                    if ((!this.privxInfo.fechanac || !this.privxInfo.nac_iso2) && this.privxInfo.verified) {
                        let error = "PROFILE_INCOMPLETE";
                        throw error;
                    }
                    let dbtrxs = this.$firebase_coreapp.database(this.$store.getters.usrtrxDatabaseUrl);
                    this.loading = true;
                    let snphot = await dbtrxs.ref(`solicit_verif_open/${this.$store.state.dev ? "d" : "p"}/${this.privxInfo.id}`).once("value");
                    this.solicitudPrevia = snphot.val();
                    this.$store.commit("setWhatTo", { what: "tieneSolicitudPendiente", to: this.solicitudPrevia });

                    // console.log(this.solicitudPrevia, "solicitudPrevia");
                    this.loading = false;
                }
                this.getMySolicitudes();
            } catch (error) {
                this.loading = false;
                console.log(error);
                if (error == "PROFILE_INCOMPLETE") {
                    this.$f7.dialog.alert(
                        this.$t("commons.debeCompletarFechaNacimiento") + this.$t("leftpanel.miPerfil"),
                        this.$t("commons.permissionDenied")
                    );
                } else {
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                }
            }
        }, 
        async getMySolicitudes() {
            if (this.privxInfo.verified || (this.tieneSolicitudPendiente && this.tieneSolicitudPendiente.wh && !this.tieneSolicitudPendiente.rjt)) {
                try {
                    this.loading = true;
                    this.solicitudes = [];
                    let datous = await this.commonExecute({}, "mevacuno-getMisSolicitudes", false);
                    if (datous.error) {
                        throw new Error(datous.error.message || datous.error);
                    }
                    let response = datous.payload || {};


                    if (response.int && response.int.length > 0) {
                        response.int.forEach((solicitud) => {
                            solicitud.tipo = "int";
                            this.solicitudes.push(solicitud);
                        });
                    }

                    if (response.nac && response.nac.length > 0) {
                        response.nac.forEach((solicitud) => {
                            solicitud.tipo = "nac";
                            this.solicitudes.push(solicitud);
                        });
                    }

                    this.solicitudes.sort((a, b) => {
                        return b.wh - a.wh;
                    });

                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(this.$t("homologacionIndex.errorFetchSolicitudes"), error);
                    console.error(error);
                }
            }
        },
        getEstadoSolicitud(sol) {
            if (sol.est == "En espera") {
                return this.$t("solicitudesMain.estadoEspera");
            } else if (sol.est == "Aceptada" && (sol.tipo !== "nac" || sol.cert.status !== "pending")) {
                return this.$t("solicitudesMain.estadoAceptada");
            } else if (sol.est == "En conflicto") {
                return this.$t("solicitudesMain.estadoEnConflicto");
            } else if (sol.est == "En edición") {
                return this.$t("solicitudesMain.estadoEdicion");
            } else if (sol.est == "En revisión") {
                return this.$t("solicitudesMain.estadoRevision");
            } else if (sol.est == "Cerrada") {
                return  this.$t("solicitudesMain.estadoCerrada");
            } else if (sol.est == "Aceptada" && sol.tipo == "nac" && sol.cert.status == "pending") {
                return  this.$t("solicitudesMain.estadoPorFirmar");
            }
        },
        getInfoEstadoSolicitud(sol) {
            if (sol.est == "En espera")
                return this.$t("solicitudesMain.estadoEsperaInfo");
            if (sol.est == "Aceptada"){
                if (sol.tipo === "nac" && sol.cert && sol.cert.status === "pending")
                    return this.$t("solicitudesMain.estadoPorFirmarInfo");
                else
                    return this.$t("solicitudesMain.estadoAceptadaInfo");
            }
            if (sol.est == "En conflicto")
                return  this.$t("solicitudesMain.estadoEnConflictoInfo");
            if (sol.est == "En edición")
                return  this.$t("solicitudesMain.estadoEdicionInfo");
            if (sol.est == "En revisión")
                return  this.$t("solicitudesMain.estadoRevisionInfo");
            if (sol.est == "Cerrada")
                return  this.$t("solicitudesMain.estadoCerradaInfo");
            return "";
        },
        checkSolicitudesVigentes(tipo) {
            let estados = ["En espera", "En conflicto", "En edición", "En revisión", "Por firmar"];
            let vigentes = this.solicitudes.filter((solicitud) => {
                return solicitud.tipo == tipo && estados.includes(solicitud.est);
            });
            return vigentes.length > 0;
        },
        checkSolicitudOnlyView(solicitud) {
            let estados = ["Cerrada", "En revisión", "Aceptada", "Por firmar"];
            return estados.includes(solicitud.est);
        },
        async entraSolicitud(solicitud) {
            try {

                let viewOnly = this.checkSolicitudOnlyView(solicitud);

                if (solicitud.tipo == "int")
                    this.$f7.views.main.router.navigate("/solicitudes/vacunas-inter/", {
                        props: { sol: solicitud, viewOnly },
                    });
                else if (solicitud.tipo == "nac")
                    this.$f7.views.main.router.navigate("/solicitudes/vacunas-nac/", {
                        props: { sol: solicitud, viewOnly },
                    });
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(this.$t("homologacionIndex.errorFetchSolicitudes"), error.message);
            }

        },
        async creaSolicitud(tipo) {
            try {
                if (this.checkSolicitudesVigentes(tipo)) {
                    this.$f7.dialog.alert(this.$t("solicitudesMain.tieneSolicitudMismoTipo"));
                    return;
                }

                this.$f7.dialog.preloader(this.$t("commons.creando"));
                let datous = await this.commonExecute({ solid: null, tipo }, "mevacuno-cargaSolicitudOrCreaNueva", false);
                if (datous.error) {
                    throw new Error(datous.error.message || datous.error);
                }
                let solicitud = datous.payload;
                this.$f7.dialog.close();

                if (tipo == "int")
                    this.$f7.views.main.router.navigate("/solicitudes/vacunas-inter/", {
                        props: { sol: solicitud, viewOnly: false },
                    });
                else if (tipo == "nac")
                    this.$f7.views.main.router.navigate("/solicitudes/vacunas-nac/", {
                        props: { sol: solicitud, viewOnly: false },
                    });
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(this.$t("homologacionIndex.errorFetchSolicitudes"), error.message);
            }
        },
        
        async generaCertificado(solicitud) {
            try {
                this.$f7.dialog.preloader(this.$t("commons.generandoDoc"));

                const response = await this.commonExecute(
                    { sid: solicitud.id, pid: solicitud.pat.pid },
                    "prosex-signCertificatePNI",
                    false
                );

                if (response.error) {
                    throw new Error(response.error.message);
                }

                const signedCertificateURL = response.signedCertificateURL;

                let fileLink = document.createElement('a');
                fileLink.href = signedCertificateURL;
                fileLink.classList.add("external");
                fileLink.setAttribute('download', 'certificado.pdf');
                fileLink.download='certificado.pdf';
                fileLink.setAttribute('target', '_blank');
                document.body.appendChild(fileLink);
                fileLink.click();

                this.$f7.dialog.close();
            } catch (error) {
                console.error(error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },

        setPopupApostilla(soli) {
            this.solicitudSelected = soli;
            this.popupApostillaOpen = true;
        },

        async handleCreateApostilla(payload) {
            try {
                const { pais, solicitud } = payload;
                this.$f7.dialog.preloader(this.$t("solicitudesMain.creandoSolicitud"));

                const reponse = await this.commonExecute({solId: solicitud.id, pais}, "mevacuno-createSolicitudApostilla");

                if (reponse.error) {
                    throw new Error(reponse.error.message);
                }

                //actualizar la solicitud dentro del array de solicitudes
                const index = this.solicitudes.findIndex(s => s.id === solicitud.id);
                if (index !== -1) {
                    this.$set(this.solicitudes[index], "actApos", reponse.payload.actApos);
                    this.$set(this.solicitudes[index], "reqApos", reponse.payload.reqApos);
                }
                
                this.popupApostillaOpen = false;
                this.$f7.dialog.close();
            } catch (error) {
                console.error(error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },

        async downloadApostilla( { sid, apos, pid } ) {
            try {
                this.$f7.dialog.preloader(this.$t("commons.generandoDoc"));

                const response = await this.commonExecute(
                    { sid, apos, pid },
                    "prosex-downloadCertificadoApostilla"
                );

                if (response.error) {
                    throw new Error(response.error.message);
                }

                const signedCertificateURL = response.signedCertificateURL;

                let fileLink = document.createElement('a');
                fileLink.href = signedCertificateURL;
                fileLink.classList.add("external");
                fileLink.setAttribute('download', `apostilla_cid_${apos.id}.pdf`);
                fileLink.download=`apostilla_cid_${apos.id}.pdf`;
                fileLink.setAttribute('target', '_blank');
                document.body.appendChild(fileLink);
                fileLink.click();

                this.$f7.dialog.close();
            } catch (error) {
                console.error(error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
    }
}
</script>

<style scoped>
.mevacuno-style {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    margin: 0 0 1rem 0;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #333;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    text-align: center;
    width: 100%;
}

.mevacuno-style table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 0;
}

.mevacuno-style table thead {
    background-color: #0b4e66;
    color: #fff !important;
}

.mevacuno-style table thead th {
    padding: 0.75rem 0.625rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 500;
    text-align: center;
    border-bottom: 1px solid #d6d6d6;
    border-right: 1px solid #d6d6d6;
    color: #fff !important;
}

.mevacuno-style table tbody tr {
    border-bottom: 1px solid #d6d6d6;
}

.mevacuno-style table tbody tr td {
    padding: 0.75rem 0.625rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
    text-align: center;
    border-right: 1px solid #d6d6d6;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.pulse-effect {
    animation: pulse 1.5s infinite;
}

.popover-options  {
    width: auto;
    height: auto;
    max-width: 90% !important;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}

.popover-info {
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}

.info-text {
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 500;
    color: #333;
    padding: 0.5rem 0.7rem;
}

.ajuste-contenido .item-title, .ajuste-contenido .item-subtitle {
    white-space: normal !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
}

.ajuste-contenido .item-title {
    font-size: 0.95rem !important;
    font-weight: 600 !important;
    line-height: 1.1rem !important;
    margin: 0 0.25rem 0.25rem 0 !important;
}

.ajuste-contenido .item-subtitle {
    font-size: 0.82rem !important;
    font-weight: 400 !important;
    line-height: 1rem !important;
    margin: 0 0 0.25rem 0 !important;
}

.ajuste-contenido .item-custom {
    cursor: pointer;
}

.ajuste-contenido .item-custom:hover {
    background-color: #f2f2f2;
}

.ajuste-contenido .item-title-row::before {
    margin: 0 0 0 0 !important;
}

.btn-wrapper {
    display: flex;
    justify-content: center;
}

.link-icon:not(:last-child) {
    margin-right: 10px;
}

.historial-soliditud {
    --f7-list-bg-color: transparent !important;
    --f7-list-item-padding-vertical: 28px !important;; 
}
</style>