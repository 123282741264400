<template>
    <f7-button @click="naveta()" large raised class="backbluex">
        <div class="btn-cu">
            <span class="cl-claveunica"></span>
            <span class="texto">{{ $t("commons.iniciarSesion") }}</span>
        </div>
    </f7-button>
</template>
<script>
export default {
    computed: {
        clientid() {
            return this.$store.state.dev ? "083ae7a890b440df8e15745b1a5e52d0" : "1e89b0b11b0e417bbd199a3aac7717ee";
        },
        callbackurl() {
            return this.$store.state.dev ? `https://${window.location.hostname}/` : `https://${window.location.hostname}`;
        },
        curstate() {
            return this.$f7.utils.id();
        },
        url() {
            return `https://accounts.claveunica.gob.cl/openid/authorize/?client_id=${
                this.clientid
            }&response_type=code&scope=openid run name&redirect_uri=${encodeURIComponent(this.callbackurl)}&state=${this.curstate}`;
        },
    },
    methods: {
        naveta() {
            if (this.$store.state.dev) {
                return this.$f7.dialog.confirm(
                    "Ud se encuentra en ambiente de desarrollo. Será dirigido al sandbox de clave única (debe usar crendenciales de prueba.)",
                    "Ambiente desarrollo",
                    () => {
                        this.gotocalvn();
                    }
                );
            } else {
                this.gotocalvn();
            }
        },
        async gotocalvn() {
            localStorage.setItem("clp_state", this.curstate);
            this.$f7.dialog.preloader(this.$t("commons.navegandoClaveUnica", { claveUnica: "Clave Única" }));
            window.location.href = this.url;
        },
    },
};
</script>
<style>
.backbluex {
    background: #1069c4;
    text-transform: unset !important;
}
.btn-cu {
    display: inline-block;
    padding: 8px 18px 8px 15px !important;
    background-color: #0f69c4;
    font-family: Roboto, sans-serif;
    font-size: 1em;
    line-height: 1.6em;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 0;
}

.btn-cu .cl-claveunica {
    color: #fff;
    display: inline-block;
    float: left;
    text-indent: -9999px;
    width: 24px;
    height: 24px;
    background: url("../static/icons/claveunica.svg"); /* Esta URL debe ser modificada segun donde quede alojado el documento */
    background-size: 24px 24px;
}

.btn-cu .texto {
    padding-left: 3px;
    text-decoration: underline;
}

.btn-cu:hover {
    background-color: #0c549c;
}

.btn-cu:hover.hover-shw {
    background-color: #0f69c4 !important;
    -webkit-box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.2);
}

.btn-cu.s {
    padding: 6px 14px 6px 12px !important;
    font-size: 0.8rem;
}

.btn-cu.s .cl-claveunica {
    width: 18px;
    height: 18px;
    background-size: 18px 18px;
}

.btn-cu.l {
    padding: 11px 30px 11px 23px !important;
    font-size: 1.25rem;
}

.btn-cu.l .cl-claveunica {
    width: 32px;
    height: 32px;
    background-size: 32px 32px;
}
</style>
