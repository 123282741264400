<template>
  <f7-card outline class="margin-bottom-half fixfamarg no-margin-horizontal">
    <f7-card-header class="display-flex justify-content-space-between align-items-center">
      <div class="display-flex align-items-center">
        <font-awesome-icon
          v-if="solicitud.est === 'Aceptada' && (solicitud.tipo !== 'nac' || solicitud.cert.status === 'approved')"
          class="fa-2x margin-right" icon="check-circle" 
          style="color: teal;"
        ></font-awesome-icon>
        <font-awesome-icon
            v-else-if="solicitud.est === 'Aceptada' && solicitud.tipo === 'nac' && solicitud.cert.status === 'pending'"
            class="fa-2x margin-right" icon="file-signature" 
            style="color: teal;"
        ></font-awesome-icon>
        <font-awesome-icon
            v-else-if="solicitud.est === 'Aceptada' && solicitud.tipo === 'nac' && solicitud.cert.status === 'signing'"
            class="fa-2x margin-right" icon="signature" 
            style="color: teal;"
        ></font-awesome-icon>
        <font-awesome-icon
            v-else-if="solicitud.est === 'Cerrada'"
            class="fa-2x margin-right" icon="times-circle" 
            style="color: gray;"
        ></font-awesome-icon>
        <div>
            <small>{{ $t('homologacionIndex.ingresadaEl', { date: $d($dayjs.unix(solicitud.wh), 'twoDigitsWithTime') }) }}</small>
            <div>
                <b>{{ $t("solicitudesMain.tipo_" + solicitud.tipo) }}</b>
            </div>
        </div>
      </div>

      <div class="display-flex align-items-center">
        <div class="display-flex align-items-center">
          <f7-button
            tooltip="Ver solicitud"
            @click="$emit('view-solicitud', solicitud)"
          >
            <font-awesome-icon size="lg" icon="eye"></font-awesome-icon>
          </f7-button>

          <f7-button  
            v-if="solicitudFirmada"
            tooltip="Descargar certificado"
            @click="$emit('cert-solicitud', solicitud)"
          >
            <font-awesome-icon size="lg" icon="file-download"></font-awesome-icon>
          </f7-button>
          <f7-button
            v-if="canViewApostilla"
            :tooltip="$t('solicitudesMain.gestionApostilla')"
            @click="showDetalle = !showDetalle"
          >
            <font-awesome-icon size="lg" icon="passport"></font-awesome-icon>
            <!-- <font-awesome-icon size="lg" :icon="showDetalle ? 'caret-up' : 'caret-down'"></font-awesome-icon> -->
          </f7-button>
        </div>
      </div>
    </f7-card-header>

    <f7-card-content class="margin-top margin-bottom" v-if="showDetalle">
      <f7-block>
        <p class="no-margin-top no-margin-bottom">
          <small class="text-line">{{ $t("solicitudesMain.infoApostillamiento") }}</small>
        </p>

        <f7-list>
          <f7-list-item
            v-if="solicitudVigente"
          >
            <div slot="title">
              <small>{{ solicitud.actApos.descripcion }} </small>
              <f7-badge color="gray"><small>{{ $t("solicitudesMain.Pendiente") }}</small></f7-badge>
            </div>

            <div slot="footer" v-if="solicitud.actApos.when">
              <small>{{ $t('solicitudesMain.ingresadaEl', { date: $d($dayjs.unix(solicitud.actApos.when), 'twoDigitsWithTime') }) }}</small>
            </div>
          </f7-list-item>
          <f7-list-item
            v-if="solicitudesHistoricas"
            v-for="(s, index) in solicitudesHistoricas"
            :key="index"
          >
            <div slot="title">
              <small>{{ s.descripcion }}</small>
              <f7-badge v-if="s.status === 'aceptada'" color="teal"><small>{{ $t("solicitudesMain.Aceptada") }}</small></f7-badge>
              <f7-badge v-else-if="s.status === 'rechazada'" color="red"><small>{{ $t("solicitudesMain.Rechazada") }}</small></f7-badge>
            </div>

            <div slot="footer" v-if="s.last_resol_at">
              <small v-if="s.status === 'aceptada'">{{ $t('solicitudesMain.aceptadaEl', { date: $d($dayjs.unix(s.last_resol_at), 'twoDigitsWithTime') }) }}</small>
              <small v-else-if="s.status === 'rechazada'">{{ $t('solicitudesMain.rechazadaEl', { date: $d($dayjs.unix(s.last_resol_at), 'twoDigitsWithTime') }) }}</small>
            </div>

            <div slot="after"  v-if="s.status === 'aceptada'">
              <f7-button
                @click="$emit('download-apostilla', { sid: solicitud.id, apos: s, pid: solicitud.pat.pid })"
              >
                <font-awesome-icon icon="file-download" color="teal"></font-awesome-icon>
              </f7-button>
            </div>
          </f7-list-item>
        </f7-list>

        <f7-button
          class="margin-top-half"
          :disabled="!canRequestApostilla"
          small
          raised
          @click="$emit('open-apostilla', solicitud)"
        >
          {{ $t("solicitudesMain.solicitarApostilla") }}
        </f7-button>
      </f7-block>
    </f7-card-content>
      
    <f7-card-footer class="no-border" :padding="false">
      <div>
        <small><b>{{ getEstadoSolicitud() }}</b></small>
        <span>
            <small>{{ getInfoEstadoSolicitud() }}</small>
        </span>
      </div>
    </f7-card-footer>
  </f7-card>
</template>

<script>
export default {
  props: {
    solicitud: Object,
  },

  data() {
    return {
      showDetalle: false,
    };
  },

  computed: {
    solicitudFirmada() {
        return this.solicitud.est === "Aceptada" && this.solicitud.tipo === "nac" && this.solicitud.cert.status === "approved";
    },
    canViewApostilla() {
      return this.solicitud.est === "Aceptada" && this.solicitud.tipo === "nac";
    },
    canRequestApostilla() {
      return this.solicitud.est === "Aceptada" && this.solicitud.tipo === "nac" 
        && (this.solicitud.cert.status === "pending" || this.solicitud.cert.status === "approved")
        && !this.solicitud.actApos;
    },
    solicitudVigente() {
      return this.solicitud.actApos || null;
    },
    solicitudesHistoricas() {
      return this.solicitud.apos ? Object.values(this.solicitud.apos) : [];
    },
  },

  methods: {
    getEstadoSolicitud() {
        if (this.solicitud.est == "En espera") {
            return this.$t("solicitudesMain.estadoEspera");
        } else if (this.solicitud.est == "Aceptada" && (this.solicitud.tipo !== "nac" || (this.solicitud.cert.status !== "pending" && this.solicitud.cert.status !== "signing"))) {
            return this.$t("solicitudesMain.estadoAceptada");
        } else if (this.solicitud.est == "En conflicto") {
            return this.$t("solicitudesMain.estadoEnConflicto");
        } else if (this.solicitud.est == "En edición") {
            return this.$t("solicitudesMain.estadoEdicion");
        } else if (this.solicitud.est == "En revisión") {
            return this.$t("solicitudesMain.estadoRevision");
        } else if (this.solicitud.est == "Cerrada") {
            return  this.$t("solicitudesMain.estadoCerrada");
        } else if (this.solicitud.est == "Aceptada" && this.solicitud.tipo == "nac" && this.solicitud.cert.status == "pending") {
            return  this.$t("solicitudesMain.estadoPorFirmar");
        } else if (this.solicitud.est == "Aceptada" && this.solicitud.tipo == "nac" && this.solicitud.cert.status == "signing") {
            return  this.$t("solicitudesMain.estadoFirmando");
        }
    },
    getInfoEstadoSolicitud() {
        if (this.solicitud.est == "En espera")
            return this.$t("solicitudesMain.estadoEsperaInfo");
        if (this.solicitud.est == "Aceptada"){
            if (this.solicitud.tipo === "nac" && this.solicitud.cert && this.solicitud.cert.status === "pending")
                return this.$t("solicitudesMain.estadoPorFirmarInfo");
            else if (this.solicitud.tipo === "nac" && this.solicitud.cert && this.solicitud.cert.status === "signing")
                return this.$t("solicitudesMain.estadoFirmandoInfo");
            else
                return this.$t("solicitudesMain.estadoAceptadaInfo");
        }
        if (this.solicitud.est == "En conflicto")
            return  this.$t("solicitudesMain.estadoEnConflictoInfo");
        if (this.solicitud.est == "En edición")
            return  this.$t("solicitudesMain.estadoEdicionInfo");
        if (this.solicitud.est == "En revisión")
            return  this.$t("solicitudesMain.estadoRevisionInfo");
        if (this.solicitud.est == "Cerrada")
            return  this.$t("solicitudesMain.estadoCerradaInfo");
        return "";
    },
  }
}
</script>

<style scoped>
/*
text-line para que el texto se vea bien junto
*/
.text-line {
  display: block;
}

</style>