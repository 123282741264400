// Import Vue
import Vue from "vue";
//CLIENTE
// Import Framework7
import Framework7 from "framework7/framework7-lite.esm.bundle.js";

// Import Framework7-Vue Plugin
import Framework7Vue from "framework7-vue/framework7-vue.esm.bundle.js";

// Import Framework7 Styles
import "framework7/css/framework7.bundle.css";

// Import Icons and App Custom Styles
import "../css/icons.css";
import "../css/app.css";
import "typeface-open-sans";

// Import App Component
import App from "../components/app.vue";

//custom hicapps
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import "firebase/analytics";
import { store } from "../store/store";
//font-awesome-icons stuff
import { library } from "@fortawesome/fontawesome-svg-core";
import { dom } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";

//dates library
import dayjs from "dayjs";
import "dayjs/locale/es";
import localeData from "dayjs/plugin/localeData";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

// Import sanitize-html
// import sanitizeHtml from "sanitize-html";
// Vue.prototype.$sanitize = sanitizeHtml;

// import dompurify sanitize
import { sanitize } from "dompurify";
Vue.prototype.$sanitize = sanitize;

const isForcingProduction = process.env.NODE_ENV === "production" && process.env.FORCE_PRODUCTION === "true";

//init firebase
const firebaseConfig = {
    apiKey: isForcingProduction ? process.env.FIREBASE_API_KEY_PRODUCTION_FORCED : process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
};
Vue.prototype.$firebase_coreapp = firebase.initializeApp(firebaseConfig);
const defaultAnalytics = firebase.analytics();

if (process.env.USE_FUNCTIONS_EMULATORS === "true") {
    const host = process.env.FUNCTIONS_EMULATOR_HOST;
    const port = parseInt(process.env.FUNCTIONS_EMULATOR_PORT);

    firebase.functions().useEmulator(host, port);
}

firebase.auth().languageCode = "es";
Vue.prototype.$firebase = firebase;

//init fontawesome
dom.watch();
library.add(fas);
Vue.component("font-awesome-icon", FontAwesomeIcon);
//dates library
dayjs.locale("es");
dayjs.extend(localeData);
Vue.prototype.$dayjs = dayjs;

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

Vue.filter("formatDate", value => {
    if (!value) return "";
    let date = value.toString();

    if (date.length === 2 || date.length === 5) {
        return (date += "/");
    }

    return date;
});

import i18n from "../i18n/config/index.js";

// Init App
new Vue({
    el: "#app",
    render: h => h(App),
    store,
    i18n,
    // Register App Component
    components: {
        app: App,
    },
});
