import Vue from "vue";
import VueI18n from "vue-i18n";

import { dateTimeFormats, getStartingLocale, loadLocaleMessages } from "./utils.js";

Vue.use(VueI18n);

export default new VueI18n({
    dateTimeFormats,
    locale: getStartingLocale(),
    fallbackLocale: "es",
    messages: loadLocaleMessages(),
});
