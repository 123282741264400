import { render, staticRenderFns } from "./terceros.vue?vue&type=template&id=772a201d&"
import script from "./terceros.vue?vue&type=script&lang=js&"
export * from "./terceros.vue?vue&type=script&lang=js&"
import style0 from "./terceros.vue?vue&type=style&index=0&id=772a201d&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports