<template>
    <f7-page name="paises">
        <f7-navbar :title="$t('declareIdentity.paisDeNacionalidad')" back-link="Back">
            <f7-subnavbar :inner="false">
                <f7-searchbar
                    search-container=".virtual-list"
                    search-item="li"
                    :placeholder="$t('commons.busquedaPais')"
                    search-in=".item-title"
                    :disable-button="true"
                ></f7-searchbar>
            </f7-subnavbar>
        </f7-navbar>
        <f7-list class="searchbar-not-found no-margin-top">
            <f7-list-item :title="$t('commons.sinResultados')"></f7-list-item>
        </f7-list>
        <f7-list
            ref="myuserslist"
            class="searchbar-found no-margin-top userslistsx"
            media-list
            virtual-list
            :virtual-list-params="{ items, searchAll, renderExternal, height: 52 }"
        >
            <ul>
                <f7-list-item
                    v-for="(item, index) in vlData.items"
                    :key="index"
                    radio
                    :checked="decl_id_pais == item.iso2"
                    :value="item.iso2"
                    @change="setNacionality($event.target.value)"
                    :title="item.name"
                    :after="item.iso2"
                    :style="`top: ${vlData.topPosition}px`"
                >
                </f7-list-item>
            </ul>
        </f7-list>
    </f7-page>
</template>
<script>
import { mapState, mapGetters } from "vuex";

export default {
    data() {
        return {
            vlData: {
                items: [],
            },
        };
    },
    computed: {
        ...mapState(["decl_id_pais"]),
        ...mapGetters(["paisesArraySorted"]),
        items() {
            return this.paisesArraySorted;
        },
    },
    methods: {
        setNacionality(valoriso2) {
            this.$store.commit("setWhatTo", { what: "decl_id_pais", to: valoriso2 });
            if (this.$f7.views.inpop) {
                this.$f7.views.inpop.router.back();
            } else {
                this.$f7.views.main.router.back();
            }
        },
        searchAll(query, items) {
            const found = [];
            let elkery = query.toLowerCase();
            for (let i = 0; i < items.length; i += 1) {
                if (query.trim() === "" || items[i].name.toLowerCase().indexOf(elkery) >= 0) found.push(i);
            }
            return found;
        },
        renderExternal(vl, vlData) {
            this.vlData = vlData;
        },
    },
};
</script>
