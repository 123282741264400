<template>
    <f7-page @page:beforein="traeCampanas()" name="campanas">
        <f7-navbar v-if="agendando_a_tercero" :title="$t('campanas.declaracionIntencion')" :subtitle="$t('campanas.declarandoPara', { name: mitercero.nombres })" back-link="Back"></f7-navbar>
        <f7-navbar v-else>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title :subtitle="$t('campanas.deVacunacion')" :title="$t('campanas.declaracionIntencion')"></f7-nav-title>
        </f7-navbar>

        <f7-block class="text-align-center" v-if="cargando"><f7-preloader></f7-preloader><br />{{ $t("campanas.cargandoCampanasActivas") }}</f7-block>

        <f7-block class="text-align-left" v-if="(!user.privx.fechanac || !user.privx.nac_iso2) && user.privx.verified">{{ $t("commons.debeVerificar") }}"<font-awesome-icon icon="user-circle" /> <b>{{ $t("leftpanel.miPerfil") }}</b>" {{ $t("commons.antesDeConsultarCampana") }}</f7-block>
        <f7-block class="text-align-left" v-else-if="user.privx.conflicto !== undefined">{{ $t("commons.debeResolverConflicto") }}"<font-awesome-icon icon="user-circle" /> <b>{{ $t("leftpanel.miPerfil") }}</b>" {{ $t("commons.antesDeConsultarCampana") }}</f7-block>
        <f7-block class="text-align-left" v-else-if="!user.privx.verified">{{ $t("commons.debeVerificar") }}"<font-awesome-icon icon="user-circle" /> <b>{{ $t("leftpanel.miPerfil") }}</b>" {{ $t("commons.antesDeConsultarCampana") }}</f7-block>
        <f7-block class="text-align-center" v-else-if="!campanasPubs.length">{{ $t("campanas.noHayCampanas") }}</f7-block>

        <one-campana
            v-for="campana in campanasPubs"
            :key="campana.id"
            @entrar="ingresacamp"
            @responder="abrepregs"
            @cierraEligibility="eligilityOpened = null"
            :campana="campana"
        ></one-campana>

        <f7-popup :opened="!!eligilityOpened" @popup:closed="eligilityOpened = null">
            <f7-page v-if="eligilityOpened">
                <f7-navbar :subtitle="$t('campanas.paraCampana', { campanaName: $store.state.campanas[eligilityOpened].nombre })" :title="$t('campanas.criteriosEligibilidad')">
                    <f7-nav-right>
                        <f7-link popup-close>{{ $t("commons.cancelar") }}</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-block class="text-align-center margin-vertical">
                    {{ $t("campanas.respondaPreguntas") }}
                </f7-block>
                <template v-if="!showresto">
                    <f7-list
                        class="no-margin-vertical nowhitespacetitle"
                        v-for="criteriogroup in criteriosExclusionEnFoco"
                        :key="`ex_${criteriogroup.id}`"
                    >
                        <f7-list-item class="encabe"
                            ><f7-block class="no-margin text-align-center fullwidth">{{ criteriogroup.encab }}</f7-block></f7-list-item
                        >
                        <f7-list-item
                            v-for="criterio in criteriogroup.crits"
                            @change="guardaResp(criterio.id, $event.target.checked)"
                            :key="criterio.id"
                            checkbox
                            :title="criterio.txt"
                        ></f7-list-item>
                    </f7-list>
                </template>
                <f7-block v-if="criteriosExclusionEnFoco.length && !showresto"
                    ><f7-button large fill @click="evaluSiExcl()" raised color="teal">{{ $t("commons.siguiente") }}</f7-button></f7-block
                >
                <template v-if="!criteriosExclusionEnFoco.length || showresto">
                    <f7-list class="no-margin-vertical nowhitespacetitle" v-for="criteriogroup in criteriosEnFoco" :key="`in_${criteriogroup.id}`">
                        <f7-list-item class="encabe"
                            ><f7-block class="no-margin text-align-center fullwidth">{{ criteriogroup.encab }}</f7-block></f7-list-item
                        >
                        <f7-list-item
                            v-for="criterio in criteriogroup.crits"
                            @change="guardaResp(criterio.id, $event.target.checked)"
                            :key="criterio.id"
                            checkbox
                            :title="criterio.txt"
                        ></f7-list-item>
                    </f7-list>
                    <f7-block>
                        <div v-html="$sanitize($t('campanas.enCasoDeComprobarseFalsedad'))"></div>
                    </f7-block>
                    <f7-block class="margin-top">
                        <f7-button class="margin-top" @click="premandaResps()" large raised fill color="primary">{{ $t("campanas.guardarDeclaracion") }}</f7-button>
                    </f7-block>
                </template>
            </f7-page>
        </f7-popup>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { commonmixin } from "../mixins/common";
import oneCampana from "../components/oneCampana.vue";
export default {
    mixins: [commonmixin],
    components: {
        oneCampana,
    },
    data() {
        return {
            cargando: false,
            eligilityOpened: null,
            criteriosEnFoco: [],
            showresto: false,
            criteriosExclusionEnFoco: [],
            dictionExclu: {},
            myansersr: {},
        };
    },
    computed: {
        ...mapState(["user", "chosenCampanaID", "agendando_a_tercero"]),
        ...mapGetters(["tieneConflicto", "privxInfo", "campanasPubs"]),
        mitercero() {
            return this.$store.getters.mitercero(this.agendando_a_tercero);
        },
        algunaAnswerEx() {
            return Object.keys(this.myansersr).filter(unkey => {
                return this.myansersr[unkey] && this.dictionExclu[unkey];
            }).length;
        },
    },
    methods: {
        evaluSiExcl() {
            if (this.algunaAnswerEx > 0 || !this.criteriosEnFoco.length) {
                this.premandaResps();
            } else {
                this.showresto = true;
            }
        },
        ingresacamp(campanaid) {
            this.$store.commit("setWhatTo", { what: "chosenCampanaID", to: campanaid });
            this.eligilityOpened = null;
            return this.$f7.views.main.router.navigate("/miesquema/");
        },
        abrepregs(payloadx) {
            if (!this.privxInfo.identity) {
                this.prrimeroDeclarate();
            } else {
                this.showresto = false;
                let potcrietiros = payloadx.criterios || {};
                let loscrierios = Object.assign({}, potcrietiros.criterios || {});
                this.myansersr = Object.assign({});
                //console.log(loscrierios);
                let criteriosArrayados = Object.values(loscrierios);
                //separar exclusion
                this.criteriosExclusionEnFoco = criteriosArrayados
                    .map(ungrup => {
                        let critesrios = ungrup.crits || {};
                        let soloExclu = Object.values(critesrios).filter(uncrit => {
                            if (uncrit.rule == "EX") {
                                this.dictionExclu[uncrit.id] = true;
                            }
                            return uncrit.rule == "EX";
                        });
                        return { id: ungrup.id, encab: ungrup.encab, crits: soloExclu };
                    })
                    .filter(ungrup => {
                        return Object.keys(ungrup.crits).length;
                    });
                this.criteriosEnFoco = criteriosArrayados
                    .map(ungrup => {
                        let critesrios = ungrup.crits || {};
                        let soloExclu = Object.values(critesrios).filter(uncrit => {
                            return uncrit.rule !== "EX";
                        });
                        return { id: ungrup.id, encab: ungrup.encab, crits: soloExclu };
                    })
                    .filter(ungrup => {
                        return Object.keys(ungrup.crits).length;
                    });

                // console.log('SOLO EXCLUSION',this.criteriosExclusionEnFoco)
                this.eligilityOpened = payloadx.campana.id;
            }
        },
        guardaResp(criterioid, ischecekd) {
            this.$set(this.myansersr, criterioid, ischecekd);
            // console.log(this.myansersr)
        },
        premandaResps() {
            let tosend = Object.keys(this.myansersr).filter(criterioid => {
                return this.myansersr[criterioid];
            });
            let campanaid = this.eligilityOpened;
            this.mandaRespuestas(tosend, campanaid, true);
        },
        mandaRespuestas(tosend, campanaid, conpreloader) {
            if (conpreloader) {
                this.$f7.dialog.preloader(this.$t("campanas.guardandoDeclaracion"));
            }
            return this.commonExecute(
                { campanaid: campanaid, tercero: this.agendando_a_tercero, answers: tosend },
                "mevacuno-checkEligibility",
                false
            )
                .then(datous => {
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    let soyelegible = datous.payload;

                    if (!soyelegible) {
                        this.$f7.dialog.close();
                        this.eligilityOpened = null;
                        this.$store.commit("updtObjProperty", { what: "myCampanaStatusCode", to: "no_elegible", idx: campanaid });
                        this.$f7.dialog.alert(
                            this.$t("campanas.thankYou"),
                            this.$t("campanas.favorEsperar")
                        );
                    } else {
                        //NO NAVEGAR ALTIRO
                        this.computarStock(campanaid);
                    }
                })
                .catch(error => {
                    this.$f7.dialog.close();
                    console.error(error);
                    
                    let errorTitle = error.code || "Error";                    
                    if(error.message == "Es necesario completar su comuna antes de continuar."){
                        errorTitle = "Aviso";
                    }

                    this.$f7.dialog.alert(error.message, errorTitle);
                });
        },
        async computarStock(campanaid) {
            try {
                let datous = await this.commonExecute(
                    { campanaid: campanaid, tercero: this.agendando_a_tercero },
                    "mevacuno-vacsConStockComun",
                    false
                );
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                let stockfund = datous.payload || {};
                let centros = stockfund.centros || [];
                this.eligilityOpened = null;
                this.$f7.dialog.close();
                if (!centros.length) {
                    this.$store.commit("updtObjProperty", { what: "myCampanaStatusCode", to: "sin_stock", idx: campanaid });
                    this.$f7.dialog.alert(
                        this.$t("campanas.consultarVacunatorioMasCercano"),
                        this.$t("campanas.puedesVacunarte")
                    );
                } else {
                    // //navegar
                    this.$store.commit("setWhatTo", { what: "chosenCampanaID", to: campanaid });
                    this.$store.commit("updtObjProperty", { what: "myCampanaStatusCode", to: "stock_ok", idx: campanaid });
                    this.$f7.views.main.router.navigate("/miesquema/");
                }
            } catch (error) {
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
        prrimeroDeclarate() {
            return this.$f7.dialog.confirm(this.$t("campanas.debeDeclararIdentidad"), () => {
                this.$store.commit("setWhatTo", { what: "activeTab", to: "/perfil/" });
                this.$f7.views.main.router.navigate("/perfil/");
            });
        },
        async traeCampanas() {
            if ((!this.campanasPubs.length || !this.$store.state.lastFullLoad.campanas ) && this.user.privx.verified) {
                this.cargando = true;
                let rorequest = [];
                console.log(this.user)
                try {
                    if((!this.user.privx.fechanac || !this.user.privx.nac_iso2) && this.user.privx.verified){
                        let error =  "PROFILE_INCOMPLETE"
                        throw error;
                    }
                    let hoy = this.$dayjs().format("YYYYMMDD");
                    this.$store.commit("setWhatTo", { what: "hoy", to: hoy });
                    let snpsj = await this.$firebase
                        .database()
                        .ref("campanas")
                        .orderByChild("pubfin")
                        .startAt(hoy)
                        .once("value");
                    if (snpsj.exists()) {
                        let campanas = snpsj.val() || {};
                        snpsj.forEach(unacampana => {
                            let campanainfo = unacampana.val();
                            if (campanainfo.gtw && campanainfo.gtw.bfr) {
                                Object.keys(campanainfo.gtw.bfr).forEach(campanaid => {
                                    if (!campanas[campanaid]) {
                                        rorequest.push(
                                            this.$firebase
                                                .database()
                                                .ref(`campanas/${campanaid}`)
                                                .once("value")
                                        );
                                    }
                                });
                            }
                        });
                        if (rorequest.length) {
                            let respon = await Promise.all(rorequest);
                            respon.forEach(potsnap => {
                                if (potsnap.exists()) {
                                    campanas[potsnap.key] = potsnap.val();
                                }
                            });
                        }
                        this.$store.commit("setObjTo", { what: "campanas", to: campanas });
                    }
                    this.cargando = false;
                } catch (error) {
                    console.log(error);
                    this.cargando = false;
                    if(error == "PROFILE_INCOMPLETE"){
                        this.$f7.dialog.alert(this.$t("commons.debeCompletarFechaNacimiento")+  this.$t("leftpanel.miPerfil") , this.$t("commons.permissionDenied"));
                    }else if (error.code === "PERMISSION_DENIED") {
                        this.$f7.dialog.alert(this.$t("campanas.debeTenerCuentaVerificada"), this.$t("commons.permissionDenied"));
                    } else {
                        this.$f7.dialog.alert(error.message, error.code);
                    }
                }
            }
        },
    },
};
</script>
<style>
li.encabe {
    background: #0088b5;
    color: #fff;
    font-weight: bold;
}
li.encabe .item-content {
    padding-left: 0px !important;
}
</style>
