<template>
    <f7-page name="vacunas-nac-create" @page:beforein="cargaUrlsArchivos()">
        <f7-navbar :title="$t('solicitudNacCreate.soliciTitle')" :subtitle="$t('solicitudNacCreate.soliciSubtitle')" back-link="Back">
            <f7-nav-right>
                <f7-block>
                    <f7-button v-if="!hideEditButton && ['En espera', 'En conflicto'].includes(solicitud.est)" @click="cambiarEstadoAEditar('En edición')" fill small>
                        <font-awesome-icon class="fa-md" icon="pencil-alt"></font-awesome-icon>
                        {{ this.$t("solicitudNacCreate.editarSolicitud") }}
                    </f7-button>
                </f7-block>
            </f7-nav-right>
        </f7-navbar>

        <f7-row no-gap>
            <f7-col v-if="!isEditing" width="100" medium="90" class="padding-horizontal-half">
                <f7c-alert v-if="sol.est == 'Cerrada'" type="danger" class="margin">
                   <b>Detalle rechazo: </b> {{ sol.motivo }}
                </f7c-alert>
                <f7c-alert v-else type="info" class="margin">
                    {{ getInfoEstadoSolicitud(solicitud) }}
                </f7c-alert>
            </f7-col>
            <input type="file" id="myfilerx-input" ref="comprobanteVacuna" @change="filesChange($event)" accept="image/*,application/pdf" />
            <f7-col width="100" medium="90">
                <f7-block class="padding-horizontal-half margin-top no-padding-top">
                    <f7-block class="no-margin-top no-padding-top">
                        <h3 class="display-flex align-items-center justify-content-space-between no-margin-bottom">
                            <span>
                                {{ $t("solicitudNacCreate.headerEsquemasAnt2013") }}
                            </span>
                        </h3>

                        <div class="separator"></div>

                        <f7-list class="lista-margin no-margin-top" no-hairlines no-hairlines-between ref="periodo">
                            <f7-list-item 
                                radio 
                                name="periodoOption"
                                value="prev2013"
                                :checked="solicitud.periodo === 'prev2013'"
                                @click="handleChangePeriodo('prev2013')"
                                :disabled="!canEditPeriod || !isEditing"
                            >
                                {{ $t("solicitudNacCreate.siAnterior2013") }}
                            </f7-list-item>
                            <f7-list-item 
                                radio 
                                name="periodoOption" 
                                value="post2013"
                                :checked="solicitud.periodo === 'post2013'"
                                @click="handleChangePeriodo('post2013')"
                                :disabled="!canEditPeriod || !isEditing"
                            >
                                {{ $t("solicitudNacCreate.noAnterior2013") }}
                            </f7-list-item>
                        </f7-list>
                    </f7-block>
                    <f7-block v-if="tipoFormulario == 1">
                        <h3 class="display-flex align-items-center justify-content-space-between no-margin-bottom">
                            <span>
                                {{ $t("solicitudNacCreate.headerVacunas") }}
                            </span>
                        </h3>

                        <div class="separator"></div>

                        <span v-if="!sortedVacunas.length">
                            {{ $t("homologacionCreate.ningunaVacunaDeclarada") }}
                        </span>
                        
                        <f7-list inset media-list class="no-margin-horizontal no-margin-top">
                            <f7-list-item
                                v-for="(vacuna, key) in sortedVacunas"
                                :key="key"
                                :link="isEditing?'#':null"
                                @click.native="editVacuna(vacuna)"
                            >
                                <span v-if="!checkVacunaStatus(vacuna, ['Aceptada', 'Rechazada'])" slot="header">
                                    {{ $t("homologacionCreate.fechaVacunacion") }}: {{ vacuna.fechavac || "-" }}
                                </span>
                                <b v-else slot="header" :class="vacuna.est.resol === 'Aceptada' ? 'text-color-green' : 'text-color-red'">
                                    <font-awesome-icon
                                        class="fa-md"
                                        :icon="vacuna.est.resol === 'Aceptada' ? 'check-circle' : 'times-circle'"
                                    ></font-awesome-icon>
                                    {{ vacuna.est.resol === "Aceptada" ? $t("homologacionCreate.aceptada") : $t("homologacionCreate.rechazada") }}
                                </b>
                                <b v-if="vacuna.est && vacuna.est.toPaxid" slot="footer">{{ $t("homologacionCreate.registradaPaciente", { pid: vacuna.est.toPaxid }) }}</b>

                                <b slot="title">{{
                                    vacuna.vac.id ? `${vacuna.vac.name}` : `${$t("homologacionCreate.vacuna")}: -`
                                }}</b>

                                <span v-if="!checkVacunaStatus(vacuna, ['Aceptada', 'Rechazada'])" slot="subtitle" style="font-size: 13px;">
                                    {{ $t("homologacionCreate.establecimiento") }}: {{ vacuna.establecimiento || "-" }}<br />
                                    <!-- Comuna: {{ vacuna.address.comuna || "-" }} - {{ vacuna.address.region_hr }}<br /> -->
                                    {{ $t("homologacionCreate.lote") }}: {{ vacuna.lote || "-" }}<br />
                                </span>
                                <span v-else slot="subtitle" style="font-size: 13px;">
                                    {{
                                        $t("homologacionCreate.vacunaStatusWithDate", {
                                            decision:
                                                vacuna.est.resol === "Aceptada"
                                                    ? $t("homologacionCreate.aceptada").toLowerCase()
                                                    : $t("homologacionCreate.rechazada").toLowerCase(),
                                            date: $d($dayjs.unix(vacuna.est.when), "twoDigitsWithTime"),
                                        })
                                    }}
                                </span>
                                <b v-if="checkVacunaStatus(vacuna, ['Rechazada'])" slot="footer" class="text-color-red">
                                    {{ $t("homologacionCreate.motivo") }}: {{ vacuna.est.motiv || "-" }}
                                </b>

                                <span v-if="isEditing && !checkVacunaStatus(vacuna, 'Aceptada')" slot="after">
                                    <font-awesome-icon class="fa-sm" icon="pencil-alt"></font-awesome-icon>
                                    {{ $t("commons.editar") }}
                                </span>
                            </f7-list-item>
                        </f7-list>
                        <f7-row>
                            <f7-col width="100" medium="50"></f7-col>
                            <f7-col width="100" medium="50">
                                <f7-button v-if="isEditing" @click="editVacuna(null)" icon-material="add" outline class="display-flex align-items-center">
                                    {{ $t("homologacionCreate.agregarVacuna") }}
                                </f7-button>
                            </f7-col>
                        </f7-row>

                        <h3 class="no-margin-horizontal overflow-text no-margin-bottom">
                            {{ $t("solicitudNacCreate.headerComprobantes") }}
                            <f7-link small popover-open=".popover-info" >
                                <font-awesome-icon icon="info-circle" class="fa-sm"/>
                            </f7-link>
                        </h3>

                        <div class="separator"></div>

                        <p v-if="!Object.values(solicitud.files || {}).length">{{ $t("homologacionCreate.ningunDocumento") }}</p>

                        <f7-list class="fixfamarg no-margin-top" media-list v-else>
                            <f7-list-item v-for="unfile in solicitud.files" :key="unfile.id" :title="unfile.name">
                                <f7-preloader slot="after" v-if="loadingFiles"></f7-preloader>
                                <div v-else-if="unfile.url" slot="after" class="display-flex align-items-center">
                                    <f7-link @click="handleViewFile(unfile)" :tooltip="$t('homologacionCreate.verArchivo')">
                                        <font-awesome-icon class="fa-lg" icon="eye"></font-awesome-icon>
                                    </f7-link>
                                    <f7-link v-if="isEditing && unfile.wh > solicitud.last_edit" @click="handleDeleteFile(unfile)" class="margin-left text-color-red" :tooltip="$t('homologacionCreate.eliminarArchivo')">
                                        <font-awesome-icon class="fa-lg" icon="trash"></font-awesome-icon>
                                    </f7-link>
                                </div>
                                <font-awesome-icon
                                    class="fa-lg"
                                    slot="media"
                                    :icon="unfile.strdname.split('.')[1] == 'pdf' ? 'file-pdf' : 'file-image'"
                                ></font-awesome-icon>
                            </f7-list-item>
                        </f7-list>
                        <f7-row>
                            <f7-col width="100" medium="50"></f7-col>
                            <f7-col width="100" medium="50">
                                <f7-button v-if="isEditing" @click="elegirFiler()" icon-material="add" outline class="display-flex align-items-center">
                                    {{ $t("homologacionCreate.agregarFilerx") }}
                                </f7-button>
                            </f7-col>
                        </f7-row>
                    </f7-block>

                    <f7-block v-if="tipoFormulario == 2">
                        <h3 class="display-flex align-items-center justify-content-space-between no-margin-bottom">
                            <span>
                                {{ $t("solicitudNacCreate.registrosACertificar") }}
                            </span>
                        </h3>

                        <div class="separator"></div>

                        <f7-list class="lista-margin no-margin-top" no-hairlines no-hairlines-between ref="registros">
                            <f7-list-item 
                                radio
                                name="registro"
                                value="todos"
                                :checked="solicitud.vacs_opt.tipo === 'todos'"
                                @change="handleVacsOptChange($event.target.value)"
                                :disabled="!isEditing"
                            >
                                {{ $t("solicitudNacCreate.todos") }}
                            </f7-list-item>
                            <f7-list-item 
                                radio
                                name="registro" 
                                value="algunos"
                                :checked="solicitud.vacs_opt.tipo === 'algunos'"
                                @change="handleVacsOptChange($event.target.value)"
                                :disabled="!isEditing"
                            >
                                {{ $t("solicitudNacCreate.algunos") }}
                            </f7-list-item>             
                        </f7-list>

                        <f7-list>
                            <f7-list-item 
                                v-for="(vacuna, index) in solicitud.vacs_opt.vacs"
                                :key="index"
                                :title="vacuna.name ? vacuna.name : vacuna"
                                :footer="vacuna.lote ? vacuna.lote : ''"
                                :disabled="!isEditing"
                                >
                                <f7-button v-if="isEditing" slot="after" @click="handleDeteleOneVacSimple(index)">
                                    <font-awesome-icon class="fa-lg" icon="trash"></font-awesome-icon>
                                </f7-button>
                            </f7-list-item>
                        </f7-list>

                        <f7-row v-if="solicitud.vacs_opt.tipo === 'algunos' && isEditing">
                            <f7-col width="100" medium="50"></f7-col>
                            <f7-col width="100" medium="50">
                                <f7-button @click="modalSimpleVacunaOpened = true" icon-material="add" outline class="display-flex align-items-center">
                                    {{ $t("homologacionCreate.agregarVacuna") }}
                                </f7-button>
                            </f7-col>
                        </f7-row>
                        
                    </f7-block>

                    <f7-block>
                        <h3 class="display-flex align-items-center justify-content-space-between no-margin-bottom">
                            <span>
                                {{ $t("solicitudNacCreate.headerMotivo") }}
                            </span>
                        </h3>

                        <div class="separator"></div>

                        <f7-list class="lista-margin no-margin-top" no-hairlines no-hairlines-between>
                            <f7-list-item 
                                radio name="motivo" 
                                value="residencia" 
                                :checked="solicitud.mtv.tipo === 'residencia'"
                                @change="handleMotivoChange($event.target.value)"
                                :disabled="!isEditing"
                            >{{ $t("solicitudNacCreate.motivoResidencia") }}</f7-list-item>
                            <f7-list-item 
                                radio name="motivo" 
                                value="viaje"
                                :checked="solicitud.mtv.tipo === 'viaje'"
                                @change="handleMotivoChange($event.target.value)"
                                :disabled="!isEditing"
                            >{{ $t("solicitudNacCreate.motivoViaje") }}</f7-list-item>
                            <f7-list-item 
                                radio name="motivo" 
                                value="estudio"
                                :checked="solicitud.mtv.tipo === 'estudio'"
                                @change="handleMotivoChange($event.target.value)"
                                :disabled="!isEditing"
                            >{{ $t("solicitudNacCreate.motivoEducacion") }}</f7-list-item>
                            <f7-list-item 
                                radio name="motivo" 
                                value="otro"
                                :checked="solicitud.mtv.tipo === 'otro'"
                                @change="handleMotivoChange($event.target.value)"
                                :disabled="!isEditing"
                            >
                                <div class="item-title">{{ $t("solicitudNacCreate.motivoOtro") }}</div>
                            </f7-list-item>
                            <f7-list-input
                                v-if="solicitud.mtv.tipo === 'otro'" 
                                type="textarea"
                                :placeholder="$t('solicitudNacCreate.indiqueOtro')"
                                :value="solicitud.mtv.desc"
                                @input="solicitud.mtv.desc = $event.target.value"
                                outline
                                resizable
                                maxlength="100"
                                @change="handleMotivoChange(solicitud.mtv.tipo, solicitud.mtv.desc)"
                                :disabled="!isEditing"
                                >
                            </f7-list-input>
                        </f7-list>

                        <h3 class="display-flex align-items-center justify-content-space-between no-margin-bottom">
                            <span>
                                {{
                                    viewOnly ?
                                        $t("homologacionCreate.notasEnSolicitud") :
                                        $t("homologacionCreate.agregaTusNotas")
                                }}
                            </span>
                        </h3>
                        <div class="separator"></div>
                        <f7-card outline class="margin-top">
                            <f7-block class="padding">
                                <h3>{{ $t("solicitudMessenger.defaultTitle") }}</h3>
                                <f7-block class="text-align-center" v-if="loadingFiles"><f7-preloader></f7-preloader></f7-block>
                                <span v-else-if="!msgsSolicitudEnEspera.length" class="text-color-gray">
                                    {{ $t("solicitudMessenger.noHayNotas") }}
                                </span>
                                <f7-list media-list class="messenger-notes">
                                    <f7-list-item class="list-item-no-padding" v-for="(msg, index) in msgsSolicitudEnEspera" :key="index" :title="msg.text">
                                        <div slot="header">
                                            <span>{{ msg.sender_uid == user.uid ? $t("solicitudMessenger.tu") : $t("solicitudMessenger.validator") }}</span>
                                        </div>
                                        <div slot="footer">
                                            <span>{{
                                                $t("solicitudMessenger.enviadoEl", { date: $d($dayjs.unix(msg.wh / 1000), "twoDigitsWithTime") })
                                            }}</span>
                                        </div>
                                    </f7-list-item>
                                </f7-list>
                                <template v-if="isEditing">
                                    <f7-list media-list>
                                        <f7-list-input
                                            class="list-item-no-padding text-area-size"
                                            outline
                                            type="textarea"
                                            clear-button
                                            maxlength="500"
                                            :placeholder="$t('solicitudMessenger.writeYourNoteHere')"
                                            :value="msg_text"
                                            @input="msg_text = $event.target.value"
                                        >
                                        </f7-list-input>
                                    </f7-list>
                                    <f7-button
                                        @click="handleMsgSent()"
                                        :disabled="loadingSendMsg"
                                        outline
                                        icon-material="add"
                                        class="display-flex align-items-center"
                                    >
                                        {{ loadingSendMsg ? $t("solicitudMessenger.adding") : $t("solicitudMessenger.addNote") }}
                                    </f7-button>
                                </template>
                            </f7-block>
                        </f7-card>

                        <h3 class="display-flex align-items-center justify-content-space-between no-margin-bottom">
                            <span>
                                {{ $t("solicitudNacCreate.comunaTramite") }}
                            </span>
                        </h3>

                        <div class="separator"></div>

                        <f7-list class="no-margin-top">
                            <ul>
                                <select-comuna
                                    :address="solicitud.address"
                                    :hideHeader="true"
                                    :editar="true"
                                    :disabled="!canEditPeriod && !isEditing"
                                    @newAddress="handleChangeComuna">  
                                </select-comuna>
                            </ul>
                        </f7-list>

                        <h3 class="no-margin-horizontal overflow-text no-margin-bottom">
                            <span>
                                {{ $t("solicitudNacCreate.apostillamiento") }}
                            </span>
                            <f7-link small popover-open=".popover-apostilla" >
                                <font-awesome-icon icon="info-circle" class="fa-sm"/>
                            </f7-link>
                        </h3>
                        <div class="separator"></div>

                        <f7-list class="no-margin-top" no-hairlines no-hairlines-between>
                            <f7-list-item 
                                checkbox 
                                :title="$t('solicitudNacCreate.requiereApostillamiento')" 
                                :checked="solicitud.reqApos"
                                :disabled="!isEditing"
                                @change="handleChangeApostilla($event.target.checked)"></f7-list-item>
                            <f7-list-item
                                v-if="solicitud.reqApos"
                                class="margin-vertical"
                                smart-select
                                :smart-select-params="{
                                    openIn: 'popup',
                                    searchbar: { removeDiacritics: true },
                                    searchbarPlaceholder: $t('homologacionCreate.buscarPais'),
                                    popupCloseLinkText: $t('commons.cerrar'),
                                    closeOnSelect: true,
                                    virtualList: true,
                                    setValueText: false,
                                }"
                                :disabled="!paisesApostilla.length || !isEditing"
                                required
                            >
                                <div slot="title">{{ $t("solicitudNacCreate.paisApostillamiento") }} <span class="text-color-red">*</span></div>
                                <div slot="after">{{ solicitud.actApos.descripcion || "" }}</div>
                                <select @change="handleCountrySelected($event.target.value)" :value="solicitud.actApos.id" name="pais">
                                    <option v-for="(pais, index) in paisesApostilla" :key="index" :value="pais.id">
                                        {{ pais.descripcion }}
                                    </option>
                                </select>
                            </f7-list-item>
                        </f7-list>

                        <template v-if="isEditing">
                            <f7-button @click="cambiarEstadoAEditar('En espera')" fill large raised>
                                <f7-icon material="send"></f7-icon>
                                {{ $t("homologacionCreate.guardarCambios") }}
                            </f7-button>
                        </template>
                    </f7-block>
                </f7-block>
            </f7-col>
        </f7-row>

        <edit-vacuna-nac-popup
            :opened="modalEditingVacunaOpened"
            :vacuna="editingVacuna"
            :isDeletable="sortedVacunas.length > 1"
            @popup-closed="modalEditingVacunaOpened = false"
            @modified="handleVacunaModified"
            @deleted="handleVacunaDeleted"
            :solicitud="solicitud"
        >
        </edit-vacuna-nac-popup>

        <f7-popover class="popover-info">
            <div class="info-text">
                Los comprobantes de vacunación deben contener la siguiente información:
                <ul>
                    <li>Nombre de la vacuna</li>
                    <li>Fecha de administración</li>
                    <li>Nombre del establecimiento dónde se administró la vacuna</li>
                    <li>Timbre del establecimiento dónde se administró la vacuna (opcional)</li>
                </ul>
            </div>
        </f7-popover >

        <f7-popover class="popover-apostilla">
            <div class="info-text">
                {{ $t("solicitudNacCreate.infoApostillamiento") }}
            </div>
        </f7-popover >

        <add-vacuna-nac-simple-popup
            :opened="modalSimpleVacunaOpened"
            @add-vacuna="handleAddVacunaSimple"
            @popup-closed="modalSimpleVacunaOpened = false">
        </add-vacuna-nac-simple-popup>
        
    </f7-page>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Alert from "../../components/utils/Alert.vue";
import { commonmixin } from '../../mixins/common'
import selectComuna from '../../components/selectComuna.vue';

import EditVacunaNacPopup from "../../components/EditVacunaNacPopup.vue";
import AddVacunaNacSimplePopup from '../../components/AddVacunaNacSimplePopup.vue';

export default {
    mixins: [commonmixin],
    components: {
        EditVacunaNacPopup,
        "f7c-alert": Alert,
        selectComuna,
        AddVacunaNacSimplePopup
    },
    props: {
        sol: {
            type: Object,
            required: true
        },
        viewOnly: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            solicitud:{
                mtv: {
                    tipo: null,
                    desc: null,
                },
                address : {
                    comuna: null,
                    region: null,
                    provincia: null,
                    region_hr: null,
                    code: null,
                }
            },
            modalEditingVacunaOpened: false,
            modalSimpleVacunaOpened: false,
            loadingVacunas: false,
            editingVacuna: null,
            progressdialog: null,
            loadingSendMsg: false,
            msgsSolicitudEnEspera: [],
            msg_text: "",
            task: null,
            fileUploadingName: null,
            loadingFiles: false,
            hideEditButton: false,
            tipoFormulario: 0,
        }
    },
    computed: {
        ...mapState(["user", "vacunasHomologacionNac"]),
        ...mapGetters(["privxInfo", "sortedVacunasHomologacionNac"]),
        sortedVacunas() {
            return Object.values(this.solicitud.vacs || {}).sort((a, b) => {
                if (a.fechavac < b.fechavac) return -1;
                if (a.fechavac > b.fechavac) return 1;
                return 0;
            });
        },
        isEditing() {
            return this.solicitud.est === "En edición";
        },
        canEditPeriod() {
            return this.solicitud.est === "En edición" && !this.solicitud.last_resol_at;
        },
        paisesApostilla() {
            if (!this.$store.getters.paisesApostillaArraySorted.length)
                return [];

            return this.$store.getters.paisesApostillaArraySorted;
        },
    },
    async mounted(){
        this.solicitud = Object.assign({}, this.sol);
        if (!this.solicitud.mtv) {
            this.$set(this.solicitud, "mtv", {
                tipo: null,
                desc: null,
            });
        }

        if (!this.solicitud.address) {
            this.$set(this.solicitud, "address", {
                comuna: null,
                region: null,
                provincia: null,
                region_hr: null,
                code: null,
            });
        }

        if (this.solicitud.periodo === "post2013" && !this.solicitud.vacs_opt) {
            this.$set(this.solicitud, "vacs_opt", {
                tipo: null,
                vacs: [],
            });
        } else if (this.solicitud.periodo === "post2013" && this.solicitud.vacs_opt && this.solicitud.vacs_opt.tipo === "algunos" && !this.solicitud.vacs_opt.vacs) {
            this.$set(this.solicitud.vacs_opt, "vacs", []);
        }

        if (this.isEditing && !this.solicitud.address.code && this.privxInfo && this.privxInfo.contc_pref && this.privxInfo.contc_pref.address){
            await this.handleUpdateField("address", this.privxInfo.contc_pref.address);
            this.$set(this.solicitud, "address", this.privxInfo.contc_pref.address)
        }

        if (this.solicitud.reqApos && this.isEditing)
            await this.fetchPaisesApostilla();
            

        this.handleChangeFormulario();
    },
    methods: {
        async cargaUrlsArchivos() {
            try {
                if (this.solicitud) {
                    console.log("cargando URLs archivos");
                    this.loadingFiles = true;

                    let resposta = await this.commonExecute({ solid: this.solicitud.id, tipo: "nac" }, "mevacuno-getAllURLsOneSol", false);
                    if (resposta.error) {
                        throw new Error(resposta.error.message || resposta.error.code || resposta.error);
                    }
                    if(resposta.payload.filesNotExisting && resposta.payload.filesNotExisting.length){
                        resposta.payload.filesNotExisting.forEach(unaMissingFileId => {
                            if(this.solicitud.files && this.solicitud.files[unaMissingFileId]){
                                this.$delete(this.solicitud.files, unaMissingFileId);
                            }
                        });
                    }

                    resposta.payload.files.forEach(unpotfile => {
                        this.solicitud.files[unpotfile.id] = Object.assign({}, unpotfile);
                    });

                    this.msgsSolicitudEnEspera = Object.values(resposta.payload.mensajesx || {}).sort((a, b) => {
                        if (a.wh < b.wh) return 1;
                        if (a.wh > b.wh) return -1;
                        return 0;
                    });

                    //mensajesx
                    this.loadingFiles = false;

                }
            } catch (error) {
                this.loadingFiles = false;
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        elegirFiler() {
            this.$$("#myfilerx-input").click();
        },
        async filesChange(e) {
            let file = e.target.files[0];
            if (file) {
                let permfile = await this.pedirPermAndUpload({ filereceive: file });
                this.subirlefile({ filereceive: file, permfile });
                this.$refs.comprobanteVacuna.value = null;
            }
        },
        async pedirPermAndUpload({ filereceive }) {
            try {
                this.$f7.dialog.preloader(this.$t("solicitarVerificacion.preparandoCarga"));
                let filename = filereceive.name;
                let extenx = filename
                    .split(".")
                    .pop()
                    .toLowerCase();
                let resposta = await this.commonExecute({ solid: this.solicitud.id, filename, ext: extenx, tipo: "nac" }, "mevacuno-getUpldPermHomolog", false);
                if (resposta.error) {
                    throw new Error(resposta.error.message || resposta.error.code || resposta.error);
                }
                await this.$firebase.auth().currentUser.reload();
                let user = this.$firebase.auth().currentUser;
                await user.getIdToken(true);
                this.$f7.dialog.close();
                return resposta.payload;
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        subirlefile({ filereceive, permfile }) {
            var metadata = {
                contentType: filereceive.type,
            };
            this.fileUploadingName = permfile;
            this.progressdialog = this.$f7.dialog.progress(this.$t("solicitarVerificacion.cargandoFiler"), 0);
            this.progressdialog.setText("0%");
            this.task = this.$firebase
                .storage()
                .ref(`verifs_${this.$store.state.dev ? "d" : "p"}/${this.user.uid}/${this.solicitud.id}/${permfile}`)
                .put(filereceive, metadata);
            this.task.on("state_changed", this.alsnapshot, this.alerror, this.altermino);
        },
        alsnapshot(snapshot) {
            let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            let nentero = Math.round(percentage);
            if (!isNaN(nentero)) {
                this.progressdialog.setProgress(nentero);
                this.progressdialog.setText(nentero + "%");
            }
        },
        alerror(err) {
            console.log(err);
            this.progressdialog.close();
            this.$f7.dialog.alert(err.code, this.$t("solicitarVerificacion.errorEnCarga"));
        },
        altermino() {
            console.log(this.task, "se subio el archivo!");
            this.progressdialog.close();
            this.$f7.dialog.preloader(this.$t("solicitarVerificacion.procesando"));
            console.log("terminó la carga de forma exitosa");
            return this.commonExecute(
                { solid: this.solicitud.id, fileid: this.fileUploadingName.split(".")[0], tipo: "nac" },
                "mevacuno-getUrlDescargaFile",
                false
            )
                .then(respoesta => {
                    this.$f7.dialog.close();
                    if (respoesta.error) {
                        throw new Error(respoesta.error.message || respoesta.error.code || respoesta.error);
                    }
                    else if(respoesta.noSaved){
                        if(this.solicitud.files && this.solicitud.files[respoesta.noSaved]){
                            this.$delete(this.solicitud.files, respoesta.noSaved);
                            return this.$f7.dialog.alert('File not saved. Try again','Error');
                        }
                    }
                    console.log("File available at", respoesta);
                    let files = {};
                    files[respoesta.payload.id] = respoesta.payload;
                    if (!this.solicitud.files) {
                        this.solicitud = Object.assign({}, this.solicitud, { files });
                    } else {
                        this.solicitud.files = Object.assign({}, this.solicitud.files, files);
                    }
                })
                .catch(error => {
                    this.$f7.dialog.close();
                    console.log(error);
                    this.$f7.dialog.alert(error.message, error.code || "Error");
                });
        },
        validaSolicitud() {
            const resp = {
                ok: true,
                msg: [],
            };
            if (!this.solicitud.periodo) {
                resp.ok = false;
                resp.msg.push(this.$t("solicitudNacValidate.periodo"));
            } 
            if (this.solicitud.periodo == "post2013" && !this.solicitud.vacs_opt.tipo){
                resp.ok = false;
                resp.msg.push(this.$t("solicitudNacValidate.vacsOptTipo"));
            }
            if (this.solicitud.periodo == "post2013" && this.solicitud.vacs_opt.tipo === 'algunos' && !this.solicitud.vacs_opt.vacs.length) {
                resp.ok = false;
                resp.msg.push(this.$t("solicitudNacValidate.vacsOptVacunas"));
            }
            if (this.solicitud.periodo == "prev2013" && !this.solicitud.vacs){
                resp.ok = false;
                resp.msg.push(this.$t("solicitudNacValidate.vacunas"));
            }
            if (!this.solicitud.mtv.tipo){
                resp.ok = false;
                resp.msg.push(this.$t("solicitudNacValidate.mtv"));
            } 
            if (this.solicitud.mtv.tipo === "otro" && !this.solicitud.mtv.desc) {
                resp.ok = false;
                resp.msg.push(this.$t("solicitudNacValidate.mtvOtro"));
            }
            if (!this.solicitud.address) {
                resp.ok = false;
                resp.msg.push(this.$t("solicitudNacValidate.address"));
            }
            if(this.solicitud.reqApos && !this.solicitud.actApos.id){
                resp.ok = false;
                resp.msg.push(this.$t("solicitudNacValidate.paisApostillamiento"));
            }
            return resp;
        },
        async cambiarEstadoAEditar(nuevoestado) {
            try {
                if (nuevoestado === "En espera") {
                    let validacion = this.validaSolicitud();
                    console.log(validacion);
                    if (!validacion.ok) {
                        return this.$f7.dialog.alert(validacion.msg.join("<br>"), this.$t("errors.error"));
                    }
                }
                this.$f7.dialog.preloader(this.$t("commons.cargando"));
                let datous = await this.commonExecute({ solid: this.solicitud.id, target: nuevoestado, tipo: "nac" }, "mevacuno-cambioEstadoSolicitud", false);
                if (datous.error) {
                    if(datous.hideEdit){
                        this.hideEditButton = true;
                    }
                    else if(datous.missingFiles && datous.missingFiles.length){
                        datous.missingFiles.forEach(unaMissingFileId => {
                            if(this.solicitud.files && this.solicitud.files[unaMissingFileId]){
                                this.$delete(this.solicitud.files, unaMissingFileId);
                            }
                        });
                    }
                    throw new Error(datous.error.message || datous.error);
                }
                this.solicitud.est = nuevoestado;
                this.$f7.dialog.close();
                if (nuevoestado === "En espera") {
                    this.$f7.views.main.router.back();
                }
                if (nuevoestado === "En edición" && this.solicitud.reqApos) {
                    await this.fetchPaisesApostilla();
                }
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message);
            }
        },
        editVacuna(vacuna) {
            const isAccepted = vacuna && vacuna.est && vacuna.est.resol === "Aceptada";

            if(this.isEditing && (!isAccepted || !vacuna)){
                this.editingVacuna = vacuna ? vacuna.id : null;
                this.modalEditingVacunaOpened = true;
            }
        },
        handleVacunaModified(vacuna) {
            if (!this.solicitud.vacs) {
                let tosave = {};
                tosave[vacuna.id] = vacuna;
                this.$set(this.solicitud, "vacs", tosave);
            } else {
                this.$set(this.solicitud.vacs, vacuna.id, vacuna);
            }
            this.editingVacuna = null;
        },
        handleVacunaDeleted(id) {
            this.$delete(this.solicitud.vacs, id);
        },
        checkVacunaStatus(vacuna, statuses) {
            return vacuna.est && statuses.includes(vacuna.est.resol);
        },
        handleViewFile(file) {
            const fileExt = file.name.split(".").pop();

            if (fileExt === "pdf") {
                window.open(file.url, '_blank')
            } else {
                this.$f7.photoBrowser.create({
                    photos: [file.url],
                    type: "popup",
                    toolbar: false,
                    popupCloseLinkText: this.$t("commons.cerrar"),
                }).open();
            }
        },
        async handleDeleteFile(file) {
            this.$f7.dialog.confirm(
                this.$t("homologacionCreate.seEliminaraArchivo"),
                this.$t("commons.areYouSure"),
                async () => {
                    try {
                        this.$f7.dialog.preloader(this.$t("homologacionCreate.eliminando"));
                        const response = await this.commonExecute({
                            solid: this.solicitud.id,
                            fileid: file.id,
                            dev: this.$store.state.dev,
                            tipo: "nac",
                        }, "mevacuno-delFileFromSolHom");
                        if (response.error) {
                            throw new Error(response.error.message || response.error.code || response.error);
                        }

                        //eliminar de la lista
                        this.$delete(this.solicitud.files, file.id);

                        this.$f7.dialog.close();
                    } catch (error) {
                        console.error(error.message);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(error.message, error.code);
                    }
                });
        },
        async handleMsgSent() {
            if (!this.msg_text.trim().length) {
                this.$f7.dialog.alert(this.$t("solicitudMessenger.debeEscribir"));
                return;
            }
            try {
                this.loadingSendMsg = true;
                let datous = await this.commonExecute({ solid: this.solicitud.id, msg: this.msg_text, tipo: "nac" }, "mevacuno-mandarMensajeHomolog", false);
                if (datous.error) {
                    throw new Error(datous.error.message || datous.error);
                }
                console.log("resposta", datous);
                let nuevoMnesjae = datous.payload;
                this.msgsSolicitudEnEspera.unshift(nuevoMnesjae);
                this.msg_text = "";
                this.loadingSendMsg = false;
            } catch (error) {
                this.loadingSendMsg = false;
                console.error(error);
                this.$f7.dialog.alert(error.message);
            }
        },
        async handleChangePeriodo(periodo) {
            try {
                if(!this.isEditing){
                    return;
                }
                if (this.solicitud.last_resol_at) {
                    this.$f7.dialog.alert(this.$t("solicitudNacCreate.alertCambioPeriodoNoPermitido"));
                    return;
                }
                if (this.solicitud.periodo && this.solicitud.periodo !== periodo){
                    this.$f7.dialog.confirm(
                        this.$t("solicitudNacCreate.alertCambioPeriodo"),
                        this.$t("commons.areYouSure"),
                        async () => {
                            this.solicitud.periodo = periodo;
                            await this.handleDeleteDataForm();
                            await this.handleUpdateField("periodo", periodo);
                            this.handleChangeFormulario();
                        },
                        () => {
                            this.$nextTick(() => {
                                console.log("next tick");
                                const radio = this.$el.querySelector(`input[name="periodoOption"][value="${this.solicitud.periodo}"]`);
                                radio.checked = true;
                                this.handleChangeFormulario();
                            });
                        }
                    )
                } else {
                    this.solicitud.periodo = periodo;
                    await this.handleUpdateField("periodo", periodo);
                    this.handleChangeFormulario();
                }
            } catch (error) {
                console.error(error.message);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        handleChangeFormulario(){
            if (this.solicitud.periodo === "prev2013") {
                this.tipoFormulario = 1;
            } else if (this.solicitud.periodo === "post2013") {
                if (!this.solicitud.vacs_opt)
                    this.$set(this.solicitud, "vacs_opt", {
                        tipo: null,
                        vacs: [],
                    });
                this.tipoFormulario = 2;
            }
            else {
                this.tipoFormulario = 0;
            }
        },
        async handleDeleteDataForm() {
            try {
                this.$f7.dialog.preloader(this.$t("solicitudNacCreate.eliminandoDatosFormulario"));
                const response = await this.commonExecute({
                    solid: this.solicitud.id,
                    dev: this.$store.state.dev,
                }, "mevacuno-deleteSolHomNacFormChange");
                if (response.error) {
                    throw new Error(response.error.message || response.error.code || response.error);
                }

                if (this.solicitud.files)
                    this.solicitud.files = {};
                if(this.solicitud.vacs)
                    this.solicitud.vacs = {};
                if (this.solicitud.vacs_opt)
                    this.solicitud.vacs_opt = {
                        tipo: null,
                        vacs: [],
                    };

                this.$f7.dialog.close();
            } catch (error) {
                console.error(error.message);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        async handleMotivoChange(motivo, desc = null) {
            try {
                let value = { tipo: motivo, desc: desc };
                let field = "mtv";
                await this.handleUpdateField(field, value);
                this.solicitud.mtv.tipo = motivo;
                this.solicitud.mtv.desc = desc;
                this.$f7.dialog.close();
            } catch (error) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, error.code);
            }
            
        },
        async handleUpdateField(field, value) {
            this.$f7.dialog.preloader(this.$t("solicitudNacCreate.guardandoCambios"));
            const response = await this.commonExecute({
                solid: this.solicitud.id,
                dev: this.$store.state.dev,
                field,
                value,
            }, "mevacuno-updateFieldSolHomNac");
            if (response.error) {
                this.$f7.dialog.close();
                throw new Error(response.error.message || response.error.code || response.error);
            }
            this.$f7.dialog.close();
        },
        async handleVacsOptChange(tipo) {
            try {
                let value = { tipo: tipo, vacs: [] };
                let field = "vacs_opt";
                await this.handleUpdateField(field, value);
                this.$set(this.solicitud.vacs_opt, "tipo", tipo);
                this.$set(this.solicitud.vacs_opt, "vacs", []);
                this.$f7.dialog.close();
            } catch (error) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        async handleChangeComuna(comuna) {
            try {
                await this.handleUpdateField("address", comuna);
                this.$set(this.solicitud, "address", comuna);
            } catch (error) {
                console.error(error.message);
                this.$f7.dialog.alert(error.message);
            }

        },
        getInfoEstadoSolicitud(sol) {
            if (sol.est == "En espera")
                return this.$t("solicitudesMain.estadoEsperaInfo");
            if (sol.est == "Aceptada"){
                if (this.solicitud.tipo === "nac" && this.solicitud.cert && this.solicitud.cert.status === "pending")
                    return this.$t("solicitudesMain.estadoPorFirmarInfo");
                else if (this.solicitud.tipo === "nac" && this.solicitud.cert && this.solicitud.cert.status === "signing")
                    return this.$t("solicitudesMain.estadoFirmandoInfo");
                else
                    return this.$t("solicitudesMain.estadoAceptadaInfo");
            }
            if (sol.est == "En conflicto")
                return  this.$t("solicitudesMain.estadoEnConflictoInfo");
            if (sol.est == "En edición")
                return  this.$t("solicitudesMain.estadoEdicionInfo");
            if (sol.est == "En revisión")
                return  this.$t("solicitudesMain.estadoRevisionInfo");
            if (sol.est == "Cerrada")
                return  this.$t("solicitudesMain.estadoCerradaInfo");
            return "";
        },
        async handleModalVacunaOpened(){
            if (!this.sortedVacunasHomologacionNac.length) {
                this.loadingVacunas = true;
                let snapshot = await this.$firebase.database().ref("vacunas").orderByChild("show_hmlg_nac").equalTo(true).once("value");
                this.$store.commit("setWhatTo", { what: "vacunasHomologacionNac", to: snapshot.val() || {} });
                this.loadingVacunas = false;
            }
        },
        async handleAddVacunaSimple(vacuna) {
            try {
                const index = this.solicitud.vacs_opt.vacs.findIndex((v) => v === vacuna);
                console.log(index);
                if (index > -1) {
                    throw new Error(this.$t("solicitudNacCreate.vacunaYaAgregada"));
                }
                this.solicitud.vacs_opt.vacs.push(vacuna);
                await this.handleUpdateField("vacs_opt", this.solicitud.vacs_opt);
                this.modalSimpleVacunaOpened = false;
            } catch (error) {
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        async handleDeteleOneVacSimple(index) {
            this.$f7.dialog.confirm(
                this.$t("solicitudNacCreate.eliminarVacuna"),
                this.$t("commons.areYouSure"),
                async () => {
                    try {
                        this.$f7.dialog.preloader(this.$t("commons.eliminando"));
                        
                        await this.handleUpdateField("vacs_opt", this.solicitud.vacs_opt);

                        this.solicitud.vacs_opt.vacs.splice(index, 1);

                        this.$f7.dialog.close();
                    } catch (error) {
                        console.error(error.message);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(error.message, error.code);
                    }
                });
            console.log(index);
        },
        async handleCountrySelected(id) {
            try {
                const country = this.paisesApostilla.find(country => country.id === id);
                console.log("country",country);
                if (!country) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(this.$t("homologacionCreate.paisNotFound"));
                    return;
                }
                this.$f7.dialog.preloader(this.$t("solicitudNacCreate.guardandoCambios"));
                
                await this.handleUpdateField("actApos", country);
        
                this.$set(this.solicitud, "actApos", country);
                this.$f7.dialog.close();
            } catch (error) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        async handleChangeApostilla(checked) {
            try {
                if (checked) {
                    this.$set(this.solicitud, "reqApos", true);
                    this.$set(this.solicitud, "actApos", {
                        id: null,
                        descripcion: null,
                    });
                    await this.fetchPaisesApostilla();
                } else {
                    this.$f7.dialog.preloader(this.$t("solicitudNacCreate.guardandoCambios"));
                    await this.handleUpdateField("reqApos", null);

                    this.$delete(this.solicitud, "reqApos");
                    this.$delete(this.solicitud, "actApos");
                    this.$f7.dialog.close();
                }
            } catch (error) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message);
            }
        },
        async fetchPaisesApostilla() {
            try {
                if (!this.$store.getters.paisesApostillaArraySorted.length) {
                    this.$f7.dialog.preloader(this.$t("commons.cargandoPaises"));
                    const response = await this.commonExecute({}, "mevacuno-getListaPaisesApostilla");

                    if (response.error) {
                        throw new Error(response.error.message || response.error.code || response.error);
                    }

                    this.$store.commit("setWhatTo", { what: "paisesApostilla", to: response.payload });

                    this.$f7.dialog.close();
                }
            } catch (error) {
                console.error(error.message);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
    }
}
</script>
<style>
#myfilerx-input {
    display: none;
}
.messenger-notes {
    max-height: 150px;
    overflow-y: scroll;
}
.vaccine-card-img {
    height: 100%;
    width: 100%;
}
.popover-vaccine-card-example {
    --f7-popover-width: 600px;
}
.vaccine-card-img-thumb {
    max-width: 100%;
}
@media (max-width: 768px) {
    .vaccine-card-img-thumb {
        max-width: 100%;
    }
    .segunda-col {
        --f7-block-margin-vertical: 0;
    }
}
.overflow-text {
    --f7-block-title-white-space: normal;
}
.separator {
    height: 2px;
    width: 100%;
    background-color: #000;
    margin: 3px 0;
}

.lista-margin {
    --f7-list-item-media-margin: 0px;
    --f7-list-item-padding-vertical: 2px;
    --f7-list-item-min-height: 35px;
    --f7-list-bg-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
}

.info-text {
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 500;
    color: #333;
    padding: 0.5rem 0.3rem;
}

.popover-info {
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}
</style>