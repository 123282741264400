<template>
    <f7-page  name="homologacion-view">
        <f7-navbar :title="$t('homologacionView.navTitle')" back-link>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
        </f7-navbar>

        <f7-block>
            <f7-list media-list>
                <f7-list-item
                    v-for="(vacuna, key) in solicitud.vacs"
                    :key="key"
                >
                    <b slot="header" class="text-color-green">
                        <font-awesome-icon 
                            class="fa-md" 
                            icon="check-circle"
                        ></font-awesome-icon>
                        {{ vacuna.est.resol === "Aceptada" ? $t("homologacionCreate.aceptada") : $t("homologacionCreate.rechazada") }}
                    </b>

                    <b slot="title">{{ vacuna.vac.id ? `${vacuna.vac.name} - ${vacuna.vac.labName}` : `${$t("homologacionCreate.vacuna")}: -` }}</b>
                    
                    <span
                        slot="subtitle" 
                        style="font-size: 13px;"
                    >
                        {{ $t('homologacionCreate.paisDeAdministracion') }}: {{ vacuna.p.name || "-" }}<br>
                        {{ $t('homologacionCreate.establecimiento') }}: {{ vacuna.establecimiento || "-" }}<br>
                        {{ $t('homologacionCreate.lote') }}: {{ vacuna.lote || "-" }}<br>
                    </span>
                    <span
                        slot="footer" 
                        style="font-size: 13px;"
                    >
                        {{ $t("homologacionCreate.vacunaStatusWithDate", { decision: vacuna.est.resol === "Aceptada" ? $t("homologacionCreate.aceptada").toLowerCase() : $t("homologacionCreate.rechazada").toLowerCase(), date: $d($dayjs.unix(vacuna.est.when), 'twoDigitsWithTime') }) }}
                    </span>
                </f7-list-item>
            </f7-list>
        </f7-block>
    </f7-page>
</template>

<script>
export default {
   data(){
       return {
           solicitud:{}
       }
   },
    methods: {
       
    }
}
</script>